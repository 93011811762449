import * as action from "./action";
import { BASE_URL } from "../../../apis/backEnd.apis";
import { team } from "./payload";
import { config } from "../../../config";
var request = require("request");
var token = config.apiAccessKey;

export const fetching = () => ({ type: action.FETCHING });
export const success = () => ({ type: action.SUCCESS });
export const _error = msg => ({ type: action.ERROR, payload: msg });
//export const onEnableDisableClick = (values) => {return{type:action.ENABLE_DISABLE_TEAM,payload:values}}
export const searchTeam = values => {
  return { type: action.SEARCH_TEAM, payload: values };
};
export const onEditTeamIconClick = values => {
  return { type: action.ON_EDIT_TEAM, payload: values };
};
export const onSaveTeamIconClick = values => {
  return { type: action.ON_SAVE_TEAM, payload: values };
};
export const handleEditInputChange = values => {
  return { type: action.HANDLE_EDIT_INPUT, payload: values };
};

export const addTeam = data => (dispatch, getState) => {
  dispatch(fetching());
  addCampaignTeam(data, (error, response, body) => {
    let res = JSON.parse(response.body);
    console.log(res.error);
    if (!res.error) {
      let res = JSON.parse(response.body);
      // alert(res.message)
      getTeams(
        data.organizationId,
        data.campaignId,
        (error, response, body) => {
          if (!error) {
            let res = JSON.parse(response.body);
            console.log(res);
            if (res.success) {
              let teams = res.data;
              for (let i = 0; i < teams.length; i++) {
                teams[i]["edit"] = false;
              }
              dispatch({ type: "TEAM_SUCCESS", payload: teams });
            }
            setTimeout(() => {
              dispatch(success());
            }, 4000);
            // dispatch(success());
          } else {
            // dispatch(_error());
            dispatch({ type: "TEAM_FAILED", payload: "Can't get Teams" });
          }
        }
      );
    } else {
      console.log("error on add team ==== >", error);
      //   dispatch(_error());
      dispatch({ type: "TEAM_FAILED", payload: res.error });
      setTimeout(() => {
        dispatch(success());
      }, 4000);
    }
  });
};

export const verifyPageName = data => (dispatch, getState) => {
  var { organizationId, campaignId, teamPageName } = data;
  dispatch(fetching());
  request(
    {
      method: "GET",
      url:
        BASE_URL +
        "campaigns/campaigns/VerifyTeamPageRoute/" +
        organizationId +
        "/" +
        campaignId +
        "/" +
        teamPageName,
      headers: { "access-token": `${token}` }
    },
    (error, response, body) => {
      if (!error) {
        let res = JSON.parse(response.body);
        //let state = getState().teamManagementReducer;
        if (!res.isVerifiedRoute) dispatch({ type: "INVALID_PAGE_ERROR" });
        else dispatch({ type: "VALID_PAGE" });
        dispatch(success());
      } else {
        dispatch(_error());
      }
    }
  );
};

const addCampaignTeam = (data, callback) => {
  const { organizationId, campaignId } = data;
  var payload = JSON.stringify(team(data));
  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
      },
      url:
        BASE_URL +
        "campaigns/campaigns/teamsOld/" +
        organizationId +
        "/" +
        campaignId,
      body: payload
    },
    callback
  );
};

const getTeams = (organizationId, campaignId, callback) => {
  console.log(
    BASE_URL + "campaigns/campaigns/teams/" + organizationId + "/" + campaignId
  );
  request(
    {
      method: "GET",
      url:
        BASE_URL +
        "campaigns/campaigns/teams/" +
        organizationId +
        "/" +
        campaignId,
      headers: { "access-token": `${token}` }
    },
    callback
  );
};

export const fetchTeams = (organizationId, campaignId) => (
  dispatch,
  getState
) => {
  if (campaignId === "none") return dispatch(_error());
  dispatch(fetching());
  getTeams(organizationId, campaignId, (error, response, body) => {
    if (!error) {
      console.log("GETTING TEAMS--->", response);
      let res = JSON.parse(response.body);
      console.log(res);
      if (res.success) {
        let teams = res.data;
        for (let i = 0; i < teams.length; i++) {
          teams[i]["edit"] = false;
        }
        dispatch({ type: "TEAM_SUCCESS", payload: teams });
      }
      dispatch(success());
    } else {
      dispatch(_error());
    }
  });
};

export const deleteTeam = teamToDelete => (dispatch, getState) => {
  dispatch(fetching());
  var { organizationId, campaignId, teamId } = teamToDelete;
  console.log(
    BASE_URL +
      "campaigns/campaigns/teams/" +
      organizationId +
      "/" +
      campaignId +
      "/" +
      teamId
  );
  request(
    {
      method: "DELETE",
      url:
        BASE_URL +
        "campaigns/campaigns/teams/" +
        organizationId +
        "/" +
        campaignId +
        "/" +
        teamId,
      headers: { "access-token": `${token}` }
    },
    function(error, response, body) {
      if (!error) {
        let res = JSON.parse(response.body);
        let state = getState().teamManagementReducer;
        let teamObject = Object.assign({}, state.teams);
        let teams = Object.keys(teamObject).map(i => teamObject[i]);
        teams = teams.filter(i => i.id !== teamId);
        dispatch({ type: "TEAM_SUCCESS", payload: teams });
        dispatch(success());
        alert(res.message);
      } else {
        dispatch(_error());
      }
    }
  );
};

export const saveEditedTeam = data => (dispatch, getState) => {
  const { organizationId, campaignId, team } = data;
  dispatch(fetching());
  var payload = JSON.stringify({
    goalAmount: team.goalAmount,
    isActive: team.isActive,
    teamName: team.teamName,
    imageURL1: team.imageURL1,
    videoURL1: team.videoURL1,
    text1: team.text1,
    avatar: team.avatar,
    firstName: team.firstName,
    lastName: team.lastName,
    email: team.email,
    phone: team.phone,
    description: team.description,
  });

  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
      },
      url:
        BASE_URL +
        "campaigns/campaigns/teams/" +
        organizationId +
        "/" +
        campaignId +
        "/" +
        team.id,
      body: payload
    },
    function(error, response, body) {
      if (!error) {
        let res = JSON.parse(response.body);

        if (res.success) {
          alert(res.message);
          getTeams(organizationId, campaignId, (error, response, body) => {
            if (!error) {
              let getRes = JSON.parse(response.body);
              if (getRes.success) {
                let teams = getRes.data;
                for (let i = 0; i < teams.length; i++) {
                  teams[i]["edit"] = false;
                }
                dispatch({ type: "TEAM_SUCCESS", payload: teams });
              }
              dispatch(success());
            } else {
              dispatch(_error());
            }
          });
          dispatch(success());
        } else {
          alert(res.message);
          dispatch(_error());
        }
      } else {
        dispatch(_error());
      }
    }
  );
};

export const onEnableDisableClick = data => (dispatch, getState) => {
  const { organizationId, campaignId, team } = data;
  let payload = {
    goalAmount: team.goalAmount,
    isActive: !team.isActive,
    teamName: team.teamName,
    imageURL1: team.imageURL1,
    videoURL1: team.videoURL1,
    text1: team.text1,
    avatar: team.avatar,
    shareGoal: team.shareGoal,
    firstName : team.firstName,
    lastName : team.lastName,
    phone: team.phone,
    email: team.email,
  };

  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
      },
      url:
        BASE_URL +
        "campaigns/campaigns/teams/" +
        organizationId +
        "/" +
        campaignId +
        "/" +
        team.id,
      body: JSON.stringify(payload)
    },
    function(error, response, body) {
      if (!error) {
        let res = JSON.parse(response.body);

        if (res.success) {
          alert(res.message);
          getTeams(organizationId, campaignId, (error, response, body) => {
            if (!error) {
              console.log("GETTING TEAMS--->", response);
              let getRes = JSON.parse(response.body);
              console.log(getRes);
              if (getRes.success) {
                let teams = getRes.data;
                for (let i = 0; i < teams.length; i++) {
                  teams[i]["edit"] = false;
                }
                dispatch({ type: "TEAM_SUCCESS", payload: teams });
              }
              dispatch(success());
            } else {
              dispatch(_error());
            }
          });
          dispatch(success());
        } else {
          alert(res.message);
          dispatch(_error());
        }
      } else {
        dispatch(_error());
      }
    }
  );
};
