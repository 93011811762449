export const currencies = [{
  name: 'United States Dollar (USD)',
  code: 'usd',
  sign: '$'
}, {
  name: 'Canadian Dollar (CAD)',
  code: 'cad',
  sign: '$'
}, {
  name: 'Israeli Shekel (ILS)',
  code: 'ils',
  sign: '₪'
}, {
  name: 'Great Britain Pound (GBP)',
  code: 'gbp',
  sign: '£'
}, {
  name: 'European Euro (EUR)',
  code: 'eur',
  sign: '€'
}, {
  name: 'Australian Dollar (AUD)',
  code: 'aud',
  sign: '$'
}, {
  name: 'Mexican Peso (MXN)',
  code: 'mxn',
  sign: '$'
}, {
  name: 'Brazilian Real (BRL)',
  code: 'brl',
  sign: 'R$'
}, {
  name: 'South African Rand (ZAR)',
  code: 'zar',
  sign: 'R'
}, {
  name: 'Russian Ruble (RUB)',
  code: 'rub',
  sign: 'р.'
}]