import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as teamActions from '../../redux/modules/team';
import * as campaignActions from '../../redux/modules/campaign';
import { fetchDonations } from '../../redux/modules/TeamLeadership/action-creator';
import { bindActionCreators } from 'redux';
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import { Title } from './Title';
import { Member } from './Member';
import { Donor } from './Donor';
import { Calculator } from '../../components/Calculator/Calculator';
import PreCampaign from '../../components/PreCampaign';
import MobileCalculator from '../../components/Calculator/Responsive/MobileCalculator';
import TeamDetails from '../../components/TeamDetail';
import CampaignItems from "../../components/CampaignItems";
import { Summary } from "../../components/CampaignItems/Summary";
import Error from '../../components/Notification/Error';
import { ProgressLoader } from "../../components/Loader";
import { currencies } from "../../Common/currencies";
import { getExchangeRate } from "../../Common/Exchange";
import { CampaignVideo } from "../Campaign/CampaignVideo";

import $ from 'jquery';
import './Team.scss';
import { NumberFormat } from '../../Common/NumberFormat';

// import animationImage from '../../assets/images/building-only.png';
import animationImage from '../../assets/images/building-onlyupdated.png';

import arrow from '../../assets/images/arrow.png';
import ImageGallery from 'react-image-gallery';
const images = [
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F52e5ee19-cdf4-4b63-81eb-e197c60e7448.jpg?alt=media&token=0ca49a43-e7c0-493a-9b35-d295d638175c',
    thumbnail: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F52e5ee19-cdf4-4b63-81eb-e197c60e7448.jpg?alt=media&token=0ca49a43-e7c0-493a-9b35-d295d638175c',
  },
];
const mobileBanner = [{
  original: 'https://firebasestorage.googleapis.com/v0/b/pledjeapi-prod.appspot.com/o/CampaignPicture%2F767701a3-527a-4364-a382-b0e86c6a901a.jpg?alt=media&token=2cdb03b3-b7fa-48ba-98c2-d80d90532961',
  thumbnail: 'https://firebasestorage.googleapis.com/v0/b/pledjeapi-prod.appspot.com/o/CampaignPicture%2F767701a3-527a-4364-a382-b0e86c6a901a.jpg?alt=media&token=2cdb03b3-b7fa-48ba-98c2-d80d90532961'
}];


class Team extends Component {
  constructor() {
    super();

    this.state = {
      error: '',
      notify: false,
      team: {},
      details: {},
      total_donation: 0,
      imageURL: '',
      responsive_calculator: false,
      donors: [],
      team_donation: 0,
      donate: [],
      donationType: "oneTime",
      loadingPercent: 0,
      currency: {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      },
      videoModal: false
    }

  }

  componentWillMount = () => {
    const { match: { params: { campaign, team } }, onTeamActions, history, onCampaignActions, fetchTopTeams } = this.props;

    if (campaign.success && campaign.data) {
      this.setState({ loadingPercent: 100 });
      const { data } = campaign;
      const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
      this.setState({ currency })
    } else {
      onTeamActions.getTeam(campaign, team)
        .then(res => {
          const { data, data: { campaignId, organizationId, teamInfo } } = res.data;
          getExchangeRate(res.data.data.campaignCurrency || res.data.data.currency || 'usd');
          this.setState({ loadingPercent: 100 })
          if (data === 'No Records Found For That Route') {
            this.setState({ error: `No Record Found for ${campaign}!`, notify: true });
            setTimeout(() => {
              history.push('/');
            }, 2000)
          } else {
            onTeamActions.teamDonation(organizationId, campaignId, teamInfo.id);
            onCampaignActions.totalDonation(organizationId, campaignId);
            onCampaignActions.getDonations(organizationId, campaignId);
            onCampaignActions.getItems(organizationId, campaignId);
            fetchTopTeams(organizationId, campaignId, res.data.data.Teams, res.data.data.matchFactor)
            setInterval(() => {
              onTeamActions.teamDonation(organizationId, campaignId, teamInfo.id);
              onCampaignActions.totalDonation(organizationId, campaignId);
              onCampaignActions.getDonations(organizationId, campaignId);
              fetchTopTeams(organizationId, campaignId, res.data.data.Teams, res.data.data.matchFactor)
            }, 40000);
          }
        })
        .catch(err => {
          this.setState({ loadingPercent: 100 })
          this.setState({ error: 'No Record Found!', notify: true });
        });
    }
  }

  componentDidUpdate(oldProps) {
    const { campaign } = this.props;

    if (oldProps.campaign !== campaign) {
      const { data } = campaign;
      const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
      this.setState({ currency })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { team: { data }, total_donation, donations, team_donation } = nextProps;
    // -------For AAnimation campaign only------
    let oldDonors = this.props.donations;
    // console.log('old>>', oldDonors.data, 'newdoation>>>>', donations.data)
    let tempOldDonorsHold = new Array();
    let tempNewDonorsHold = new Array();
    let animationDonorsArr = [];
    let newDonationsCount = 0;
    let currentTeamId = (data.teamInfo && (Object.keys(data.teamInfo).length)) ? data.teamInfo.id : '';
    tempOldDonorsHold = oldDonors.data ? oldDonors.data : [];
    tempNewDonorsHold = donations.data ? donations.data : [];
    //Preparing data for animation after loading**************
    newDonationsCount = (tempNewDonorsHold.length - tempOldDonorsHold.length);
    if (tempOldDonorsHold.length !== 0) {
      for (let i = 1; i <= newDonationsCount; i++) {
        if ((parseInt(tempNewDonorsHold[i].amount) >= 500) && (tempNewDonorsHold[i].teamId == currentTeamId)) {
          animationDonorsArr.push(tempNewDonorsHold[i - 1]);
        }
      }
    }
    //******************** */

    // ---------------
    if (typeof data === 'object') {
      const { teamInfo } = data;
      this.setState({
        team: teamInfo,
        title: data.name,
        video: data.videoURL,
        powerDonars: data.superDonors,
        details: data,
        imageURL: data.mainContent.imageURL
      });
      if (data.status === 'pre-campaign' && new Date() < new Date(data.preCampaignEndDate)) {
        this.setState({ open: true });
      }
    }
    if (team_donation.data) {
      this.setState({ team_donation: team_donation.data.total });
    }
    if (total_donation.data) {
      this.setState({ total_donation: total_donation.data.total });
    }
    if (donations.data) {
      this.setState({ donors: donations.data });
    }
  }

  openCalculator = () => {
    this.setState({ responsive_calculator: true });
  }

  closeCalculator = () => {
    this.setState({ responsive_calculator: false });
  }

  donate = donated => {
    this.setState({ donate: donated });
  };

  checkout = total => {
    const { onCampaignActions, history } = this.props;
    const {
      donate,
      details: { matchFactor },
      donationType,
      currency
    } = this.state;

    if (!total) {
      this.setState({
        error: `Your shopping cart is empty. Please choose items to donate or enter a valid general donation amount to proceed. Thanks You!`,
        notify: true
      });

      return;
    }

    const data = {
      amount: total,
      donation_type: donationType,
      matchFactor,
      selectedItems: donate,
      duration: donationType === "monthly" ? 10 : 10,
      currency,
      cart: true
    };

    onCampaignActions.setDonation(data);
    history.push("/checkout");
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCurrency = (c) => {
    this.setState({ currency: c });
  }

  openVideoModal = () => {
    this.setState({
      videoModal: true
    });
  }

  closeVideoModal = () => {
    this.setState({
      videoModal: false
    });
  }

  render() {
    const {
      details,
      notify,
      error,
      team,
      open,
      responsive_calculator,
      total_donation,
      team_donation,
      donors,
      loadingPercent,
      donationType,
      imageURL,
      currency,
      donate
    } = this.state;
    const { onCampaignActions, history, donationDetails, items } = this.props;


    // new code added for bonus round matchfactor update--------------
    const timeRemaining = new Date(details.campaignEndTime) - new Date();
    // const isBonusRoundActive = total_donation > parseInt(details.goalAmount) && timeRemaining >= 0;

    //new calculation to add shareamount to active bonus ----

    let shareGoal2 = details && details.shareGoal ? parseInt(details.shareGoal) : 0;
    let shareAmount2 = details && details.shareAmount ? parseInt(details.shareAmount) : 0;
    let shareCount2 = details && details.shareCount ? parseInt(details.shareCount) : 0;

    let totalCampaignDonationIncludingShareAmount = shareCount2 >= shareGoal2 ? (total_donation + shareAmount2) : total_donation;

    //---------

    const isBonusRoundActive = totalCampaignDonationIncludingShareAmount > parseInt(details.goalAmount);
    // console.log("bt>>team page", totalCampaignDonationIncludingShareAmount)
    // -------------------------------------


    // For animation campaign only-------------------------
    let showAnimationCampaign = details.campaignPage ? ((details.campaignPage.toLowerCase() == "ccla" || details.campaignPage.toLowerCase() == "mpcorpnew") ? true : false) : false;
    let tempAnimationArr = [];
    let teamId = team && Object.keys(team).length ? team.id : '';
    let margins = 0;
    let totalTeamDonationAchieved = team_donation ? parseInt(team_donation) : 0;
    let teamGoalAmount = team.goalAmount ? parseInt(team.goalAmount) : 0;
    let video = details.videoURL ? details.videoURL : '';
    let originalAmount = 0;

    let achivedPercentage = ((parseInt(totalTeamDonationAchieved)) * 100) / teamGoalAmount;
    achivedPercentage = achivedPercentage >= 100 ? 100 : achivedPercentage;
    let finalMargin = 87 - ((parseInt(achivedPercentage) * 80) / 100)
    margins = Math.ceil(finalMargin).toString();
    if (showAnimationCampaign) {
      donors.filter((item, index) => {
        originalAmount = (parseInt(item.amount)) / parseInt(details.matchFactor);
        if ((parseInt(originalAmount) >= 500 && teamId == item.teamId)) {
          let result = Math.floor((parseInt(originalAmount)) / 500);
          tempAnimationArr.push(item);
        }
      });
    }
    // ----------------------------------------------------
    return (
      <div>
        {!showAnimationCampaign ?
          <div className="team_wrapper">
            <ProgressLoader loadingPercent={loadingPercent}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{team.teamName ? `${details.name} - ${team.teamName}` : ''}</title>
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <div className="wrapper">
                <div className="content">
                  <Title title={details.name} logo={details.organizationLogo} />
                  <Member team={team} status={details.status} />
                  <Donor details={details} />
                  <img className='team-img' src={imageURL} alt='Team' />
                  {Object.keys(details).length > 0 &&
                    details.mode === "e-commerce" &&
                    items.length > 0 ? (
                    <>
                      <CampaignItems
                        handleChange={this.handleChange}
                        donationType={donationType}
                        handleDonate={this.donate}
                        donate={donate}
                        items={items}
                        details={details}
                        onCampaignActions={onCampaignActions}
                        history={history}
                        handleCurrency={this.handleCurrency}
                      />
                      <Hidden mdUp>
                        <Summary
                          donate={donate}
                          details={details}
                          checkout={this.checkout}
                          currency={currency}
                        />
                      </Hidden>
                    </>
                  ) : (
                    // <img className="camp-img" src={imageURL} alt="Campaign" />
                    <p></p>

                  )}
                </div>
                {Object.keys(details).length > 0 && <TeamDetails donationDetails={donationDetails} details={details} team={team} donation={total_donation} team_donation={team_donation} donors={donors} history={history} />}
              </div>
              <div style={{ clear: 'both' }}></div>
              {Object.keys(details).length > 0 &&
                (details.mode === "e-commerce" ? (
                  <Hidden smDown>
                    <Summary
                      donate={donate}
                      details={details}
                      checkout={this.checkout}
                      currency={currency}
                    />
                  </Hidden>
                ) : (
                  details.hideCalculator ?
                    new Date() < new Date(details.campaignEndTime) ?
                      <Calculator
                        onCampaignActions={onCampaignActions}
                        history={history}
                        details={details}
                        isBonusRoundActive={isBonusRoundActive}
                      />
                      :
                      null
                    :
                    <Calculator
                      onCampaignActions={onCampaignActions}
                      history={history}
                      details={details}
                      isBonusRoundActive={isBonusRoundActive}
                    />
                ))
              }
              {details.mode !== "e-commerce" && (
                details.hideCalculator ?
                  new Date() < new Date(details.campaignEndTime) ?
                    <div className="mobile_donate" onClick={this.openCalculator}>
                      <p style={{ textAlign: "center" }}>DONATE NOW</p>
                    </div>
                    :
                    null
                  :
                  <div className="mobile_donate" onClick={this.openCalculator}>
                    <p style={{ textAlign: "center" }}>DONATE NOW</p>
                  </div>
              )}
              {open && <PreCampaign onCampaignActions={onCampaignActions} teamPage={true} history={history} details={details} />}
              {responsive_calculator && details.mode !== "e-commerce" &&
                <MobileCalculator history={history} handleClose={this.closeCalculator} open={responsive_calculator} details={details} isBonusRoundActive={isBonusRoundActive} />
              }
              <Error open={notify} message={error} handleClose={this.handleNotification} />
            </ProgressLoader>
          </div>
          :
          // for animation campaign only july 2020 , do not edit this block for other campaigns ------------------
          <div className="team_wrapper">
            <ProgressLoader loadingPercent={loadingPercent}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{team.teamName ? `${details.name} - ${team.teamName}` : ''}</title>
                <link rel="canonical" href={window.location.href} />
              </Helmet>

              <div className="banner">
                <ImageGallery items={images} onClick={this.openVideoModal} showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false} />;
              </div>
              <div className="banner-for-mobile">
                <ImageGallery items={mobileBanner} onClick={this.openVideoModal} showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false} />;
              </div>

              {this.state.videoModal ? <div id='description' className="description block description-popup">
                <span className="btn-close" onClick={this.closeVideoModal}><i className="fa fa-times"></i></span>
                {video && (
                  <CampaignVideo displayImg={true} video={video} status={details.status} location='' preCampaignEnd={details.preCampaignEndDate} />
                )}
              </div> : ""}
              {this.state.videoModal ? <div className="mask"></div> : ""}
              <div className="wrapper">
                <div className="content">
                  <Title title={details.name} logo={details.organizationLogo} />
                  <Member team={team} status={details.status} showAnimationCampaign={showAnimationCampaign} />
                  {/* <Donor details={details} /> */}
                  {/* put animation building here--------------------- */}
                  <div className="wrapper team-wrapper">
                    <div className="content">
                      <div className="image-section">
                        <img src={animationImage} className="animationImage" />
                        <div className="xDollar-outer">
                          <div className="xDollar">
                            {
                              tempAnimationArr.map((item, index) => {
                                return (
                                  <span>
                                    <span className="name" title={item.alias == 'Anonymous' ? 'Anonymous' : item.name}>{item.alias == 'Anonymous' ? 'Anonymous' : item.name}</span>
                                    <span className="dollor">${NumberFormat(parseInt(item.amount || 0))}</span>
                                  </span>
                                )
                              })
                            }
                          </div>
                        </div>
                        <div className="percentage">
                          <span className="full">100%</span><span>90%</span><span>80%</span><span>70%</span><span>60%</span><span className="half">50%</span><span>40%</span><span>30%</span><span>20%</span><span>10%</span><span>0%</span>
                        </div>
                        <div className="progress-bar"></div>
                        <img style={{ top: `${margins}%` }} className="per-arrow" src={arrow} />
                      </div>
                    </div>
                  </div>
                  {/* -------------------------------- */}
                  <img className='team-img' src={imageURL} alt='Team' style={{ marginTop: '0' }} />
                  {Object.keys(details).length > 0 &&
                    details.mode === "e-commerce" &&
                    items.length > 0 ? (
                    <>
                      <CampaignItems
                        handleChange={this.handleChange}
                        donationType={donationType}
                        handleDonate={this.donate}
                        donate={donate}
                        items={items}
                        details={details}
                        onCampaignActions={onCampaignActions}
                        history={history}
                        handleCurrency={this.handleCurrency}
                      />
                      <Hidden mdUp>
                        <Summary
                          donate={donate}
                          details={details}
                          checkout={this.checkout}
                          currency={currency}
                        />
                      </Hidden>
                    </>
                  ) : (
                    // <img className="camp-img" src={imageURL} alt="Campaign" />
                    <p></p>

                  )}
                </div>
                {Object.keys(details).length > 0 && <TeamDetails donationDetails={donationDetails} details={details} team={team} donation={total_donation} team_donation={team_donation} donors={donors} history={history} />}
              </div>
              <div style={{ clear: 'both' }}></div>
              {Object.keys(details).length > 0 &&
                (details.mode === "e-commerce" ? (
                  <Hidden smDown>
                    <Summary
                      donate={donate}
                      details={details}
                      checkout={this.checkout}
                      currency={currency}
                    />
                  </Hidden>
                ) : (
                  details.hideCalculator ?
                    new Date() < new Date(details.campaignEndTime) ?
                      <Calculator
                        onCampaignActions={onCampaignActions}
                        history={history}
                        details={details}
                        isBonusRoundActive={isBonusRoundActive}
                      />
                      :
                      null
                    :
                    <Calculator
                      onCampaignActions={onCampaignActions}
                      history={history}
                      details={details}
                      isBonusRoundActive={isBonusRoundActive}
                    />
                ))
              }
              {details.mode !== "e-commerce" && (
                details.hideCalculator ?
                  new Date() < new Date(details.campaignEndTime) ?
                    <div className="mobile_donate" onClick={this.openCalculator}>
                      <p style={{ textAlign: "center" }}>DONATE NOW</p>
                    </div>
                    :
                    null
                  :
                  <div className="mobile_donate" onClick={this.openCalculator}>
                    <p style={{ textAlign: "center" }}>DONATE NOW</p>
                  </div>
              )}
              {open && <PreCampaign onCampaignActions={onCampaignActions} teamPage={true} history={history} details={details} />}
              {responsive_calculator && details.mode !== "e-commerce" &&
                <MobileCalculator history={history} handleClose={this.closeCalculator} open={responsive_calculator} details={details} isBonusRoundActive={isBonusRoundActive} />
              }
              <Error open={notify} message={error} handleClose={this.handleNotification} />
            </ProgressLoader>
          </div>
          // -------animation campaign block ends----------------------------------------------------
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    team: state.team.get('team_data'),
    team_donation: state.team.get('team_donation'),
    total_donation: state.campaign.get('total_donation'),
    donations: state.campaign.get('donations'),
    donationDetails: state.teamLeadershipReducer.donationDetails,
    items: state.campaign.get("items")
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onTeamActions: bindActionCreators(teamActions, dispatch),
    onCampaignActions: bindActionCreators(campaignActions, dispatch),
    fetchTopTeams: bindActionCreators(fetchDonations, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
