import React from 'react';
import { NumberFormat } from '../../../Common/NumberFormat';
import { SocialIcons } from '../../../components/SocialPlugins/SocialPlugins';

// import './SocialGoals.scss';
import "../../../components/CampaignDetails/SocialGoals.scss";

export const SocialShareLatest = ({ details, desc, shares, imageUrl, handleShare, sign }) => {
  let showAnimationCampaign = (details && (Object.keys(details).length) && ((details.campaignPage.toString() == 'ccla') || (details.campaignPage.toString() == 'mpcorpnew'))) ? true : false;

  return (
    <section>
      {!showAnimationCampaign ? <div>
        {shares && parseInt(shares.shareCount) >= parseInt(shares.shareGoal) ? <div className="shr_trsur_box">
          <p>Social Share Goal of 111 </p>
          <h2><span>{shares && Object.keys(shares).length > 0 ? NumberFormat(details.shareGoal) : 0}</span><span> Shares </span></h2>
          <div className="max-share-reached">
            <div className="reached_hsr">
              <h1>{sign}{details.socialShareAmount}</h1>
              <p>Funded</p>
            </div>
            <span><img src="/images/trophy.png" alt="Trophy" /></span>
          </div>
        </div> :
          <div className='share-inprogress'>
            <div className='title'>HELP US RECEIVE AN <b>ADDITIONAL</b></div>
            <h1>{sign}{details.socialShareAmount}</h1>
            <div className='shares-needed'><b><span className='count'>{shares && Object.keys(shares).length > 0 ? shares.shareGoal : 0}</span> SOCIAL SHARES</b> ARE NEEDED</div>
            <div className='bottom-label'>TO RECEIVE THIS DONATION111</div>
          </div>}
        <div id="social-widget">
          <SocialIcons o_id={details.organizationId} c_id={details.campaignId} title={details.name} desc={desc} imageUrl={imageUrl} handleShare={handleShare} />
          <div className="total_share">
            <div className="tot_shr_get">
              <span id="result_share_get">{details.shareCount}/{details.shareGoal}</span>
            </div>
            Total shares
        </div>
        </div>
      </div> :
        // show social icons  for animation campaign july 2020------------
        <div id="social-widget">
          <SocialIcons o_id={details.organizationId} c_id={details.campaignId} title={details.name} desc={desc} imageUrl={imageUrl} handleShare={handleShare} />
        </div>}
      {/* --------------------- */}
    </section>
  );
}
