import React, { Component } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { currencies as currencyList } from "../../Common/currencies";
import { convertCurrency } from "../../Common/CurrencyCalculator";
import './Summary.scss';

class Summary extends Component {
  constructor() {
    super();

    this.state = {
      currency: {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      }
    }
  }

  componentWillMount() {
    const { details } = this.props;
    let c = '';

    if (details.campaignCurrency) {
      c = currencyList.find(e => e.code === details.campaignCurrency);
    } else {
      c = currencyList.find(e => e.code === details.currency);
    }
    this.setState({ currency: c });
  }

  componentDidUpdate(oldProps) {
    const { currency } = this.props;

    if (oldProps.currency !== currency) {
      this.setState({ currency });
    }
  }

  render() {
    const { donate, details, checkout } = this.props;

    const { currency } = this.state;
    let total = 0;
    let mobileTotal = 0;
    let price = 0;
    let mobilePrice = 0;

    return (
      <div id='donation-summary'>
        <Hidden smDown>
          <section className='donation-summary common-style fixed'>
            <div className='suggestion'>YOU MAY SPREAD YOUR TOTAL DONATION OVER <b>10 MONTHS</b> IN THE CHECKOUT PAGE</div>
            <div className='flex-row checkout-info'>
              <div className='items-total flex-column'>
                {donate.length > 0 ? donate.map(d => {
                  price = convertCurrency(d.price, currency.code);
                  total = d.count ? parseFloat(d.count) * parseFloat(price) + total : parseFloat(d.price) + total;
                  return (
                    <div key={d.title} className='item flex-row'>
                      <label className='title'>{d.title}</label>
                      {d.title === 'general donation' ?
                        <span>{currency.sign}{d.price}</span> :
                        <div className='calc flex-row'>
                          <span>{d.count}</span>
                          <label>x</label>
                          <span>{currency.sign}{price}</span>
                        </div>
                      }
                    </div>
                  )
                }) :
                  <div className='empty-cart'>
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    YOUR SHOPPING CART IS EMPTY
                  </div>
                }
              </div>
              <div className='right-sec flex-row'>
                <label className='total-donation flex-column'>Total Donation
                  <span>{currency.sign}{total}</span>
                </label>
                {details.matchFactor > 1 ?
                  <>
                    <div className='matching flex-column'>
                      <label>Matched</label>
                      <div className='flex-row factor'>
                        <span>x</span>
                        <div>{details.matchFactor}</div>
                      </div>
                    </div>
                    <div className='eq-sign'>=</div>
                  </>
                  : null}
                <div className='grand-total flex-column'>
                  {details.name} Receives:
                  <span>{currency.sign}{total * details.matchFactor}</span>
                </div>
                <div className='checkout-btn'>
                  <button onClick={() => checkout(total)}>CHECKOUT</button>
                </div>
              </div>
            </div>
          </section>
        </Hidden>
        <Hidden mdUp>
          <section className='mobile-donation-summary common-style flex-column'>
            {details.mode === 'e-commerce' && <div className='checkout-btn checkout-fixed'>
              <div className='items-total flex-column'>
                {donate.length > 0 ? donate.map(d => {
                  mobilePrice = convertCurrency(d.price, currency.code);
                  mobileTotal = d.count ? parseFloat(d.count) * parseFloat(mobilePrice) + mobileTotal : parseFloat(d.price) + mobileTotal;
                  return (
                    <div key={d.title} className='item flex-row'>
                      <label className='title'>{d.title}</label>
                      {d.title === 'general donation' ?
                        <span>{currency.sign}{d.price}</span> :
                        <div className='calc flex-row'>
                          <span>{d.count}</span>
                          <label>x</label>
                          <span>{currency.sign}{mobilePrice}</span>
                        </div>
                      }
                    </div>
                  )
                }) :
                  <div className='empty-cart'>
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    YOUR SHOPPING CART IS EMPTY
                  </div>
                }
              </div>
              <div className='total flex-row'>
                <label className='total-donation flex-column'>Total Donation
                  <span>{currency.sign}{mobileTotal}</span>
                </label>
                {details.matchFactor > 1 ?
                  <>
                    <div className='matching flex-column'>
                      <label>Matched</label>
                      <div className='flex-row factor'>
                        <span>x</span>
                        <div>{details.matchFactor}</div>
                      </div>
                    </div>
                    <div className='eq-sign'>=</div>
                  </>
                  : null}
                <div className='grand-total flex-column'>
                  {details.name} Receives:
                  <span>{currency.sign}{mobileTotal * details.matchFactor}</span>
                </div>
              </div>
              <button onClick={() => checkout(mobileTotal)}>CHECKOUT</button>
            </div>}
          </section>
        </Hidden>
      </div>
    );
  }
}

export { Summary };
