import { createAction } from "redux-actions";
import axios from "axios";
import * as action from "./actions";
import { BASE_URL } from "../../../apis/backEnd.apis";
import { config } from "../../../config";

var request = require("request");
const token = config.apiAccessKey;

export const fetching = () => ({ type: action.FETCHING });
export const success = () => ({ type: action.SUCCESS });
export const _error = msg => ({ type: action.ERROR, payload: msg });
export const searchUser = values => {
  return { type: action.SEARCH_USER, payload: values };
};

export const setVolunteer = info => {
  return {
    type: "VOLUNTEER_INFO",
    payload: info
  };
};

const getList = (organizationId, dispatch) => {
  dispatch(fetching());
  
  request(
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
      },
      url: BASE_URL + "users/users/" + organizationId
    },
    (error, response) => {
      if (!error) {
        let data = JSON.parse(response.body).data;
        dispatch(success());
        dispatch({ type: action.GET_USERS, payload: data });
      } else {
        console.log(error);
        dispatch(_error());
      }
    }
  );
};

export const fetch = organizationId => (dispatch, getState) => {
  getList(organizationId, dispatch);
};

const addUser = data =>
  axios.post(`${BASE_URL}users/user`, data.user, {
    headers: { "Content-Type": "application/json", "access-token": `${token}` }
  });

export const add = createAction(action.SUCCESS, addUser);

export const update = data => dispatch => {
  dispatch(fetching());
  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
      },
      body: JSON.stringify(data.user),
      url: BASE_URL + "users/user/" + data.user.userId
    },
    (error, response) => {
      if (!error) {
        let res = JSON.parse(response.body);
        dispatch(success());
        getList(data.user.organizationId, dispatch);
      } else {
        console.log(error);
        dispatch(_error());
      }
    }
  );
};

export const resetPassword = data => (dispatch, getState) => {
  console.log(
    BASE_URL +
    "users/user/reset-password" +
    ":for the email address:" +
    data.userEmail
  );
  console.log(data.userEmail);
  dispatch(fetching());
  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
      },
      body: JSON.stringify(data),
      url: BASE_URL + "users/user/reset-password"
    },
    (error, response) => {
      if (!error) {
        let res = JSON.parse(response.body);
        alert(res.message);
        dispatch(success());
        getList(data.organizationId, dispatch);
      } else {
        console.log(error);
        dispatch(_error());
      }
    }
  );
};

export const remove = data => (dispatch, getState) => {
  dispatch(fetching());
  request(
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      url: BASE_URL + "users/user/" + data.userid,
      "access-token": `${token}`
    },
    (error, response) => {
      if (!error) {
        let res = JSON.parse(response.body);;
        dispatch(success());
        getList(data.organizationId, dispatch);
      } else {
        console.log(error);
        dispatch(_error());
      }
    }
  );
};
