
export const FETCH_PROSPECTS = "FETCH_PROSPECTS"
export const CLEAR_FILTER = "CLEAR_FILTER"
export const APPLY_FILTER = "APPLY_FILTER"
export const SELECT = "SELECT"
export const ADD_PROSPECT = "ADD_PROSPECT"
export const FETCHING = "PROS/FETCHING"
export const SUCCESS = "PROS/SUCESS"
export const REFRESH = "PROS/REFRESH"
export const ERROR = "PROS/ERROR"
export const UPDATE_LIST = "PROS/UPDATE_LIST"
export const SET_PROSPECT_ID = "PROS/SET_PROSPECT_ID"
export const REMOVE_PROSPECT_ID = "PROS/REMOVE_PROSPECT_ID"
export const SEARCH_PROSPECT = "SEARCH_PROSPECT"
export const GET_PROSPECT = "GET_PROSPECT"
export const FILTER_BY_ASSIGNMENT = "FILTER_BY_ASSIGNMENT"
