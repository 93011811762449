import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,

} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import {
  fetchCampaignsForCapture,
  fetchCampaigns,
  getCampaigns,
  searchCampaign,
  getReport,
  filterReports,
  setInitial,
  updateDonation,
  handleCapture
} from "../../redux/modules/CampaignReports/action-creator";
import CampaignHeader from "./CampaignHeader";
import Donors from './Donors';
import Captured from './Captured';
import './index.css';
import { getCampaign, totalDonation, totalDonationsForCapturing,captureDonations } from "../../redux/modules/campaign";
import { fetchTeams } from "../../redux/modules/TeamManagement/action-creator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getExchangeRate } from "../../Common/Exchange";
class CaptureDonations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: '',
      toggleView: false,
      campaigns:[],
      campaignReports: [],
      selected: [],
      captured: [],
      recurring: [],
      loading:true,
      value:null,
      initState : false,
      captureLoader:false
    }
    if(this.props.authUser.role ==="super-admin")
    {
      this.props.fetchCampaignsForCapture(this.props.authUser.organizationId);
    }
    else
    {
      this.props.fetchCampaigns(this.props.authUser.organizationId);
    }
    this.loader=false;
  }

  handleCapture = () => {
    const{captureDonations} = this.props;
    const {selected,campaignReports} = this.state;
    let array =[];
    campaignReports.map((item,index)=>
    {
      if(selected[index])
      {
        array.push(item.transactionId);
      }
    })
    console.log("go" , array)
    captureDonations(this.state.campaign.organizationId,this.state.campaign.campaignId,array);
    this.setState({
      captureLoader: true
    })
  }

  toggleCapture = (view) => {
    if (!view) {
      this.setState({
        toggleView: true
      })
    }
  }

  toggleDonations = () => {
    this.setState({
      toggleView: false
    })

  }

  componentDidUpdate(oldProps) {
    const { setInitial } = this.props;
    if(this.state.value != null)
    {
      setInitial(this.state.value);
    }
    const newProps = this.props;
  
    if (oldProps.campaignReports !== newProps.campaignReports ) {
      if(this.state.value == null && this.state.initState)
      {
        this.setState({
          loading:false,
          initState:false
        })
      }
    }
    

    if (oldProps.initialCampaign !== newProps.initialCampaign ) {
      if(this.state.value != null)
      {
        this.setState({
          value:null
        })
      }
      else{
        this.setState({ loading: false });
      }
      getExchangeRate(newProps.initialCampaign.campaignCurrency || newProps.initialCampaign.currency || 'usd');
      this.setState({ campaign: newProps.initialCampaign }, () => {
        newProps.getCampaign(this.state.campaign.campaignPage).then(res => {
          newProps.totalDonation(res.data.data.organizationId, res.data.data.campaignId || this.state.campaign.Id);
          this.props.fetchTeams(
            this.state.campaign.organizationId,
            this.state.campaign.Id || this.state.campaign.campaignId
          );
          this.props.getReport(
            this.state.campaign.organizationId,
            this.state.campaign.Id || this.state.campaign.campaignId
          );
          this.setState({
            initState:true
          })
        });
      });
    }
  }

  onFormInputValueChange = e => {
    this.loader=true;
    const { setInitial } = this.props;
    const { value } = e.target;
    if(value != this.state.campaign)
    {
      this.setState({
        loading:true,
        value
      })
    }
    //setInitial(value);
  };

  setSelected = (index) => {
    this.setState(state => {
      const list = state.selected.map((item, i) => {
        if (index === i) {
          return !item;
        } else {
          return item;
        }
      })
      return { selected: list };
    });
  }

  componentWillReceiveProps(props) {
    if (props.campaignReports) {      
        let arr = [], captured = [], recurring = [];
        props.campaignReports.map(val => {
          if(val.status == "Pending")
          {
            arr.push(true);
          }
          else
          {
            arr.push(false);
          }
          if (val.status == "Success") {
            captured.push(val)
          }
          if(val.mode == "Recurring")
          {
            recurring.push(val);
          }

        })
      
        this.setState({
          campaignReports: props.campaignReports,
          selected: arr,
          captured,
          recurring
        })
      

    }
  }

  render() {
    const { allDonations } = this.props;
    const { campaignReports,loading, selected, captured, uncaptured } = this.state;
    const {
      toggleView,
      campaigns,
      tableWidth
    } = this.state;

    // if(campaigns.length > 0)
    // {
    //   camps = campaigns;
    // }
    return (
      <div className="spinner-overlay">
         <LoadingOverlay active={loading || this.state.captureLoader} spinner text="Loading">
           <CampaignHeader
           onFormInputValueChange={this.onFormInputValueChange}
           campaignList={this.props.campaigns}
           campaign={this.state.campaign}
           toggleCapture={this.toggleCapture}
           view={toggleView}
         /> 
        {!toggleView ? <Donors organizationId ={this.state.campaign.organizationId} handleChange={this.setSelected} handleCapture={this.handleCapture} selected={selected} donations={campaignReports} /> :
          <Captured campaign={this.state.campaign} toggleDonations={this.toggleDonations} recurring={this.state.recurring} donations={captured} />}
      </LoadingOverlay>
      </div>
    );
  }
}

CaptureDonations.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    campaignReports: state.campaignReportReducer.campaignReports || [],
    filteredReports: state.campaignReportReducer.filteredReports,
    searchItem: state.campaignReportReducer.searchItem,
    campaigns: state.campaignReportReducer.campaigns,
    allCampaigns:state.campaignReportReducer.allCampaigns,
    authUser: state.userAuthReducer.authUser,
    initialCampaign: state.campaignReportReducer.initialCampaign,
    campaign: state.campaign.get("campaignData"),
    total_donation: state.campaign.get("total_donation"),
    allDonations: state.campaign.get("allDonations"),
    teams: state.teamManagementReducer.teams
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCampaigns,
      searchCampaign,
      getReport,
      filterReports,
      getCampaign,
      fetchCampaignsForCapture,
      totalDonation,
      totalDonationsForCapturing,
      setInitial,
      fetchTeams,
      updateDonation,
      handleCapture,
      captureDonations
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaptureDonations);


