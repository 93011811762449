import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox, TablePagination, TableSortLabel, withStyles } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {getStatusColor} from './helperFuntions';
import './ProspectTable.scss';

const columns = [
  { id: 'all', label: 'All' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'action', label: 'Action' },
  { id: 'notes', label: 'Notes' },
  { id: 'status', label: 'Status' },
  { id: 'suggestedDonation', label: 'Suggested Amount' },
  { id: 'donationAmount', label: 'Donated Amount' },
  { id: 'user', label: 'Assigned To' },
  { id: 'paymentType', label: 'Donation Method' },
  { id: 'archive', label: 'Archive' },
]

const paperStyle = {
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  }
};

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class ProspectTable extends Component {
  constructor() {
    super();

    this.state = {
      order: 'asc',
      orderBy : 'donationAmount'
    }
  }

  handleRequestSort = (e) => {
    const { order, orderBy } = this.state;
    let newOrder = 'desc';

    if (orderBy === e && order === 'desc') {
      newOrder = 'asc';
    }

    this.setState({ order: newOrder, orderBy: e });
  };

  renderHeader = (prospects) => {
    const { order, orderBy } = this.state;
    const { selectedProspects, onAllProspects } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map((row, i) => (
            row.label === 'All' ?
            <TableCell component='th' key={i}>
              <div>
                <Checkbox checked={selectedProspects.length === prospects.length} onChange={(e) => onAllProspects(e, prospects)} icon={<CheckBoxOutlineBlankIcon fontSize="small"/>} checkedIcon={<CheckBoxIcon className='checkbox' fontSize="small" />}/>
                {row.label}
              </div>
            </TableCell> :
            <TableCell component='th' key={i} style={{ whiteSpace: (i === 6 || i === 7) && 'normal', paddingRight: (i === 5 || i === 6 )&& '24px'  }} >
              { row.label === 'Suggested Amount' || row.label === 'Donated Amount' ?
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={() => this.handleRequestSort(row.id)}
                  IconComponent = {() => order === 'asc' ?
                    <i className="fa fa-caret-square-o-down sort-icon" aria-hidden="true" /> :
                    <i className="fa fa-caret-square-o-up sort-icon" aria-hidden="true" />
                  }
                >
                  {row.label}
                </TableSortLabel> :
                row.label
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  scrollTable = () => {
    const empty = document.getElementById('empty-wrapper');
    const table = document.getElementById('prospect-table-container');

    empty.scrollLeft = table.scrollLeft;
  }

  render() {
    const { classes, handleChangePage, rowsPerPage, page, onCheckedboxClick, onDeleteProspectClick,
    showArchivedProspects, editProspectModal, selectedProspects } = this.props;
    let { prospects } = this.props;
    const { order, orderBy } = this.state;
    if(JSON.parse(localStorage.getItem('userInfo')).role === "volunteer")
    {
      prospects = prospects.filter(item => item.userId === JSON.parse(localStorage.getItem('userInfo')).uid)
    }
    let archivedProspects = prospects.filter((prospect => prospect.status === 'Archive'))
    let otherProspects =  prospects.filter((prospect => prospect.status !== 'Archive'))
    prospects =  showArchivedProspects ? archivedProspects : otherProspects;
    const sorted = stableSort(prospects, getSorting(order, orderBy));

    return (
      <Paper className='table-section'>
        <div onScroll={this.scrollTable} id='prospect-table-container' className='prospect-table-container' style={{ marginBottom: prospects.length > rowsPerPage && 40 }}>
          <Table id='prospect-table'>
            {this.renderHeader(prospects)}
            <TableBody>
              { sorted
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prospect,index) => (
                  <TableRow key={index} style = {{ backgroundColor :  index % 2 === 0 ? '#fafafa' : '#fff'}}>
                    <TableCell>
                      <Checkbox
                        checked={selectedProspects.indexOf(prospect.id) > -1}
                        onChange = {(e) => onCheckedboxClick(e, prospect)}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon className='checkbox' fontSize="small" />}
                      />
                    </TableCell>
                    <TableCell component="th" >{prospect.firstName}</TableCell>
                    <TableCell component="th">{prospect.lastName}</TableCell>
                    <TableCell component="th">
                      <div className ="pointer prospectBtn" onClick={() => editProspectModal(prospect, prospects)} >Access Prospect</div>
                    </TableCell>
                    <TableCell >   
                      <div style ={{textAlign : 'center' , background:'#aaabaa' , width : 30, height : 30, borderRadius : 30, display : 'flex' , justifyContent : 'center' , alignItems : 'center'}}><FontAwesomeIcon icon={faEdit} color ='white' size='lg'/></div>
                    </TableCell>
                    <TableCell component="th" style={{ paddingRight: 24, color : prospect.status === 'Clear' ? '#000' : '#fff' , background: getStatusColor(prospect.status) }} >{prospect.status}</TableCell>
                    <TableCell component="th" style={{ paddingRight: 24 }}>{prospect.suggestedDonation ? `${prospect.sign}${prospect.suggestedDonation}` : '-'}</TableCell>
                    <TableCell component="th">{prospect.donationAmount ? `${prospect.sign}${prospect.donationAmount}` : '-'}</TableCell>
                    <TableCell component="th">{prospect.user}</TableCell>
                    <TableCell component="th">{prospect.paymentType ? prospect.paymentType : '-'}</TableCell>
                    <TableCell component="th"><FontAwesomeIcon icon={faTrashAlt} color ='#aaabaa'  onClick ={(event) => onDeleteProspectClick(event, prospect.id)}/></TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </div>
        { prospects.length > rowsPerPage && <TablePagination
            classes={{ caption: classes.caption, toolbar: classes.toolbar }}
            className ="pldj-pagination"
            rowsPerPageOptions={[]}
            component="div"
            count={prospects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          />
        }
      </Paper>
    );
  }
}

export default withStyles(paperStyle)(ProspectTable);