import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import axios from 'axios';

import './campaignreport.scss';
import './CampaignTable.scss';
import './custom.scss';
import moment from 'moment';
import { convertCurrencyNew } from './../../Common/CurrencyCalculator';


import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

let rows = [
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'lastName', numeric: false, disablePadding: true, label: 'Last Name' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'donationDate', numeric: true, disablePadding: false, label: 'Time of Donation' },
  { id: 'alias', numeric: true, disablePadding: false, label: 'Alias/Anonymous' },
  { id: 'donationAmount', numeric: false, disablePadding: true, label: 'Donation Amount' },
  { id: 'donationMatched', numeric: true, disablePadding: false, label: 'Donation Matched' },
  { id: 'totalDonation', numeric: true, disablePadding: false, label: 'Total Before Match' },
  { id: 'totalDonationInUSD', numeric: true, disablePadding: false, label: 'Converted Currency to USD' },
  { id: 'paymentType', numeric: true, disablePadding: false, label: 'Payment Method' },
  { id: 'teamId', numeric: false, disablePadding: true, label: 'Team Member' },
  { id: 'mode', numeric: true, disablePadding: false, label: 'Payment Mode' },
  { id: 'transactionCostCovered', numeric: true, disablePadding: false, label: 'Transaction Covered' },
  { id: 'transactionCost', numeric: true, disablePadding: false, label: 'Transaction Cost' },
  { id: 'transactionId', numeric: true, disablePadding: false, label: 'Transaction ID' },
  { id: 'billingPhone', numeric: true, disablePadding: false, label: 'Phone' },
  { id: 'billingAddress', numeric: true, disablePadding: false, label: 'Address' },
  { id: 'dedicationMessage', numeric: true, disablePadding: false, label: 'Dedication Message' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Donation Status' },
  { id: 'raffle', numeric: false, disablePadding: false, label: 'Raffle Categories' },
  { id: 'specialNotes', numeric: false, disablePadding: false, label: 'Special Instrctions' },
  { id: 'items', numeric: false, disablePadding: false, label: 'Items Donated' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'zip', numeric: false, disablePadding: false, label: 'Zip Code' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
];

class CampaignTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, fields } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              fields[row.id] &&
              <TableCell
                key={row.id}
                className="campaign-table-cell"
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  IconComponent={() => order === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }
}

CampaignTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

const styles = () => ({
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  }
});

class CampaignTable extends React.Component {
  constructor() {
    super()
    this.finalExcelArr = [];
    this.currencyRates = {};
    this.state = {
      order: 'asc',
      orderBy: 'aliasName',
      selected: [],
      page: 0
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc')
      order = 'asc';

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  scrollTable = () => {
    const empty = document.getElementById('empty-wrapper');
    const table = document.getElementById('campaign-report-table');

    empty.scrollLeft = table.scrollLeft;
  }

  capital_letter = (str) => {
    str = str.split(" ");
    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = (str[i][0] ? str[i][0].toUpperCase() : "") + str[i].substr(1);
    }
    return str.join(" ");
  }

  componentDidMount = () => {
    let symbols = ['USD', 'EUR', 'GBP', 'ILS', 'MXN', 'CAD', 'AUD', 'BRL', 'ZAR', 'RUB'];
    axios.get(`https://api.exchangeratesapi.io/latest?base=USD&symbols=USD,EUR,GBP,ILS,MXN,CAD,AUD,BRL,ZAR,RUB`)
      .then(res => {
        console.log('-------------------', res.data.rates);
        this.currencyRates = res.data.rates ? res.data.rates : {};
      });

  }


  groupBy1 = (cars) => {

    const result = cars.reduce(function (r, a) {
      r[a.teamId] = r[a.teamId] || [];
      r[a.teamId].push(a);
      return r;
    }, Object.create(null));
    this.finalExcelArr = [];
    for (let i in result) {

      if (result[i][0].teamData2) {
        this.finalExcelArr.push({
          teamId: i,
          teamName: result[i][0].teamData2.teamName
        });
      }
    }

  };

  filterArchivedDonations(campaignReports) {
    let filterdDonation = [];
    campaignReports.map(donation => {
      if (donation.originalStatus.trim().toUpperCase() != 'ARCHIVED') {
        filterdDonation.push(donation);
      }
    });
    return filterdDonation;
  }


  render() {
    const { classes, /*campaignReports,*/ rowsPerPage, fields, onEditDonation } = this.props;
    const { order, orderBy, selected, page } = this.state;

    //type casting for proper sorting on donationAmount
    let { campaignReports } = this.props;
    const exportArr = [];

    // let filterdDonations = this.filterArchivedDonations(campaignReports);
    // campaignReports = [];
    // campaignReports = filterdDonations;
    console.log(">>>>>>>>>>>>>111111111", campaignReports);
    // this.groupBy1(campaignReports)
    for (let loop = 0; loop < campaignReports.length; loop++) {

      // exportArr.push({
      //   teamId: campaignReports[loop].teamId,
      //   teamName: campaignReports[loop].teamData2 && campaignReports[loop].teamData2.teamName
      // })



      if ((isNaN(campaignReports[loop].donationDate))) {
        // let timestamp = Date.parse(campaignReports[loop].donationDate);
        // campaignReports[loop].donationDate = moment(timestamp).format('MM/DD/YYYY ,hh:mm:ss A');
      }

      campaignReports[loop].firstName = campaignReports[loop].firstName ? this.capital_letter(campaignReports[loop].firstName.trim()) : "";
      campaignReports[loop].lastName = campaignReports[loop].lastName ? this.capital_letter(campaignReports[loop].lastName.trim()) : "";
      campaignReports[loop].alias = campaignReports[loop].alias ? this.capital_letter(campaignReports[loop].alias.trim()) : "";
      campaignReports[loop].email = campaignReports[loop].email ? campaignReports[loop].email.toLowerCase().trim() : "";

      // let currencySignMP = campaignReports[loop].donationAmount.charAt(0);
      let currencySignMP = campaignReports[loop].sign;
      let monthCount = 1;
      let donationAmtMonthly = 1;

      campaignReports[loop].donationMatchedMonthly = parseFloat(campaignReports[loop].donationMatched.toFixed(2));

      if (campaignReports[loop].mode.trim().toUpperCase() == "RECURRING") {
        let tmpArr = campaignReports[loop].donationAmount.split('x');
        tmpArr.push(campaignReports[loop].donationMatched);
        if (tmpArr.length > 2) {
          donationAmtMonthly = tmpArr[2];
          monthCount = parseInt(tmpArr[1].replace('months', '').trim());
        }
        if (donationAmtMonthly && monthCount) {
          let newVal = (donationAmtMonthly * monthCount).toFixed(2);
          campaignReports[loop].donationMatchedMonthly = parseFloat(newVal);
        }

      }

      campaignReports[loop].donationAmount = String(campaignReports[loop].donationAmount);
      let numericVal = parseFloat(campaignReports[loop].donationAmount.replace(currencySignMP, '')).toFixed(2);
      numericVal = isNaN(numericVal) ? 0 : numericVal;

      campaignReports[loop].donationAmountNVal = numericVal;
      campaignReports[loop].currencySignMP = currencySignMP;
      campaignReports[loop].monthCount = monthCount;

      campaignReports[loop].totalDonation = parseFloat((campaignReports[loop].donationAmountNVal * campaignReports[loop].monthCount).toFixed(2));

      let currencyRecieved = campaignReports[loop].currency || 'usd';
      let currencyUsed = currencyRecieved.toUpperCase();
      campaignReports[loop].totalDonationInUSD = campaignReports[loop].totalDonation;
      if (currencyUsed != 'USD') {
        campaignReports[loop].totalDonationInUSD = parseFloat((campaignReports[loop].totalDonation / this.currencyRates[currencyUsed]).toFixed(2));
      }

    }
    //type casting for proper sorting on donationAmount

    return (
      <Paper className='table-root'>


        {/* <ExcelFile element={<button>Download Data11111111</button>}>
          <ExcelSheet data={this.finalExcelArr} name="Employees">
            <ExcelColumn label="teamId" value="teamId" />

            <ExcelColumn label="teamName" value="teamName" />
          </ExcelSheet>
        </ExcelFile> */}







        <div onScroll={this.scrollTable} id='campaign-report-table' className='table-body' style={{ marginBottom: campaignReports.lenght > rowsPerPage && 40 }} >
          <Table id='report-table' className='report-table' aria-labelledby="tableTitle">
            <CampaignTableHead
              fields={fields}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={campaignReports.length}
            />
            <TableBody>
              {stableSort(campaignReports, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  return (
                    <TableRow
                      key={index}
                      style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }}
                    >
                      {fields.firstName && <TableCell component="th" scope="row" className="campaign-table-cell">{n.firstName}</TableCell>}
                      {fields.lastName && <TableCell className="campaign-table-cell">{n.lastName}</TableCell>}
                      {fields.email && <TableCell className="campaign-table-cell">{n.email}</TableCell>}
                      {fields.donationDate && <TableCell className="campaign-table-cell">{moment(n.donationDate).format('MM/DD/YYYY ,hh:mm:ss A')}</TableCell>}
                      {fields.alias && (<TableCell className="campaign-table-cell">{n.alias ? n.alias : null}</TableCell>)}
                      {fields.donationAmount && <TableCell component="th" className="campaign-table-cell nb-white-space">{n.sign}{n.donationAmount}</TableCell>}
                      {fields.donationMatched && <TableCell className="campaign-table-cell">{n.currencySignMP + "" + n.donationMatchedMonthly}</TableCell>}
                      {/* {fields.totalDonation && <TableCell className="campaign-table-cell">{n.currencySignMP+""+(n.donationAmountNVal*n.monthCount).toFixed(2)}</TableCell>} */}
                      {fields.totalDonation && <TableCell className="campaign-table-cell">{n.currencySignMP + "" + n.totalDonation}</TableCell>}
                      {fields.totalDonationInUSD && <TableCell className="campaign-table-cell">{'$' + "" + n.totalDonationInUSD}</TableCell>}
                      {fields.paymentType && <TableCell className="campaign-table-cell">{n.paymentType === "check" ? 'pledge' : n.paymentType === "cash" ? "received" : n.paymentType}</TableCell>}
                      {fields.teamId && <TableCell className="campaign-table-cell">{n.teamData2 && n.teamData2.teamName}</TableCell>}
                      {fields.mode && <TableCell className="campaign-table-cell">{n.mode}</TableCell>}
                      {fields.transactionCostCovered && <TableCell className="campaign-table-cell">{n.transactionCostCovered}</TableCell>}
                      {fields.transactionCost && <TableCell className="campaign-table-cell">{n.transactionCost}</TableCell>}
                      {fields.transactionId && <TableCell className="campaign-table-cell">{n.transactionId}</TableCell>}
                      {fields.billingPhone && <TableCell className="campaign-table-cell">{n.billingPhone}</TableCell>}
                      {fields.billingAddress && <TableCell className="campaign-table-cell">{n.billingAddress}</TableCell>}
                      {fields.dedicationMessage && <TableCell className="campaign-table-cell">{n.dedicationMessage}</TableCell>}
                      {fields.status &&
                        <TableCell style={{ color: n.status === 'Failed' ? '#f44336' : n.status === 'Success' ? '#51b748' : '#838383', fontSize: 14 }}>{n.status}</TableCell>
                      }
                      {fields.raffle && <TableCell className="campaign-table-cell">{n.raffle}</TableCell>}
                      {fields.specialNotes && <TableCell className="campaign-table-cell">{n.specialNotes}</TableCell>}
                      {fields.items && <TableCell className="campaign-table-cell">{n.items}</TableCell>}
                      {fields.state && <TableCell className="campaign-table-cell">{n.state}</TableCell>}
                      {fields.city && <TableCell className="campaign-table-cell">{n.city}</TableCell>}
                      {fields.zip && <TableCell className="campaign-table-cell">{n.zip}</TableCell>}
                      <TableCell onClick={() => onEditDonation(n)} className="campaign-table-cell"><FontAwesomeIcon icon={faEdit} color='#aaabaa' /></TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {campaignReports.length > rowsPerPage && <TablePagination
          classes={{ caption: classes.caption, toolbar: classes.toolbar }}
          className="pldj-pagination"
          rowsPerPageOptions={[]}
          component="div"
          count={campaignReports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
        />}
      </Paper>
    );
  }
}

export default withStyles(styles)(CampaignTable);