import React, { Component } from 'react';
import { AppBar, InputBase, NativeSelect } from '@material-ui/core';
import UserList from '../../components/Dialog/UserList';
import ConfirmationDialog from '../../components/Dialog/Confirmation';
import Snackbar from '../../components/Snackbar/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import './ProspectTableHeader.scss';

class ProspectTableHeader extends Component {
  constructor() {
    super();

    this.state = {
      error: false,
      message: '',
      openUserList: false,
      openConfirmation: false,
      selectedUser: ''
    }
  }

  selectUser = (e) => {
    this.setState({ openConfirmation: true, selectedUser: e });
  }

  handleConfirmation = (confirm) => {
    this.setState({ openConfirmation: false });
    if(confirm) {
      const { selectedUser } = this.state;
      const { onAssignProspect } = this.props;
      this.setState({ openUserList: false });
      onAssignProspect(selectedUser);
    }
  }

  openList = () => {
    const { selectedProspects } = this.props;
    if(selectedProspects.length > 0) {
      this.setState({ openUserList: true });
    } else {
      this.setState({ error: true, message: 'Select one or more prospects in order for you to assign them to a volunteer' });
      setTimeout(()=>{
        this.setState({ error: false, message: '' });
      }, 5000)
    }
  }

  closeUserList = () => {
    this.setState({ openUserList: false });
  }

  render() {
    const { openUserList, error, message, openConfirmation } = this.state;
    const { searchItem, searchProspect, onFilterClick, hideFilter, rowsPerPage, searchUser,
      handleChangeRowsPerPage, users, showFilters, filteredUsers, selectedProspects, prospects, authUser } = this.props;
    const userList = filteredUsers ? filteredUsers : users;    

    return (
      <div className='prospect-table-header'>
        <AppBar position="static" className='header'>
          <div className='selectUser-container'>
            <label>Reassign to Another Volunteer</label>
            <div style={{ background: selectedProspects.length === 0 && '#b5b5b5', color: selectedProspects.length === 0 && '#4a4a4a' }} className='select-volunteer-btn' onClick={this.openList}>Select Volunteer</div>
          </div>
          <div className='filter-prospects'>
            <div className='search'>
              <InputBase className='input' placeholder="Search" name="searchItem" value={searchItem} onChange={searchProspect} />
              <i className="fa fa-search" aria-hidden="true"></i>
            </div>
            { !showFilters ?
              <span onClick={onFilterClick} className='filter'>
                <span className='text'>Filters</span>
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </span> :
              <span onClick={hideFilter} className='filter'>
                <span className='text'>Filters</span>
                <i className="fa fa-chevron-up" aria-hidden="true"></i>
              </span>
            }
          </div>
          <div className='display'>
            <div className='show'>Show
              <NativeSelect
                className='select'
                disableUnderline
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                name="viewTableRows"
              >
                <option value="15" className="view-option">15</option>
                <option value="30" className="view-option">30</option>
                <option value="60" className="view-option">60</option>
                <option value="100" className="view-option">100</option>
                <option value="150" className="view-option">150</option>
                <option value={prospects.length} className="view-option">All</option>
              </NativeSelect>
            </div>
          </div>
        </AppBar>
        { openUserList && <UserList authUser={authUser} userList={userList} searchUser={searchUser} selectUser={this.selectUser} openUserList={openUserList} closeUserList={this.closeUserList} />}
        <Snackbar
          message={message}
          color='danger'
          icon={ErrorIcon}
          place='tr'
          open={error}
        />
        <ConfirmationDialog
          dialogtitle="Reasign to Volunteer"
          dialogContent="Are you sure you want to reassign these propects"
          open={openConfirmation}
          handleConfirmation={this.handleConfirmation}
        />
      </div>
    );
  }
}

export default ProspectTableHeader;
