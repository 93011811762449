import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { TextValidator } from "react-material-ui-form-validator";

class BillingInfo extends Component {
  handleBlur = e => {
    this.refs[e.target.name].validate(e.target.value, true);
  };

  render() {
    const { inputChange, inputs } = this.props;
    const {
      billingAddress,
      billingCity,
      billingState,
      zipCode,
      mobilePhone
    } = inputs.toJS();

    return (
      <Paper className="checkout mt40">
        <div className="number">
          <span>2</span>
        </div>
        <Grid container spacing={8}>
          <h3>BILLING INFORMATION</h3>
          <Grid item xs={12}>
            <label className="form-name">
              Address <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="billingAddress"
              onBlur={this.handleBlur}
              placeholder="Address"
              className="checkout_input"
              value={billingAddress}
              name="billingAddress"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <label className="form-name">
              City/Town <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="billingCity"
              onBlur={this.handleBlur}
              placeholder="City/Town"
              className="checkout_input"
              value={billingCity}
              name="billingCity"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <label className="form-name">
              State/Provinces <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="billingState"
              onBlur={this.handleBlur}
              placeholder="State/Province"
              className="checkout_input"
              value={billingState}
              name="billingState"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <label className="form-name">
              Zip/Postal Code <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="zipCode"
              onBlur={this.handleBlur}
              placeholder="Zip/Postal Code"
              className="checkout_input"
              value={zipCode}
              name="zipCode"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label className="form-name">
              Phone <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              type="tel"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="mobilePhone"
              onBlur={this.handleBlur}
              placeholder="Phone Number"
              className="checkout_input"
              value={mobilePhone}
              name="mobilePhone"
              onChange={inputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export { BillingInfo };
