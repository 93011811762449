import React from 'react';
import { ProgressBar } from "react-step-progress-bar";
import { NumberFormat } from '../../Common/NumberFormat';
import '../../components/CampaignDetails/Progress.scss';
import "react-step-progress-bar/styles.css";
import "./progress.scss";
import heartNew from "../../assets/images/heart-new.png";
import heartPercent from "../../assets/images/heart-percent.png";
import clockNew from "../../assets/images/clock-icon.png";

import dilShadowLatest from '../../assets/images/dilShadowLatest.png';


export const getPercent = (goal, raised) => {
    const percent = (parseFloat(raised) / parseFloat(typeof goal === 'string' ? goal.replace(/,/g, '') : goal)) * 100;
    return percent.toFixed(2);
}

export const LatestProgress = ({ goalAmount, donation, bonusGoal, shares, socialShareAmount, color, sign, details }) => {

    let raised = donation || 0;
    console.log(donation, socialShareAmount, shares, "donation----new----->")
    raised = shares && parseInt(shares.shareCount) >= parseInt(shares.shareGoal) ? parseInt(raised) + parseInt(socialShareAmount) : parseInt(raised);
    const bonus = (goalAmount && raised > goalAmount);
    const check = (bonus ? <i className="fa fa-check" aria-hidden="true" style={{ left: `calc(${getPercent(bonusGoal, raised) > 100 ? 100 : getPercent(bonusGoal, raised)}% - 20px)` }}></i> : null);
    const themeColor = details && details.themeColor || "#336DFF";

    return (

        <div className="heart-percentage">
            <div className="percent">
                <img alt="heart" className="img-fluid" src={heartNew} />
                <img alt="heart-percent" className="img-fluid heart-percent" src={heartPercent} />
                <img alt="heart-shadow" className="img-fluid heart-shadow" src={dilShadowLatest} />
                <span>{raised ? getPercent(goalAmount, raised) : 0}%</span>
            </div>

            <p className="raisedAmountLatest"><span className="left-span">RAISED</span> | <span style={{ "color": themeColor }}>{sign}{NumberFormat(parseInt(raised)) || 0}</span></p>
            {/* <div className="heart-content">
                <h6>Bonus Goal $325,0000</h6>
                <h1>$324,000</h1>
            </div> */}

            {/* 
            <section>
                <p className="product_pledged" style={{ color: color ? color : '' }}>{sign}{NumberFormat(parseInt(raised)) || 0}</p>
                <ProgressBar
                    percent={getPercent((bonus ? bonusGoal : goalAmount) || 0, raised || 0) > 100 ? 100 : getPercent((bonus ? bonusGoal : goalAmount) || 0, raised || 0)}
                    filledBackground={bonus ? '#51b748' : color ? color : `#2995c3`}
                    unfilledBackground={bonus ? color ? color : "#2995c3" : "#d6d6d6"}
                    height="14px"
                    width="100%"
                >
                    {check}
                </ProgressBar>
                <span className="percent_br">{raised ? getPercent(goalAmount, raised) : 0}% </span>
            </section> */}
        </div>

        /* <div className="col-md-4">

        </div>
        <div className="col-md-4">
            <div className="clock">
                <img alt="heart" className="img-fluid" src={clockNew} />
                <div className="time">
                    <p><span className="top-span">2</span><span className="bottom-span">Days</span></p>
                    <p className="colon-box"><span className="colon"></span><span className="colon"></span></p>
                    <p><span className="top-span">02</span><span className="bottom-span">Hrs</span></p>
                    <p className="colon-box"><span className="colon"></span><span className="colon"></span></p>
                    <p><span className="top-span">30</span><span className="bottom-span">Mins</span></p>
                    <p className="colon-box"><span className="colon"></span><span className="colon"></span></p>
                    <p><span className="top-span">51</span><span className="bottom-span">Secs</span></p>
                </div>
                <p className="text">This campaign has flexible funding: the organization will receive all donations.</p>
            </div>
        </div> */
        // </div>
        // </div>
        // <section>
        //     <p className="product_pledged" style={{ color: color ? color : '' }}>{sign}{NumberFormat(parseInt(raised)) || 0}</p>
        //     <ProgressBar
        //         percent={getPercent((bonus ? bonusGoal : goalAmount) || 0, raised || 0) > 100 ? 100 : getPercent((bonus ? bonusGoal : goalAmount) || 0, raised || 0)}
        //         filledBackground={bonus ? '#51b748' : color ? color : `#2995c3`}
        //         unfilledBackground={bonus ? color ? color : "#2995c3" : "#d6d6d6"}
        //         height="14px"
        //         width="100%"
        //     >
        //         {check}
        //     </ProgressBar>
        //     <span className="percent_br">{raised ? getPercent(goalAmount, raised) : 0}% </span>

        //     {/* <span className="percent_br" style={{fontSize: "17px", color: '#3f51b5'}}><strong>Social Share Amount {sign}{socialShareAmount}</strong> </span> */}
        // </section>
    );
}
