//export const BASE_URL = "https://pledjeapi-prod.appspot.com/";
export const BASE_URL = "https://pledjeapi-270017.appspot.com/";
//export const BASE_URL = "https://test-ef716.appspot.com/";
// export const BASE_URL = "https://pledje-drafts2.appspot.com/";
// export const BASE_URL = "http://localhost:8080/";

//For production@@
//last comment testing66666 30september updated--- no need again
//LAST DEPLOY - 
//PROD -
//DRAFT
//last added on 25 dec 2020 till stripe campaign-------------