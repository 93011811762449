import { createAction, handleActions } from "redux-actions";
import * as CheckoutAPI from "../../lib/checkout";
import { Map } from "immutable";

const CHANGE_INPUT = "checkout/CHANGE_INPUT";
const INITIALIZE_FORM = "checkout/INITIALIZE_FORM";
const CHECKOUT = "checkout/CHECKOUT";

export const changeInput = createAction(CHANGE_INPUT);
export const initializeForm = createAction(INITIALIZE_FORM);
export const postCheckoutInfo = createAction(
  CHECKOUT,
  CheckoutAPI.postCheckoutInfo
);

const initialState = Map({
  inputs: Map({
    firstName: "",
    lastName: "",
    email: "",
    donorsListName: "",
    message: "",
    displayName: false,
    displayStatus: true,
    creditCardNumber: "",
    cvv: "",
    expirationMonth: "",
    expirationYear: "",
    routing_number: "",
    account_number: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    zipCode: "",
    mobilePhone: "",
    specialNotes: "",
    method: "credit card",
    processingFee: ""
  })
});

export default handleActions(
  {
    [CHANGE_INPUT]: (state, action) => {
      const { name, value, checked } = action.payload;
      if (name === 'displayName') {
        return state.setIn(["inputs", name], checked);
      }
      return state.setIn(["inputs", name], value);
    },
    [INITIALIZE_FORM]: (state, action) => {
      const initialForm = initialState.get(action.payload);
      return state.set(action.payload, initialForm);
    }
  },
  initialState
);
