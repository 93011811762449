import React from 'react';
import { NumberFormat } from '../../Common/NumberFormat';
import { getPercent } from '../CampaignDetails/Progress';

import './TeamDetails.scss';

export const CampaignGoal = ({ goal, donation, shares, sign,socialShareAmount }) => {
  let raised = donation;
  raised = shares && parseInt(shares.shareCount) >= shares.shareGoal ? raised + parseInt(socialShareAmount) : raised + 0;

  return(
    <section className="campaign-goal">
      <p className="goal">Total Campaign Goal {sign}{NumberFormat(goal)}</p>
      <p className="reached"><span>Total Raised {sign}{NumberFormat(raised) || 0}</span> | {getPercent(goal, raised)}% Reached</p>
    </section>
  );
}
