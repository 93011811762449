import React from 'react';
import { InputBase, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const tableHeaders = [ 'Date', 'User', 'Notes', '' ];
const userNotes = [{
  date: '01/17/19', user: 'Link-Admin'
}, {
  date: '01/17/19', user: 'Admin'
}, {
  date: '01/17/19', user: 'Manager'
}]

export const Notes = () => {
  return (
    <div className='notes flex-column'>
      <label className='title'>Notes</label>
      <div className='notes-content'>
        <div className='row1'>
          <label>Please add your comments here...</label>
          <InputBase
            multiline={true}
            rows={3}
            className="input"
          />
        </div>
        <Button className='blue notes-submit'>Submit</Button>
        <Table className='notes-table'>
          <TableHead>
            <TableRow>
              {tableHeaders.map(head => <TableCell padding='none' align='center' key={head}>{head}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {userNotes.map((notes, i) => 
              <TableRow key={i}>
                <TableCell padding='none' align='center' className='tc'>{notes.date}</TableCell>
                <TableCell padding='none' align='center' className='tc'>{notes.user}</TableCell>
                <TableCell padding='none' align='center' className='tc'>
                  <InputBase className='input' />
                </TableCell>
                <TableCell padding='none' align='center' className='tc'><i className="fa fa-trash-o" aria-hidden="true"></i></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
