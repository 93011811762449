import React, { Component } from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    AppBar, InputBase, NativeSelect,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper
} from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import './recurring.scss';
import moment from 'moment';
import axios from 'axios';
import LoadingOverlay from "react-loading-overlay";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Recurring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: '',
            open: false,
            loading: true,
            search: '',
            recurring: [],
            months: [],
            month:'',
            total:0
        };
        this.myRef = React.createRef();
        this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.date = new Date();
        this.month = this.date.getMonth();
    }

    componentDidMount() {
            let months = [],total=0;
            let date = new Date(this.props.campaign.campaignStartTime);
            let month = date.getMonth();
            for (let i = month; i <= this.month; i++) {
                months.push(this.months[i]);
            }
            this.props.recurring.map(item=>
                {            
                    let str = item.donationAmount.split(' months');
                    let m = parseInt(str[0].slice(-1));
                    
                    total = total + item.donationMatched;
                })
        this.setState({
            recurring: this.props.recurring,
            months,
            month:months[0],
            total:parseFloat(total/parseInt(this.props.campaign.matchFactor)).toFixed(2)
        })
    }

    details = (txnsId) => {
        let headers = {
            "Content-Type": "application/json",
            APIKEY: "bdfaff171efc326c0367107a10dd7059"
        }
        axios.get('https://test-api.payrix.com/txns/' + txnsId, { headers })
            .then(res => {
                
                this.setState({
                    payments: res.data.response.data[0],
                    loading: false
                })
            })
            .catch(err => {
            
            })
        this.setState({
            open: true,
            loading: true
        })
    }

    handleSearch = (e) => {
        const { recurring } = this.props;
        let searched = [];
        
        recurring.map(item => {
            let res1 = item.fullname.search(e.target.value);
            let res2 = item.email.search(e.target.value);
            if (res1 > -1 || res2 > -1) {
                searched.push(item);
            }
        })
        this.setState({
            search: e.target.value,
            recurring: searched
        })
    }

    select=(e)=>
    {
        
        this.setState({
            month:e.target.value
        })
    }

    componentWillReceiveProps() {
        if (this.props.campaign) {
            let months = [],total =0;
            let date = new Date(this.props.campaign.campaignStartTime);
            let month = date.getMonth();
            for (let i = month; i <= this.month; i++) {
                months.push(this.months[i]);
            }
            this.props.recurring.map(item=>
                {         
                    total = total + item.donationMatched;
                })
              
            this.setState({
                recurring: this.props.recurring,
                months,
                month:months[0],
                total:parseFloat(total/parseInt(this.props.campaign.matchFactor)).toFixed(2)
            })
        }
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        const { payments, open, loading, search, total, recurring, months } = this.state;
        return (
            <div className="main-wrap">
                <Dialog
                    fullWidth={true}
                    maxWidth='sm'
                    className='dialog dialog-width'
                    open={open}
                    TransitionComponent={Transition}
                    autoDetectWindowHeight={true}
                >
                    <DialogTitle>Recurring transaction details </DialogTitle>
                    <DialogContent>
                        <LoadingOverlay active={loading} spinner text="Loading">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <strong>Transaction ID : </strong>
                                </div>
                                <div className="col-md-6">
                                    {payments.id}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <strong>Funding Currency : </strong>
                                </div>
                                <div className="col-md-6">
                                    {payments.fundingCurrency}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <strong>Donation Date : </strong>
                                </div>
                                <div className="col-md-6">
                                    {moment(payments.created).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <strong>Last Modification Date : </strong>
                                </div>
                                <div className="col-md-6">
                                    {moment(payments.modified).format("YYYY-MM-DD HH:mm")}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Payment Approved : </strong>
                                </div>
                                <div className="col-md-6">
                                    {payments.approved / 100}
                                </div>
                            </div>
                            {/* <b></b> :   {payments.id}<br/> */}
                            {/* <b>Funding Currency</b> : <br/>
            <b>Donation Date</b> : <br/>
            <b>Last Modification Date</b> : {moment(payments.modified).format("YYYY-MM-DD HH:mm")} <br/>
            <b>Payment Approved</b> : {payments.approved/100}  */}
                        </LoadingOverlay>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
          </Button>
                    </DialogActions>
                </Dialog>
                <div className="recurring-wrap">
                    <div className="div-donation clearfix">
                        <button onClick={this.props.switch} className="capture-donation">BACK TO CAPTURED DONATIONS</button>
                    </div>

                    <div className="title-donation text-uppercase">Recurring Payments</div>
                    <>

                        <AppBar position="static" id="report-table-header">
                            <div className="table-view">
                                <div className="total-expected text-uppercase">
                                    total expected per month: {total}
                                </div>
                                <NativeSelect
                                    className="select"
                                    onChange={this.select}
                                    disableUnderline
                                    ref={this.myRef}
                                    value={this.state.month}
                                    name="viewTableRows">
                                    {months.map(item =>
                                        <option value={item}>
                                            {item}
                                        </option>
                                    )}
                                </NativeSelect>
                                <div className="donation-success text-uppercase">
                                    SUCCEsS: $900
                                </div>
                                <div className="donation-failed text-uppercase">
                                    donation failed: $900
                                </div>
                            </div>
                            <div className="search">
                                <InputBase
                                    className="input"
                                    placeholder="Search"
                                    value={search}
                                    onChange={this.handleSearch}
                                />
                                <i className="fa fa-search" aria-hidden="true" />
                            </div>
                        </AppBar>
                        <div className="table-responsive">
                            <Table className="table table-font w-100">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="nowrap">
                                            Serial Number
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donor Name
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donor Email
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Current Payment Status
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donation Date
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donation Amount
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Transaction Cost
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ cursor: 'pointer' }}>
                                    {recurring.map((item, index) =>
                                        <TableRow onClick={() => this.details(item.transactionId)}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.fullname}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>
                                                {item.status == "captured" ? <i className="fa fa-circle color-green" /> : <i className="fa fa-circle color-red" />}
                                            </TableCell>
                                            <TableCell>
                                                {moment(item.donationDate).format("YYYY-MM-DD HH:mm")}
                                            </TableCell>
                                            <TableCell>{item.donationAmount}</TableCell>
                                            <TableCell>{item.transactionCost}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>

                        {/* <div className="noDonation">No donations to display</div> */}

                    </>





                </div>
            </div>
        )
    }
}

export default Recurring;