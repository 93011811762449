import React, { Component } from 'react';
import CircularProgressbar from 'react-circular-progressbar';
// import './TeamDetails.scss';
import "../../components/TeamDetail/TeamDetails.scss";
import "./progress.scss";
import heartNew from "../../assets/images/heart-new.png";
import heartPercent from "../../assets/images/heart-percent.png";
import clockNew from "../../assets/images/clock-icon.png";
let countdown = null;

class LetestTimer extends Component {
    constructor() {
        super();

        this.state = {
            remaining: 0,
            currentTime: 0,
            rem: { hours: 0, minutes: 0, seconds: 0 }
        }
    }

    componentWillMount() {
        this.setTimer(this.props);
    }

    componentDidMount() {
        countdown = setInterval(() => {
            if (this.check_zero()) {
                this.setState(prevState => ({
                    currentTime: prevState.currentTime - 1000,
                    rem: this.getRemaining(prevState.currentTime - 1000)
                }));
            } else {
                clearInterval(countdown)
            }
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        this.setTimer(nextProps);
    }

    componentWillUnmount() {
        clearInterval(countdown);
    }

    setTimer = (props) => {
        const { startTime, endTime, bonus, bonusEnd } = props;
        const endingDate = bonus ? bonusEnd : endTime;

        this.setState({
            remaining: (new Date(endingDate || 0)) - new Date(startTime || 0),
            currentTime: (new Date(endingDate || 0)) - new Date(),
            rem: this.getRemaining(new Date(endingDate || 0) - new Date())
        })
    }

    getRemaining = (millisec) => {
        if (millisec > 0) {
            let seconds = (millisec / 1000).toFixed(0);
            let minutes = Math.floor(seconds / 60);
            let hours = "";
            let daysRemaining = 0;
            let latestHoursRemaining = 0;

            if (minutes > 59) {
                hours = Math.floor(minutes / 60);
                minutes = minutes - (hours * 60);
            }
            if (hours > 0) {
                daysRemaining = Math.floor(hours / 24);
                latestHoursRemaining = (hours % 24);
            }

            seconds = Math.floor(seconds % 60);
            if (hours !== "") {
                return {
                    hours,
                    minutes,
                    seconds,
                    daysRemaining,
                    latestHoursRemaining
                };
            }
            return {
                hours: 0,
                minutes,
                seconds,
                daysRemaining,
                latestHoursRemaining
            }
        }

        return {
            hours: 0, minutes: 0, seconds: 0
        }
    }

    check_zero = () => {
        const { rem: { hours, minutes, seconds } } = this.state;
        if (!seconds && !minutes && !hours) {
            return false;
        }
        return true
    }

    render() {
        const { remaining, currentTime, rem: { hours, minutes, seconds, daysRemaining, latestHoursRemaining } } = this.state;
        const { isMobile, showLatestUi } = this.props;

        console.log("------------------log", showLatestUi);
        // if (showLatestUi) {
        //     return (<div><h1>HI LATEST TIMER HERE111</h1></div>)
        // }
        // else {
        return (

            // <div className="container">
            //     <div className="row">
            /* <div className="col-md-4">
                     <div className="heart-percentage">
                         <div className="percent">
                             <img alt="heart" className="img-fluid" src={heartNew} />
                             <img alt="heart" className="img-fluid heart-percent" src={heartPercent} />
                             <span>25%</span>
                         </div>
                         <div className="content">
                             <h6>Bonus Goal $325,0000</h6>
                             <h1>$324,000</h1>
                         </div>
                     </div>
                 </div>
                 <div className="col-md-4">

                 </div> */
            /* <div className="col-md-4"> */
            <div className="clock">
                <img alt="heart" className="img-fluid" src={clockNew} />
                <div className="time">
                    <p><span className="top-span">{daysRemaining ? daysRemaining : '00'}</span><span className="bottom-span">Days</span></p>
                    <p className="colon-box"><span className="colon"></span><span className="colon"></span></p>
                    <p><span className="top-span">{latestHoursRemaining ? latestHoursRemaining : '00'}</span><span className="bottom-span">Hrs</span></p>
                    <p className="colon-box"><span className="colon"></span><span className="colon"></span></p>
                    <p><span className="top-span">{minutes < 10 ? `0${minutes}` : minutes}</span><span className="bottom-span">Mins</span></p>
                    <p className="colon-box"><span className="colon"></span><span className="colon"></span></p>
                    <p><span className="top-span">{seconds < 10 ? `0${seconds}` : seconds}</span><span className="bottom-span">Secs</span></p>
                </div>
            </div>
            //         </div>
            //     </div>
            // </div>




            // isMobile ?
            //     <div className="responsive-timer">
            //         <i className="fa fa-clock-o" aria-hidden="true"></i>
            //         <div className='timer'>
            //             <div className='time'>
            //                 <span>{hours < 10 ? `0${hours}` : hours}</span>
            //                 <span>:</span>
            //                 <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
            //                 <span>:</span>
            //                 <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            //             </div>
            //             <div className='labels'>
            //                 <span>Hrs</span>
            //                 <span>Min</span>
            //                 <span>Sec</span>
            //             </div>
            //         </div>
            //     </div>
            //     :
            //     <section className="team-goals">
            //         <div className="time-remaining">
            //             <img src='/images/clock.png' alt='Clock' />
            //             <CircularProgressbar
            //                 percentage={currentTime / remaining * 100}
            //             />
            //             <div className="t-timer">
            //                 <div className="hr">{hours < 10 ? `0${hours}` : hours}</div>
            //                 <div className="min">{minutes < 10 ? `0${minutes}` : minutes}</div>
            //                 <div className="sec">{seconds < 10 ? `0${seconds}` : seconds}</div>
            //             </div>
            //             <div className="t-labels">
            //                 <div className="hr">H</div>
            //                 <div className="min">M</div>
            //                 <div className="sec">SECONDS</div>
            //             </div>
            //         </div>
            //     </section>
        )
        // }
    }
}

export { LetestTimer };
