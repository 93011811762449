import axios from "axios";
import { BASE_URL } from "../apis/backEnd.apis";
import firebase from "../apis/firebase.apis";
import { config } from "../config";
import { getOrganizations } from "../redux/modules/NewCampaign/action-creator";
import { convertCurrency } from "../Common/CurrencyCalculator";
import _ from 'lodash';
import { clearCache } from './../redux/modules/CampaignReports/action-creator';
import { date } from "yup";

const token = config.apiAccessKey;
const FIRESTORE = firebase.firestore();

export const fetchCampaignFromFirebase = (organizationId, campaignId) => {
  return FIRESTORE.collection("organizations")
    .doc(organizationId)
    .get()
    .then(org => {
      let camp = org.data().campaigns[campaignId];
      camp = camp ? camp : {};
      camp.bonusGoalEndDate = camp.bonusGoalEndDate ? new Date(camp.bonusGoalEndDate) : new Date();
      camp.campaignEndTime = camp.campaignEndTime ? new Date(camp.campaignEndTime) : new Date();
      camp.campaignStartTime = camp.campaignStartTime ? new Date(camp.campaignStartTime) : new Date();
      camp.preCampaignEndDate = camp.preCampaignEndDate ? new Date(camp.preCampaignEndDate) : new Date();
      camp.preCampaignStartDate = camp.preCampaignStartDate ? new Date(camp.preCampaignStartDate) : new Date();
      camp.socialShareAmount = camp.shareAmount;
      camp.enableTransactionCost = camp.enableTransactionCost === false || camp.enableTransactionCost === true ? camp.enableTransactionCost : true;

      return {
        data: {
          data: camp,
          success: true
        }
      };
    });
};

export const getAllDonations = (organizationId, id, getAll) => {
  getAll = getAll ? true : '';
  return axios.get(`${BASE_URL}donors/donations/${organizationId}/${id}/${getAll}`, {
    headers: { "access-token": `${token}` }
  });
}

export const socialShare = (campaignId, url, image, title) => {
  return axios.get(`${BASE_URL}campaigns/socialShare`, {
    headers: { "access-token": `${token}` }
  });
}

export const getCampaign = campaign => {
  return FIRESTORE.collection("routes")
    .where("route", "==", `/${campaign.toLowerCase()}/`)
    .limit(1)
    .get()
    .then(res => {
      let route = {};
      if (res.size) {
        res.forEach(doc => {
          route = doc.data();
        });
      }

      if (route.firebase) {
        return fetchCampaignFromFirebase(
          route.organizationId,
          route.campaignId
        );
      } else {
        return axios.get(`${BASE_URL}campaigns/campaign/%2F${campaign}%2F`, {
          headers: { "access-token": `${token}` }
        });
      }
    });
};

export const getCampaignById = (oId, cId) => {
  return fetchCampaignFromFirebase(oId, cId);
};

export const totalDonationsForCapturing = (organizationId, id) => {
  return getAllDonations(organizationId, id)
    .then(res => {
      const donorsData = res.data.data.donations;
      return {
        data: donorsData
      };
    });
}

export const captureDonations = (organizationId, id, array) => {
  let data = {
    array
  }

  return axios.post(`${BASE_URL}campaigns/captureDonations/${organizationId}/${id}`, data, {
    headers: { "access-token": `${token}` }
  })
    .then(res => {
      alert("Capturing done");
      window.location.reload();
    })
    .catch(err => {
      alert("Error while capturing");
    })
}

export const totalDonation = (organizationId, id) => {
  return getAllDonations(organizationId, id)
    .then(res => {
      const donorsData = res.data.data.donations;
      let total = 0;
      donorsData.forEach(donorData => {
        donorData.amount = parseInt(donorData.amount);
        total += convertCurrency(donorData.amount, donorData.currency || 'usd');
      });

      return {
        data: {
          total: total
        }
      };
    });
}

export const getDonations = (organizationId, id) => {
  return getAllDonations(organizationId, id)
    .then(res => {
      //console.log('++++++++++++++', res);
      const donorsData = res.data.data.donations;
      const data = _.orderBy(donorsData, ['date'], ['desc']);;

      return { data };
    })
}

export const getItems = (organization, id) =>
  axios.get(`${BASE_URL}campaigns/items/${organization}/${id}`, {
    headers: { "access-token": `${token}` }
  });

export const fetchFeatureCampaigns = () => {
  return getOrganizations()
    .then(res => {
      const campaigns = res.map(e => e.campaigns);
      const featuredCampaigns = [];
      let campaign = {}
      campaigns.forEach(c => {
        for (let i in c) {
          campaign = c[i];
          if (campaign.isFeatured && (campaign.campaignEndTime && (new Date() < new Date(campaign.campaignEndTime)))) {
            featuredCampaigns.push(campaign);
          }
        }
      });

      return featuredCampaigns;
    })
}

export const fetchOrg = async (orgId) => {
  let userData = {};
  userData = await getUser(orgId);
  return FIRESTORE.collection("organizations")
    .get()
    .then((res) => {
      let orgData = {};
      getUser(orgId);
      res.forEach(doc => {
        if (orgId == doc.id) {
          orgData = doc.data();
          orgData.orgEmail = userData.email;
        }
      });
      return orgData;
    });
}

const getUser = (orgId) => {
  return FIRESTORE.collection("users")
    .where("organizationId", "==", orgId)
    .where("role", "==", 'admin')
    .get()
    .then((res) => {
      let userData = {};
      res.forEach(doc => {
        userData = doc.data();
      });
      return userData;
    });
}
