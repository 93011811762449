import React, { Component } from 'react';
import Footer from '../../components/Base/Footer';

class FooterContainer extends Component {
  render() {
    return (
      <Footer fixed/>
    );
  }
}

export default FooterContainer;