import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import HeaderComponent from '../HeaderComponent';
import TeamTable from './TeamTable';
import {fetchDonations} from '../../redux/modules/TeamLeadership/action-creator';
import { fetchCampaigns } from '../../redux/modules/CampaignReports/action-creator';
import { setInitial } from "../../redux/modules/CampaignReports/action-creator";
import TeamTableHeader from './TeamTableHeader';
import LoadingOverlay from 'react-loading-overlay';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '../../components/Snackbar/Snackbar';

class TeamLeadership extends React.Component {
  constructor(){
    super();

    this.state={ 
      rowsPerPage: 15,
      page: 0,
      campaign: ''
    }
  }

  componentDidMount() {
    this.props.fetchCampaigns(this.props.authUser.organizationId)
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.initialCampaign !== newProps.initialCampaign) {
      this.setState({ campaign: newProps.initialCampaign }, () => {
        const { campaign } = this.state;
        this.getDonations(campaign.organizationId, campaign.Id || campaign.campaignId, campaign.Teams);
      });
    }
  }

  getDonations = (organizationId, campaignId, teams) => {
    this.props.fetchDonations(organizationId, campaignId, teams)
  }

  onCampaignChange = e => {
    const { setInitial } = this.props;
    setInitial(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  showSnackBar = (message,isError) => {
    this.setState({ showMessage: true, message, error: isError })
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" })
    }, 1500)
  }

  handleChangePage = (event, page) => {
     this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: parseInt(event.target.value)});
  }
  
  render(){
    const { campaign } = this.state;
    const { authUser } = this.props;

    return (
      authUser.role !== 'volunteer' ?
      <LoadingOverlay
        active={this.props.isFetching}
        spinner
      >
        <Snackbar
          message = {this.state.message}
          color =  {this.state.error?'danger':'success'}
          icon = {this.state.error?ErrorIcon:CheckCircleIcon}
          place = 'tr'
          open =  {this.state.showMessage}
        />
        <HeaderComponent
          title1='LEADERS'
          title2='BOARD'
          baseColor="#fcbe11"
          select={true}
          campaignList={this.props.campaigns}
          campaign={campaign}
          onCampaignChange={this.onCampaignChange}
        />
        <div className="main-wrap">
          <TeamTableHeader 
            rowsPerPage = {this.state.rowsPerPage}
            handleChangeRowsPerPage = {this.handleChangeRowsPerPage}
            organizationData = {this.props.organizationData}
          />
          <TeamTable
            donationDetails={this.props.donationDetails}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            handleChangePage={(event,page)=>this.handleChangePage(event,page)}
            campaign={campaign}
          />
        </div>
      </LoadingOverlay>
      :
      null
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.teamLeadershipReducer.isFetching,
    donationDetails : state.teamLeadershipReducer.donationDetails,
    organizationData  : state.campaignReportReducer.organizationData,
    authUser : state.userAuthReducer.authUser,
    campaigns: state.campaignReportReducer.campaigns,
    initialCampaign: state.campaignReportReducer.initialCampaign,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCampaigns,
  fetchDonations,
  setInitial,
}, dispatch)

export default connect(mapStateToProps,mapDispatchToProps)(TeamLeadership);