import React, { Component } from 'react';
import Recurring from './Recurring';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    AppBar, InputBase, NativeSelect,
    Typography,
    Button,
    withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper
} from "@material-ui/core";
import './recurring.scss';
class Captured extends Component {
    constructor(props) {
        super(props);
        this.state={
            captureData:true,
            recurringData:false
        }
    }

    toggleView=()=>
    {
        this.setState({
            captureData:false,
            recurringData:true
        })
    }

    back=()=>
    {
        this.setState({
            captureData:true,
            recurringData:false
        })
    }

    render() {
        const { donations,campaign, toggleDonations,recurring } = this.props;
        if(this.state.captureData)
        {
            return (
                <div className="main-wrap">
                
                    <div className="div-donation clearfix">
                        <button className="capture-donation" onClick={this.toggleView}>RECURRING PAYMENTS SECTION</button>

                        <button className="capture-donation" onClick={toggleDonations}>BACK TO ALL DONATIONS</button>
                    </div>
    
                    <div className="title-donation">CAPTURED DONATIONS</div>
                    <>
                        {donations && donations.length > 0 ?
                            <>
                                <Table className="table-bordered">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="nowrap">
                                                Serial Number
                    </TableCell>
                                            <TableCell className="nowrap">
                                                Donor Name
                    </TableCell>
                                            <TableCell className="nowrap">
                                                Donation Date
                    </TableCell>
                                            <TableCell className="nowrap">
                                                Donation Amount
                    </TableCell>
                                        </TableRow>
                                    </TableHead>
    
                                    <TableBody>
                                        {donations.map((item, index) =>
                                            <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item.fullname}</TableCell>
                                                <TableCell>{item.donationDate}</TableCell>
                                                <TableCell>{item.donationAmount}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
    
                                </Table>
                            </> :
                            <div className="noDonation">No donations to display</div>
                        }
                    </>
                </div>)
        }
        else{
            return <Recurring campaign={campaign} recurring={recurring} switch={this.back}/>
        }
    }
}

export default Captured;