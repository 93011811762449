import React, { Component } from "react";
import { Dialog, DialogTitle, DialogActions, Grid, withMobileDialog } from "@material-ui/core";

class CalculatorSetup extends Component {
  constructor() {
    super();

    this.state = {
      setup: []
    };
  }

  componentDidMount() {
    const { calculatorSetup, mode } = this.props;
    let c = calculatorSetup;
    c = c.find(e => e.mode === mode) || {};

    this.setState({
      setup: c.setup || []
    })
  }

  handleSave = () => {
    const { setup } = this.state;
    const { saveSetup, showSnackBar, mode } = this.props;
    const calculatorSetup = [{
      mode, setup
    }]

    if (setup.length > 0) {
      saveSetup(calculatorSetup);
    } else {
      showSnackBar("Please Add at lease 1 value", true)
    }
  }

  handleChange = (e, i) => {
    const { value, name } = e.target;
    let s = [...this.state.setup];
    s[i][name] = value;

    this.setState({ setup: s });
  }

  handleAdd = () => {
    const { mode } = this.props;
    const s = [...this.state.setup];
    let info = {};
    if(mode === 'monthly') {
      info = {
        amount: 10,
        noOfMonths: 10
      }
    } else if(mode === 'oneTime') {
      info = {
        amount: 10
      }
    }

    s.push(info);
    this.setState({ setup: s });
  }

  handleRemove = (e, i) => {
    const s = [...this.state.setup];
    s.splice(i, 1);
    this.setState({ setup: s });
  }

  render() {
    const { fullScreen, handleCalculatorSetup, mode } = this.props;
    const { setup } = this.state;

    return (
      <Dialog
        id='additional-info-dialog'
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={true}
      >
        <DialogTitle className="title">
          Calculator Setup
        </DialogTitle>
        <Grid className="container" container spacing={0}>
          { setup.map((e, i) => {
            return (
              <Grid className="items closable" container key={i}>
                <div onClick={e => this.handleRemove(e, i)} className="close">x</div>
                <Grid className="item" item xs={12} sm={6}>
                  <label>Amount</label>
                  <input min="1" type="number" className="input" name="amount" onChange={e => this.handleChange(e, i)} value={e.amount} />
                </Grid>
                { mode === 'monthly' && <Grid className="item" item xs={12} sm={6}>
                  <label>No. of Months</label>
                  <input min="1" max="12" typ="number" className="input" name="noOfMonths" onChange={e => this.handleChange(e, i)} value={e.noOfMonths} />
                </Grid>}
              </Grid>
            )
          }) }
          <button onClick={this.handleAdd} className="add-btn">Add Values</button>
        </Grid>
        <DialogActions className="actions">
          <button onClick={handleCalculatorSetup}>Cancel</button>
          <button onClick={this.handleSave}>Save</button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(CalculatorSetup);
