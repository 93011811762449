import React, { Component } from "react";
import { Dialog, DialogTitle, DialogActions, Grid, withMobileDialog } from "@material-ui/core";
import { Formik } from "formik";
import { organizationSchema } from "../../YupValidation/OrganizationSchema";

import "./OrganizationForm.scss";

const initialValues = {
  organizationName: '',
  organizationAddress: '',
  organizationTaxId: '',
  organizationPhone: '',
  firstName: '',
  lastName: '',
  password: '',
  email: '',
}

class OrganizationForm extends Component {

  formSubmit = (e) => {
    const { createOrganization } = this.props;

    createOrganization(e);
  }

  render() {
    const { fullScreen, handleClose } = this.props;

    return (
      <Dialog
        id='organization-dialog'
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={true}
      >
        <DialogTitle className="title">
          Create Organization
        </DialogTitle>
        <Formik validationSchema={organizationSchema} onSubmit={this.formSubmit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid className="container" container spacing={0}>

                  <Grid className="item" item xs={12} sm={6}>
                    <label>First Name</label>
                    <input
                      className="input"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <p>{errors.firstName}</p>
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Last Name</label>
                    <input
                      className="input"
                      name="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <p>{errors.lastName}</p>
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Email</label>
                    <input
                      className="input"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <p>{errors.email}</p>
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Password</label>
                    <input
                      type="password"
                      className="input"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <p>{errors.password}</p>
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label>Organization Name</label>
                    <input
                      name="organizationName"
                      onChange={handleChange}
                      value={values.organizationName}
                      isInvalid={!!errors.organizationName}
                    />
                    <p>{errors.organizationName}</p>
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Organization Tax ID</label>
                    <input
                      name="organizationTaxId"
                      onChange={handleChange}
                      value={values.organizationTaxId}
                      isInvalid={!!errors.organizationTaxId}
                    />
                    <p>{errors.organizationTaxId}</p>
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Organization Phone</label>
                    <input
                      name="organizationPhone"
                      onChange={handleChange}
                      value={values.organizationPhone}
                      isInvalid={!!errors.organizationPhone}
                    />
                    <p>{errors.organizationPhone}</p>
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label>Organization Address</label>
                    <textarea
                      row={2}
                      name="organizationAddress"
                      onChange={handleChange}
                      value={values.organizationAddress}
                      isInvalid={!!errors.organizationAddress}
                    />
                    <p>{errors.organizationAddress}</p>
                  </Grid>
                </Grid>
                <DialogActions className="actions">
                  <button onClick={handleClose}>Cancel</button>
                  <button type="submit">Save</button>
                </DialogActions>
              </form>
            )
          }}
        </Formik>
      </Dialog>
    );
  }
}

export default withMobileDialog()(OrganizationForm);
