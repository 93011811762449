import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, TableSortLabel, withStyles } from '@material-ui/core';
import {getStatusColor} from '../helperFuntions';
import './Table.scss';

const columns = [
  { id: 'firstName', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'suggestedDonation', numeric: false, disablePadding: false, label: 'Suggested Amount' },
  { id: 'donationAmount', numeric: false, disablePadding: false, label: 'Donated Amount' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
]

const paperStyle = {
  caption: {
    fontSize: 12,
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  }
};

const desc = (a, b, orderBy) => {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class ProspectTable extends Component {
  constructor() {
    super();

    this.state = {
      order: 'asc',
      orderBy : 'firstName'
    }
  }

  handleRequestSort = (e) => {
    const { order, orderBy } = this.state;
    let newOrder = 'desc';

    if (orderBy === e && order === 'desc') {
      newOrder = 'asc';
    }

    this.setState({ order: newOrder, orderBy: e });
  };

  renderHeader = () => {
    const { order, orderBy } = this.state;

    return (
      <TableHead>
        <TableRow>
          {columns.map((row, i) => (
            <TableCell component='th' key={i} >
              { row.label === 'Name' || row.label === 'Last Name' ?
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={() => this.handleRequestSort(row.id)}
                  IconComponent = {() => order === 'asc' ?
                    <i className="fa fa-caret-square-o-down sort-icon" aria-hidden="true" /> :
                    <i className="fa fa-caret-square-o-up sort-icon" aria-hidden="true" />
                  }
                >
                  {row.label}
                </TableSortLabel> :
                row.label
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  render() {
    const { classes, handleChangePage, rowsPerPage, page, showArchivedProspects, openProspectModal, expanded } = this.props;
    let { prospects } = this.props;
    const { order, orderBy } = this.state;
    let archivedProspects = prospects.filter((prospect => prospect.status === 'Archive'))
    let otherProspects =  prospects.filter((prospect => prospect.status !== 'Archive'))
    prospects =  showArchivedProspects ? archivedProspects : otherProspects;
    const sorted = stableSort(prospects, getSorting(order, orderBy));

    return (
      <Paper className='mp-table-section'>
        <div className='mp-table' style={{ paddingBottom: prospects.lenght > rowsPerPage && 40, paddingTop: expanded ? 445 : 250 }}>
          <Table>
            {this.renderHeader()}
            <TableBody>
              { sorted
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prospect,index) => (
                  <TableRow key={index} style = {{ backgroundColor :  index % 2 === 0 ? '#fafafa' : '#fff'}}>
                    <TableCell component = "th" >{prospect.firstName}</TableCell>
                    <TableCell component = "th">{prospect.lastName}</TableCell>
                    <TableCell component = "th" style={{ color : prospect.status === 'Clear' ? '#000' : '#fff' , background: getStatusColor(prospect.status) }} >{prospect.status}</TableCell>
                    <TableCell component = "th">{prospect.suggestedDonation ? `$${prospect.suggestedDonation}` : '-'}</TableCell>
                    <TableCell component = "th">{prospect.donationAmount ? `$${prospect.donationAmount}` : '-'}</TableCell>
                    <TableCell component = "th">
                      <div className ="pointer prospectBtn" onClick={() => openProspectModal(prospect, prospects)} >Access</div>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </div>
        { prospects.length > rowsPerPage && <TablePagination
          classes={{ caption: classes.caption, toolbar: classes.toolbar }}
          className ="pldj-pagination"
          rowsPerPageOptions={[]}
          component="div"
          count={prospects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />}
      </Paper>
    );
  }
}

export default withStyles(paperStyle)(ProspectTable);