import { handleActions } from "redux-actions";
import * as action from "./action";

const initialState = {
  donationDetails: [],
  isFetching: false,
  verifyPageErr: false
};

const handlers = {
  [action.FETCHING]: (state, action) => ({
    ...state,
    isFetching: true,
    selectedToEdit: ""
  }),
  [action.SUCCESS]: (state, action) => ({ ...state, isFetching: false }),
  [action.ERROR]: (state, action) => ({ ...state, isFetching: false }),
  "DONATION_DETAILS_SUCCESS": (state, action) => ({ ...state, donationDetails: action.payload }),
  "INVALID_PAGE_ERROR": (state, action) => ({
    ...state,
    verifyPageErr: true
  }),
  "VALID_PAGE": (state, action) => ({ ...state, verifyPageErr: false })
};

export default handleActions(handlers, initialState);
