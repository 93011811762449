import { handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as action from "./action";
import { getDate } from "../../../Common/getDate";
import { getTime } from "../../../Common/getTime";
import { reportFilters } from "./reportFilters";
import { currencies } from "../../../Common/currencies"
import { stat } from "fs";

const initialState = {
  searchItem: "",
  donor: "allDonors",
  payment: "allPayments",
  campaigns: [],
  organizationData: "",
  initialCampaign: "",
  campaignReports: [],
  campaigns: [],
  filteredReports: "",
  teams: [],
  campaignData: {},
  cache: null
};

const searchReports = (state, action) => {
  state.searchItem = action.payload.e;

  return reportFilters(state);
};

const filterReports = (state, action) => {
  console.log("***" , state,action);
  const {
    payload: { donor, payment }
  } = action;
  state.donor = donor;
  state.payment = payment;

  return reportFilters(state);
};

const setReport = (state, payload) => {
  console.log('payload', payload);
  const {
    teams,
    campaignData: { data }
  } = state;
  const update =
    payload &&
    payload.map(r => {
      let i = '';
      if (r.items) {
        r.items.forEach(e => {
          if (e) {
            i = i + e.count + ' x ' + e.title + ', '
          }
        })
        i = i ? i.substr(0, i.length - 2) : '';
      }
      const team = r.teamId ? teams.find(t => t.id === r.teamId) : "";
      const donationMatched = r.donationAmount * data.matchFactor;
      let raf = '';
      if (r.raffleCategories) {
        raf = raf + '{'
        r.raffleCategories.forEach(c => {
          raf = `${raf + c},`
        })
        raf = raf + '}';
      }
      const s = currencies.find(c => c.code === r.currency);
      const sign = s ? s.sign : '';
      return {
        firstName: r.firstName,
        lastName: r.lastName,
        // donationDate: `${getDate(new Date(r.donationDate))} ${getTime(
        //   new Date(r.donationDate)
        // )}`,
        donationDate: r.donationDate,

        alias: r.alias,
        donationAmount:
          r.mode !== "monthly"
            ? `${r.donationAmount}`
            : `${r.donationAmount} x ${
            r.noOfMonths
            } months`,
        donationMatched: donationMatched,
        currency: r.currency,
        paymentType: r.paymentType,
        prospectId: r.prospectId ? r.prospectId : "",
        teamId: r.teamId ? r.teamId : "",
        transactionCostCovered: r.processingFeeCovered === true ? "Yes" : "No",
        transactionCost: r.processingFee,
        transactionId: r.transactionId,
        billingPhone: r.billingPhone,
        billingCountry: r.billingCountry,
        billingAddress: r.billingAddress,
        raffle: raf || '',
        dedicationMessage: r.dedicationMessage,
        status:
          r.status === "authorized"
            ? "Pending"
            : r.status === "declined"
              ? "Failed"
              : "Success",
        mode: r.isManual ? 'Manual Donation' : r.mode === 'oneTime' ? 'One Time' : r.mode === 'monthly' ? 'Recurring' : '',
        isManual: r.isManual,
        fullname: `${r.firstName} ${r.lastName}`,
        specialNotes: r.specialNotes,
        items: i,
        email: r.email,
        state: r.billingState,
        city: r.billingCity,
        zip: r.billingZip,
        id: r.id,
        sign: sign,
        mobilePhone: r.mobilePhone,
        workPhone: r.workPhone,
        homePhone: r.homePhone,
        teamData2:team,
        originalStatus : r.status,
        isBonusRoundActive : r.isBonusRoundActive
      };
    });

  return update;
};

const handlers = {
  [action.FETCH_CAMPAIGNS]: (state, action) => ({ ...state }),
  [action.ALL_CAMPAIGNS]: (state, action) => ({ ...state, allCampaigns: action.payload }),
  [action.SEARCH_CAMPAIGN]: (state, action) => ({
    ...state,
    filteredReports: searchReports(state, action)
  }),
  [action.FILTER_CAMPAIGN]: (state, action) => ({
    ...state,
    filteredReports: filterReports(state, action)
  }),
  [action.GET_REPORT]: (state, action) => ({
    ...state,
    campaignReports: setReport(state, action.payload)
  }),
  [action.SET_INITIAL]: (state, action) => ({
    ...state,
    initialCampaign: action.payload
  }),
  [action.SUCCESS]: (state, action) => ({
    ...state,
    campaigns: action.payload.campaigns || [],
    organizationData: action.payload,
    initialCampaign: action.payload.campaigns ? action.payload.campaigns[0] : ""
  }),
  "CLEARED": (state, action) => ({ ...state, cache: action.payload }),
  TEAM_SUCCESS: (state, action) => ({ ...state, teams: action.payload }),
  ...pender({
    type: "campaign/GET_CAMPAIGN",
    onSuccess: (state, action) => ({
      ...state,
      campaignData: action.payload.data
    })
  })
};

export default handleActions(handlers, initialState);