import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import * as shareAPI from '../../lib/share';
import { Map } from 'immutable';

const GET_SHARE = 'share/GET_SHARE';
const UPDATE_SHARE = 'share/UPDATE_SHARE';

export const getShareCount = createAction(GET_SHARE, shareAPI.getShareCount);
export const updateShareCount = createAction(UPDATE_SHARE, shareAPI.updateShareCount);

const initialState = Map({
  share: {}
})

export default handleActions({
  ...pender({
    type: GET_SHARE,
    onSuccess: (state, action) => state.set('share', action.payload.data)
  })
}, initialState)