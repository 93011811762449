import React, { Component } from 'react';
import { Widget, addResponseMessage, addLinkSnippet, addUserMessage } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import './Helpdesk.scss';

class Helpdesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openhelpdesk: false,
      showForm: true,
      showPhone: false,
      emailContent: {
        firstname: "",
        email: "",
        question: ""
      }
    }
  }

  changeValue(e) {
    //
    const { value, name } = e.target;
    const updatedEmailContent = Object.assign({}, this.state.emailContent, { [name]: value })
    this.setState({ emailContent: updatedEmailContent })
  }

  sendEmail() {
    const { emailContent } = this.state;
    if (emailContent.firstname == '') {
      alert("Please enter first name")
    } else if (emailContent.email == '') {
      alert("Please enter Email")
    } else if (emailContent.email.indexOf('@') == -1) {
      alert("Please include an '@' in email address");
    } else if (emailContent.question == '') {
      alert("Please enter your question")
    } else {
      this.setState({
        showForm: false
      })
    }
  }

  render() {
    if (this.props.pathname == "/forgot-password")
      return null;
    let arr = this.props.pathname.split('/');
    if (arr[1] == "reset-password")
      return null;
    const emailContent = this.state.emailContent;
    return (
      <div id="helpdesk" className="col-sm-6 col-xs-10 col-lg-3 col-md-3">
        {/* <Widget
            // handleNewUserMessage={this.handleNewUserMessage}
            // title="Email Us"
            // subtitle="Email Us"
          /> */}

        <label className={this.state.showPhone ? "phone" : "hidden"}>+91-9985741254</label>
        <div className={this.state.openhelpdesk ? "hidden" : "sub-header"}>
          <button className="email-us" onClick={() => this.setState({ openhelpdesk: true, showForm: true, showPhone: false })}> <p className="heading-title">Can we answer any questions ?</p></button>
          <button className="btn-phone pull-right" onClick={() => this.setState({ showPhone: !this.state.showPhone })}><span className="glyphicon glyphicon-earphone" /></button>
        </div>

        <div className={this.state.openhelpdesk ? "sub-header" : "hidden"}>
          <button className="email-us" onClick={() => this.setState({ openhelpdesk: true, showForm: true, showPhone: false })}> <h3>Email Us</h3></button>
          <button className="btn-close pull-right" onClick={() => this.setState({ openhelpdesk: false })}><span className="glyphicon glyphicon-chevron-down" /></button>
        </div>

        <div className={this.state.openhelpdesk ? "body" : "hidden"}>
          <div className={this.state.showForm ? "email-form" : "hidden"}>
            <div className="body-content">
              <label className="subtitle">Email Us for Quick Response...</label>
            </div>
            <div className="body-content">
              <input
                type="text"
                className="text-field"
                value={emailContent.firstname}
                name="firstname"
                placeholder="Enter First Name"
                onChange={(e) => this.changeValue(e)}
              />
            </div>
            <div className="body-content">
              <input
                type="text"
                className="text-field"
                value={emailContent.email}
                name="email"
                placeholder="Enter Email"
                onChange={(e) => this.changeValue(e)}
              />
            </div>
            <div className="body-content">
              <textarea
                className="text-field"
                rows="5" cols="10"
                placeholder="Enter your Questions"
                value={emailContent.question}
                name="question"
                onChange={(e) => this.changeValue(e)}
              >
              </textarea>
            </div>
            <div className="body-content text-center">
              <button className="btn-send" onClick={() => this.sendEmail()}>Send Email</button>
            </div>
          </div>

          <div className={this.state.showForm ? "hidden" : "email-response"}>
            <div className="body-content">
              <label className="subtitle">Email Sent</label>
            </div>
            <div className="body-content">
              <label
                className="label-field">
                First Name <br />
                {emailContent.firstname}
              </label>
            </div>
            <div className="body-content">
              <label
                className="label-field">
                Email <br />
                {emailContent.email}
              </label>
            </div>
            <div className="body-content">
              <label
                className="label-field">
                Enter your Question <br />
                {emailContent.question}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Helpdesk;