import React from 'react';

export const Multiplier = ({ matchFactor, donate_type, total_amount, donate_amount, duration, currency, isBonusRoundActive, campaignFullDetails }) => {
  return (
    <div className='multiplier'>
      <label>Thanks to Matching Pledges</label>
      <div className='multiply'>
        {!isBonusRoundActive && <p>{matchFactor}</p>
        }
        {isBonusRoundActive && <p>{campaignFullDetails.bonusGoal1MatchFactor}</p>
        }
        <span className='cross'>x</span>
        <span className='equal'>=</span>
      </div>
      <span>=</span>
      {!isBonusRoundActive && <div className='calculated-total'>
        {currency.sign}{donate_type == "monthly" ? donate_amount * duration * matchFactor : donate_amount * matchFactor}
      </div>
      }

      {isBonusRoundActive && <div className='calculated-total'>
        {currency.sign}{donate_type == "monthly" ? donate_amount * duration * campaignFullDetails.bonusGoal1MatchFactor : donate_amount * campaignFullDetails.bonusGoal1MatchFactor}
      </div>
      }
    </div>
  );
}
