import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";
import Error from '../Notification/Error';
import { currencies as currencyList } from "../../Common/currencies";
import { convertCurrency } from "../../Common/CurrencyCalculator";
import './Calculator.scss';
import oneTimeImage from "../../assets/images/latestImages/grey-heart-one-time.png";
import { Monthly } from './../../pages/Checkout/Calculate';

class Calculator extends Component {
  constructor() {
    super();

    this.state = {
      amount: 0,
      donation_type: '',
      duration: '',
      monthly: '',
      total_values: [],
      notify: false,
      showDecimal: false,
      oneTimeDonate: '',
      currencies: [],
      currency: {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      }
    }
  }

  componentWillMount() {
    const { details } = this.props;
    let c = '';
    if (details.campaignCurrency) {
      c = currencyList.find(e => e.code === details.campaignCurrency);
    } else {
      c = currencyList.find(e => e.code === details.currency);
    }
    this.setState({ currency: c });
  }

  componentWillReceiveProps(nextProps) {
    const { details: { calculatorSetup, mode } } = nextProps;
    const { donation_type } = this.state;
    let { details: { currency } } = nextProps;

    if (calculatorSetup) {
      if (currency && typeof currency === 'string') {
        let c = {};
        if (currency !== 'usd') {
          c = currencyList.find(e => e.code === currency)
        }

        currency = [{
          name: 'United States Dollar (USD)',
          code: 'usd',
          sign: '$'
        }, c]
      }
      const values = calculatorSetup.find(calc => calc.mode === mode);
      if (values) {
        this.setState({
          donation_type: donation_type ? donation_type : mode,
          total_values: values.setup,
          currencies: currency
        });
      }
    }
  }

  oneTimeDonation = (e) => {
    this.setState({ amount: e.target.value, oneTimeDonate: e.target.value, duration: '', monthly: '', donation_type: 'oneTime' });
  }

  handleSelect = (e, amount, duration) => {
    this.setState({ amount, duration, monthly: e.target.value, donation_type: duration ? 'monthly' : 'oneTime', oneTimeDonate: '' })
  }

  handleCustomDonate = () => {
    const { onCampaignActions, history, details: { matchFactor } } = this.props;
    const { currency } = this.state;
    const data = {
      amount: 0,
      donation_type: 'monthly',
      duration: '0',
      matchFactor,
      currency
    };
    onCampaignActions.setDonation(data);
    history.push('/checkout');
  }

  handleDonate = () => {
    const { onCampaignActions, history, details: { matchFactor } } = this.props;
    const { amount, donation_type, duration, currency } = this.state;
    if (amount) {
      const data = {
        amount,
        donation_type,
        duration,
        matchFactor,
        currency
      }
      onCampaignActions.setDonation(data);
      history.push('/checkout');
    } else {
      this.setState({ notify: true });
    }
  }

  handleNotification = () => {
    this.setState({ notify: false });
  }

  handleFocus = () => {
    this.setState({ showDecimal: true, donation_type: 'oneTime' });
  }

  handleBlur = () => {
    this.setState({ showDecimal: false });
  }

  handleKeyDown = (e) => {
    if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.')
      e.preventDefault()
  }

  handleCurrency = (e) => {
    const { currencies } = this.state;
    const c = currencies.find(f => f.code === e.target.value);
    this.setState({ [e.target.name]: c });
  }

  render() {
    const { total_values, amount, duration, notify, showDecimal, oneTimeDonate, currencies } = this.state;
    const { details, isBonusRoundActive } = this.props;
    const enableLatestUi = details && details.enableLatestUi;
    const themeColor = details && details.themeColor;
    let { currency } = this.state;
    if (!currency) {
      currency = {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      }
    }

    return (
      <div className="donation_calc_part">
        {/* latest UI---------------------------------------------- */}
        {enableLatestUi && <div id="donation_wrapper">
          <div className="donation_container">
            <div className="plan_donation" style={{ 'background': themeColor }}>
              <div className="options">
                {total_values.map(value => {
                  const amount = convertCurrency(value.amount, currency.code);
                  return (
                    <div key={amount} className="donation_option">
                      <label className="option">
                        <input className="radio" value={`d${amount}`} type="radio" name="monthly" checked={`d${amount}` === this.state.monthly} onChange={(e) => this.handleSelect(e, amount, value.noOfMonths)} />
                        <span className="price" style={{'color': '#fff', 'fontSize': '22px' }}>{currency.sign}{amount}</span>
                      </label>
                      {value.noOfMonths && <div className="duration">
                        <label className="duration_text"  style={{ 'fontWeight': '500' }}>X {value.noOfMonths} Months</label>
                      </div>}
                    </div>
                  );
                }
                )}
                <div onClick={this.handleCustomDonate} className="donation_option">
                  <label style={{ cursor: 'pointer', 'color': '#fff', 'fontWeight': '400' }} className="option">
                    Custom <span style={{ 'display': 'block' }}>Monthly</span><span style={{ 'display': 'block' }}>Donation</span>
                      </label>

                </div>

              </div>
              {/* <div className="or">OR</div> */}
            </div>
            <div className="onetime_donation" style={{ 'background': '#b8b8b8' }}>
              <label className="label label-latest"><img src={oneTimeImage} alt="onetimeimage" />One-Time<br></br>Donation</label>
              <div className="form_text form_text_latest">
                <input type="number" value={oneTimeDonate} placeholder={showDecimal ? '' : 0} id="onetime_donation" onBlur={this.handleBlur} onFocus={this.handleFocus} onKeyDown={this.handleKeyDown} onChange={this.oneTimeDonation} />
                <label htmlFor="onetime_donation" className="input_label">{currency.sign}
                  {currency.code}
                </label>
                <div className='decimal'>.00</div>
              </div>
            </div>
          </div>
          <div className="result_container">
            <div className="donate_result donate_result_latest">
              {details.campaignCurrency && details.enableCurrency && details.currency.length > 1 &&
                <div className="currency-selector">
                  <div className="text">CHANGE YOUR CURRENCY</div>
                  <Select
                    disableUnderline={true}
                    className="select"
                    name="currency"
                    value={currency.code}
                    onChange={this.handleCurrency}
                  >
                    {currencies.map(c =>
                      <MenuItem key={c.code} value={c.code}>{c.code}</MenuItem>
                    )}
                  </Select>
                </div>
              }
              {/* added condition for adding bonus round matchfactor-------------- */}
              {!isBonusRoundActive && details.matchFactor > 1 && <div className="match_price">
                <label>Combined With Matched Pledges</label>
                <p className="match_info">
                  <strong><span className="cross">X</span><span className="multi_factr">{details.matchFactor}</span></strong>
                </p>
              </div>
              }
              {isBonusRoundActive && <div className="match_price">
                <label>Combined With Matched Pledges</label>
                <p className="match_info">
                  <strong><span className="cross">X</span><span className="multi_factr">{details.bonusGoal1MatchFactor}</span></strong>
                </p>
              </div>
              }

              {!isBonusRoundActive && <div className="org_receiving">
                <label>{details.name} Receives</label>
                <p><span className="cur-icon">{currency.sign}</span><span className="total">{amount * (details.matchFactor || 0) * (duration ? duration : 1)}</span></p>
              </div>
              }

              {isBonusRoundActive && <div className="org_receiving">
                <label>{details.name} Receives</label>
                <p><span className="cur-icon">{currency.sign}</span><span className="total">{amount * (details.bonusGoal1MatchFactor || 0) * (duration ? duration : 1)}</span></p>
              </div>
              }
              {/* -------------------------- */}

              <div className='donate-button'>
                <input type="button" id="donate_now" value="DONATE" onClick={this.handleDonate} style={{ 'background': themeColor }} />
              </div>
            </div>
          </div>
        </div>}

        {/* for old campaigns or ui------------------------------- */}
        {!enableLatestUi && <div id="donation_wrapper">
          <div className="donation_container">
            <div className="plan_donation">
              <div className="options">
                {total_values.map(value => {
                  const amount = convertCurrency(value.amount, currency.code);
                  return (
                    <div key={amount} className="donation_option">
                      <label className="option">
                        <input className="radio" value={`d${amount}`} type="radio" name="monthly" checked={`d${amount}` === this.state.monthly} onChange={(e) => this.handleSelect(e, amount, value.noOfMonths)} />
                        <span className="price">{currency.sign}{amount}</span>
                      </label>
                      {value.noOfMonths && <div className="duration">
                        <label className="duration_text">X {value.noOfMonths} Months</label>
                      </div>}
                    </div>
                  );
                }
                )}
                <div onClick={this.handleCustomDonate} className="donation_option">
                  <label style={{ cursor: 'pointer' }} className="option">
                    CUSTOM DONATION
                      </label>

                </div>

              </div>
              <div className="or">OR</div>
            </div>
            <div className="onetime_donation">
              <label className="label">Enter<br></br>One-Time<br></br>Donation</label>
              <div className="form_text">
                <input type="number" value={oneTimeDonate} placeholder={showDecimal ? '' : 0} id="onetime_donation" onBlur={this.handleBlur} onFocus={this.handleFocus} onKeyDown={this.handleKeyDown} onChange={this.oneTimeDonation} />
                <label htmlFor="onetime_donation" className="input_label">{currency.sign}<br></br>
                  {currency.code}
                </label>
                <div className='decimal'>.00</div>
              </div>
            </div>
          </div>
          <div className="result_container">
            <div className="donate_result">
              {details.campaignCurrency && details.enableCurrency && details.currency.length > 1 &&
                <div className="currency-selector">
                  <div className="text">CHANGE YOUR CURRENCY</div>
                  <Select
                    disableUnderline={true}
                    className="select"
                    name="currency"
                    value={currency.code}
                    onChange={this.handleCurrency}
                  >
                    {currencies.map(c =>
                      <MenuItem key={c.code} value={c.code}>{c.code}</MenuItem>
                    )}
                  </Select>
                </div>
              }
              {/* added condition for adding bonus round matchfactor-------------- */}
              {!isBonusRoundActive && details.matchFactor > 1 && <div className="match_price">
                <label>Combined With Matched Pledges</label>
                <p className="match_info">
                  <strong><span className="cross">X</span><span className="multi_factr">{details.matchFactor}</span></strong>
                </p>
              </div>
              }
              {isBonusRoundActive && <div className="match_price">
                <label>Combined With Matched Pledges</label>
                <p className="match_info">
                  <strong><span className="cross">X</span><span className="multi_factr">{details.bonusGoal1MatchFactor}</span></strong>
                </p>
              </div>
              }

              {!isBonusRoundActive && <div className="org_receiving">
                <label>{details.name} Receives</label>
                <p><span className="equal">=</span><span className="cur-icon">{currency.sign}</span><span className="total">{amount * (details.matchFactor || 0) * (duration ? duration : 1)}</span></p>
              </div>
              }

              {isBonusRoundActive && <div className="org_receiving">
                <label>{details.name} Receives</label>
                <p><span className="equal">=</span><span className="cur-icon">{currency.sign}</span><span className="total">{amount * (details.bonusGoal1MatchFactor || 0) * (duration ? duration : 1)}</span></p>
              </div>
              }
              {/* -------------------------- */}

              <div className='donate-button'>
                <input type="button" id="donate_now" value="DONATE NOW" onClick={this.handleDonate} />
              </div>
            </div>
          </div>
        </div>}
        <Error open={notify} message='Please select donation amount or enter a one time donation' handleClose={this.handleNotification} />
      </div>
    );
  }
}

export { Calculator };
