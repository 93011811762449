import React from "react";
import { AppBar, InputBase, NativeSelect } from "@material-ui/core";
import "./CampaignTableHeader.scss";
function CampaignTableHeader({
  searchItem,
  searchCampaign,
  rowsPerPage,
  handleChangeRowsPerPage
}) {
  return (
    <AppBar position="static" id="report-table-header">
      <div className="search">
        <InputBase
          className="input"
          placeholder="Search"
          value={searchItem}
          onChange={searchCampaign}
        />
        <i className="fa fa-search" aria-hidden="true" />
      </div>

      <div className="table-view">
        View
        <NativeSelect
          className="select"
          disableUnderline
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          name="viewTableRows">
          <option value="15" className="view-option">
            15
          </option>
          <option value="30" className="view-option">
            30
          </option>
          <option value="60" className="view-option">
            60
          </option>
          <option value="100" className="view-option">
            100
          </option>

          <option value="150" className="view-option">
            150
          </option>
         
        </NativeSelect>
      </div>
    </AppBar>
  );
}

export default CampaignTableHeader;
