import React from 'react';
import { Goals } from '../TeamDetail/Goals';
import { Progress } from '../CampaignDetails/Progress';
import { currencies } from '../../Common/currencies';

export const AdminTimer = ({ details, donation, color }) => {
  console.log(details,"details------>")
  const timeRemaining = new Date(details.campaignEndTime) - new Date();
  const bonusEnd = new Date(details.bonusGoalEndDate) - new Date();
  const bonus = donation > parseInt(details.goalAmount) && timeRemaining >= 0;
  const sign = details.campaignCurrency ? (currencies.find(e => e.code === details.campaignCurrency)).sign : '$';
  return (
    <div className='timer'>
      { donation !== '' && 
        ((timeRemaining <= 0 && bonusEnd <= 0 && bonus) ?
          <img className='success-img' src="/images/successful.png" alt="Success" /> :
          (timeRemaining <= 0 && !bonus) ? 
          <img className='success-img' src='/images/unsuccessful.png' alt='Unsuccessful' /> :
          <Goals startTime={details.campaignStartTime} endTime={details.campaignEndTime} bonus={bonus} bonusEnd={details.bonusGoalEndDate} />
        )
      }
      { donation !== '' && <div className='admin-goalAmount'><span>GOAL {sign}{details.goalAmount}</span> {details.campaignCurrency ? details.campaignCurrency.toUpperCase() : 'USD'}</div>}        
      { donation !== '' && <Progress color={color} bonusGoal={details.bonusGoalAmount1} goalAmount={details.goalAmount} donation={donation} sign={sign} socialShareAmount={details.socialShareAmount} shares={{shareCount:details.shareCount,shareGoal:details.shareGoal}} />}
    </div>
  )
}