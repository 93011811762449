import React from 'react';
import { ProgressBar } from "react-step-progress-bar";
import { NumberFormat } from '../../Common/NumberFormat';
import './Progress.scss';
import "react-step-progress-bar/styles.css";

export const getPercent = (goal, raised) => {
  const percent = (parseFloat(raised) / parseFloat(typeof goal === 'string' ? goal.replace(/,/g, '') : goal)) * 100;
  return percent.toFixed(2);
}

export const Progress = ({ goalAmount, donation, bonusGoal, shares, socialShareAmount, color, sign }) => {

  let raised = donation || 0;
  console.log(donation, socialShareAmount, shares, "donation----new----->")
  raised = shares && parseInt(shares.shareCount) >= parseInt(shares.shareGoal) ? parseInt(raised) + parseInt(socialShareAmount) : parseInt(raised);
  const bonus = (goalAmount && raised > goalAmount);
  const check = (bonus ? <i className="fa fa-check" aria-hidden="true" style={{ left: `calc(${getPercent(bonusGoal, raised) > 100 ? 100 : getPercent(bonusGoal, raised)}% - 20px)` }}></i> : null);
  console.log("inside team page@2", raised);
  return (
    <section>
      <p className="product_pledged" style={{ color: color ? color : '' }}>{sign}{NumberFormat(parseInt(raised)) || 0}</p>
      <ProgressBar
        percent={getPercent((bonus ? bonusGoal : goalAmount) || 0, raised || 0) > 100 ? 100 : getPercent((bonus ? bonusGoal : goalAmount) || 0, raised || 0)}
        filledBackground={bonus ? '#51b748' : color ? color : `#2995c3`}
        unfilledBackground={bonus ? color ? color : "#2995c3" : "#d6d6d6"}
        height="14px"
        width="100%"
      >
        {check}
      </ProgressBar>
      <span className="percent_br">{raised ? getPercent(goalAmount, raised) : 0}% </span>
      {/* <span className="percent_br" style={{fontSize: "17px", color: '#3f51b5'}}><strong>Social Share Amount {sign}{socialShareAmount}</strong> </span> */}
    </section>
  );
}
