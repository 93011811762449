import React from "react";
import { DisplayOptions } from "./Components/DisplayOptions";
import { Filters } from "./Components/Filters";
import { AdminTimer } from "../../components/Timer";
import "./CampaignMiddleSection.scss";
import "../../Common/Clock.scss";

const CampaignMiddleSection = ({
  filterByDonor,
  filterByPayment,
  onCampaignSortbyRadioChange,
  onFilterByPayment,
  handleCheckbox,
  fields,
  completeReport,
  details,
  donation,
  visibleFields,
  campaignReports,
  captureDonation
}) => {
  return (
    <div className="middle-section">
      <DisplayOptions
        handleCheckbox={handleCheckbox}
        fields={fields}
      />
      <Filters
        filterByDonor={filterByDonor}
        filterByPayment={filterByPayment}
        onFilterByPayment={onFilterByPayment}
        onCampaignSortbyRadioChange={onCampaignSortbyRadioChange}
        completeReport={completeReport}
        visibleFields={visibleFields}
        campaignReports={campaignReports}
        captureDonation={captureDonation}
        campaignName={details.name}
      />
      <AdminTimer color="#7c6a9c" details={details} donation={donation} />
    </div>
  );
};

export default CampaignMiddleSection;
