import React from 'react';
import { ProgressBar } from "react-step-progress-bar";
import './index.scss';

export const ProgressLoader = (props) => {
  return (
    props.loadingPercent < 100 ? 
    <div id='progress-loader'>
      <ProgressBar
        percent={props.loadingPercent}
        height="10px"
        width="30%"
      />
      <label>Loading {props.loadingPercent}%</label>
    </div> :
    props.children
  );
}
