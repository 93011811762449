import React, { Component } from "react";
import { getSign } from "../../../Common/getSign";
import moment from "moment";
import "../../../components/CampaignDetails/Donors.scss";
// import animationImage from '../../../assets/images/heart.png';
import animationImage from '../../../assets/images/heart.png';
// import heartImageLatest from '../../../assets/images/heartImageLatest.png';
import "./donorBoxRoot.scss";
import { ProgressBar } from "react-step-progress-bar";
import { NumberFormat } from '../../../Common/NumberFormat';
import CountUp from 'react-countup';

class DonorBoxLatest extends Component {
  constructor() {
    super();
    this.teamSearchInput = React.createRef()
    this.state = {
      filteredDonors: [],
      fullTeamDetailsFilteredArr: [],
      show_btn: false,
      length: 0,
      expanded: false,
      allTeamsLength: 0,
    };
  }

  componentWillMount() {
    const { donors } = this.props;
    let { fullTeamDetailsFilteredArr } = this.props
    fullTeamDetailsFilteredArr = (fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length > 0) ? fullTeamDetailsFilteredArr : [];

    this.setState({
      // fullTeamDetailsFilteredArr: fullTeamDetailsFilteredArr.filter((team, i) => i < 10) || 0,
      fullTeamDetailsFilteredArr: fullTeamDetailsFilteredArr || 0,
      // filteredDonors: (donors && donors.filter((donor, i) => i < 10)) || [],
      filteredDonors: donors || [],
      show_btn: (donors && donors.length > 10 ? true : false) || (fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length > 10 ? true : false),
      length: donors && donors.length,
      allTeamsLength: fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length
    });
  }

  componentWillReceiveProps(nextProps) {
    const { expanded } = this.state;
    const { donors } = nextProps;

    this.setState({
      filteredDonors: !expanded
        // ? (donors && donors.filter((donor, i) => i < 10)) || []
        ? donors || []
        : donors,
      show_btn: donors && (donors.length > 10 && !expanded) ? true : false,
      length: donors && donors.length
    });
  }

  expand = () => {
    const { donors, fullTeamDetailsFilteredArr } = this.props;

    this.setState({ filteredDonors: donors, show_btn: false, expanded: true, fullTeamDetailsFilteredArr });
  };

  searchTeams = () => {

    let allSearchedTeams = [];
    let keyword = (this.teamSearchInput.current.value).trim().toLowerCase();
    let tempString = "";
    let regex = new RegExp(keyword, 'g');
    let { fullTeamDetailsFilteredArr } = this.props;
    fullTeamDetailsFilteredArr = (fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length > 0) ? fullTeamDetailsFilteredArr : [];
    allSearchedTeams = fullTeamDetailsFilteredArr.filter((team, index) => {
      tempString = team.teamName.trim().toLowerCase();
      if (regex.test(tempString)) {
        return true;
      }
    });
    Promise.all(allSearchedTeams).then(allSearchedTeams => {
      this.setState({
        fullTeamDetailsFilteredArr: allSearchedTeams
      })
    });
  }

  render() {
    const { filteredDonors, show_btn, length, fullTeamDetailsFilteredArr } = this.state;
    const { team, allTeams, campaignCurrency, mainCampaignPath, activeTab, details } = this.props;
    let flag = '';
    var liClasses = '';
    let animationClass = '';
    let themeColor = details && details.themeColor || "#996DFF";
    console.log("at>>", allTeams, '>>>>', team, this.state.fullTeamDetailsFilteredArr, '?????????', mainCampaignPath)
    return (

      <div className="outer-box">
        {(activeTab == "1") && filteredDonors.map((donor, i) => {
          return (
            <div className="messg-box">
              <span className="img-span"><img alt="" className="" src="" /></span>
              <div className="messg-box-content">
                <div>
                  <h5> {team ? donor.teamName : donor.displayName ? (donor.alias).trim() || donor.name : (donor.name).trim()}
                  </h5>
                  <p className="text truncate-overflow">{donor.message}</p>
                </div>
                <div>
                  <p className="date">{moment(donor.date).format('MM/DD | hh:mm A')} <span>{getSign(donor.currency || campaignCurrency)}{team ? (donor.totalChargedAmount).toFixed(0) : (donor.amount)}</span></p>
                </div>
              </div>
            </div>
          );
        })
        }

        { (activeTab == "2") && fullTeamDetailsFilteredArr &&
          fullTeamDetailsFilteredArr.map(team => {
            return (
              <div className="team-box-outer">
                <div className="team-box-latest">
                  <div className="username">
                    <span className="img-span">{team.textImage}</span>
                    <h4 style={{ 'color': themeColor }}>{team.teamName}</h4>
                  </div>
                  <div className="content">
                    <p className="text truncate-overflow">{team.description}.</p>
                  </div>
                </div>
                <div className="progress-bar-latest">
                  {/* <span className="arrow-down">2%</span> */}
                  <div class="steps step-1 step-2 step-3 step-4 step-5 step-6 step-7 step-8 step-9 step-10 step-11" style={{ left: `calc(${parseInt(team.percentage).toFixed(0)}%)` }}>
                    <span className="percent" style={{ 'color': themeColor }}>{team.percentage || 0}%</span>
                    <div class="triangle-demo" style={{ 'borderTopColor': themeColor }}></div>
                  </div>
                  <ProgressBar
                    percent={Math.ceil(team.percentage) || 0}
                    filledBackground={themeColor}
                    unfilledBackground="#eee"
                    height="8px"
                    width="100%"
                    hasStepZero="true"
                  >
                  </ProgressBar>
                </div>
                <div className="price-box">
                  <p><span className="price" style={{ 'color': themeColor }}>{team.teamDonorsCount}</span><span className="price-text">donors</span></p>
                  <p><span className="price" style={{ 'color': themeColor }}>{team && team.sign} <CountUp end={NumberFormat(team.amountRaised)} />
                  </span><span className="price-text">of {team && team.sign}{NumberFormat(team.goalAmount)} RAISED</span></p>
                  <button className="btn btn-view" onClick={(e) => { window.open(team.teamLink) }}>VIEW</button>
                </div>
              </div>
            )
          })
        }

        {/* 
        <div className="team-box-outer">
          <div className="team-box-latest">
            <div className="username">
              <span className="img-span">CS</span>
              <h4 style={{ 'color': themeColor }}>Chris Sato</h4>
            </div>
            <div className="content">
              <p className="text truncate-overflow">Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
            </div>
          </div>

          <div className="progress-bar-latest">
            {check}
            <ProgressBar
              percent="60"
              filledBackground="#4199b5"
              unfilledBackground="#eee"
              height="8px"
              width="100%"
            >
            </ProgressBar>
          </div>

          <div className="price-box">
            <p><span className="price" style={{ 'color': "#4199b5" }}>180</span><span className="price-text">donors</span></p>
            <p><span className="price" style={{ 'color': "#4199b5" }}>$18,520</span><span className="price-text">of $54,000 Riased</span></p>
            <button className="btn btn-view">View</button>
          </div>
        </div> */}
      </div>
      // <div>

      //   HI I M HEREE
      // </div>


      // <section className="donars_section">
      //   <div className="donar_header">
      //     {allTeams && <div className="search-wrap"><i className="fa fa-search"></i> <input type="text" className="form-control" ref={this.teamSearchInput} onChange={this.searchTeams} placeholder="Search Your Team..." /></div>}
      //     {!allTeams && <h2 className="left_don">{team ? 'Teams' : 'Donors'}</h2>}
      //     {allTeams && <h2 className="left_don"></h2>}
      //     {<h2 className="right_don">{allTeams ? '' : 'Donations Matched'}</h2>}
      //   </div>
      //   {allTeams ?
      //     <div className="donor-listing">
      //       {this.state.fullTeamDetailsFilteredArr.map(team => {
      //         return (<div className="donar_block">
      //           {team.imageURL1 && <img
      //             src={team.imageURL1}
      //             className="team_donar_img"
      //             alt="Donor"
      //           />}
      //           {!team.imageURL1 && <img
      //             src="/images/donar1.png"
      //             className="donar_heart"
      //             alt="Donor"
      //           />}
      //           <div className="donor_info">
      //             <p className="alias">
      //               {team.teamName}
      //             </p>
      //           </div>
      //           <button className="btn btn-visit" onClick={() => window.open('https://pledje.com/' + team.campaignPath)}>Visit</button>
      //         </div>)
      //       })
      //       }
      //     </div>
      //     :
      //     <div className="donor-listing">
      //       {filteredDonors.map((donor, i) => {
      //         flag = ((parseInt(donor.amount) >= 500 || parseInt(donor.totalChargedAmount) >= 500)) && ((mainCampaignPath == "ccla") || (mainCampaignPath == "mpcorpnew")) && (!team);
      //         animationClass = flag ? 'animation-donor-info' : '';
      //         liClasses = `donar_block ${animationClass}`;
      //         return (
      //           <div key={i} className={liClasses}>
      //             {!flag && <img
      //               src="/images/donar1.png"
      //               className="donar_heart"
      //               alt="Donor"
      //             />}
      //             {flag && <img style={{ "height": "40px" }}
      //               src={animationImage}
      //               className="donar_heart"
      //               alt="Donor"
      //             />}
      //             <div className="donor_info">
      //               <p className="alias">
      //                 {team ? donor.teamName : donor.displayName ? (donor.alias).trim() || donor.name : (donor.name).trim()}
      //               </p>
      //               <p>{(donor.alias == "Anonymous" || donor.teamName == "Anonymous") && (!team) && moment(donor.date).format('MM/DD | hh:mm A')}</p>
      //               <p className="message">{donor.message}</p>
      //             </div>
      //             <label className="amount">{getSign(donor.currency || campaignCurrency)}{team ? (donor.totalChargedAmount).toFixed(0) : (donor.amount)}</label>
      //           </div>
      //         )
      //       })}
      //     </div>}
      //   {show_btn && (
      //     <button className="sidebar-blue-btn" onClick={this.expand}>
      //       See All { allTeams ? this.state.allTeamsLength : length} {team ? "Team's Performance" : allTeams ? "Teams" : "Donors"}
      //     </button>
      //   )}
      //   {!show_btn && <span className="text-highlight">{(allTeams) ? ((this.state.allTeamsLength > 10) ? this.state.allTeamsLength : '') : (length > 10 ? length : '')} {team ? (length > 10 ? "Team's Performance" : '') : allTeams ? ((this.state.allTeamsLength > 10) ? "Teams" : '') : (length > 10 ? "Donors" : '')}</span>}
      // </section>
    );
  }
}

export { DonorBoxLatest };
