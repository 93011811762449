import firebase from "../../../apis/firebase.apis";
import * as action from "./action";

const FIRESTORE = firebase.firestore();

export const fetchCampaigns = (org) => dispatch => {
  console.log("@c", org)
  dispatch({
    type: action.FETCH_CAMPAIGNS,
    payload: org
  })
}

/*
export const getOrganizations = async () => {
  return FIRESTORE.collection("organizations")
    .get()
    .then(res => {
      let organizations = res.docs.map(async e => {
        let orgData;
        let d = e.data();
        const id = e.id;
        // await FIRESTORE.collection("users").where("organizationId", "==", id).get().then(response => {
        //   response.docs.map(item => {
        //     let user = item.data();
        //     const uid = user.id;
        //     d.email = user.email;
        //     return;
        //   })
        // })
        return {
          ...d,
          id
        };
      });

      return organizations;
    });
}
*/

export const getOrganizations = () => {
  return FIRESTORE.collection("organizations")
    .get()
    .then(res => {
      let organizations = res.docs.map(e => {
        const d = e.data();
        const id = e.id;
        let emailId;
        FIRESTORE.collection("users").where("organizationId", "==", id).get().then(response => {
          emailId = response.docs.map(item => {
            let user = item.data();
            const uid = user.id;
            emailId = user.email;
            return emailId;
          })
        })
        return {
          ...d,
          id, emailId
        };
      });

      return organizations;
    });
}

export const getOrganizationCollection = () => dispatch => {
  dispatch({ type: "LOADING" });
  return new Promise(function (resolve, reject) {
    getOrganizations()
      .then(organizations =>
        resolve(dispatch({
          type: action.GET_ORGANIZATION,
          payload: organizations
        }))

      )
      .catch(err =>
        reject(dispatch({
          type: action.ORGANIZATION_ERR,
          payload: "An error has occured"
        }))
      );
  });
};

export const addOrganization = (data) => dispatch => {
  dispatch({ type: "LOADING" });
  return new Promise(function (resolve, reject) {
    FIRESTORE.collection("organizations")
      .add(data)
      .then(res => {
        return resolve(dispatch({ type: action.ORG_CREATE, payload: res.id }))
      })
      .catch(() => {
        return reject(dispatch({
          type: action.ORG_CREATE_ERR,
          payload: "An error has occured"
        }))
      });
  });
};

export const removeOrganization = id => dispatch => {
  FIRESTORE.collection("organizations")
    .doc(id)
    .delete();
}

export const addRoute = (data) => dispatch => {
  dispatch({ type: "LOADING" });
  return new Promise(function (resolve, reject) {
    FIRESTORE.collection("routes")
      .add(data)
      .then(res => {
        return resolve(dispatch({ type: action.ROUTE_CREATE }))
      })
      .catch(err => {
        return reject(dispatch({
          type: action.ROUTE_ERR,
          payload: "An error has occured while creating the campaign route"
        }))
      });
  });
};

export const addCampaign = (o) => dispatch => {
  dispatch({ type: "LOADING" });
  // New  block is added for campaign page calender issue as we have to select date after each campaign update--------------
  let tempObj = o.campaigns;
  let copy = Object.assign({}, tempObj)

  for (let i in copy) {
    copy[i].campaignStartTime = typeof (copy[i].campaignStartTime) == 'object' ? new Date(copy[i].campaignStartTime).getTime() : copy[i].campaignStartTime;
    copy[i].campaignEndTime = typeof (copy[i].campaignEndTime) == 'object' ? new Date(copy[i].campaignEndTime).getTime() : copy[i].campaignEndTime;
    copy[i].bonusGoalEndDate = typeof (copy[i].bonusGoalEndDate) == 'object' ? new Date(copy[i].bonusGoalEndDate).getTime() : copy[i].bonusGoalEndDate;

  }
  //------------------------------------------------
  
  return new Promise(function (resolve, reject) {
    FIRESTORE.collection("organizations")
      .doc(o.id)
      .update({
        campaigns: copy,
        organizationPhone: o.organizationPhone || '',
        organizationTaxId: o.organizationTaxId || '',
        organizationAddress: o.organizationAddress || ''
      })
      .then(() => {
        return resolve(dispatch({ type: action.CAMPAIGN_CREATE }))
      })
      .catch(err => {
        return reject(dispatch({
          type: action.CAMPAIGN_ERR,
          payload: "An error has occured while creating the campaign"
        }))
      });
  });
};
