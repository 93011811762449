import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, withStyles, Button } from '@material-ui/core';
import { getSign } from '../../Common/getSign';

const paperStyle = {
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0,
  }
};
const columns = ['Date', 'Name', 'Amount', 'Currency', 'Status', 'Toggle Status'];

function TeamTable({ donations, updateDisplayStatus, statusOrder, handleChangePage, rowsPerPage, page, asc, classes, campaign }) {
  if (donations.length > 0) {
    if (asc) {
      donations.sort((a, b) =>
        parseFloat(a.amount) - parseFloat(b.amount)
      )
    }
    else {
      donations.sort((a, b) =>
        parseFloat(b.amount) - parseFloat(a.amount)
      )
    }
  }

  if (statusOrder != "all") {
    if (statusOrder == "true")
      donations = donations.filter(donation => donation.displayStatus)
    else
      donations = donations.filter(donation => !donation.displayStatus)
  }

  return (
    <div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((columnName, index) =>
                <TableCell key={index} className="team-table-cell-head" >{columnName}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
        

            {donations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((donation, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }}>
                <TableCell component="th">{new Date(donation.date).toLocaleDateString("en-US")}
                </TableCell>
                <TableCell component="th">{donation.name}
                </TableCell>
                <TableCell component="th">{getSign(donation.currency)}{(donation.amount).toFixed(2)}
                </TableCell>
                <TableCell component="th">{donation.currency ?(donation.currency.toUpperCase()):'NA'}
                </TableCell>
                <TableCell component="th">{donation.displayStatus ? <i className="fa fa-circle color-green" /> : <i className="fa fa-circle color-gray" />}
                </TableCell>
                <TableCell component="th">
                  <Button variant="outlined" size="large" onClick={(event) => updateDisplayStatus(campaign.organizationId, campaign.Id || campaign.campaignId, donation.donationId, !donation.displayStatus)}>
                    {(donation.displayStatus) ? "Hide" : "Show"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        classes={{ caption: classes.caption, toolbar: classes.toolbar }}
        component="div"
        count={donations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={[]}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

export default withStyles(paperStyle)(TeamTable);
