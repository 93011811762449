import React, { Component } from "react";
import { Dialog, DialogTitle, DialogActions, Grid, withMobileDialog } from "@material-ui/core";
import { Formik } from "formik";
import { RouteSchema } from "../../YupValidation/CampaignSchema";

const _v = require("uuid/v4");
const _v2 = _interopRequireDefault(_v);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

let initialValues = {
  campaignId: '',
  organizationId: '',
  campaignPage: '',
  route: '',
  teamId: (0, _v2.default)(),
  teamPage: ''
}

class OrganizationForm extends Component {

  componentDidMount() {
    const { campaign } = this.props;

    initialValues.campaignId = campaign.campaignId;
    initialValues.organizationId = campaign.organizationId;
    initialValues.campaignPage = `/${campaign.campaignPage}/`;
    initialValues.route = `/${campaign.campaignPage}/`;
  }

  formSubmit = (e) => {
    const { addRoute } = this.props;
    e.route = `${e.route}${e.teamPage}/`;
    e.teamPage = `/${e.teamPage}/`;

    addRoute(e);
  }

  render() {
    const { fullScreen, handleClose } = this.props;

    return (
      <Dialog
        id='additional-info-dialog'
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={true}
      >
        <DialogTitle className="title">
          Create Route
        </DialogTitle>
        <Formik
          validationSchema={RouteSchema}
          onSubmit={this.formSubmit}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, values, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid className="container" container spacing={0}>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Campaign Page Name</label>
                    <input
                      className='input'
                      name="campaignPage"
                      value={values.campaignPage}
                      readOnly
                    />
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Route</label>
                    <input
                      className='input'
                      name="route"
                      value={`${values.route}${values.teamPage ? `${values.teamPage}/` : ''}`}
                      readOnly
                    />
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Team ID</label>
                    <input
                      className='input'
                      name="teamId"
                      value={values.teamId}
                      readOnly
                    />
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Team Page</label>
                    <input
                      className='input'
                      name="teamPage"
                      onChange={handleChange}
                      value={values.teamPage}
                    />
                    <p>{errors.teamPage}</p>
                  </Grid>
                </Grid>
                <DialogActions className="actions">
                  <button onClick={handleClose}>Cancel</button>
                  <button type="submit">Save</button>
                </DialogActions>
              </form>
            )
          }}
        </Formik>
      </Dialog>
    );
  }
}

export default withMobileDialog()(OrganizationForm);
