import React from 'react';

export const Title = ({ title, logo }) => {
  return(
    <section className={!logo ? 'team-title team-center' : 'team-title'} >
      { logo && <img alt='Logo' src={logo}></img>}
      <h1>{title}</h1>
    </section>
  );
}
