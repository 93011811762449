import React from 'react';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import AccessProspect from './AccessProspect';
import { AdminTimer } from '../../components/Timer';
import FileUploader from "react-firebase-file-uploader";
import UserList from '../../components/Dialog/UserList';
import './AddProspectSection.scss';
import '../../Common/Clock.scss';
import firebase from "../../apis/firebase.apis";

function AddProspectSection({ sortby, onSortChange, success, onFileChange, pospectModal, openProspectModal, closeProspectModal,
  onSubmitAndClose, onManualPaymentClick, details, donation, selectUser, openUserList, closeUserList, ...props }) {
  const { authUser, campaignReports, filteredUsers, teams, users, searchUser } = props;
  const userList = filteredUsers ? filteredUsers : users;

  return (
    <div className='prospect-main'>
      <div className='section1'>
        <div className='title'>Good Luck {authUser.firstName} {authUser.lastName}</div>
        <div className='filter-title'>Filter By</div>
        <FormControl className="sort-radio" component="fieldset">
          {/* <RadioGroup
            className='filters'
            aria-label="sortBy"
            name="sortBy"
            value={sortby}
            onChange={onSortChange}
          >
            <FormControlLabel className="radio-item" value="allProspects" control={<Radio />} label='All Prospects' />
                    <FormControlLabel className="radio-item" value="assignedProspects" control={<Radio />} label='Your Prospects' />
                    <FormControlLabel className="radio-item" value="archivedProspects" control={<Radio />} label='Archived Prospects' />
                    <FormControlLabel className="radio-item" value="volunteerProspects" control={<Radio />} label="Volunteer's Prospects" />
          </RadioGroup> */}
          {JSON.parse(localStorage.getItem('userInfo')).role === "volunteer" ?
            <RadioGroup
              className='filters'
              aria-label="sortBy"
              name="sortBy"
              value={sortby}
              onChange={onSortChange}
            >
              <FormControlLabel className="radio-item" value="assignedProspects" control={<Radio />} label='Your Prospects' />
            </RadioGroup> :
            <RadioGroup
              className='filters'
              aria-label="sortBy"
              name="sortBy"
              value={sortby}
              onChange={onSortChange}
            >
              <FormControlLabel className="radio-item" value="allProspects" control={<Radio />} label='All Prospects' />
              <FormControlLabel className="radio-item" value="assignedProspects" control={<Radio />} label='Your Prospects' />
              <FormControlLabel className="radio-item" value="archivedProspects" control={<Radio />} label='Archived Prospects' />
              <FormControlLabel className="radio-item" value="volunteerProspects" control={<Radio />} label="Volunteer's Prospects" />
            </RadioGroup>}
          <div className='user-list-btn' onClick={(e) => onSortChange(e, 'volunteerProspects')}>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </FormControl>
      </div>

      <div className='section2'>
        <div className='title'>Total Number of Donors: <span>{campaignReports ? campaignReports.length : 0}</span></div>
        {JSON.parse(localStorage.getItem('userInfo')).role !== "volunteer" ?
          <Button variant="contained" onClick={openProspectModal}>
            <i className="fa fa-users" aria-hidden="true"></i>
            ADD NEW PROSPECT
        </Button> : null}
        <Button variant="contained" onClick={() => alert('Work in progress')}>
          <i className="fa fa-list-alt" aria-hidden="true"></i>
          SCRIPTS & INCENTIVES
        </Button>
        <input
          className={"ContainedButtons-input-2"}
          id="contained-button-file"
          type="file"
          accept=".csv"
          onChange={onFileChange}
          style={{ "display": "none" }}
        />
        {JSON.parse(localStorage.getItem('userInfo')).role !== "volunteer" ?
          <label className='upload-btn' >
            <Button variant="contained" component="span" className='span' >
              <i className="fa fa-cloud-upload" aria-hidden="true"></i>
              <div className="positionRelative">
                <span className="fileUploadText">UPLOAD CSV</span>
                <FileUploader
                  accept="csv/*"
                  name="csvURL"
                  className="fileUploadBtn"
                  randomizeFilename
                  onUploadSuccess={filename => success("Prospectcsv", filename, "imageURL")}
                  storageRef={firebase.storage().ref("Prospectcsv")}
                />

              </div>
            </Button>
          </label> : null}
      </div>

      <AdminTimer details={details} donation={donation} />

      {openUserList && <UserList userList={userList} searchUser={searchUser} selectUser={selectUser} openUserList={openUserList} closeUserList={closeUserList} />}

      {pospectModal && <AccessProspect
        closeProspectModal={closeProspectModal}
        onSubmitAndClose={onSubmitAndClose}
        campaignTeams={teams}
        onManualPaymentClick={onManualPaymentClick}
        campaignData={details}
        {...props}
      />}
    </div>
  );
}

export default AddProspectSection;