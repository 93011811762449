import React, { Component } from "react";
import { connect } from "react-redux";
import { getDate } from "../../Common/getDate";
import { getTime } from "../../Common/getTime";
import "./thankyou.scss";
import "font-awesome/css/font-awesome.min.css";
import { convertCurrencyNew } from './../../Common/CurrencyCalculator';

class Thankyou extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      campaign: "",
      donation: "",
      pMonth: ''
    };
    this.pMonth = 0;

  }

  componentDidMount() {
    const { donation } = this.state;

    // --------FIX for stripe cad campaign--------
    const originalAmount = donation;
    console.log("**##", this.props.donation)
    this.originalDonationAmount = this.props.donation && parseInt(this.props.donation.amount);
    console.log("###2222", this.originalDonationAmount)

    // ------------------------------------------


    let pMonth, perMonth;
    let currencyRecieved = this.props.donation.currency || 'usd';
    let currencyUsed = currencyRecieved && currencyRecieved.code && currencyRecieved.code.toUpperCase();
    if (this.props.donation.donation_type === "monthly") {
      if (donation % 1 === 0) {
        perMonth = donation * this.props.donation.duration;
        pMonth = donation;
      }
      else
        pMonth = parseFloat(
          donation
        ).toFixed(2);
      perMonth = parseFloat(
        donation * this.props.donation.duration
      ).toFixed(2);
      let am = convertCurrencyNew(pMonth, currencyUsed);
      let amNext = convertCurrencyNew(perMonth, currencyUsed);
      am.then(result => {
        amNext.then(resultNext => {
          this.setState({
            pMonth: result,
            perMonth: resultNext
          })
        })
      })
    }
    else {
      // let am = convertCurrencyNew(donation, currencyUsed);
      let am = new Promise(function(myResolve, myReject) { myResolve(true)});
      am.then(result => {
        this.setState({
          // donation: result //new yesterday
          donation: donation
        })
      })
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    const {
      donation,
      inputs,
      campaign: { data },
      history
    } = this.props;
    //new added for stripe cad campaign------------------
    this.paymentGateway = data && data.gateway;
    console.log("###!!!!", this.paymentGateway)
    // --------------------------


    const { firstName, processingFee } = inputs.toJS();
    if (data.mode == "e-commerce") {
      if (firstName && data && donation) {
        this.setState({
          name: firstName,
          campaign: data.name,
          donation: donation.donation_type == 'oneTime' ? (donation.feeCovered
            ? parseFloat(donation.amount) + parseFloat(processingFee || 0)
            : parseFloat(donation.amount)) : (donation.feeCovered
              ? (parseFloat(donation.amount) + parseFloat(processingFee || 0)) / donation.duration
              : parseFloat(donation.amount) / donation.duration)
        });
      } else {
        history.push("/");
      }
    }
    else {
      if (firstName && data && donation) {
        this.setState({
          name: firstName,
          campaign: data.name,
          donation: donation.donation_type == 'oneTime' ? (donation.feeCovered
            ? parseFloat(donation.amount) + parseFloat(processingFee || 0)
            : parseFloat(donation.amount)) : (donation.feeCovered
              ? parseFloat(donation.amount) + parseFloat(processingFee || 0) / donation.duration
              : parseFloat(donation.amount))
        });
      } else {
        history.push("/");
      }
    }

  }

  render() {
    console.log("###333", this.paymentGateway, "????", this.originalDonationAmount)
    let currencyRecieved = this.props.donation.currency || 'usd';
    let currencyUsed = currencyRecieved && currencyRecieved.code && currencyRecieved.code.toUpperCase();
    const { name, campaign, donation, pMonth } = this.state;
    const { inputs } = this.props;
    const { processingFee } = inputs.toJS();
    this.stripeCampProcessingFee = parseInt(Math.ceil(processingFee) || 0);
    console.log("####4444", processingFee)
    let perMonth = 0;
    if (this.props.donation.donation_type === "monthly") {
      if (processingFee && processingFee > 0) {
        perMonth = (
          (Number.parseFloat(this.props.donation.amount) +
            Number.parseFloat(processingFee)) / this.props.donation.duration
        ).toFixed(2);
      } else {
        perMonth = Number.parseFloat(this.props.donation.amount / this.props.donation.duration).toFixed(2);
      }
    }
    if (donation % 1 === 0) {
      this.pMonth = donation * this.props.donation.duration
    }
    else {
      this.pMonth = parseFloat(
        donation * this.props.donation.duration
      ).toFixed(2)
    }
    return (
      <div className="thankyou-wrapper">
        <img src="/images/logo.png" alt="LOGO" />
        <div className="header">
          <h3>THANK YOU,</h3>
          <h6>YOU ARE AWESOME</h6>
          <img src="/images/thankyou.png" alt="HANDSHAKE" />
        </div>
        <div className="message">
          <p>
            Dear <strong>{name} ,</strong>
          </p>
          <p>
            Thank you for your generous donation & support of {campaign}.
          </p>
          <br />
          {this.props.donation.donation_type &&
            this.props.donation.donation_type === "monthly" ? (
              <p>
                Following is the summary of your pledge toward's the cause which
                will be charged each month upon the completion of the campaign
              </p>
            ) : (
              <p>
                Following is a summary of your pledge toward's the cause which
                will be charged upon completion of the campaign.
              </p>
            )}
          <br />
          <p>From all of us at Pledje & {campaign}, THANK YOU!</p>
          {(this.paymentGateway == "Stripe" && this.stripeCampProcessingFee && <p>FOR YOUR DONATION AND COVERING THE TRANSACTION COST (${this.stripeCampProcessingFee}) ASSOCIATE WITH THIS DONATION.</p>) ||""}
          <br />
          {this.props.donation.donation_type === "monthly" ? (
            <div>
              <p>
                <strong>Monthly Commitment</strong>
              </p>
              {this.paymentGateway != "Stripe" && <p>
                Total Amount of Donation <strong>Per Month</strong> :{" "}
                <span>{`$  ${parseFloat(pMonth).toFixed(2)}`}</span>
              </p>
              }

              {this.paymentGateway == "Stripe" && <p>
                Total Amount of Donation <strong>Per Month</strong> :{" "}
                <span>${this.originalDonationAmount + this.stripeCampProcessingFee} {this.stripeCampProcessingFee ? "(" + this.originalDonationAmount + "+" + this.stripeCampProcessingFee + " Transaction Cost" + ")" : ""}</span>
              </p>
              }
              <p>
                Number of Months: <span>{this.props.donation.duration}</span>
              </p>
              { this.paymentGateway != "Stripe" && <p>
                Total Amount of Donation :{" "}
                <span>
                  {`$ ${parseFloat(this.state.perMonth).toFixed(2)}`}
                </span>
              </p>
              }
              { this.paymentGateway == "Stripe" && <p>
                Total Amount of Donation :{" "}
                <span>
                  ${(this.originalDonationAmount + this.stripeCampProcessingFee) * this.props.donation.duration}
                </span>
              </p>
              }
              {this.props.donation.selectedItems ?
                <p>Items Donated:</p> : null}
              {this.props.donation.selectedItems &&
                this.props.donation.selectedItems.map((item, index) => {
                  if (item.title !== "general donation" && this.props.donation.selectedItems.length > 1)
                    return (
                      <div>
                        <p>
                          {index + 1}) : {item.title}. {"X "} {item.count}
                        </p>
                      </div>
                    );
                  if (item.title !== "general donation" && this.props.donation.selectedItems.length <= 1)
                    return (
                      <div>
                        <p>
                          {item.title}. {"X "} {item.count}
                        </p>
                      </div>
                    );

                })}

              <p>
                Date: <span>{getDate(new Date())}</span>
                <span>{"--"}</span>
                <span>{getTime(new Date())}</span>
              </p>
            </div>
          ) : (
              <div>
                <h3>One Time Donation</h3>
                {this.paymentGateway != "Stripe" && <p>
                  Total Amount of Donation :{" "}
                  <span>{`$${parseFloat(donation).toFixed(2)}`}</span>
                </p>
                }
                {this.paymentGateway == "Stripe" && <p>
                  Total Amount of Donation :{" "}
                  <span>${this.originalDonationAmount + this.stripeCampProcessingFee} {this.stripeCampProcessingFee ? "(" + this.originalDonationAmount + "+" + this.stripeCampProcessingFee + "Transaction Cost" + ")" : ""} </span>
                </p>
                }
                {this.props.donation.selectedItems ?
                  <p>Items Donated:</p> : null}
                {this.props.donation.selectedItems &&
                  this.props.donation.selectedItems.map((item, index) => {
                    if (item.title !== "general donation" && this.props.donation.selectedItems.length > 1)
                      return (
                        <div>
                          <p>
                            {index + 1}) : {item.title}. {"X "} {item.count}
                          </p>
                        </div>
                      );
                    if (item.title !== "general donation" && this.props.donation.selectedItems.length <= 1)
                      return (
                        <div>
                          <p>
                            {item.title}. {"X "} {item.count}
                          </p>
                        </div>
                      );
                  })}
                <p>
                  Date : <span>{getDate(new Date())}</span>
                  <span>{"--"}</span>
                  <span>{getTime(new Date())}</span>
                </p>
              </div>
            )}
        </div>
        <div className="footer">
          <i className="fa fa-facebook" />
          <i className="fa fa-twitter" />
          <br />
          <input type="button" value='Go Back To Campaign' onClick={() => {
            this.props.history.goBack();
          }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const team = state.team.get("team_data");
  const campaign = state.campaign.get("campaignData");

  return {
    inputs: state.checkout.get("inputs"),
    campaign: Object.entries(campaign).length !== 0 ? campaign : team,
    donation: state.campaign.get("checkout")
  };
};

export default connect(mapStateToProps)(Thankyou);
