import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as checkoutActions from "../../redux/modules/checkout";
import * as campaignActions from "../../redux/modules/campaign";
import { fetchTeams } from "../../redux/modules/TeamManagement/action-creator";
import { Monthly } from "./Calculate";
import { OneTime } from "./OneTime";
import Summary from "./Summary";
import { UserInfo } from "./UserInfo";
import { CardInfo } from "./CardInfo";
import { ChequeInfo } from "./ChequeInfo";
import { BillingInfo } from "./BillingInfo";
import CampaignDetails from "../../components/CampaignDetails";
import Checkbox from "@material-ui/core/Checkbox";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "../../components/Notification/Error";
import { ValidatorForm } from "react-material-ui-form-validator";
import { getDate } from "../../Common/getDate";
import { getTime } from "../../Common/getTime";
import "./Checkout.scss";
import { sendBNotification } from './../../redux/modules/CampaignReports/action-creator';
import { convertCurrencyNew } from './../../Common/CurrencyCalculator';

class Checkout extends Component {
  constructor() {
    super();

    this.state = {
      total_donation: "",
      donors: [],
      error: "",
      notify: false,
      loading: false,
      feeCovered: false,
      donation: {},
      raffleCategories: [],
      message: "",
      showMessage: false,
      isError: false,
      team: ''
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    const {
      total_donation,
      donations,
      donation,
      history,
      prospect,
      fetchTeams,
      campaign: { data },
    } = this.props;


    if (
      Object.entries(donation).length === 0 &&
      Object.entries(prospect).length === 0
    ) {
      history.push("/");
    } else {
      fetchTeams(data.organizationId, data.campaignId);
      this.setState({ team: data.teamInfo ? { id: data.teamInfo.id, teamName: data.teamInfo.teamName } : "", donation });
      if (total_donation.data) {
        this.setState({ total_donation: total_donation.data.total });
      }
      if (donations.data) {
        this.setState({ donors: donations.data });
      }
      if (prospect) {
        const { CheckoutActions } = this.props;
        for (let name in prospect) {
          CheckoutActions.changeInput({ name, value: prospect[name] });
        }
      }
    }
  }

  handleDonate = async () => {
    const { feeCovered, donation, raffleCategories, team } = this.state;
    let donationAmount = 0;
    const {
      history,
      CheckoutActions,
      campaign: { data },
      inputs,
      onCampaignActions,
      prospect
    } = this.props;
    const userInfo = inputs.toJS();
    if (data.mode == "e-commerce") {
      donationAmount = donation.amount;
    }
    else {
      if (donation.donation_type == "monthly") {
        donationAmount = donation.amount * donation.duration
      }
      else {
        donationAmount = donation.amount;
      }
    }
    const d = {
      ...donation,
      feeCovered
    };
    if (donation.duration === "" && donation.donation_type === "monthly") {
      this.setState({ error: `Please enter duration`, notify: true });
    } else if (!donation.amount || donation.amount === "0") {
      this.setState({ error: `Invalid Donation Amount`, notify: true });
    } else {
      onCampaignActions.setDonation(d);
      // ---------new code for effecting bonus round matchfactor--------

      //****** new logic to include social share amount april 2021

      let shareGoal2 = data && data.shareGoal ? parseInt(data.shareGoal) : 0;
      let shareAmount2 = data && data.shareAmount ? parseInt(data.shareAmount) : 0;
      let shareCount2 = data && data.shareCount ? parseInt(data.shareCount) : 0;

      let totalCampaignDonationIncludingShareAmount = shareCount2 >= shareGoal2 ? (this.state.total_donation + shareAmount2) : this.state.total_donation;


      //******************** */



      const timeRemaining = new Date(data.campaignEndTime) - new Date();
      // const isBonusRoundActive = this.state.total_donation > parseInt(data.goalAmount) && timeRemaining >= 0;
      // const isBonusRoundActive = this.state.total_donation > parseInt(data.goalAmount);
      const isBonusRoundActive = totalCampaignDonationIncludingShareAmount > parseInt(data.goalAmount);
      // console.log("data??", data, "bonus>>>", isBonusRoundActive, "tt", totalCampaignDonationIncludingShareAmount)
      // return false
      // -----------------------------------

      let body = {
        items: donation.selectedItems
          ? donation.selectedItems.filter(d => d.title !== "general donation")
          : [],
        campaignId: data.campaignId,
        currency: donation.currency ? donation.currency.code || donation.currency : 'usd',
        teamId: team ? team.id : '',
        organizationId: prospect.organizationId
          ? prospect.organizationId
          : data.organizationId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        dedicationMessage: userInfo.message,
        paymentType: userInfo.method,
        creditCardNumber: userInfo.creditCardNumber,
        cvc: userInfo.cvc,
        expiryMonth: userInfo.expirationMonth,
        expiryYear: userInfo.expirationYear,
        routing_number: userInfo.routing_number,
        account_number: userInfo.account_number,
        billingAddress: userInfo.billingAddress,
        billingCity: userInfo.billingCity,
        billingState: userInfo.billingState,
        billingZip: userInfo.zipCode,
        billingPhone: userInfo.mobilePhone,
        billingCountry: "CA",
        donationAmount: donation.donation_type == "oneTime" ? parseFloat(donationAmount) : parseFloat(donationAmount / donation.duration),
        processingFee: feeCovered ? parseFloat(userInfo.processingFee || 0).toFixed(2) : 0,
        processingFeeCovered: feeCovered,
        //totalChargedAmount: parseFloat(donation.amount) + parseFloat(userInfo.processingFee || 0),
        totalChargedAmount: donation.donation_type == "oneTime" ? parseFloat(donationAmount) + parseFloat(userInfo.processingFee || 0) : (parseFloat(donationAmount) + parseFloat(userInfo.processingFee || 0)) / donation.duration,
        donationDate: new Date(),
        gateway: data.gateway,
        campaignEndDate: data.campaignEndTime,
        accountId: data.stripeConnectedAccountId,
        isManual: false,
        displayName: !userInfo.displayName,
        campaignPage: data.name,
        alias: !userInfo.displayName ? userInfo.donorsListName : "Anonymous",
        mode: donation.donation_type,
        noOfMonths: donation.duration ? donation.duration : "",
        merchantId: data.splashMerchantId,
        prospectId: prospect.id ? prospect.id : "",
        raffleCategories,
        sendGridDate: getDate(new Date()) + "--" + getTime(new Date()),
        isBonusRoundActive: isBonusRoundActive,
        specialNotes :userInfo.specialNotes? userInfo.specialNotes :""
      };
      // ---------------------------------------
      body.donationAmountForStripeCamp = parseFloat(donationAmount).toFixed(0);
      body.processingFeeForStripeCamp = Math.ceil(body.processingFee);
      console.log("chk$$", body)
      // return false;
      // ----------------------

      if (body.noOfMonths == 1 || body.noOfMonths == "1") {
        this.setState({ error: `Please enter month greater than 1 in recurring donation`, notify: true });
        return false;
      }

      this.setState({ loading: true });

      let am = convertCurrencyNew(body.donationAmount, donation.currency.code);
      // let am = new Promise(function(myResolve, myReject) { myResolve(true)});
      let convertedProcessingFee = 0;
      if (donation.currency.code == 'usd') {
        console.log(">>>>>>>>>>>>>currency", donation.currency);
        convertedProcessingFee = body.processingFee;
      } else {
        convertedProcessingFee = await convertCurrencyNew(body.processingFee, donation.currency.code);
      }
      am.then((result) => {
        body.processingFee = parseFloat(convertedProcessingFee).toFixed(2);
        body.totalChargedAmount = parseFloat(result).toFixed(2);
        body.donationAmount = parseFloat(result).toFixed(2);

        // body.processingFee = parseFloat(body.processingFee);
        // body.totalChargedAmount = parseFloat(body.donationAmount);
        // body.donationAmount = parseFloat(body.donationAmount);

        console.log("jay ho?????????????" , body)

        // return false;

        CheckoutActions.postCheckoutInfo(body)
          .then(res => {

            this.setState({ loading: false });
            history.push("/thank-you");

          })
          .catch(err => {
            this.setState({
              loading: false,
              error: `An Error Occurred While Processing Your Request: ${err.response.data.error
                ? err.response.data.error
                : err.response.data.message
                }`,
              notify: true
            });
          });
      })
      // for (var loop = 0; loop < body.items.length; loop++) {
      //   body.items[loop].donation = parseInt(body.items[loop].donation);
      // }
    }
  };



  emailValidChange = (name, value) => {
    const { CheckoutActions } = this.props;
    CheckoutActions.changeInput({ name, value });
  }

  cvvChange = (name, value) => {
    const { CheckoutActions } = this.props;
    CheckoutActions.changeInput({ name, value });
  }

  inputChange = e => {
    const { CheckoutActions } = this.props;
    const { name, value, checked } = e.target;



    if (name === "creditCardNumber" && value.length > 19) {
      return;
    } else if (name === "cvc" && value.length > 3) {
      return;
    } else if (name === "expirationMonth" && value.length > 2) {
      return;
    } else if (name === "mobilePhone" && value.length > 16)
      return;
    else if (name === "expirationYear" && value.length > 2) {
      return;
    }

    if (name === "displayName") {
      CheckoutActions.changeInput({ name, checked });
    } else {
      CheckoutActions.changeInput({ name, value });
    }
  };

  handleMultipleRaffle = e => {
    e.persist();
    this.setState(prevState => ({
      raffleCategories: e.target.checked
        ? [...prevState.raffleCategories, e.target.value]
        : prevState.raffleCategories.filter(r => r !== e.target.value)
    }));
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleNotification = () => {
    this.setState({ notify: false });
  };

  checkProcessing = (e, value) => {
    const { CheckoutActions } = this.props;
    const { checked, name } = e.target;
    if (!checked) {
      value = "0";
      this.setState({ feeCovered: false });
    } else {
      this.setState({ feeCovered: true });
    }
    CheckoutActions.changeInput({ name, value });
  };

  handleMonths = e => {
    const reg = new RegExp("^\\d+$");
    if (
      (reg.test(e.target.value) &&
        parseInt(e.target.value) <= 12 &&
        parseInt(e.target.value) >= 1) ||
      e.target.value === ""
    ) {
      e.persist();
      this.setState(prevState => ({
        donation: {
          ...prevState.donation,
          duration: e.target.value === "" ? "" : parseInt(e.target.value)
        }
      }));
    }
  };

  decrement = () => {
    this.setState(prevState => ({
      donation: {
        ...prevState.donation,
        duration:
          prevState.donation.duration === ""
            ? 1
            : parseInt(prevState.donation.duration) - 1
      }
    }));
  };

  increment = () => {
    this.setState(prevState => ({
      donation: {
        ...prevState.donation,
        duration:
          prevState.donation.duration === ""
            ? 1
            : parseInt(prevState.donation.duration) + 1
      }
    }));
  };

  handleAmount = e => {
    const value = e.target.value;
    this.setState(prevState => ({
      donation: {
        ...prevState.donation,
        amount: value
      }
    }));
  };

  changeDonation = (e, type) => {
    this.setState(prevState => ({
      donation: {
        ...prevState.donation,
        donation_type: type
      }
    }));

    if (type === "monthly") {
      if (
        parseInt(e.target.value) <= 10 ||
        parseInt(e.target.value > 0) ||
        e.target.value === ""
      ) {
        e.persist();
        this.setState(prevState => ({
          donation: {
            ...prevState.donation,
            duration: parseInt(e.target.value) || ""
          }
        }));
      }
    }
  };

  updateItems = donation => {
    this.setState({ donation });
  };

  handleError = err => {
    this.setState({
      error: "Please Fix The Marked Fields",
      notify: true
    });
  };

  handleTeam = (e) => {
    const { teams } = this.props;
    const team = teams.find(t => e.target.value === t.id);

    this.setState({ team: team ? { id: team.id, teamName: team.teamName } : '' });
  }

  render() {
    const {
      total_donation,
      donors,
      notify,
      error,
      loading,
      donation,
      donation: { currency },
      feeCovered,
      team
    } = this.state;
    const {
      campaign: { data },
      inputs,
      location,
      history,
      teams,
      fullTeamsDetails
    } = this.props;

    const { method } = inputs.toJS();
    let processingFee = 0;

    if (data) {
      const { transactionCostPercent, transactionCostFlat } = data;
      processingFee = (
        (parseFloat(transactionCostPercent) / 100) *
        parseFloat(donation.amount) +
        parseFloat(transactionCostFlat)
      ).toFixed(2);
    }
    let processingFeeMonthly = 0;
    if (donation.donation_type === "monthly") {
      processingFeeMonthly = Number.parseFloat(processingFee).toFixed(2);
    }

    // For bonus round new code addeed-------------------

    //****** new logic to include social share amount april 2021

    let shareGoal2 = data && data.shareGoal ? parseInt(data.shareGoal) : 0;
    let shareAmount2 = data && data.shareAmount ? parseInt(data.shareAmount) : 0;
    let shareCount2 = data && data.shareCount ? parseInt(data.shareCount) : 0;

    let totalCampaignDonationIncludingShareAmount = shareCount2 >= shareGoal2 ? (total_donation + shareAmount2) : total_donation;


    //******************** */



    const timeRemaining = new Date(data.campaignEndTime) - new Date();
    // const isBonusRoundActive = total_donation > parseInt(data.goalAmount) && timeRemaining >= 0;
    const isBonusRoundActive = totalCampaignDonationIncludingShareAmount > parseInt(data.goalAmount);
    console.log("bbtt>>>", total_donation)
    console.log("bb2222>>>", timeRemaining)
    console.log("bb3333333>>>", isBonusRoundActive)
    console.log("bbtt444444", totalCampaignDonationIncludingShareAmount)
    //----------------------------------------

    return (
      <div className="checkout_component_wrapper">
        <div className="wrapper">
          <div className="content">
            <Button className="back-btn" onClick={this.goBack}>
              BACK
            </Button>
            <ValidatorForm
              ref="form"
              onSubmit={this.handleDonate}
              onError={this.handleError}
              instantValidate={false}
            >
              {donation.cart ? (
                <Summary
                  details={data}
                  changeDonation={this.changeDonation}
                  donation={donation}
                  updateItems={this.updateItems}
                />
              ) : donation.donation_type !== "oneTime" ? (
                <Monthly
                  handleMonths={this.handleMonths}
                  name={data && data.name}
                  donation={donation}
                  increment={this.increment}
                  decrement={this.decrement}
                  handleAmount={this.handleAmount}
                  isBonusRoundActive={isBonusRoundActive}
                  campaignFullData={data}
                />
              ) : (
                <OneTime currency={currency} donation={donation} name={data && data.name} isBonusRoundActive={isBonusRoundActive} campaignFullData={data} />
              )}
              <UserInfo
                handleTeam={this.handleTeam}
                team={team}
                teams={teams}
                inputs={this.props.inputs}
                donation={donation}
                data={data}
                inputChange={this.inputChange}
                emailValidChange={this.emailValidChange}
                handleMultipleRaffle={this.handleMultipleRaffle}
              />

              {data && data.enableTransactionCost === false ?
                null :
                this.state.donation.donation_type === "monthly" ? (
                  <div className="process_fee">
                    <label>
                      To help {data && data.name} may we ask you to cover the
                      Monthly processing fee of {currency.sign}{processingFeeMonthly} ?
                      <span className="yes">
                        Yes
                        <Checkbox
                          color="default"
                          name="processingFee"
                          onChange={e => this.checkProcessing(e, processingFee)}
                        />
                      </span>
                    </label>
                  </div>
                ) : (
                  <div className="process_fee">
                    <label>
                      To help {data && data.name} may we ask you to cover the
                      processing fee of {currency && currency.sign}{processingFee} ? Thank you
                      <span className="yes">
                        Yes{" "}
                        <Checkbox
                          checked={feeCovered}
                          color="default"
                          name="processingFee"
                          onChange={e => this.checkProcessing(e, processingFee)}
                        />
                      </span>
                    </label>
                  </div>
                )
              }

              {method === "credit card" && (
                <CardInfo {...this.props} cvvChange={this.cvvChange} inputChange={this.inputChange} />
              )}
              {method === "check" && (
                <ChequeInfo {...this.props} inputChange={this.inputChange} />
              )}

              <BillingInfo {...this.props} inputChange={this.inputChange} />
              <div className="submit-donation">
                <Button disabled={loading} type="submit" className="donate-btn">
                  DONATE
                </Button>
                {loading && (
                  <CircularProgress size={48} className="circular-loader" />
                )}
              </div>
            </ValidatorForm>
          </div>
          <Hidden only={["xs", "sm"]}>
            <CampaignDetails
              location={location}
              details={data}
              donation={total_donation}
              donors={donors}
              history={history}
              fullTeamsDetails={teams}
            />
          </Hidden>
        </div>
        <div style={{ clear: "both" }} />
        <Error
          open={notify}
          message={error}
          handleClose={this.handleNotification}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const team = state.team.get("team_data");
  const campaign = state.campaign.get("campaignData");

  return {
    inputs: state.checkout.get("inputs"),
    campaign: Object.entries(campaign).length !== 0 ? campaign : team,
    donation: state.campaign.get("checkout"),
    donations: state.campaign.get("donations"),
    total_donation: state.campaign.get("total_donation"),
    prospect: state.prospectManagementReducer.prospect,
    teams: state.teamManagementReducer.teams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    CheckoutActions: bindActionCreators(checkoutActions, dispatch),
    onCampaignActions: bindActionCreators(campaignActions, dispatch),
    fetchTeams: bindActionCreators(fetchTeams, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
