import React from 'react';
import { AppBar, IconButton, Hidden, NativeSelect } from '@material-ui/core';
import TvIcon from '@material-ui/icons/TvRounded';
import styles from './style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeaderComponent from '../HeaderComponent';
import './campaignreport.scss';
import './custom.scss';

function CampaignHeader({ campaignList, view, toggleCapture, campaign, onFormInputValueChange }) {
 
  return (
    <div>
    <Hidden smDown implementation="css">
      <HeaderComponent
        title1 = "CAPTURE"
        title2 = "DONATIONS"
        baseColor = {styles.theme.color}
        select = {true}
        capture={true}
        view={view}
        toggleCapture={toggleCapture}
        campaignList = {campaignList}
        campaign={campaign}
        onCampaignChange={onFormInputValueChange}
      />
    </Hidden>
    <Hidden mdUp implementation="css">
      <AppBar position="static" style={styles.appBar}>
        <div>
          <div style = {{padding : 5, display  : 'flex' , alignItems : 'center'}}>
            <IconButton style={styles.menuButton} color="inherit" aria-label="tv" rounded="true">
                <TvIcon  style={styles.theme}/>
            </IconButton>
            <div style = {{ marginLeft : 5 }}>
              <b>CAMPAIGN </b> <span style = {{marginLeft :2 }}>REPORT </span>
            </div>
          </div>

          <div  style = {{display  : 'grid' , alignItems : 'center' ,gridTemplateColumns: 'auto auto'}}>
            <div style ={{whiteSpace:'nowrap'}}>Select Campaign</div>
            <NativeSelect disableUnderline
              name="selectCampaign"
              style ={{...styles.inputStyle , ...styles.h50,color:'#aaabaa'}}
              IconComponent={() => (
                <ExpandMoreIcon className ="view-down-icon" />
                )}>
              >
              <option value="campaignOne" className = "view-option">Campaign one</option>
              <option value="campaignTwo" className = "view-option">Campaign two</option>
            </NativeSelect>
          </div>
        </div>
      </AppBar>
    </Hidden>
   </div>
  );
}

export default CampaignHeader;