import sessionStore from "../lib/sessionStorage";
import { getRates } from './Exchange';

export const convertCurrency = (amount, currency) => {
  const rates = sessionStore.get("exchange");
  if (rates && currency) {
    let convert = 1;
    const i = rates[currency.toUpperCase()];
    if (i) {
      convert = rates[currency.toUpperCase()];
    }
    return Math.floor(parseInt(amount) * convert);
  }
  else {
    let convert = 1;
    return Math.floor(parseInt(amount) * convert);
  }
}

export const convertCurrencyNew = (amount, currency) => {

  if(currency)
  {
    let conv = getRates(currency.toUpperCase());
    return conv.then(function (result) {
      return parseFloat(amount * result.USD).toFixed(2);
    });
  }
}
