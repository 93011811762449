import React, { Component } from "react";
import { Hidden } from "@material-ui/core";
import _ from "lodash";
import { convertCurrency } from "../../Common/CurrencyCalculator";

class Items extends Component {

  constructor(props) {
    super(props);
    this.items = [];
  }

  lastEnteredVal = 0;
  donate = (e, item, index) => {

    // ---old code---
    // if (e.target.value > item.total - item.donated) {
    //   e.target.value = this.lastEnteredVal;
    //   return;
    // }
    // -----------

    // ****************new code******

    if (item.total !== "") {
      if (e.target.value > item.total - item.donated) {
        e.target.value = this.lastEnteredVal;
        return;
      }
    }
    // *******

    this.lastEnteredVal = e.target.value;

    const { donate } = this.props;
    let donated = [...this.props.selectedItems];

    if (donated.length === 0) {
      donated = [{ ...item, count: e.target.value }];
    } else {
      if (!e.target.value) {
        _.remove(donated, (d) => d.title === item.title);
      } else {
        const find = donated.find((d) => d.title === item.title);
        if (find) {
          donated.map((d) => {
            if (d.title === item.title) {
              d.count = e.target.value;
              d.index = index;
              return d;
            }
            return d;
          });
        } else {
          item.count = e.target.value;
          item.index = index;
          donated.push(item);
        }
      }
    }
    donate(donated);
  };

  componentWillMount() {
    let { items } = this.props;
    // items.sort(function (a, b) {
    //   return parseInt(a.price) - parseInt(b.price);
    // });
    this.items = items.map(ele => ele);
  }

  render() {
    const { showMore, currency, location } = this.props;
    //const { items } = this.props;


    return (
      <section className="campaign-items flex-column">
        <Hidden smDown>
          {this.items.map((item, index) => (
            <div className="item">
              {/* {item.total - item.donated > 0 ? ( */}
              {item.total - item.donated > 0 || item.total === "" ? (
                <div className="left flex-column">
                  <span>I would like to donate:</span>
                  <input type="number" onChange={(e) => this.donate(e, item, index)} />
                </div>
              ) : (
                  <div style={{ background: 'none' }} className="left flex-column">
                    <img src="images/badge.jpg" />
                  </div>
                )}

              <div className="center flex-row">
                <div className="img-container">
                  <img
                    src={item.image}
                    alt="item-img"
                    width={150}
                    height={100}
                  />
                </div>
                <div className="details flex-column">
                  <span className="title">
                    {item.title}
                    <span className="flex-row">
                      <b>
                        {currency.sign}
                        {convertCurrency(item.price, currency.code)}
                      </b>
                      <p>Each</p>
                    </span>
                  </span>
                  <p className="desc">
                    {showMore === item.id
                      ? item.desc.substr(0, 130)
                      : item.desc}
                  </p>
                  {showMore !== item.id && item.desc.length > 130 && (
                    <p onClick={() => showMore(item.id)} className="show-more">
                      Show More...
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="right flex-column">
                <div className="total">
                  <b>Total Available:</b>
                  <b>
                    {item.total - item.donated > 0
                      ? `${item.total - item.donated}`
                      : 0}
                  </b>
                </div>
                <div className="total">
                  <p>Donated:</p>
                  <p>
                    {item.donated}/{item.total}
                  </p>
                </div>
              </div> */}
              <div className="right flex-column">
                {(item.total !== '') ? <div className="total">
                  <b>Total Available:</b>
                  <b>
                    {item.total - item.donated > 0
                      ? `${item.total - item.donated}`
                      : 0}
                  </b>
                </div> : <div><b></b></div>}
                {item.total !== '' ? <div className="total">
                  <p>Donated:</p>
                  <p>
                    {item.donated}/{item.total}
                  </p>
                </div> : <p>Donated:0</p>}
              </div>

            </div>
          ))}
        </Hidden>
        <Hidden mdUp>
          {this.items.map((item) => (
            <div className="mobile-item mobile-item--custom">
              <div className="center flex-row">
                <div className="img-container">
                  <img
                    src={item.image}
                    alt="item-img"
                    width={150}
                    height={100}
                  />
                </div>
                <div className="details flex-column">
                  <div className="title">{item.title}</div>
                  <div className="price flex-row">
                    <b>
                      {currency.sign}
                      {convertCurrency(item.price, currency.code)}
                    </b>
                    <p>Each</p>
                  </div>
                  {item.total - item.donated > 0 ? (
                    <div className="donation flex-row">
                      <span>I would like to donate:</span>
                      <input type="number" min="1" max={item.total - item.donated} onChange={(e) => this.donate(e, item)} />
                    </div>
                  ) : (
                      <div style={{ background: 'none' }} className="left flex-column flex-d">
                        <img src="images/badge.jpg" />
                      </div>
                    )}

                </div>
              </div>
              <div className="desc-container">
                <p className="desc">
                  {showMore === item.id ? item.desc.substr(0, 130) : item.desc}
                </p>
                {showMore !== item.id && item.desc.length > 130 && (
                  <p onClick={() => showMore(item.id)} className="show-more">
                    Show More...
                  </p>
                )}
              </div>
              <div className="count flex-row">
                <b>Total Available:</b>
                <b>
                  {item.total - item.donated > 0
                    ? `${item.total - item.donated}`
                    : 0}
                </b>
                <p>
                  Donated: {item.donated}/{item.total}
                </p>
              </div>
            </div>
          ))}
        </Hidden>
      </section>
    );
  }
}

export { Items };
