import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export const DisplayOptions = ({
  fields,
  handleCheckbox
}) => {
  return (
    <div className="campaign-checkbox">
      <h3>
        <b>Choose Fields To</b> <span>Display or Export</span>
      </h3>
      <div className="columns">
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.firstName}
              value="firstName"
              onChange={handleCheckbox("firstName")}
            />
          }
          label="First Name"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.lastName}
              value="lastName"
              onChange={handleCheckbox("lastName")}
            />
          }
          label="Last Name"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.email}
              value="email"
              onChange={handleCheckbox("email")}
            />
          }
          label="Email"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.donationDate}
              value="donationDate"
              onChange={handleCheckbox("donationDate")}
            />
          }
          label="Time of Donation"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.alias}
              value="alias"
              onChange={handleCheckbox("alias")}
            />
          }
          label="Alias/Anonymous"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.donationAmount}
              value="donationAmount"
              onChange={handleCheckbox("donationAmount")}
            />
          }
          label="Donation Amount"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.donationMatched}
              value="donationMatched"
              onChange={handleCheckbox("donationMatched")}
            />
          }
          label="Donation Matched"
        />

        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.totalDonation}
              value="totalDonation"
              onChange={handleCheckbox("totalDonation")}
            />
          }
          label="Total Before Match"
        />


        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.totalDonationInUSD}
              value="totalDonationInUSD"
              onChange={handleCheckbox("totalDonationInUSD")}
            />
          }
          label="Converted Currency to USD"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.paymentType}
              value="paymentType"
              onChange={handleCheckbox("paymentType")}
            />
          }
          label="Payment Method"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.teamId}
              value="teamId"
              onChange={handleCheckbox("teamId")}
            />
          }
          label="Team Member"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.mode}
              value="mode"
              onChange={handleCheckbox("mode")}
            />
          }
          label="Payment Mode"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.transactionCostCovered}
              value="transactionCostCovered"
              onChange={handleCheckbox("transactionCostCovered")}
            />
          }
          label="Transaction Cost Covered"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.transactionCost}
              value="transactionCost"
              onChange={handleCheckbox("transactionCost")}
            />
          }
          label="Transaction Cost"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.transactionId}
              value="transactionId"
              onChange={handleCheckbox("transactionId")}
            />
          }
          label="Transaction ID"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.billingPhone}
              value="billingPhone"
              onChange={handleCheckbox("billingPhone")}
            />
          }
          label="Phone Number"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.billingAddress}
              value="billingAddress"
              onChange={handleCheckbox("billingAddress")}
            />
          }
          label="Address"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.dedicationMessage}
              value="dedicationMessage"
              onChange={handleCheckbox("dedicationMessage")}
            />
          }
          label="Dedication Message"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.status}
              value="status"
              onChange={handleCheckbox("status")}
            />
          }
          label="Donation Status"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.raffle}
              value="raffle"
              onChange={handleCheckbox("raffle")}
            />
          }
          label="Raffle Categories"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.specialNotes}
              value="specialNotes"
              onChange={handleCheckbox("specialNotes")}
            />
          }
          label="Special Instructions"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.items}
              value="items"
              onChange={handleCheckbox("items")}
            />
          }
          label="Items Donated"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.state}
              value="state"
              onChange={handleCheckbox("state")}
            />
          }
          label="State"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.city}
              value="city"
              onChange={handleCheckbox("city")}
            />
          }
          label="City"
        />
        <FormControlLabel
          className="label"
          control={
            <Checkbox
              className="checkbox"
              checked={fields.zip}
              value="zip"
              onChange={handleCheckbox("zip")}
            />
          }
          label="Zip Code"
        />
      </div>
    </div>
  );
};