import React, { Component } from "react";
import { getSign } from "../../Common/getSign";
import moment from "moment";
import "./Donors.scss";
import animationImage from '../../assets/images/heart.png';

class Donars extends Component {
  constructor() {
    super();
    this.teamSearchInput = React.createRef()
    this.state = {
      filteredDonors: [],
      fullTeamDetailsFilteredArr: [],
      show_btn: false,
      length: 0,
      expanded: false,
      allTeamsLength: 0,
    };
  }

  componentWillMount() {
    const { donors } = this.props;
    let { fullTeamDetailsFilteredArr } = this.props
    fullTeamDetailsFilteredArr = (fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length > 0) ? fullTeamDetailsFilteredArr : [];

    this.setState({
      fullTeamDetailsFilteredArr: fullTeamDetailsFilteredArr.filter((team, i) => i < 10) || 0,
      filteredDonors: (donors && donors.filter((donor, i) => i < 10)) || [],
      show_btn: (donors && donors.length > 10 ? true : false) || (fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length > 10 ? true : false),
      length: donors && donors.length,
      allTeamsLength: fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length
    });
  }

  componentWillReceiveProps(nextProps) {
    const { expanded } = this.state;
    const { donors } = nextProps;

    this.setState({
      filteredDonors: !expanded
        ? (donors && donors.filter((donor, i) => i < 10)) || []
        : donors,
      show_btn: donors && (donors.length > 10 && !expanded) ? true : false,
      length: donors && donors.length
    });
  }

  expand = () => {
    const { donors, fullTeamDetailsFilteredArr } = this.props;

    this.setState({ filteredDonors: donors, show_btn: false, expanded: true, fullTeamDetailsFilteredArr });
  };

  searchTeams = () => {

    let allSearchedTeams = [];
    let keyword = (this.teamSearchInput.current.value).trim().toLowerCase();
    let tempString = "";
    let regex = new RegExp(keyword, 'g');
    let { fullTeamDetailsFilteredArr } = this.props;
    fullTeamDetailsFilteredArr = (fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.length > 0) ? fullTeamDetailsFilteredArr : [];
    allSearchedTeams = fullTeamDetailsFilteredArr.filter((team, index) => {
      tempString = team.teamName.trim().toLowerCase();
      if (regex.test(tempString)) {
        return true;
      }
    });
    Promise.all(allSearchedTeams).then(allSearchedTeams => {
      this.setState({
        fullTeamDetailsFilteredArr: allSearchedTeams
      })
    });
  }

  render() {
    const { filteredDonors, show_btn, length } = this.state;
    const { team, allTeams, campaignCurrency, mainCampaignPath } = this.props;
    let flag = '';
    var liClasses = '';
    let animationClass = '';
    console.log("at>>", allTeams, '>>>>', team, this.state.fullTeamDetailsFilteredArr, '?????????', mainCampaignPath)
    return (
      <section className="donars_section">
        <div className="donar_header">
          {allTeams && <div className="search-wrap"><i className="fa fa-search"></i> <input type="text" className="form-control" ref={this.teamSearchInput} onChange={this.searchTeams} placeholder="Search Your Team..." /></div>}
          {!allTeams && <h2 className="left_don">{team ? 'Teams' : 'Donors'}</h2>}
          {allTeams && <h2 className="left_don"></h2>}
          {<h2 className="right_don">{allTeams ? '' : 'Donations Matched'}</h2>}
        </div>
        {allTeams ?
          <div className="donor-listing">
            {this.state.fullTeamDetailsFilteredArr.map(team => {
              return (<div className="donar_block">
                {team.imageURL1 && <img
                  src={team.imageURL1}
                  className="team_donar_img"
                  alt="Donor"
                />}
                {!team.imageURL1 && <img
                  src="/images/donar1.png"
                  className="donar_heart"
                  alt="Donor"
                />}
                <div className="donor_info">
                  <p className="alias">
                    {team.teamName}
                  </p>
                </div>
                <button className="btn btn-visit" onClick={() => window.open('https://pledje.com/' + team.campaignPath)}>Visit</button>
              </div>)
            })
            }
          </div>
          :
          <div className="donor-listing">
            {filteredDonors.map((donor, i) => {
              flag = ((parseInt(donor.amount) >= 500 || parseInt(donor.totalChargedAmount) >= 500)) && ((mainCampaignPath == "ccla") || (mainCampaignPath == "mpcorpnew")) && (!team);
              animationClass = flag ? 'animation-donor-info' : '';
              liClasses = `donar_block ${animationClass}`;
              return (
                <div key={i} className={liClasses}>
                  {!flag && <img
                    src="/images/donar1.png"
                    className="donar_heart"
                    alt="Donor"
                  />}
                  {flag && <img style={{ "height": "40px" }}
                    src={animationImage}
                    className="donar_heart"
                    alt="Donor"
                  />}
                  <div className="donor_info">
                    <p className="alias">
                      {team ? donor.teamName : donor.displayName ? (donor.alias).trim() || donor.name : (donor.name).trim()}
                    </p>
                    <p>{(donor.alias == "Anonymous" || donor.teamName == "Anonymous") && (!team) && moment(donor.date).format('MM/DD | hh:mm A')}</p>
                    <p className="message">{donor.message}</p>
                  </div>
                  <label className="amount">{getSign(donor.currency || campaignCurrency)}{team ? (donor.totalChargedAmount).toFixed(0) : (donor.amount)}</label>
                </div>
              )
            })}
          </div>}
        {show_btn && (
          <button className="sidebar-blue-btn" onClick={this.expand}>
            See All { allTeams ? this.state.allTeamsLength : length} {team ? "Team's Performance" : allTeams ? "Teams" : "Donors"}
          </button>
        )}
        {!show_btn && <span className="text-highlight">{(allTeams) ? ((this.state.allTeamsLength > 10) ? this.state.allTeamsLength : '') : (length > 10 ? length : '')} {team ? (length > 10 ? "Team's Performance" : '') : allTeams ? ((this.state.allTeamsLength > 10) ? "Teams" : '') : (length > 10 ? "Donors" : '')}</span>}
      </section>
    );
  }
}

export { Donars };
