import React from "react";
import { Goals } from "../TeamDetail/Goals";
import { NumberFormat } from "../../Common/NumberFormat";

export const CampaignGoals = ({ details, donation, sign }) => {
  const timeRemaining = new Date(details.campaignEndTime) - new Date();
  const bonusEnd = new Date(details.bonusGoalEndDate) - new Date();
  const bonus = donation > parseInt(details.goalAmount) && timeRemaining <= 0
  let currency='USD';
  if(details.campaignCurrency)
  {
    currency = details.campaignCurrency ? details.campaignCurrency.toUpperCase() : details.currency[0].code.toUpperCase();
  }
  
  return (
    <section className="goals">
      {(timeRemaining <= 0 && bonusEnd <= 0 && bonus) || (timeRemaining <= 0 && !bonus) ? (
        <div className="bouns_goal_div">
          <img src="/images/successful.png" alt="Success" />
        </div>
      ) : (
          // <Goals
          //   startTime={details.campaignStartTime}
          //   endTime={details.campaignEndTime}
          //   bonus={bonus}
          //   bonusEnd={details.bonusGoalEndDate}
          // />

          (Object.keys(details).length) && (!details.enableNoTimeCampaign) ? <Goals
          startTime={details.campaignStartTime}
          endTime={details.campaignEndTime}
          bonus={bonus}
          bonusEnd={details.bonusGoalEndDate}
        /> : null




        )}
      {/* {(timeRemaining >=0 )&& (donation > parseInt(details.goalAmount)) && details.bonusGoalAmount1 && ( */}
      {((donation + 2500) > parseInt(details.goalAmount)) && details.bonusGoalAmount1 && (
        <p className="bouns-goal-point text-center">
          <strong>New GOAL {sign}{NumberFormat(details.bonusGoalAmount1)}</strong>{" "}
          {currency}
        </p>
      )}
      <p className="goal-point goal-point-bouns text-center">
        <strong className={bonus && "check"}>
          GOAL {sign}{NumberFormat(details.goalAmount)}
        </strong>{" "}
        {currency}
      </p>
    </section>
  );
};
