import React from 'react';
import { InputBase, Checkbox, Radio, RadioGroup, FormControlLabel, Grid } from '@material-ui/core';

export const Donation = ({ details, handleDisplayName, onChange }) => {
  return (
    <div className='donation'>
      <div className='title'>Manual Donation</div>
      <div className='donation-content'>
        <div className='row1 space-between'>
          <label>Amount:</label>
          <div className='with-absolute'>
            <InputBase className='input' name='donationAmount' value={details.donationAmount} onChange={onChange} />
            <label>$</label>
          </div>
        </div>
        <div className='row2'>
          <label>Show Donation As Anonymous: </label>
          <Checkbox color="default" name="displayName" checked={details.displayName} onChange={handleDisplayName} />
        </div>
        { !details.displayName && <div className='or'>OR</div> }
        { !details.displayName && <div className='row3'>
          <label>Name to be displayed on campaign name:</label>
          <InputBase className='input' name='alias' value={details.alias} onChange={onChange} />
        </div> }
        <div className='row4'>
          <RadioGroup
            className='radio-group'
            aria-label="paymentType"
            name="paymentType"
            value={details.paymentType}
            onChange={onChange}
          >
            <FormControlLabel
              className="radio-btn"
              value="check"
              control={<Radio style={{ color: 'grey' }} />}
              label="Check"
              labelPlacement="start"
            />
            <FormControlLabel
              className="radio-btn"
              value="cash"
              control={<Radio style={{ color: 'grey' }} />}
              label="Cash"
              labelPlacement="start"
            />
          </RadioGroup>
        </div>
        { details.paymentType === 'check' && 
          <Grid container className='check-container'>
            <Grid xs={12} item className='space-between'>
              <label>Routing Number (Optional):</label>
              <InputBase className='input'
                value={details.routing_number}
                name="routing_number"
                onChange={onChange}
              />
            </Grid>
            <Grid xs={12} item className='space-between'>
              <label>Account Number (Optional):</label>
              <InputBase className='input'
                value={details.account_number}
                name="account_number"
                onChange={onChange}
              />
            </Grid>
          </Grid>
        }
        <div className='row5'>
          <label>Message/Dedication to be Displayed on the Campaign Page:</label>
          <InputBase
            multiline={true}
            rows={3}
            className="input"
            value={details.dedicationMessage}
            name="dedicationMessage"
            onChange={onChange}
          />
        </div>
        <div className='row5'>
          <label>Donation Notes for Administrator:</label>
          <InputBase
            multiline={true}
            rows={3}
            className="input"
            value={details.specialNotes}
            name="specialNotes"
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
}