import { combineReducers } from 'redux';
import base from './base';
import auth from './auth';
import user from './user';
import checkout from './checkout';
import campaign from './campaign';
import team from './team';
import share from './share';
import prospectManagementReducer from './ProspectManagement';
import campaignReportReducer from './CampaignReports'
import teamManagementReducer from './TeamManagement'
import teamLeadershipReducer from './TeamLeadership'
import preCampaignVaultReducer from './PreCampaignVault'
import userManagementReducer from './Users'
import userAuthReducer from './UserAuth'
import homeReducer from './Home'
import newCampaign from './NewCampaign'

import { penderReducer } from 'redux-pender';

export default combineReducers({
    base,
    auth,
    user,
    checkout,
    campaign,
    team,
    share,
    pender: penderReducer,
    prospectManagementReducer,campaignReportReducer,teamManagementReducer,teamLeadershipReducer,userManagementReducer,userAuthReducer, preCampaignVaultReducer, homeReducer, newCampaign
});
