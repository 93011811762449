import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { clearCache } from "../../redux/modules/CampaignReports/action-creator";
import Snackbar from "../../components/Snackbar/Snackbar";
import { getOrganizationCollection, fetchCampaigns, addOrganization, removeOrganization, addRoute, addCampaign } from "../../redux/modules/NewCampaign/action-creator";
import HeaderComponent from "../HeaderComponent";
import { withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from '@material-ui/core';
import "./index.scss";

class Settings extends Component {
  constructor() {
    super();

    this.state = {
      message: '',
      error: false,
      showMessage: false,
      organizations: [],
      campaigns: []
    }
    this.baseUrl = window.location.protocol + "//" + window.location.host;
  }

  componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations = () => {
    const { getOrganizations } = this.props;

    getOrganizations()
      .catch(err => this.showSnackBar(err.payload, true));
  }

  componentDidUpdate(oldProps) {
    const { organizations, campaigns } = this.props;
    const { cache } = this.props;

    if(cache !== oldProps.cache) {
      if(cache === 'OK') {
        this.showSnackBar('Cache has been cleared', false);
      }
    }
    if (oldProps.organizations !== organizations) {
      let campaigns = [];
      organizations.map(item => {
        let camp = Object.keys(item.campaigns);
        camp.map(ele => {
          campaigns.push(item.campaigns[ele]);
        })
      })
      this.setState({ organizations, campaigns });
    }
    if (oldProps.campaigns !== campaigns) {
      this.setCampaign(campaigns[0]);
    }
  }

  setCampaign = (value) => {
    this.setState({
      edit: value ? true : false,
      campaign: value || {},
      items: value ? value.items : [],
      superDonors: value ? value.superDonors : [],
      showSuperDonors: value ? value.showSuperDonors : true,
      calculatorSetup: value ? value.calculatorSetup : [],
      mode: value ? value.mode : 'monthly'
    });
  }

  handleCache = () => {
    const { onClearCache } = this.props;

    onClearCache();
  }

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 3000);
  };

  render() {
    const { campaigns } = this.state;
    return (
      <div id="settings">
        <Snackbar
          message={this.state.message}
          color={this.state.error ? "danger" : "success"}
          icon={this.state.error ? ErrorIcon : CheckCircleIcon}
          place="tr"
          open={this.state.showMessage}
        />
        <div className="clearfix">
          <button className="cache-btn" onClick={this.handleCache}>Clear Cache</button>
        </div>
        {JSON.parse(localStorage.getItem("userInfo")).role === "super-admin" ?
        <>
        <div className="title">Campaign matrix links :</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Campaign Name
            </TableCell>
              <TableCell>
                Matrix Link
            </TableCell>0
            </TableRow>
          </TableHead>

          <TableBody>
            {campaigns.map((item, index) => item.status !== "completed" &&
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }}><TableCell>{item.name}</TableCell> <TableCell><a target="_blank" href={this.baseUrl + '/signup-volunteer-team/' + item.organizationId + "/" + item.campaignId + "/"+item.campaignPage}>Click Here</a></TableCell></TableRow>
            )
            }
          </TableBody>

        </Table>
        </>:null
        }


      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
    cache: state.campaignReportReducer.cache,
    organizations: state.newCampaign.organizations,
    campaigns: state.newCampaign.campaigns
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearCache: bindActionCreators(clearCache, dispatch),
    getOrganizations: bindActionCreators(getOrganizationCollection, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
