import React from "react";
import { AppBar, IconButton, Select, MenuItem } from "@material-ui/core";
import TvIcon from '@material-ui/icons/TvRounded';

import "./Header.scss";

export const Header = ({ organizations, organization, selectOrganization, handleOpen, title }) => {
  return (
    <AppBar id='new-camp-header' position="static">
      <div className='title'>
        <IconButton color="inherit" aria-label="tv" rounded="true">
          <TvIcon />
        </IconButton>
        <span className='title-text'>
          <label>{title ? title : 'New Campaign'}</label>
        </span>
      </div>
      <div className='select-box'>
        <div className='text'>Select Organization</div>
        <Select
          disableUnderline
          className='select'
          value={organization.organizationName}
          onChange={selectOrganization}
        >
          { organizations.map(e =>
            <MenuItem value={e.organizationName} key={e.organizationName}>{e.organizationName}</MenuItem>
          )}
        </Select>
        <button onClick={handleOpen} className="new-org-btn">
          NEW ORGANIZATION
        </button>
      </div>
    </AppBar>
  );
}