import React, { Component } from 'react';
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import './Header.scss';
import pledjeLogo from "../../assets/images/latestImages/logo.png";

// pledjeReact\src\assets\images\latestImages\logo.png

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images1: '/images/1.png',
      images4: '/images/4.png',
      images5: '/images/5.png',
      images6: '/images/6.png',
    }
  }

  handleCheck = (e) => {
    if (e.currentTarget.dataset.id === '1') {
      this.setState({
        images1: '/images/1-green.png',
      });
    }
    if (e.currentTarget.dataset.id === '4') {
      this.setState({
        images4: '/images/4-green.png'
      });
    }
  }

  openNavMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu
    })
  }

  render() {
    return (
      <header id="header" className="headset">
        <nav className="navbar navbar-inverse" data-spy="affix" data-offset-top="30">
          <div className="container-fluid" >
            <div className="navbar-header">
              <div className="menu-opener" onClick={this.openNavMenu}>
                <div className={this.state.openMenu ? "menu-opener-inner open_menu" : "menu-opener-inner"}></div>
              </div>
              <NavLink className="navbar-brand" to="/" activeClassName="active" onClick={this.handleCheck} data-id="1"><img src="/images/bottom-logo.png" className="main-logo" alt='logo' width='185' /> </NavLink>
            </div>

            <div className={this.state.openMenu ? "collapse navbar-collapse open_menu" : "collapse navbar-collapse"} id="myNavbar">
              <ul className="nav navbar-nav navbar-right">
                <li><NavLink exact={true} to="/whychooseus" activeClassName="active" onClick={this.handleCheck} data-id="1"><img src={this.state.images1} alt='Why choose us' /> Why Choose Us </NavLink></li>
                <li><NavLink to="/contactus" activeClassName="active" onClick={this.handleCheck} data-id="4"><img src={this.state.images4} alt='Contact' /> Contact </NavLink></li>
                <li><NavLink to="/login" activeClassName="active" onClick={this.handleCheck} data-id="5"><img src={this.state.images5} alt='Login' /> Login</NavLink></li>
                <li><NavLink to="/dashboard/dashboard" activeClassName="active" onClick={this.handleCheck} data-id="6"><img src={this.state.images6} alt='Dashboard' /> Dashboard</NavLink></li>
              </ul>
            </div>
          </div>

        </nav>
 
        {/* <nav className="navbar navbar-inverse latest-navbar" data-spy="affix" data-offset-top="30">
          <div className="container-fluid" >
            <div className="navbar-header">
              <div className="menu-opener" onClick={this.openNavMenu}>
                <div className={this.state.openMenu ? "menu-opener-inner open_menu" : "menu-opener-inner"}></div>
              </div>
              <NavLink className="navbar-brand" to="/" activeClassName="active" onClick={this.handleCheck} data-id="1"><img src={pledjeLogo} className="main-logo" alt='logo' width='150' /> </NavLink>
            </div>
            <div className={this.state.openMenu ? "collapse navbar-collapse open_menu" : "collapse navbar-collapse"} id="myNavbar">
              <ul className="nav navbar-nav navbar-right">
                <li><NavLink exact={true} to="/whychooseus" activeClassName="active" onClick={this.handleCheck} data-id="1">Why Choose Us </NavLink></li>
                <li><NavLink to="/contactus" activeClassName="active" onClick={this.handleCheck} data-id="4">Contact </NavLink></li>
                <li><NavLink to="/dashboard/dashboard" className="dashboard-latest" activeClassName="active" onClick={this.handleCheck} data-id="6">Dashboard</NavLink></li>
                <li><NavLink to="/login" className="login-latest" activeClassName="active" onClick={this.handleCheck} data-id="5">Login</NavLink></li>
              </ul>
            </div>
          </div>
        </nav>  */}

      </header>
    );
  }
}

export default Header;
