import React, { Component } from 'react';
import FreedomEvent from "../FreedomEvent";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { Link } from 'react-scroll';

import Videobox from "../Videobox";
import './latestHome2.scss';

import featureImg from "../../../assets/images/latestImages/100-features/100-feature-img.png";

import bannerImg1 from "../../../assets/images/latestImages/banner/banner-img-1.png";
import bannerImg2 from "../../../assets/images/latestImages/banner/banner-img-2.png";
import bannerLogo from "../../../assets/images/latestImages/banner/banner-logo.png";

import bannerLogo1 from "../../../assets/images/latestImages/campaign-features-icon/1.png";
import bannerLogo2 from "../../../assets/images/latestImages/campaign-features-icon/2.png";
import bannerLogo3 from "../../../assets/images/latestImages/campaign-features-icon/3.png";
import bannerLogo4 from "../../../assets/images/latestImages/campaign-features-icon/4.png";
import bannerLogo5 from "../../../assets/images/latestImages/campaign-features-icon/5.png";
import bannerLogo6 from "../../../assets/images/latestImages/campaign-features-icon/6.png";
import bannerLogo7 from "../../../assets/images/latestImages/campaign-features-icon/7.png";
import bannerLogo8 from "../../../assets/images/latestImages/campaign-features-icon/8.png";
import bannerLogo9 from "../../../assets/images/latestImages/campaign-features-icon/9.png";
import bannerLogo10 from "../../../assets/images/latestImages/campaign-features-icon/10.png";

import cumulativeMatch from "../../../assets/images/latestImages/campign-types-icons/cumulative-match.png";
import eventRaising from "../../../assets/images/latestImages/campign-types-icons/event-raising.png";
import incentive from "../../../assets/images/latestImages/campign-types-icons/incentive.png";
import marketplaceDrives from "../../../assets/images/latestImages/campign-types-icons/marketplace-drives.png";
import raffle from "../../../assets/images/latestImages/campign-types-icons/raffle.png";
import saferTorah from "../../../assets/images/latestImages/campign-types-icons/safer-torah.png";
import shopify from "../../../assets/images/latestImages/campign-types-icons/shopify.png";

import feesIcon1 from "../../../assets/images/latestImages/fees-icon/1.png";
import feesIcon2 from "../../../assets/images/latestImages/fees-icon/2.png";
import feesIcon3 from "../../../assets/images/latestImages/fees-icon/3.png";
import feesIcon4 from "../../../assets/images/latestImages/fees-icon/4.png";
import feesBgImg from "../../../assets/images/latestImages/fees-icon/fees-bg-img.png";



import whyPledje1 from "../../../assets/images/latestImages/why-pledje-icon/1.png";
import whyPledje2 from "../../../assets/images/latestImages/why-pledje-icon/2.png";
import whyPledje3 from "../../../assets/images/latestImages/why-pledje-icon/3.png";
import whyPledje4 from "../../../assets/images/latestImages/why-pledje-icon/4.png";
import whyPledje5 from "../../../assets/images/latestImages/why-pledje-icon/5.png";
import whyPledje6 from "../../../assets/images/latestImages/why-pledje-icon/6.png";
import whyPledje7 from "../../../assets/images/latestImages/why-pledje-icon/7.png";
import whyPledje8 from "../../../assets/images/latestImages/why-pledje-icon/8.png";
import whyPledjeImg from "../../../assets/images/latestImages/why-pledje-icon/why-pledje-img.png";


import teamImg from "../../../assets/images/latestImages/team-icon/team-img.png";
import teamImg1 from "../../../assets/images/latestImages/team-icon/1.png";
import teamImg2 from "../../../assets/images/latestImages/team-icon/2.png";
import teamImg3 from "../../../assets/images/latestImages/team-icon/3.png";

import caseStudy1 from "../../../assets/images/latestImages/case-studies-icon/1.png";
import caseStudy2 from "../../../assets/images/latestImages/case-studies-icon/2.png";
import caseStudy3 from "../../../assets/images/latestImages/case-studies-icon/3.png";
import caseStudy4 from "../../../assets/images/latestImages/case-studies-icon/4.png";
import caseStudy5 from "../../../assets/images/latestImages/case-studies-icon/5.png";
import bolt from "../../../assets/images/latestImages/case-studies-icon/bolt.png";
import boxImg from "../../../assets/images/latestImages/case-studies-icon/box-img-1.jpg";

import caseStudyA from "../../../assets/images/latestImages/case-studies-icon/case-study-1.jpg";
import caseStudyB from "../../../assets/images/latestImages/case-studies-icon/case-study-2.jpg";
import caseStudyC from "../../../assets/images/latestImages/case-studies-icon/case-study-3.jpg";


import shadowImg from "../../../assets/images/latestImages/meet-pledje/shadow.png";
import quotes from "../../../assets/images/latestImages/meet-pledje/quotes.png";
import newsLetter from "../../../assets/images/latestImages/newsletter/newletter-img.png";
import footerLogo from "../../../assets/images/latestImages/footer-logo.png";
import thumbnail1 from "../../../assets/images/latestImages/thumbnail-1.png";
import thumbnail2 from "../../../assets/images/latestImages/thumbnail-2.png";
import comChart2020 from "../../../assets/images/latestImages/com-chart-for-website2022.jpg";
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

// $urls : "../../../assets/images/fees-icon/fees-bg-img.png";
import { background } from "../../../assets/images/latestImages/fees-icon/fees-bg-img.png";

export default class LatestHome2 extends Component {
    constructor() {
        super();
        this.state = {
            showComparisonModa: false
        }
    }
    render() {
        return (
            <div className='iframe-page'>
                {this.props.featureCampaigns && Array.isArray(this.props.featureCampaigns) && this.props.featureCampaigns.map(item =>
                    <FreedomEvent activeCampaign={item} totalDonation={this.props.totalDonation} />
                )}
                {/* <iframe src="https://pledje2-staging-user-panel.firebaseapp.com/home" title="Pledje.com">
				</iframe> */}
                {/* <header className="header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="#"><img alt="logo" className="logo" height="65px" src="images/logo.png" /></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="#">Why pledje?</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Features</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Pricing</a>
                  </li>
                  <li className="dashboard nav-item">
                    <a className="dashboard nav-link" href="#">Dashboard</a>
                  </li>
                  <li className="login nav-item">
                    <a className="login nav-link" href="#">Log in</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header> */}

                <ul style={{ display: 'flex', listStyle: 'none', justifyContent: 'space-around' }}>
                    <li><Link activeClass="active" to="whyPledjeId" spy={true} smooth={true}>WHY PLEDJE?</Link></li>
                    <li><Link activeClass="active" to="featuresId" spy={true} smooth={true}>FEATURES</Link></li>
                    <li><Link activeClass="active" to="campaignTypesId" spy={true} smooth={true}>CAMPAIGN TYPES</Link></li>
                    <li><Link activeClass="active" to="teamsId" spy={true} smooth={true}>TEAMS</Link></li>
                    <li><Link activeClass="active" to="pricingId" spy={true} smooth={true}>PRICING</Link></li>
                    <li><NavLink activeClass="active" to="/contactus">Contact</NavLink></li>
                    <li><NavLink activeClass="active" to="/login">Login</NavLink></li>
                </ul>

                <section className="banner-latest">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="banner-content" data-aos="zoom-out-right">
                                    <div className="logo-div">
                                        <img alt="Banner Logo" className="img-fluid" src={bannerLogo} />
                                        <p>Introducing Pledje 2.0</p>
                                    </div>
                                    <p>A suite of the most innovative fundraising solutions that</p>
                                    <h1>Guarantee</h1>
                                    <h3>Bigger <span>Donations</span></h3>
                                    <h5><span className="new">New</span> <span className="flat">Flat Rate Pricing</span> <span className="from">From $997</span></h5>
                                    <button className="btn btn-schedule">Schedule a FREE consultation</button>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12" >
                                <img alt="Banner Image" className="img-fluid" src={bannerImg2} data-aos="zoom-out-left" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="meet-pledje">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-3 col-md-6" data-aos="zoom-out-right">
                                <div className="img-box">
                                    <h4>Meet Pledje</h4>
                                    <iframe
                                        className="border_frame"
                                        width="100%"
                                        height="215"
                                        src="https://www.youtube.com/embed/RKkhH44iKfs"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                    <img alt="" className="img-fluid shadow" src={shadowImg} />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6" data-aos="zoom-out-right">
                                <div className="img-box">
                                    <h4>Testimonial</h4>
                                    <iframe
                                        className="border_frame"
                                        width="100%"
                                        height="215"
                                        src="https://www.youtube.com/embed/ObSQ-Hjl9Jk"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                    <img alt="" className="img-fluid" src={shadowImg} />
                                </div>
                            </div>
                        </div> */}

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="img-box video-box-latest-1">
                                    <h4>Meet Pledje</h4>
                                    <img class="thumbnail-img" src={thumbnail2} />
                                    <iframe class="border_frame" width="100%" height="215" src="https://www.youtube.com/embed/RKkhH44iKfs"
                                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                    <img alt="" class="img-fluid shadow-img" src={shadowImg} />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="img-box video-box-latest-2">
                                    <h4>Testimonial</h4>
                                    <img class="thumbnail-img" src={thumbnail1} />
                                    <iframe class="border_frame" width="100%" height="215" src="https://www.youtube.com/embed/ObSQ-Hjl9Jk"
                                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                    </iframe>
                                    <img alt="" class="img-fluid shadow-img" src={shadowImg} />
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <section className="why-pledje" id="whyPledjeId">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12" >
                                <div className="img-box">
                                    <img data-aos="zoom-out-right" alt="Feature Image" className="img-fluid" src={whyPledjeImg} />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12" data-aos="zoom-out-left">
                                <h1>Why Pledje?</h1>
                                <div className="row">
                                    <div className="col-md-6" data-aos="zoom-out-right">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje1} />
                                            </div>
                                            <p><span className="bold-span">Affordable pricing&nbsp;</span> <span>Sure to make</span> your valet happy</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out-left">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje2} />
                                            </div>
                                            <p><span className="bold-span">Credit card&nbsp;</span><span> <span className="bold-span bold-span-inline-block">processing</span> fees</span> as low as <span className="bold-span bold-span-inline-block">1%</span></p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-md-6" data-aos="zoom-out-right">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje3} />
                                            </div>
                                            <p><span>Receive &nbsp;<span className="bold-span bold-span-inline-block">larger donations&nbsp;</span></span> <span className="bold-span">from your current donors&nbsp;</span> <span>with Pledje’s unique</span> solutions</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out-left">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje4} />
                                            </div>
                                            <p><span>Gain <span className="bold-span bold-span-inline-block">new donors</span> &amp;</span> <span>spread the word via</span> Care-to-Share program</p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-md-6" data-aos="zoom-out-right">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje5} />
                                            </div>
                                            <p><span>Affordable yet unique&nbsp;</span> <span>ways to&nbsp;<span className="bold-span bold-span-inline-block">tell your story</span>,</span> <span>&amp; inspire your donors</span> with Pledje studio</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out-left">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje6} />
                                            </div>
                                            <p><span>Tools/campaigns to</span> <span>fill your <span className="bold-span bold-span-inline-block">fundraising &nbsp;</span></span> <span className="bold-span">calendar year long</span></p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col-md-6" data-aos="zoom-out-right">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje7} />
                                            </div>
                                            <p><span>Tools to turn your&nbsp;&nbsp;</span> <span><span className="bold-span bold-span-inline-block">volunteer&nbsp;</span> to&nbsp;</span> <span>fundraising super</span> machines</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="zoom-out-left">
                                        <div className="icon-box-outer">
                                            <div className="icon-box">
                                                <img alt="" className="" src={whyPledje8} />
                                            </div>
                                            <p><span>Proprietary marketing &nbsp;</span><span>tools to <span className="bold-span bold-span-inline-block">reach donors &nbsp;</span></span> <span className="bold-span">in record time</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="features" id="featuresId">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12" data-aos="zoom-out-right">
                                <div className="img-box">
                                    <img alt="Feature Image" className="img-fluid" src={featureImg} />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12" data-aos="zoom-out-left">
                                <div className="feature-content">
                                    <h1>There are over 100 features</h1>
                                    <p><span>that makes Pledje the 1st choice for a successful</span><span className="fund">fundraising campaign that yields BIG results.</span></p>
                                    <div className="btn-box">
                                        <button className="btn btn-learn" onClick={() => { this.setState({ showComparisonModa: true }) }}>See Comparison Chart </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="available-campaign" id="campaignTypesId">
                    <div className="container">
                        <div className="heading-latest campaign-heading"><h1><span>Available Campaign Types</span></h1></div>
                        <p data-aos="zoom-in-up">Pledje is proud to introduce 5 new campaigns available for the first time to non-profits around the world guaranteed to pave the way to <span>larger donations</span>.</p>
                        <div className="row">
                            <div className="icon-box-outer" data-aos="zoom-out-right">
                                <div className="icon-box">
                                    <img alt="" className="" src={cumulativeMatch} />
                                </div>
                                <p>Time Based Matching<span>Campaigns</span></p>
                                {/* <button className="btn btn-learn">Learn More</button> */}
                            </div>
                            <div className="icon-box-outer" data-aos="zoom-out-right">
                                <div className="icon-box">
                                    <img alt="" className="" src={raffle} />
                                </div>
                                <p>Raffle</p>
                                {/* <button className="btn btn-learn">Learn More</button> */}
                            </div>
                            <div className="icon-box-outer" data-aos="zoom-out-top">
                                <div className="icon-box">
                                    <img alt="" className="" src={saferTorah} />
                                </div>
                                <p>Sefer Torah</p>
                                {/* <button className="btn btn-learn">Learn More</button> */}
                            </div>
                            <div className="icon-box-outer" data-aos="zoom-out-left">
                                <div className="icon-box">
                                    <img alt="" className="" src={marketplaceDrives} />
                                </div>
                                <p>Marketplace <span>Drives</span></p>
                                {/* <button className="btn btn-learn">Learn More</button> */}
                            </div>
                            <div className="icon-box-outer event" data-aos="zoom-out-left">
                                <div className="icon-box">
                                    <img alt="" className="" src={eventRaising} />
                                </div>
                                <p>Event Raising</p>
                                {/* <button className="btn btn-learn">Learn More</button> */}
                            </div>
                        </div>
                    </div >
                </section >

                <section className="campaign-features">
                    <div className="container">
                        <h1 data-aos="zoom-in-up">Campaign Features</h1>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo1} />
                                    </div>
                                    <p><span>State of the art&nbsp;</span> &nbsp;&nbsp;<span>communication tools to&nbsp;</span>&nbsp;&nbsp; <span>reach record number</span> of donors in real time</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" >
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo2} />
                                    </div>
                                    <p><span>Revolutionary ways&nbsp;</span> &nbsp;&nbsp;<span>to collect owed</span> pledges</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo3} />
                                    </div>
                                    <p><span>Call center/management</span> tools</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo4} />
                                    </div>
                                    <p><span>Customizable&nbsp;</span>&nbsp;&nbsp; <span>campaign pages to</span> match any look & feel</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo2} />
                                    </div>
                                    <p><span>No cost social</span> media marketing</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo5} />
                                    </div>
                                    <p><span>Volunteer</span> management</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo6} />
                                    </div>
                                    <p>Simplified Accounting</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo2} />
                                    </div>
                                    <p><span>Live stream from your &nbsp;</span>&nbsp;&nbsp; &nbsp;&nbsp;<span>events/campaign</span> room</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo7} />
                                    </div>
                                    <p>Team creation</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo8} />
                                    </div>
                                    <p><span>Creative donation</span> options</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo2} />
                                    </div>
                                    <p><span>Targeted social&nbsp;</span>&nbsp;&nbsp; <span>media campaign</span> tools</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo6} />
                                    </div>
                                    <p><span>Sub campaign/</span>&nbsp;&nbsp;<span>personal/team</span> pages</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo9} />
                                    </div>
                                    <p><span>Fortune 500 type&nbsp;</span> &nbsp;&nbsp;<span>on-demand</span> reporting</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo2} />
                                    </div>
                                    <p>Multiple currency</p>
                                </div>
                            </div>
                            <div className="col-md-4" data-aos="zoom-out-left">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo6} />
                                    </div>
                                    <p>Gamification</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo10} />
                                    </div>
                                    <p><span>Simple to</span>&nbsp;&nbsp; <span>use / no learning</span> curve</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo2} />
                                    </div>
                                    <p>Raffle drawing tools</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={bannerLogo6} />
                                    </div>
                                    <p><span>Support via</span> phone/sms/email</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="team" id="teamsId">
                    <div className="container">
                        <h1 data-aos="zoom-in-up">Team</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="img-box">
                                    <img data-aos="zoom-out-right" alt="Team Image" className="img-fluid" src={teamImg} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img data-aos="zoom-out-left" alt="" className="" src={teamImg1} />
                                    </div>
                                    <p data-aos="zoom-out-left"><span>Team of Google certified</span><span>marketing professionals here</span>to help spread the word</p>
                                </div>
                                <div className="icon-box-outer">
                                    <div className="icon-box">
                                        <img data-aos="zoom-out-left" alt="" className="" src={teamImg2} />
                                    </div>
                                    <p data-aos="zoom-out-left"><span>In-house team of award</span><span>winning 3D artists, graphic</span>designers &amp; video professionals</p>
                                </div>
                                <div className="icon-box-outer mb-0">
                                    <div className="icon-box">
                                        <img data-aos="zoom-out-left" alt="" className="" src={teamImg3} />
                                    </div>
                                    <p data-aos="zoom-out-left"><span>Seasoned fundraising</span>consultants</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="fees" id="pricingId">
                    <div className="container">
                        <div className="heading-latest fees-heading"><h1 data-aos="zoom-in-up"><span>Fees</span></h1></div>
                        <div className="row">
                            <div className="col-md-6" >
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={feesIcon1} />
                                    </div>
                                    <p><span>Flat rate &amp; post pay</span> model</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={feesIcon2} />
                                    </div>
                                    <p><span>Effective credit card</span> <span>processing fee as</span> low as 1%</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="icon-box-outer" data-aos="zoom-out-right">
                                    <div className="icon-box">
                                        <img alt="" className="" src={feesIcon3} />
                                    </div>
                                    <p><span>No fee until you</span> <span>meet your</span> fundraising goal</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="icon-box-outer" data-aos="zoom-out-left">
                                    <div className="icon-box">
                                        <img alt="" className="" src={feesIcon4} />
                                    </div>
                                    <p><span>Solutions to help</span> <span>offset your</span> <span>campaign costs by</span> securing corporate sponsore</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-box">
                            <button className="btn btn-learn">Learn More</button>
                        </div> */}
                    </div>
                </section>

                <section className="case-studies">
                    <div className="container">
                        <h1>Case Studies</h1>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="img-box">
                                    <img alt="" className="img-fluid" src={caseStudyA} />
                                    <p className="bonus-img"></p>
                                    <p className="goal"><span className="mr-2"><i className="fa fa-bullseye"></i></span>Goal $125,000</p>
                                    <p className="raised"><img alt="" className="img-fluid mr-2" src={bolt} />Raised $157,000</p>
                                    <div className="content">
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>Raised $32,000 Above Original Goal</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>Remodeling Campaign</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>New Organization with a Small List of Donors</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>3D Model of the New Center was Designed by Pledje Studio</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="img-box">
                                    <img alt="" className="img-fluid" src={caseStudyB} />
                                    <p className="bonus-img"></p>
                                    <p className="goal"><span className="mr-2"><i className="fa fa-bullseye"></i></span>Goal $450,000</p>
                                    <p className="raised"><img alt="" className="img-fluid mr-2" src={bolt} />Raised $609,000</p>
                                    <div className="content">
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>3-part Capital Campaign</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>$159,000 Raised Above Original Goal</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>36 hours</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="img-box">
                                    <img alt="" className="img-fluid" src={caseStudyC} />
                                    <p className="bonus-img"></p>
                                    <p className="goal"><span className="mr-2"><i className="fa fa-bullseye"></i></span>Goal $125,000</p>
                                    <p className="raised"><img alt="" className="img-fluid mr-2" src={bolt} />Raised $153,644</p>
                                    <div className="content">
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>Staff Expansion Campaign</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>$28,644 Raised Above Original Goal</p>
                                        <p><span className="mr-2"><i className="fa fa-check"></i></span>36 hours</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="logo-box">
                                    <span><img alt="" className="img-fluid mr-2" src={caseStudy1} /></span>
                                    <span><img alt="" className="img-fluid mr-2" src={caseStudy2} /></span>
                                    <span><img alt="" className="img-fluid mr-2" src={caseStudy3} /></span>
                                    <span><img alt="" className="img-fluid mr-2" src={caseStudy4} /></span>
                                    <span><img alt="" className="img-fluid mr-2" src={caseStudy5} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="newsletter">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-4">
                                <div className="img-box">
                                    <img alt="Newsletter Image" className="img-fluid" src={newsLetter} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4">
                                        <ul>
                                            <li>
                                                <Link activeClass="active" to="whyPledjeId" spy={true} smooth={true}>WHY PLEDJE?</Link>
                                                {/* <a className="nav-link"
                                                >WHY PLEDJE?</a> */}
                                            </li>
                                            <li>
                                                <Link activeClass="active" to="campaignTypesId" spy={true} smooth={true}>CAMPAIGN TYPES</Link>
                                                {/* <a className="nav-link"
                                                >CAMPAIGN TYPES</a> */}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul>
                                            <li>
                                                <Link activeClass="active" to="featuresId" spy={true} smooth={true}>FEATURES</Link>
                                                {/* <a className="nav-link"
                                                >FEATURES</a> */}
                                            </li>
                                            <li>
                                                <Link activeClass="active" to="teamsId" spy={true} smooth={true}>TEAMS</Link>
                                                {/* <a className="nav-link"
                                                >TEAMS</a> */}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul>
                                            <li>
                                                <Link activeClass="active" to="pricingId" spy={true} smooth={true}>PRICING</Link>
                                                {/* <a className="nav-link"
                                                >PRICING</a> */}
                                            </li>

                                            <li>
                                                <NavLink to="/contactus" activeClassName="active" > CONTACT </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                <footer class="footer" style={{ margin: '5px 0 -6%', position: 'relative' }}>
                    <div class="container">
                        <div class="m-0 row w-100">
                            <p class="w-100 text-center mb-0"><img alt="Footer Logo" class="" height="100px" src={footerLogo} /></p>
                        </div>
                    </div>
                    <div class="scroll-to-top" style={{ position: 'absolute' }}>
                        <i class="pi pi-arrow-up"></i>
                    </div>
                </footer>

                {/* <footer className="footer">
					<div className="container">
						<div className="row">
							<p><img alt="Footer Logo" className="" height="100px" src="images/footer-logo.png" /></p>
						</div>
					</div>
				</footer> */}

                {/* <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
	 <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
	<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script> */}
                {/* <Dialog
                    open={this.state.showComparisonModa}
                    onClose={() => { this.setState({ showComparisonModa: false }) }}
                    className="confirmation-dialog"
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"

                >
                    <DialogTitle id="confirmation-dialog-title" className="title">UploadedCsvs</DialogTitle>
                    <DialogContent className="content">
                        <div>
                            <img src={comChart2020} style={{ maxWidth: '100%' }} />
                        </div>
                    </DialogContent>
                </Dialog> */}


                <Dialog
                    open={this.state.showComparisonModa}
                    onClose={() => { this.setState({ showComparisonModa: false }) }}
                    aria-labelledby="form-dialog-title"
                    scroll="body"
                >
                    <img src={comChart2020} style={{ maxWidth: '100%' }} />

                </Dialog>
            </div >
        )
    }
}
