import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Select, MenuItem, InputBase, Button, withStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getStatuses } from "../helperFuntions";
import './Filters.scss';

const styles = {
  expanded: {
    margin: '0 10px !important'
  },
  root: {
    minHeight: '48px !important',
  },
  rounded: {
    borderRadius: '0px !important'
  }
};

class Filters extends Component {
  constructor() {
    super();

    this.state = {
      status: '',
      amountFrom: '',
      amountTo: '',
      suggFrom: '',
      suggTo: '',
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleApply = () => {
    const { applyFilter } = this.props;
    const { status, amountFrom, amountTo, suggFrom, suggTo, method, notes } = this.state;

    applyFilter({ status, amountFrom, amountTo, suggFrom, suggTo, method, notes })
  }

  handleClear = () => {
    const { clearFilter } = this.props; 
    this.setState({ status: '', amountFrom: '', amountTo: '', suggFrom: '', suggTo: '', method: '', notes: false });

    clearFilter();
  }

  render() {
    const { status, amountFrom, amountTo, suggFrom, suggTo } = this.state;
    const { classes, expanded } = this.props;

    return (
      <div className='mp-filters'>
        <ExpansionPanel onChange={expanded} classes={{ rounded: classes.rounded }} >
          <ExpansionPanelSummary classes={{ expanded: classes.expanded, root: classes.root }} expandIcon={<ExpandMoreIcon />}>Filters</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className='filter-container'>
              <div className='filters'>
                <div className='status'>
                  <label>Status</label>
                  <Select className='select' onChange={this.handleChange} disableUnderline={true} name='status' value={status}>
                    { getStatuses().map((status, index) =>
                        <MenuItem id='status-item' key={index} value={status.status}>{status.status}</MenuItem>
                      )
                    }
                  </Select>
                </div>
                <div className='amount'>
                  <label>Suggested Amount</label>
                  <div className='range'>
                    <InputBase name='suggFrom' value={suggFrom} onChange={this.handleChange} className='input' />
                    <label>To</label>
                    <InputBase name='suggTo' value={suggTo} onChange={this.handleChange} className='input' />
                  </div>
                </div>
                <div className='amount'>
                  <label>Donated Amount</label>
                  <div className='range'>
                    <InputBase name='amountFrom' value={amountFrom} onChange={this.handleChange} className='input' />
                    <label>To</label>
                    <InputBase name='amountTo' value={amountTo} onChange={this.handleChange} className='input' />
                  </div>
                </div>
                <div className='actions'>
                  <Button className='green' onClick={this.handleClear}>Clear Filters</Button>
                  <Button className='blue' onClick={this.handleApply}>Apply Filters</Button>
                </div>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(Filters);
