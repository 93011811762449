import * as Yup from 'yup';

const pageNameRgx = /^[a-zA-Z0-9]{3,20}$/;
const urlRgx = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const campaignSchema = Yup.object({
  autoCapture: Yup.boolean(),
  bonusGoal1MatchFactor: Yup.number().min(1).integer('Must Be an Integer'),
  bonusGoal1Name: Yup.string(),
  bonusGoalAmount1: Yup.number().min(0).integer('Must Be an Integer'),
  bonusGoalEndDate: Yup.date().default(function() {
    return new Date();
  }),
  campaignEndTime: Yup.date().default(function() {
    return new Date();
  }).required('Please enter campaign end time'),
  campaignId: Yup.string().required('Please enter campaign ID'),
  campaignPage: Yup.string().matches(pageNameRgx, 'Page Name should not contain any special character and should be atleat 3 character long').required('Please enter the Campaign Page Route'),
  campaignStartTime: Yup.date().default(function() {
    return new Date();
  }).required('Please enter campaign start time'),
  donationMedalAmount: Yup.number().min(0).integer('Must Be an Integer'),
  donationTrophyAmount: Yup.number().min(0).integer('Must Be an Integer'),
  enableAnonymous: Yup.boolean(),
  enableCheckOption: Yup.boolean(),
  enableCurrency: Yup.boolean(),
  gateway: Yup.string().required('Please select the payment gateway'),
  goalAmount: Yup.number().min(0, 'Goal Amount should be greater than 0').integer('Must Be an Integer'),
  isFeatured: Yup.boolean(),
  matchFactor: Yup.number().min(1, 'Match Factor should be greater than 0').integer('Must Be an Integer').required('Please enter Match Factor'),
  name: Yup.string().required('Enter the name of the campaign'),
  raffleOptions: Yup.string(),
  recieptExtraInfo: Yup.string(),
  shareGoal: Yup.number().min(0, 'Share Goal should be greater than 0').integer('Must Be an Integer').required('Please enter Share Goal'),
  shareAmount: Yup.number().min(0, 'Share Amount should be greater than 0').integer('Must Be an Integer').required('Please enter Share Amount'),
  splashMerchantId: Yup.string(),
  status: Yup.string(),
  stripeConnectedAccountId: Yup.string(),
  transactionCostFlat: Yup.number().min(0, 'Trasaction cost flat should be greater than 0'),
  transactionCostPercent: Yup.number().min(1, 'Transaction cost percent should be greater than 0'),
  videoURL: Yup.string().matches(urlRgx, 'Please enter a valid URL'),
  preCampaignVideoURL: Yup.string().matches(urlRgx, 'Please enter a valid URL')
});

export const RouteSchema = Yup.object({
  teamPage: Yup.string().matches(pageNameRgx, 'Team Page Name should not contain any special character and should be atleat 3 character long').required('Please enter the Team Page Name')
});
