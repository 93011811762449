import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Switch,
  Button
} from "@material-ui/core";
import styles from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
const paperStyle = {
  caption: {
    fontSize: 14
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: "#008eb5",
      "& + $colorBar": {
        backgroundColor: "#008eb5"
      }
    }
  },
  colorBar: {},
  colorChecked: {}
};

let columns = [
  "Email",
  "First Name",
  "Last Name",
  "Role",
  "Phone",
  "Active/Inactive",
  "Social Media",
  "Availability",
  "Owns Laptop",
  "Edit",
  "Delete",
  "Reset Password"
];
function UserTable({
  users,
  onEditUserClick,
  onResetPassword,
  onDeleteUserClick,
  page,
  rowsPerPage,
  handleChangePage,
  handleStatus,
  classes
}) {
  return (
    <div>
      <Paper style={styles.tableRoot}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((columnName, index) => (
                <TableCell
                  key={index}
                  className="team-table-cell-head team-table-cell nowrap"
                >
                  {columnName === "Availability" ? (
                    <div>
                      {`${columnName} in Hours`}
                      <br />
                      {"Morning/Afternoon/Evening"}
                    </div>
                  ) : (
                    <div>{columnName}</div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fafafa" : "white"
                  }}
                >
                  <TableCell component="th" scope="row" className="team-table-cell">
                    {user.email}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    {user.firstName}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    {user.lastName}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    {user.role}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    {user.phone}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    <Switch
                      classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                        bar: classes.colorBar
                      }}
                      checked={user.status.toLowerCase() === "active"}
                      name="status"
                      onChange={e => handleStatus(e, user)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    {user.socialMedia}
                  </TableCell>
                  <TableCell component="th">
                    {user.day1Morning !== undefined && (
                      <div>
                        {`Day 1: ${
                          user.day1Morning ? user.day1Morning : user.d1m
                        }/${
                          user.day1Afternoon ? user.day1Afternoon : user.d1a
                        }/${user.day1Evening ? user.day1Evening : user.d1e}`}
                      </div>
                    )}
                    {user.day2Morning !== undefined && (
                      <div>
                        {`Day 2: ${
                          user.day2Morning ? user.day2Morning : user.d2m
                        }/${
                          user.day2Afternoon ? user.day2Afternoon : user.d2a
                        }/${user.day2Evening ? user.day2Evening : user.d2e}`}
                      </div>
                    )}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    {user.laptop}
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="#aaabaa"
                      size="lg"
                      onClick={() => onEditUserClick(user)}
                    />
                  </TableCell>
                  <TableCell component="th" className="team-table-cell">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color="#aaabaa"
                      size="lg"
                      onClick={() => onDeleteUserClick(user.userId)}
                    />
                  </TableCell>
                  <TableCell component="th" className="team-table-cell nowrap">
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      className={classes.margin}
                      onClick={event => onResetPassword(event, user.email)}
                    >
                      Reset Password
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        classes={{ caption: classes.caption, toolbar: classes.toolbar }}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={[]}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(paperStyle)(UserTable);
