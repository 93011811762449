import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import * as campaignActions from "../../redux/modules/campaign";
import * as campaignActions from "../../redux/modules/campaign";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./notfound.scss";
import FreedomEvent from "../../pages/Home/FreedomEvent";
class Notfound404 extends Component {
  state = { isLoading: true };
  componentWillMount() {
    const { history } = this.props;
    if (!this.props.history.location.state) {
      history.push("/");
    } else {
      const { onCampaignActions } = this.props;
      onCampaignActions
        .fetchFeatureCampaigns("ORGID")
        .then(res => {
          setInterval(() => {
            this.setState({ isLoading: false });
          }, 1000);
        })
        .catch(err => {});
    }
  }
  render() {
    const { featureCampaigns, onCampaignActions } = this.props;

    return (
      <div className="notfound-wrapper">
        <div className="banner">
          <h2>CAMPAIGN NOT FOUND</h2>
          <p>Make sure you are typing the right URL</p>
        </div>
        <h3 className="heading">Here are Featured Campaigns</h3>
        <div className="featured-campaigns">
          <br />
          <br />
          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            featureCampaigns.map(item =>
              <FreedomEvent activeCampaign={item} totalDonation={onCampaignActions.totalDonation} />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    campaign: state.campaign.get("campaignData"),
    donations: state.campaign.get("donations"),
    total_donation: state.campaign.get("total_donation"),
    items: state.campaign.get("items"),
    featureCampaigns: state.campaign.get("featureCampaigns")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notfound404);
