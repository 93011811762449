import React from 'react';

export const OneTime = ({ donation, name, currency, isBonusRoundActive, campaignFullData }) => {
  return (
    <div className="total_donation">
      <div className="my_donation">
        <h1>YOUR DONATION</h1>
        <span>{currency ? currency.sign : "$"}&nbsp;<label>{donation.amount}</label></span>
      </div>
      {(donation.matchFactor !== 1) && <div className="total_fund">
        <p>THANKS TO OUR POWER DONOR'S MATCHING FUND</p>
        <h5>{name} Will Receive</h5>
        <div>
          {!isBonusRoundActive && <strong>
            X<span className="multiple">{donation.matchFactor}</span>
            =<span className="currency"> {currency ? currency.sign : "$"} </span><span className="fund_amount">{donation.amount * donation.matchFactor}</span>
          </strong>
          }

          {isBonusRoundActive && <strong>
            X<span className="multiple">{campaignFullData.bonusGoal1MatchFactor}</span>
            =<span className="currency"> {currency ? currency.sign : "$"} </span><span className="fund_amount">{donation.amount * campaignFullData.bonusGoal1MatchFactor}</span>
          </strong>
          }

        </div>
      </div>}
    </div>
  );
}
