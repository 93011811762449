import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  TextField
} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { MSGS } from "../../messages";
import FileUploader from "react-firebase-file-uploader";
import firebase from "./../../apis/firebase.apis";
import LoadingOverlay from "react-loading-overlay";
import "./AddTeamForm.scss";

const TeamForm = ({
  handleAddTeamFormClose,
  onFormInputValueChange,
  onAddTeamClick,
  isFetching,
  isUploading,
  progress,
  onPageNameBlur,
  verifyPageErr,
  handleUploadStart,
  handleUploadError,
  handleUploadSuccess,
  handleProgress,
  state
}) => (
  <div className="team-management-form">
    <LoadingOverlay
      active={isFetching || isUploading}
      spinner
      text={
        isUploading
          ? "File is being uploaded... " + progress + "%"
          : "Please wait..."
      }>
      <DialogTitle className="title">
        {state.isAddModal ? "ADD A NEW TEAM" : "UPDATE TEAM"}
      </DialogTitle>
      <Grid
        container
        className="container"
        style={{ justifyContent: "center" }}>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Team Name:</label>
          <TextField
            className="checkout_input"
            name="teamName"
            value={state.teamName}
            onChange={onFormInputValueChange}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Team Page Name:</label>
          <div className="page-name">
            <label className="base-url">https://pledje.com/</label>
            <TextField
              className="checkout_input"
              name="teamPageName"
              value={state.teamPageName}
              onChange={onFormInputValueChange}
              onBlur={state.isAddModal ? onPageNameBlur : null}
              style={{ border: verifyPageErr ? "1px solid red" : "none" }}
            />
          </div>
          {verifyPageErr ? (
            <span style={{ color: "red" }}>{MSGS.PAGE_NAME_ERR}</span>
          ) : null}
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Campaign Goal (in $):</label>
          <TextField
            disableUnderline
            className="checkout_input"
            type="number"
            name="campaignGoal"
            value={state.campaignGoal}
            onChange={onFormInputValueChange}
          />
          {state.campaignGoalErr ? (
            <span style={{ color: "red" }}>{MSGS.CAMPAIGN_GOAL_ERR}</span>
          ) : null}
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Owner Email:</label>
          <TextField
            disableUnderline
            className="checkout_input"
            name="email"
            value={state.email}
            onChange={onFormInputValueChange}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Owner First Name:</label>
          <TextField
            disableUnderline
            className="checkout_input"
            name="firstName"
            value={state.firstName}
            onChange={onFormInputValueChange}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Owner Last Name:</label>
          <TextField
            disableUnderline
            className="checkout_input"
            name="lastName"
            value={state.lastName}
            onChange={onFormInputValueChange}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Campaign Picture:</label>
          <FileUploader
            accept="image/*"
            name="campaignPicture"
            randomizeFilename
            storageRef={firebase.storage().ref("CampaignPicture")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={filename =>
              handleUploadSuccess(
                "CampaignPicture",
                filename,
                "campaignPicture"
              )
            }
            onProgress={handleProgress}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Avatar:</label>
          <FileUploader
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={firebase.storage().ref("Avatar")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={filename =>
              handleUploadSuccess("Avatar", filename, "avatar")
            }
            onProgress={handleProgress}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Campaign Video:</label>
          <FileUploader
            accept="video/mp4,video/x-m4v,video/*"
            name="campaignVideo"
            randomizeFilename
            storageRef={firebase.storage().ref("CampaignVideos")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={filename =>
              handleUploadSuccess("CampaignVideos", filename, "campaignVideo")
            }
            onProgress={handleProgress}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={5}>
          <label className="label">Or Enter a URL for Video</label>
          <TextField
            disableUnderline
            className="checkout_input"
            placeholder="Enter a video url here..."
            type="text"
            name="campaignVideo"
            value={state.campaignVideo}
            onChange={onFormInputValueChange}
          />
        </Grid>
        <Grid item className="section" xs={10} sm={10}>
          <label className="label">Text 1:</label>
          <TextField
            disableUnderline
            className="checkout_input text-area"
            multiline
            rows={3}
            name="text1"
            value={state.text1}
            onChange={onFormInputValueChange}
          />
        </Grid>
      </Grid>
      <DialogActions className="buttonContainer">
        <Button
          onClick={handleAddTeamFormClose}
          style={{ background: "#d9544f" }}
          className="button">
          Cancel
        </Button>
        <Button
          onClick={onAddTeamClick}
          style={{ background: "#5282b3" }}
          className="button">
          {state.isAddModal ? "Add" : "Update"}
        </Button>
      </DialogActions>
    </LoadingOverlay>
  </div>
);

function AddTeamForm(props) {
  return (
    <Dialog
      open={props.addTeamFormVisible}
      onClose={props.handleAddTeamFormClose}
      aria-labelledby="form-dialog-title"
      scroll="body">
      <TeamForm {...props} />
    </Dialog>
  );
}

export default withMobileDialog()(AddTeamForm);
