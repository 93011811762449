import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import HeaderComponent from '../HeaderComponent';
import DonationTable from './DonationTable';
import {fetchDonations, searchDonation, updateDisplayStatus} from '../../redux/modules/PreCampaignVault/action-creator';
import { fetchCampaigns } from '../../redux/modules/CampaignReports/action-creator';
import { setInitial } from "../../redux/modules/CampaignReports/action-creator";
import DonationTableHeader from './DonationTableHeader';
import LoadingOverlay from 'react-loading-overlay';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '../../components/Snackbar/Snackbar';

class PreCampaignVault extends React.Component {
  constructor(){
    super();

    this.state = { 
      rowsPerPage: 15,
      page: 0,
      campaign: '',
      asc:false,
      amountOrder:'desc',
      statusOrder : "all"
    }
  }

  componentDidMount(){
    this.props.fetchCampaigns(this.props.authUser.organizationId)
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.initialCampaign !== newProps.initialCampaign) {
      this.setState({ campaign: newProps.initialCampaign }, () => {
        const { campaign } = this.state;
        const { authUser: { organizationId } } = this.props;
        this.getDonations(organizationId, campaign.Id || campaign.campaignId);
      });
    }
  }

  getDonations = (organizationId, campaignId) => {
    this.props.fetchDonations(organizationId, campaignId)
  }

  onCampaignChange = e => {
    const { setInitial } = this.props;
    setInitial(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  showSnackBar = (message,isError) => {
    this.setState({showMessage:true,message,error : isError})
    setTimeout(()=>{
        this.setState({showMessage:false,message:""})
    },1500)
  }

  handleChangePage = (event,page) => {
     this.setState({ page });
  };

  handleAmountSort=(e)=>
  {
    
    if(e.target.value == "asc")
    this.setState({
      asc :true,
      amountOrder : e.target.value
    })
    else
    this.setState({
      asc : false,
      amountOrder : e.target.value
    })
  }

  handleStatusSort=(e)=>
  {

      this.setState({
        statusOrder : e.target.value
      })
  }

  handleChangeRowsPerPage = (e) => {
		this.setState({ rowsPerPage: parseInt(e.target.value)});
  }
  
  render() {
    const { campaign,asc,amountOrder,statusOrder } = this.state;
    const { authUser } = this.props;

    return (
      authUser.role !== 'volunteer' ?
      <LoadingOverlay
        active={this.props.isFetching}
        spinner
      >
        <Snackbar
          message={this.state.message}
          color={this.state.error?'danger':'success'}
          icon={this.state.error?ErrorIcon:CheckCircleIcon}
          place='tr'
          open={this.state.showMessage}
        />
        <HeaderComponent
          title1='CAMPAIGN'
          title2='VAULT'
          baseColor="silver"
          select={true}
          campaignList={this.props.campaigns}
          campaign={campaign}
          onCampaignChange={this.onCampaignChange}
        />
        <div className="main-wrap">
          <DonationTableHeader 
            searchItem = {this.props.searchItem} 
            searchDonation = {(event)=>this.props.searchDonation({e:event})}
            rowsPerPage = {this.state.rowsPerPage}
            amountOrder={amountOrder}
            statusOrder={statusOrder}
            handleChangeRowsPerPage = {this.handleChangeRowsPerPage}
            handleAmountSort={this.handleAmountSort}
            handleStatusSort={this.handleStatusSort}
          />
          <DonationTable
            donations={this.props.filteredDonations || this.props.donations}
            updateDisplayStatus = {this.props.updateDisplayStatus}
            rowsPerPage= {this.state.rowsPerPage}
            page={this.state.page}
            asc={asc}
            statusOrder={statusOrder}
            handleChangePage = {(event,page)=>this.handleChangePage(event,page)}
            campaign={campaign}
          />
        </div>
      </LoadingOverlay>
      :
      null
    );
  }
}

const mapStateToProps = state => {
  return {
    filteredDonations: state.preCampaignVaultReducer.filteredDonations,
    searchItem: state.preCampaignVaultReducer.searchItem,
    isFetching: state.preCampaignVaultReducer.isFetching,
    donations: state.preCampaignVaultReducer.donations,
    organizationData: state.campaignReportReducer.organizationData,
    authUser: state.userAuthReducer.authUser,
    campaigns: state.campaignReportReducer.campaigns,
    initialCampaign: state.campaignReportReducer.initialCampaign,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  searchDonation,
  fetchCampaigns,
  fetchDonations,
  updateDisplayStatus,
  setInitial,
},dispatch)

export default connect(mapStateToProps,mapDispatchToProps)(PreCampaignVault);