import React from "react";
import { CampaignVideo } from "../../pages/Campaign/CampaignVideo";
import { Calculator } from "./Calculator";
import { Countdown } from "./Countdown";


// FOR SLIDER----------------
import "./preCampaign.scss";
import ImageGallery from 'react-image-gallery';
// -----------

export const ModalContent = ({/*  */
  campaignInfo,
  name,
  sec,
  mins,
  hours,
  days,
  total_days,
  handleDonate,
  matchFactor,
  preCampaignVideoURL,
  status,
  teamPage,
  teamInfo,
  preCampaignEnd,
  currency
}) => {
  let sliderImages = campaignInfo.mainContent ? (campaignInfo.mainContent.sliderImages ? campaignInfo.mainContent.sliderImages : []) : []
  // let images = sliderImages.map(imagesUrl => {
  //   return {
  //     original: imagesUrl,
  //     thumbnail: imagesUrl,
  //   }
  // });

  return (
    <section className="pre-campaign" style={{marginTop: '30px',marginBottom:'30px'}}>
      {teamPage && <p>
        <h2 className="text-uppercase">
          Welcome To {teamInfo.teamName} 
        </h2>
      </p>}
      <h1>TIME UNTIL START OF THE CAMPAIGN</h1>
      <p>
        YOU CAN MAKE A DONATION TODAY{" "}
        <span className="green-text">
          WHICH WILL BE COUNTED TOWARDS THE CAMPAIGN
        </span>
      </p>
      <Countdown
        sec={sec}
        mins={mins}
        hours={hours}
        days={days}
        total_days={total_days}
      />
      <div className="main-section">
        {preCampaignVideoURL && <CampaignVideo video={preCampaignVideoURL} status={status} preCampaignEnd={preCampaignEnd} />}
        <div className="banner">
          YOUR NAME & DONATION WILL APPEAR ON THE CAMPAIGN PAGE ONCE THE
          CAMPAIGN IS LIVE
          <img
            className="left"
            alt="triangle"
            src="/images/Rectangle 5 copy.png"
          />
          <img className="right" alt="triangle" src="/images/Rectangle 5.png" />
        </div>
        <Calculator
          currency={currency}
          matchFactor={matchFactor}
          name={name}
          handleDonate={handleDonate}
        />

      </div>
      
      {/* <div style={{ marginTop: "50px" }}>
        {images.length ? <ImageGallery items={images} autoPlay={true} /> : null}
      </div> */}
    </section>
  );
};
