import React, { Component } from 'react';
import Header from '../../components/Base/Header';


class HeaderContainer extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        if (this.props.pathname == "/forgot-password")
            return null;
        let arr = this.props.pathname.split('/');
        if (arr[1] == "reset-password")
            return null;
        return (
            <Header fixed />
        );
    }
}

export default HeaderContainer;