import React from 'react';
import { Button } from '@material-ui/core';
import AddTeamForm from './AddTeamForm';


function AddTeamSection(props) {
  return (
    <div className='team-main-actions'>
      <Button className='add-team-btn' variant="contained" onClick ={props.handleAddTeamFormOpen}>
        <i className="fa fa-user-plus" aria-hidden="true"></i>
        ADD A NEW TEAM
      </Button>
      <AddTeamForm {...props} />
    </div>
  );
}

export default AddTeamSection;