import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shareActions from "../../redux/modules/share";
import { CampaignGoals } from "./Goals";
import { Progress } from "./Progress";
import { SocialGoals } from "./SocialGoals";
import { Donars } from "./Donars";
import { Comment } from "./Comment";
import { getSign } from "../../Common/getSign";
import "./CampaignDetails.scss";
import DocumentMeta from 'react-document-meta';
import * as campaignActions from "../../redux/modules/campaign";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { convertCurrency } from "../../Common/CurrencyCalculator.js";
class CampaignDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {},
      donation: 0,
      donors: [],
      shares: {},
      desc: '',
      value: 0,
      showLatestUi: false
      // all: donors,
      // top: top
    };
    this.fullTeamsDetails = [];
    this.finalArr = [];
    this.fullTeamDetailsFilteredArr = [];
  }

  componentWillMount() {
    const { details, donation, donors, onShareActions , showLatestUi} = this.props;
    if (details) {
      this.setState({ details, donation, donors, showLatestUi });
      const { organizationId, campaignId } = details;
      onShareActions.getShareCount({
        organization: organizationId,
        campaign: campaignId
      });
    }
  }



  componentWillReceiveProps(nextProps) {
    const { details, donation, donors, share, fullTeamsDetails } = nextProps;

    this.setState({ details, donation, donors, shares: share.data || {} });
  }

  handleShare = () => {
    const { details, onShareActions } = this.props;
    const { organizationId, campaignId } = details;
    // window.location.href='https://www.facebook.com/sharer/sharer.php?u=https://socialshare-js23.herokuapp.com/socialshare/'+ campaignId;
    //window.open('https://www.facebook.com/sharer/sharer.php?u=https://socialshare-js23.herokuapp.com/socialshare/'+ campaignId,'_blank');
    // window.open(
    //   'https://www.facebook.com/sharer/sharer.php?u=https://d6b466af.ngrok.io/campaigns/socialShare',
    //   '_blank' // <- This is what makes it open in a new window.
    // );
    //     window.open(
    //   'https://twitter.com/intent/tweet?url=https://b9f974aa.ngrok.io/campaigns/socialShare',
    //   '_blank' // <- This is what makes it open in a new window.
    // );
    // const {
    //   onCampaignActions
    // } = this.props;
    // onCampaignActions
    //     .socialShare('campaign')

    // onShareActions
    //   .updateShareCount({ organizationId, campaignId })
    //   .then(res => {
    //     onShareActions.getShareCount({
    //       organization: organizationId,
    //       campaign: campaignId
    //     });
    //   });
    // };

    onShareActions
      .updateShareCount({ organizationId, campaignId })
      .then(res => {
        onShareActions.getShareCount({
          organization: organizationId,
          campaign: campaignId
        });
      });
  };

  signup = (id, campaign) => {
    const { history } = this.props;
    history.push(`/signup-volunteer/${id}/${campaign}`, {
      organizationId: id,
      campaignId: campaign,
      name: this.props.history.location.pathname
    });
  };

  join = (id, campaign) => {
    const { history } = this.props;
    const { details } = this.state;
    history.push(`/signup-volunteer-team/${id}/${campaign}/${details.campaignPage}`, {
      organizationId: id,
      campaignId: campaign,
      name: details.campaignPage
    });
  };

  addTeam = (id, campaign) => {
    const { history } = this.props;
    history.push(`/add-team/${id}/${campaign}`, {
      organizationId: id,
      campaignId: campaign,
      name: this.props.history.location.pathname
    });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getTeamName = (teamObj, fullTeamsDetails) => {
    for (let id in fullTeamsDetails) {
      if (id == teamObj.teamId || fullTeamsDetails[id].id == teamObj.teamId) {
        // if(!fullTeamsDetails[id].amount){
        //   fullTeamsDetails[id].amount = 0;
        // }
        this.finalArr.push({
          teamName: fullTeamsDetails[id].teamName,
          totalChargedAmount: teamObj.totalChargedAmount
        })

      }
    }
  }


  groupByTeamId = (details) => {
    return details.reduce(
      (r, o) => (
        r[o.teamId]
          ? (r[o.teamId].totalChargedAmount += o.totalChargedAmount)
          : (r[o.teamId] = { ...o }), r
      ),
      {}
    );
  }

  render() {
    const { details, donation, donors, shares } = this.state;
    const { fullTeamsDetails } = this.props;
    let mainCampaignPath = details.campaignPage;
    console.log("dddd>>>>>>>>>", this.props, mainCampaignPath)

    if ((Object.keys(donors).length > 0) || (donors.length > 0)) {
      let donorDetailsNew = [];
      donors.forEach(donationDetail => {
        if (donationDetail.teamId) {
          donorDetailsNew.push({
            teamId: donationDetail.teamId,
            totalChargedAmount:
              convertCurrency(donationDetail.amount, donationDetail.currency || 'usd')
          });
        }
      });
      this.finalArr = [];
      let groupedTeam = this.groupByTeamId(donorDetailsNew);
      for (let i in groupedTeam) {
        this.getTeamName(groupedTeam[i], fullTeamsDetails)
      }
      let sortTestArr = this.finalArr;
      sortTestArr.sort((a, b) => {
        return (parseFloat(b.totalChargedAmount) - parseFloat(a.totalChargedAmount))
      })
      this.finalArr = sortTestArr;

    }



    let desc = "";
    if (details.desc) {
      desc = details.desc;
    }
    const {
      imageURL,
      location: { pathname }
    } = this.props;
    // const meta = {
    //   title: 'Some Meta Title',
    //   description: 'I am a description, and I can create multiple tags',
    //   canonical: 'http://example.com/path/to/page',
    //   'og:image':imageURL,
    //   'og:image:secure_url':imageURL,
    //   meta: {
    //     charset: 'utf-8',

    //     name: {
    //       keywords: 'react,meta,document,html,tags'
    //     }
    //   }
    // };
    if (imageURL) {
      process.env.imageUrl = imageURL;

      //       document.getElementsByTagName('meta')["og:image"].content = imageURL;
      // document.getElementsByTagName('meta')["og:image:secure_url"].content = imageURL;

      // var metas = document.getElementsByTagName('meta');
      // for (let i = 0; i < metas.length; i++) {
      //   if (metas[i].getAttribute('property') == "og:image" || metas[i].getAttribute('property') == "og:image:secure_url") {
      //     metas[i].setAttribute("content",imageURL);
      //   }
      // }

      // for (let i = 0; i < metas.length; i++) {
      //   if (metas[i].getAttribute('property') == "og:image" || metas[i].getAttribute('property') == "og:image:secure_url") {
      //     console.log('meta',metas[i].getAttribute('content'));
      //   }
      // }

      // var meta1 = document.createElement('meta');
      // meta1.property = "og:image";
      // meta1.content = imageURL;
      // document.getElementsByTagName('head')[0].appendChild(meta1);

      // var meta2 = document.createElement('meta');
      // meta2.property = "og:image:secure_url";
      // meta2.content = imageURL;
      // document.getElementsByTagName('head')[0].appendChild(meta2);


    }
    const sign = getSign(details.campaignCurrency || details.currency);
    this.fullTeamDetailsFilteredArr = [];
    for (let id in fullTeamsDetails) {
      if (fullTeamsDetails[id].isActive) {
        this.fullTeamDetailsFilteredArr.push({
          campaignPath: mainCampaignPath + '/' + fullTeamsDetails[id].pageName,
          imageURL1: fullTeamsDetails[id].avatar,
          teamName: fullTeamsDetails[id].teamName
        });
      }
    }
    return (
      <aside id="project-sidebar">
        { !this.state.showLatestUi &&
          <div className='side-clock' data-aos="zoom-out-left">
            <CampaignGoals details={details} donation={donation} sign={sign} />
            <Progress
              shares={shares}
              bonusGoal={details.bonusGoalAmount1}
              goalAmount={details.goalAmount}
              socialShareAmount={details.socialShareAmount}
              donation={donation}
              sign={sign}
            />
          </div>}
        <div className="mobile-change-order">

          {/* <DocumentMeta {...meta}>
          <h1>Hello World!</h1>
        </DocumentMeta> */}
          <SocialGoals
            shares={shares}
            desc={desc}
            imageUrl={imageURL}
            details={details}
            sign={sign}
            handleShare={this.handleShare}
          />
          {pathname !== "/checkout" && details.mode !== "e-commerce" && (
            <img className="camp-img-resp" src={imageURL} alt="Campaign" />
          )}

          <section className="donor-list" data-aos="zoom-out-left">
            <Tabs className='tabs' value={this.state.value} indicatorColor="primary"
              textColor="primary" onChange={this.handleChange}>
              <Tab label="All Donors" />
              <Tab label="Top Teams" />
              <Tab label="All Teams" />
            </Tabs>
            {this.state.value == 0 && <Donars donors={donors} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" />}
            {this.state.value == 1 && <Donars donors={this.finalArr} team={true} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" />}
            {this.state.value == 2 && <Donars donors={this.finalArr} fullTeamDetailsFilteredArr={this.fullTeamDetailsFilteredArr} team={false} allTeams={true} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" />}

          </section>

          {/* <Donars donors={donors} campaignCurrency={details.campaignCurrency} /> */}
          {/* <button
            className="sidebar-blue-btn"
            onClick={() => this.addTeam(details.organizationId, details.campaignId)}
          >
            JOIN OUR CAMPAIGN
          </button>

          <button
            className="sidebar-blue-btn"
            onClick={() =>
              this.signup(details.organizationId, details.campaignId)
            }
          >
            JOIN AS A VOLUNTEER
          </button> */}

          <button
            className="sidebar-blue-btn"
            onClick={() =>
              this.join(details.organizationId, details.campaignId)
            }
          >
            CREATE A TEAM PAGE
          </button>

        </div>
        <Comment details={details} />
      </aside>
    );
  }
}

const mapStateToProps = state => {
  return {
    share: state.share.get("share"),
    verifyPageErr: state.teamManagementReducer.verifyPageErr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShareActions: bindActionCreators(shareActions, dispatch), onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDetails);
