import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import LoadingOverlay from "react-loading-overlay";
import Snackbar from "../../components/Snackbar/Snackbar";
import { Header } from "./Header";
import CampaignForm from "./CampaignForm";
import OrganizationForm from "./OrganizationForm";
import SuperDonors from "./SuperDonors";
import CampaignItems from "./CampaignItems";
import AddRoute from "./AddRoute";
import CalculatorSetup from "./CalculatorSetup";
import "./index.scss";
import "./AdditionalInfo.scss";

class NewCampaign extends Component {
  constructor() {
    super();

    this.state = {
      organizations: [],
      organization: {},
      campaign: {},
      openOrg: false,
      openSuperDonors: false,
      superDonors: [],
      showSuperDonors: true,
      openItems: false,
      items: [],
      showMessage: false,
      error: false,
      message: "",
      edit: false,
      openRoute: false,
      calculatorSetup: [],
      openCalculatorSetup: false,
      mode: 'monthly',
      submit: false,
      isItemValidationErr: false
    }
  }

  componentDidMount() {
    this.getOrganizations();

  }

  componentDidUpdate(oldProps) {
    const { organizations, campaigns } = this.props;
    if (oldProps.organizations !== organizations) {
      const organization = organizations.find(o => o.organizationName === this.state.organization.organizationName)
      if (this.state.submit) {
        this.setState({ organizations, organization: organization });
        this.getCampaigns(organization);

      }
      else {
        this.setState({ organizations, organization: organizations[0] });
        this.getCampaigns(organizations[0]);

      }
      // this.setState({ organizations, organization: organizations[0] });
      // this.getCampaigns(organizations[0]);
    }
    if (oldProps.campaigns !== campaigns) {
      if (this.state.submit) {

        this.setCampaignonSubmit(this.state.campaign)

      }
      else {

        this.setCampaign(campaigns[0]);
      }
    }
  }

  getOrganizations = () => {
    const { getOrganizations } = this.props;

    getOrganizations()
      .catch(err => this.showSnackBar(err.payload, true));
  }

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 3000);
  };

  setCampaign = (value) => {
    this.setState({
      edit: value ? true : false,
      campaign: value || {},
      items: value ? value.items : [],
      superDonors: value ? value.superDonors : [],
      showSuperDonors: value ? value.showSuperDonors : true,
      calculatorSetup: value ? value.calculatorSetup : [],
      mode: value ? value.mode : 'monthly'
    });
  }

  setCampaignonSubmit = (value) => {
    this.setState({
      edit: value ? true : false,
      campaign: value || {},
      items: value ? value.items : [],
      superDonors: value ? value.superDonors : [],
      showSuperDonors: value ? value.showSuperDonors : true,
      calculatorSetup: value ? value.calculatorSetup : [],
      mode: value ? value.mode : 'monthly',
      submit: false
    });
  }

  selectOrganization = (e) => {
    const { organizations } = this.state;
    const organization = organizations.find(o => o.organizationName === e.target.value)
    //console.log('000000000000000000000',organization)
    this.setState({ organization });
    this.getCampaigns(organization);
  }

  selectCampaign = (campaigns, event) => {    //only param (e) before
    // UNCOMMENT BELWO 2 LINES IF ISSUE CREATES -----------------
    // const { value } = e.target;
    // this.setCampaign(value);
    // ----------------------------------------------
    const { value } = event.target;
    let currentSelectedCam = campaigns.filter((campaign) => (campaign.name).trim() == value.trim())
    this.setCampaign(currentSelectedCam[0]);
  }

  getCampaigns = (e) => {
    const { fetchCampaigns } = this.props;
    fetchCampaigns(e);
  }

  createNewCampaign = () => {
    this.setState({
      campaign: {},
      edit: false,
      superDonors: [],
      items: [],
      calculatorSetup: [],
      mode: 'monthly'
    })
  }

  handleOrg = () => {
    this.setState({ openOrg: !this.state.openOrg });
  }

  createOrganization = (data) => {
    const { addOrganization, addUser, removeOrganization } = this.props;
    const orgData = {
      campaigns: {},
      organizationName: data.organizationName,
      organizationAddress: data.organizationAddress,
      organizationTaxId: data.organizationTaxId,
      organizationPhone: data.organizationPhone,
      firebase: true
    }
    let organizationId = '';

    addOrganization(orgData)
      .then(res => {
        organizationId = res.payload;
        const adminData = {
          user: {
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
            email: data.email,
            role: 'admin',
            status: 'Active',
            organizationId: res.payload
          }
        }
        addUser(adminData)
          .then(() => {
            this.showSnackBar("Organization has been Created!", false);
            this.getOrganizations();
            this.handleOrg();
          })
          .catch(err => {
            removeOrganization(organizationId);
            this.showSnackBar(
              err.response.data.error.message
                ? err.response.data.error.message
                : err.response.data.error,
              true
            )
          })
      })
      .catch(err => {
        this.showSnackBar(err.payload, true)
      })
  }

  handleOrganizationChange = (e) => {
    e.persist();
    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        [e.target.name]: e.target.value
      }
    }))
  }

  saveCampaign = (data) => {
    const { superDonors, submit, items, edit, organization, calculatorSetup, mode } = this.state;
    const { addRoute } = this.props;


    if (calculatorSetup.length === 0 && mode !== 'e-commerce') {
      return this.showSnackBar('Please setup calculator values', true);
    }

    data.superDonors = superDonors;
    data.items = items;
    data.organizationId = organization.id;

    data.preCampaignStartDate = new Date(data.preCampaignStartDate).getTime()
    data.preCampaignEndDate = new Date(data.campaignStartTime).getTime()
    data.campaignStartTime = new Date(data.campaignStartTime).getTime()
    data.campaignEndTime = new Date(data.campaignEndTime).getTime()
    data.bonusGoalEndDate = new Date(data.bonusGoalEndDate).getTime()
    data.calculatorSetup = calculatorSetup;
    data.mode = mode;

    organization.campaigns[data.campaignId] = data;
    if (edit) {
      this.addCampaign(organization);
      this.setState({
        submit: true
      })
    } else {
      const route = {
        campaignId: data.campaignId,
        organizationId: organization.id,
        campaignPage: `/${data.campaignPage.toLowerCase()}/`,
        route: `/${data.campaignPage.toLowerCase()}/`,
        firebase: true,
        teamId: "",
        teamPage: ""
      };

      addRoute(route)
        .then(() => this.addCampaign(organization))
        .catch(err => this.showSnackBar(err.payload, true));
    }
  }

  addCampaign = (organization) => {
    console.log("?@@", organization)
    const { addCampaign } = this.props;
    const { edit } = this.state;

    addCampaign(organization)
      .then(() => {
        if (edit) {
          this.showSnackBar('Campaign has been Updated!', false)
        } else {
          this.showSnackBar('A new Campaign has been Created!', false)
        }

        this.getOrganizations();
      })
      .catch(err => {
        console.log("err@@", err)
        this.showSnackBar(err.payload, true)
      });
  }

  handleSuperDonors = () => {
    this.setState({ openSuperDonors: !this.state.openSuperDonors });
  }

  saveSuperDonors = data => {
    this.setState({ superDonors: data });
    this.handleSuperDonors();
  }

  handleItems = () => {
    this.setState({ openItems: !this.state.openItems });
  }

  saveItems = data => {
    
    let tempItemsArr = data;
    let flag = tempItemsArr.some((item, index) => item.price == 0);
    if (flag) {
      this.setState({
        isItemValidationErr: true
      })
    } else {
      this.setState({ items: data });
      this.handleItems();
    }

  }

  handleRoute = () => {
    this.setState({ openRoute: !this.state.openRoute });
  }

  addRoute = (route) => {
    const { addRoute } = this.props;

    this.handleRoute();
    addRoute(route)
      .catch(err => this.showSnackBar(err.payload, true));
  }

  handleCalculatorSetup = () => {
    this.setState({ openCalculatorSetup: !this.state.openCalculatorSetup });
  }

  saveSetup = setup => {
    this.setState({ calculatorSetup: setup });
    this.handleCalculatorSetup();
  }

  showOverlay = () => {
    this.setState({
      loading: true
    })
  }

  hideOverlay = () => {
    this.setState({
      loading: false
    })
  }

  changeMode = (e) => {
    this.setState({ mode: e.target.value });
  }

  render() {
    const { organizations, organization, campaign, openOrg, error, showMessage, message, openSuperDonors, superDonors, openItems, items, edit, openRoute, showSuperDonors, openCalculatorSetup, calculatorSetup, mode } = this.state;
    const { campaigns, loading, authUser } = this.props;
    const campaignUrl = `https://pledje.com/${campaign.campaignPage}`;
    return (
      authUser.role === 'super-admin' ?
        <LoadingOverlay active={loading} spinner text="Loading">
          <Snackbar
            message={message}
            color={error ? "danger" : "success"}
            icon={error ? ErrorIcon : CheckCircleIcon}
            place="tr"
            open={showMessage}
          />
          <div id='new-campaign'>
            <Header title={campaign.name} handleOpen={this.handleOrg} organizations={organizations} organization={organization} selectOrganization={this.selectOrganization} />
            <div className="mid-section">
              {edit && <button className="route-btn"><a target="_blank" href={campaignUrl}>PREVIEW CAMPAIGN</a></button>}
              {edit && <button onClick={this.handleRoute} className="route-btn">ADD ROUTE</button>}
              <div className="edit-action">
                <label>Update Campaign</label>
                {/* <Select
                  disableUnderline={true}
                  className="select"
                  name="campaign"
                  value={campaign}
                  onChange={this.selectCampaign}
                >
                  {campaigns.map(e =>
                    <MenuItem value={e} key={e.name}>{e.name}</MenuItem>
                  )}
                </Select> */}
                {/* NEW DROPDOWN ADDED IF IT WILL CREATE ISSUE THEN COMMENT BELOW SELCET BOX AND UNCOMMENT ABOVE SELECT 
                 BOX */}
                <Select
                  disableUnderline={true}
                  className="select"
                  name="campaign"
                  value={campaign.name}
                  onChange={(event) => this.selectCampaign(campaigns, event)}
                >
                  {campaigns.map(e =>
                    <MenuItem value={e.name} key={e}>{e.name}</MenuItem>
                  )}
                </Select>



              </div>
              <h4>OR</h4>
              <button onClick={this.createNewCampaign} className="create-btn">
                CREATE NEW CAMPAIGN
            </button>
            </div>
            <CampaignForm organization={organization} showOverlay={this.showOverlay} hideOverlay={this.hideOverlay} handleOrganizationChange={this.handleOrganizationChange} campaign={campaign} handleSuperDonors={this.handleSuperDonors} handleItems={this.handleItems} saveCampaign={this.saveCampaign} showSnackBar={this.showSnackBar} handleCalculatorSetup={this.handleCalculatorSetup} changeMode={this.changeMode} mode={mode} />
            {openOrg && <OrganizationForm handleClose={this.handleOrg} createOrganization={this.createOrganization} />}
            {openSuperDonors && <SuperDonors showSuperDonors={showSuperDonors} superDonors={superDonors} handleSuperDonors={this.handleSuperDonors} showSnackBar={this.showSnackBar} saveSuperDonors={this.saveSuperDonors} />}
            {openItems && <CampaignItems isItemValidationErr={this.state.isItemValidationErr} items={items} handleItems={this.handleItems} showSnackBar={this.showSnackBar} saveItems={this.saveItems} />}
            {openRoute && <AddRoute handleClose={this.handleRoute} campaign={campaign} addRoute={this.addRoute} />}
            {mode !== 'e-commerce' && openCalculatorSetup && <CalculatorSetup calculatorSetup={calculatorSetup} saveSetup={this.saveSetup} showSnackBar={this.showSnackBar} mode={mode} handleCalculatorSetup={this.handleCalculatorSetup} />}
          </div>
        </LoadingOverlay>
        :
        null
    );
  }
};

export default NewCampaign;
