import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import firebase from "firebase";
import HeaderComponent from "../HeaderComponent";
import AddTeamSection from "./AddTeamSection";
import TeamTable from "./TeamTable";
import {
  addTeam,
  onEnableDisableClick,
  searchTeam,
  onEditTeamIconClick,
  onSaveTeamIconClick,
  handleEditInputChange,
  fetchTeams,
  deleteTeam,
  saveEditedTeam,
  verifyPageName
} from "../../redux/modules/TeamManagement/action-creator";
import { fetchCampaigns } from "../../redux/modules/CampaignReports/action-creator";
import TeamTableHeader from "./TeamTableHeader";
import { MSGS } from "../../messages";
import LoadingOverlay from "react-loading-overlay";
import { isOnlyFloatOrInteger } from "../../validations";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "../../components/Snackbar/Snackbar";
import { getExchangeRate } from "../../Common/Exchange";
import styles from "./style";
import "./index.scss";

import ReactExport from "react-data-export";
import { getAllDonations } from "../../lib/campaign"
import campaign from "../../redux/modules/campaign";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class TeamManagement extends React.Component {
  constructor() {
    super();

    this.state = {
      enableDisableLoader: false,
      addTeamFormVisible: false,
      avatar: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      teamName: "",
      teamPageName: "",
      teamCampaign: "",
      campaignGoal: "",
      campaignPicture: "",
      campaignVideo: "",
      text1: "",
      text2: "",
      text3: "",
      shareGoal: "",
      rowsPerPage: 15,
      page: 0,
      campaignGoalErr: false,
      isAddModal: true,
      campaign: "",
      message: "",
      teamData: {},
      finalExcelArr: [],
      showDownloadButton: false,
      showExcelLoader: false
    };
    this.updateTeam = this.updateTeam.bind(this);
    this.excelFileName = "";
  }

  componentDidMount() {
    const {
      authUser: { organizationId }
    } = this.props;
    this.props.fetchCampaigns(organizationId);
  }

  componentDidUpdate(oldProps) {
    const { initialCampaign } = this.props;
    if (oldProps.initialCampaign !== initialCampaign) {
      getExchangeRate(initialCampaign.campaignCurrency || initialCampaign.currency || 'usd');
      this.setState(
        { campaign: initialCampaign, teamCampaign: initialCampaign.Id || initialCampaign.campaignId },
        () => {
          const {
            fetchTeams,
            authUser: { organizationId }
          } = this.props;
          fetchTeams(organizationId, this.state.campaign.Id || this.state.campaign.campaignId);
        }
      );
    }
  }

  changeFormInputValue = e => {
    let value = e.target.value;

    if (e.target.name === "campaignGoal") {
      if (!isOnlyFloatOrInteger(e.target.value)) {
        if (!e.target.value === "") {
          this.setState({ campaignGoalErr: true });
        } else {
          this.setState({ campaignGoalErr: false });
        }
      } else {
        this.setState({ campaignGoalErr: false });
      }
    }

    this.setState({ [e.target.name]: value });
  };

  onCampaignSelect = e => {
    this.setState(
      { [e.target.name]: e.target.value, teamCampaign: e.target.value.Id },
      () => {
        const {
          fetchTeams,
          authUser: { organizationId }
        } = this.props;
        const { campaign } = this.state;
        fetchTeams(organizationId, campaign.Id || campaign.campaignId);
      }
    );
  };

  deleteTeam = id => {
    const {
      campaign: { Id, campaignId }
    } = this.state;
    let teamToDelete = {
      organizationId: this.props.authUser.organizationId,
      campaignId: Id || campaignId,
      teamId: id
    };
    this.props.deleteTeam(teamToDelete);
  };

  updateStatus = team => {
    const {
      campaign: { Id, campaignId }
    } = this.state;
    const {
      authUser: { organizationId },
      onEnableDisableClick
    } = this.props;
    let teamData = {
      organizationId: organizationId,
      campaignId: Id || campaignId,
      team: team
    };
    onEnableDisableClick(teamData);
    this.setState({ enableDisableLoader: true });
  };

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 3000);
  };

  handleImageChange = event => {
    const supportedExtensions = ["jpeg", "jpg", "png", "gif"];
    this.checkFileExtension(event, supportedExtensions);
  };

  handleVideoChange = event => {
    const supportedExtensions = ["mp4"];
    this.checkFileExtension(event, supportedExtensions);
  };

  checkFileExtension = (event, supportedExtensions) => {
    const path = event.target.files[0].name.split(".");
    const extension = `${path[path.length - 1]}`;

    if (supportedExtensions.includes(extension)) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.showSnackBar("Invalid file type", true);
      this.setState({ [event.target.name]: "" });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  addTeam = () => {
    const {
      teamName,
      teamPageName,
      teamCampaign,
      campaignPicture,
      campaignVideo,
      campaignGoal,
      text1,
      avatar
    } = this.state;
    const {
      authUser: { organizationId }
    } = this.props;
    if (
      teamName === "" ||
      teamPageName === "" ||
      teamCampaign === "" ||
      teamCampaign === "none" ||
      campaignGoal === ""
    ) {
      return this.showSnackBar(MSGS.REQUIRED_ERR, true);
    }
    if (this.state.campaignGoalErr) {
      return this.showSnackBar(MSGS.CAMPAIGN_GOAL_ERR, true);
    }

    let team = {
      organizationId,
      campaignId: teamCampaign,
      teamName,
      teamPageName,
      teamCampaign,
      campaignPicture,
      campaignVideo,
      avatar,
      campaignGoal,
      text1
    };
    this.props.addTeam(team);
    this.handleAddTeamFormClose();
  };

  async updateTeam() {
    const {
      teamCampaign,
      teamName,
      avatar,
      teamId,
      campaignGoal,
      campaignPicture,
      campaignVideo,
      text1,
      shareGoal,
      campaign: { Id, campaignId }
    } = this.state;
    const {
      authUser: { organizationId }
    } = this.props;

    let team = {
      id: teamId,
      teamName,
      teamCampaign,
      campaignPicture,
      campaignVideo,
      avatar,
      campaignGoal,
      text1,
      shareGoal
    };

    await this.props.saveEditedTeam({ organizationId, campaignId: Id || campaignId, team });
    this.handleAddTeamFormClose();
  }

  verifyPage = event => {
    if (!this.state.teamCampaign || !event.target.value) {
      return;
    }

    this.props.verifyPageName({
      organizationId: this.props.authUser.organizationId,
      campaignId: this.state.teamCampaign,
      teamPageName: event.target.value
    });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  }

  onEditTeamIconClick = team => {
    console.log("jay ho inside" , team)
    const {
      avatar,
      goalAmount,
      id,
      imageURL1,
      isActive,
      pageName,
      firstName,
      lastName,
      email,
      phone,
      teamName,
      text1,
      videoURL1,
      shareGoal
    } = team;
    this.setState({
      addTeamFormVisible: true,
      isAddModal: false,
      teamData: team
    });
  };

  handleAddTeamFormClose = () => {
    this.setState({
      addTeamFormVisible: false,
      teamName: "",
      teamPageName: "",
      shareGoal: "",
      avatar: "",
      campaignGoal: "",
      campaignPicture: "",
      campaignVideo: "",
      text1: "",
      text2: "",
      text3: "",
      campaignGoalErr: false,
      teamData: []
    });
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    this.showSnackBar("Error uploading file", true);
  };

  handleUploadSuccess = (source, filename, keyName) => {
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(source)
      .child(filename)
      .getDownloadURL()
      .then(url =>
        this.setState({ [keyName]: url }, () =>
          this.showSnackBar("File uploaded", false)
        )
      );
  };

  componentWillReceiveProps() {
    this.setState({ enableDisableLoader: false });
  }

  setExcelData = () => {
    const {
      campaign: { Id, campaignId }
    } = this.state;
    let allTeams = [];
    let allDonations = [];
    let totalDonation = 0;
    let campId = this.state.campaign ? (this.state.campaign.campaignId ? this.state.campaign.campaignId : this.state.campaign.Id ) : "";
    allTeams = this.props.teams && this.props.teams.length > 0 ? this.props.teams : [];
    if (this.props.authUser.organizationId && campId) {
      this.setState({ showExcelLoader: true, showDownloadButton: false })
      getAllDonations(this.props.authUser.organizationId, campId).then(ress => {
        if (ress.data) {
          allDonations = ress.data.data.donations;
          Promise.all(allTeams.map((team) => {
            allDonations.map((donation) => {
              if (donation.teamId === team.id) {
                totalDonation = totalDonation + donation.amount;
              }
            });
            let tempObj = {
              teamName: team.teamName,
              email: team.email,
              phone: team.phone,
              goalAmount: team.goalAmount,
              amountRaised: totalDonation,
              teamLink: `https://pledje.com/${this.state.campaign.campaignPage}/${team.pageName}`
            };
            totalDonation = 0;
            return tempObj;
          })
          ).then(finalData => {
            this.setState({
              showDownloadButton: true,
              showExcelLoader: false,
              finalExcelArr: finalData.sort((a, b) => b.amountRaised - a.amountRaised)
            });
          });
        }
      });
    }
  }

  render() {
    const { campaign } = this.state;
    this.excelFileName = campaign && campaign.campaignPage ? `${campaign.campaignPage}'s-All-Teams-Info-${new Date().getMonth()}/${new Date().getDate()}/${new Date().getFullYear()}` : "All Team's Details";
    return (
      <LoadingOverlay
        active={this.props.isFetching || this.state.isUploading || this.state.enableDisableLoader}
        spinner
        text={
          this.state.isUploading
            ? "File is being uploading... " + this.state.progress + "%"
            : "Please wait..."
        }
      >
        <Snackbar
          message={this.state.message}
          color={this.state.error ? "danger" : "success"}
          icon={this.state.error ? ErrorIcon : CheckCircleIcon}
          place="tr"
          open={this.state.showMessage}
        />
        <div
          className="team-management-container"
          style={{ ...styles.container }}
        >
          <HeaderComponent
            title1="TEAM"
            title2="MANAGEMENT"
            baseColor="#7CFC00"
            select={true}
            campaignList={this.props.campaigns}
            campaign={campaign}
            onCampaignChange={this.onCampaignSelect}
          />

          {(!this.state.showDownloadButton && !this.state.showExcelLoader) && <button className="cta-downlaod-btn" type="button" onClick={this.setExcelData}> <i className="fa fa-building"></i> Prepare Excel Sheet </button>}
          {this.state.showExcelLoader && <button className="cta-downlaod-btn" type="button" disabled> <i className="fa fa-circle-notch fa-spin"></i> Preparing... </button>}
          {this.state.showDownloadButton && <ExcelFile filename={this.excelFileName} element={<button className="cta-downlaod-btn" type="button"><i className="fa fa-download"></i> Download Ready</button>}>
            <ExcelSheet data={this.state.finalExcelArr} name="Employeeswew">
              <ExcelColumn label="Team Name" value="teamName" />

              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Phone" value="phone" />
              <ExcelColumn label="Goal Amount" value="goalAmount" />
              <ExcelColumn label="Amount Raised" value="amountRaised" />
              <ExcelColumn label="Team Link" value="teamLink" />
            </ExcelSheet>
          </ExcelFile>}


          <AddTeamSection
            campaignId={this.state.teamCampaign}
            organizationId={this.props.authUser.organizationId}
            campaignName={campaign.campaignPage}
            addTeamFormVisible={this.state.addTeamFormVisible}
            handleAddTeamFormOpen={() =>
              this.setState({ addTeamFormVisible: true, isAddModal: true, teamData: [] })
            }
            handleAddTeamFormClose={this.handleAddTeamFormClose}
            handleImageChange={this.handleImageChange}
            handleVideoChange={this.handleVideoChange}
            onFormInputValueChange={this.changeFormInputValue}
            onAddTeamClick={this.addTeam}
            onPageNameBlur={this.verifyPage}
            verifyPageErr={this.props.verifyPageErr}
            handleUploadStart={this.handleUploadStart}
            handleUploadError={this.handleUploadError}
            handleUploadSuccess={this.handleUploadSuccess}
            handleProgress={this.handleProgress}
            isFetching={this.state.isFetching}
            isUploading={this.state.isUploading}
            progress={this.state.progress}
            onUpdateTeam={() => this.updateTeam()}
            onEditorStateChange={event => console.log(event)}
            team={this.state.teamData}
          />
          <div className="main-wrap">
            <TeamTableHeader
              searchItem={this.props.searchItem}
              searchTeam={event => this.props.searchTeam({ e: event })}
              rowsPerPage={this.state.rowsPerPage}
              handleChangeRowsPerPage={event =>
                this.handleChangeRowsPerPage(event)
              }
            />
            <TeamTable
              teams={this.props.filteredTeams || this.props.teams}
              onEnableDisableClick={this.updateStatus}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              handleChangePage={(e, page) => this.handleChangePage(e, page)}
              onEditTeamIconClick={this.onEditTeamIconClick}
              editTeamName={this.props.editTeamName}
              editTeamPageName={this.props.editTeamPageName}
              editCampaignGoal={this.props.editCampaignGoal}
              editText1={this.props.editText1}
              editText2={this.props.editText2}
              editText3={this.props.editText3}
              handleEditInputChange={event =>
                this.props.handleEditInputChange(event)
              }
              onSaveTeamIconClick={(e, team) =>
                this.props.saveEditedTeam({
                  organizationId: this.props.authUser.organizationId,
                  campaignId: this.state.campaign.Id || this.state.campaign.campaignId,
                  team: team
                })
              }
              onDeleteTeamIconClick={this.deleteTeam}
              campaignFullInfo ={campaign}
            />
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.teamManagementReducer.isFetching,
    teams: state.teamManagementReducer.teams,
    filteredTeams: state.teamManagementReducer.filteredTeams,
    searchItem: state.teamManagementReducer.searchItem,
    editTeamName: state.teamManagementReducer.editTeamName,
    editTeamPageName: state.teamManagementReducer.editTeamPageName,
    editCampaignGoal: state.teamManagementReducer.editCampaignGoal,
    editText1: state.teamManagementReducer.editText1,
    editText2: state.teamManagementReducer.editText2,
    editText3: state.teamManagementReducer.editText3,
    verifyPageErr: state.teamManagementReducer.verifyPageErr,
    initialCampaign: state.campaignReportReducer.initialCampaign,
    campaigns: state.campaignReportReducer.campaigns,
    authUser: state.userAuthReducer.authUser
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addTeam,
      onEnableDisableClick,
      searchTeam,
      onEditTeamIconClick,
      onSaveTeamIconClick,
      handleEditInputChange,
      fetchCampaigns,
      fetchTeams,
      deleteTeam,
      saveEditedTeam,
      verifyPageName
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamManagement);
