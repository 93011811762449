import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const Countdown = ({ sec, mins, hours, days, total_days }) => {
  return (
    <Grid className='countdown' container spacing={24}>
      <Grid className='item' item xs={3}>
        <CircularProgressbar
          percentage={days/total_days * 100}
          background={true}
          backgroundPadding={0}
          styles={{
            path: { stroke: '#1abc9c' },
          }}
        />
        <div className='svg-text days'><div className='remaining'>{days}</div><div className='datetime'>DAYS</div></div>
      </Grid>
      <Grid className='item' item xs={3}>
        <CircularProgressbar
          percentage={hours/24 * 100}
          background={true}
          backgroundPadding={0}
          styles={{
            path: { stroke: '#2980b9' },
          }}
        />
        <div className='svg-text hours'><div className='remaining'>{hours}</div><div className='datetime'>HOURS</div></div>
      </Grid>
      <Grid className='item' item xs={3}>
        <CircularProgressbar
          percentage={mins/60 * 100}
          background={true}
          backgroundPadding={0}
          styles={{
            path: { stroke: '#8e44ad' },
          }}
        />
        <div className='svg-text mins'><div className='remaining'>{mins}</div><div className='datetime'>MINUTES</div></div>
      </Grid>
      <Grid className='item' item xs={3}>
        <CircularProgressbar
          percentage={sec/60 * 100}
          background={true}
          backgroundPadding={0}
          styles={{
            path: { stroke: '#f39c12' },
          }}
        />
        <div className='svg-text sec'><div className='remaining'>{sec}</div><div className='datetime'>SECONDS</div></div>
      </Grid>
    </Grid>
  )
}