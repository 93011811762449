import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as campaignAPI from "../../lib/campaign";
import _ from 'lodash';
import { Map } from "immutable";

const SOCIAL_SHARE = "campaign/SOCIAL_SHARE";
const GET_CAMPAIGN = "campaign/GET_CAMPAIGN";
const TOTAL_DONATIONS = "campaign/TOTAL_DONATIONS";
const ALLDONATIONS = "campaign/ALLDONATIONS";
const CAPTURE_DONATIONS="campaigns/CAPTURE_DONATIONS";
const GET_DONATIONS = "campaign/GET_DONATIONS";
const SET_DONATION = "campaign/SET_DONATION";
const GET_ITEMS = "campaign/GET_ITEMS";
const FETCH_FEATURED = "campaign/FETCH_FEATURED";
const GET_CAMPAIGN_BY_ID = "campaign/GET_CAMPAIGN_BY_ID";

export const setDonation = createAction(SET_DONATION);
 export const socialShare = createAction(SOCIAL_SHARE, campaignAPI.socialShare);
export const getCampaign = createAction(GET_CAMPAIGN, campaignAPI.getCampaign);
export const getCampaignById = createAction(GET_CAMPAIGN_BY_ID, campaignAPI.getCampaignById);
export const totalDonation = createAction(TOTAL_DONATIONS, campaignAPI.totalDonation);
export const  captureDonations= createAction(CAPTURE_DONATIONS, campaignAPI.captureDonations);
export const  totalDonationsForCapturing= createAction(ALLDONATIONS, campaignAPI.totalDonationsForCapturing);
export const getDonations = createAction(GET_DONATIONS, campaignAPI.getDonations);
export const getItems = createAction(GET_ITEMS, campaignAPI.getItems);
export const fetchFeatureCampaigns = createAction(FETCH_FEATURED, campaignAPI.fetchFeatureCampaigns);
export const fetchOrg = createAction(FETCH_FEATURED, campaignAPI.fetchOrg);

const initialState = Map({
  campaignData: {},
  checkout: {},
  total_donation: {},
  allDonations:{},
  donations: {},
  items: [],
  featureCampaigns: []
});

// const sortItems = (payload) => {
//   return _.orderBy(payload, ['price'], ['asc']);
// }

export default handleActions(
  {
    [SET_DONATION]: (state, action) => state.set("checkout", action.payload),
    ...pender({
      type: GET_ITEMS,
      onSuccess: (state, action) =>
        state.set("items", action.payload.data.data1)
    }),
    ...pender({
      type: GET_CAMPAIGN,
      onSuccess: (state, action) =>
        state.set("campaignData", action.payload.data)
    }),
    ...pender({
      type: GET_CAMPAIGN_BY_ID,
      onSuccess: (state, action) =>
        state.set("campaignData", action.payload.data)
    }),
    ...pender({
      type: TOTAL_DONATIONS,
      onSuccess: (state, action) =>
        state.set("total_donation", action.payload)
    }),
    ...pender({
      type: ALLDONATIONS,
      onSuccess: (state, action) =>
        state.set("allDonations", action.payload)
    }),
    ...pender({
      type: GET_DONATIONS,
      onSuccess: (state, action) => state.set("donations", action.payload)
    }),
    ...pender({
      type: FETCH_FEATURED,
      onSuccess: (state, action) =>
        state.set("featureCampaigns", action.payload)
    })
  },
  initialState
);
