import React from 'react';
import { AppBar, InputBase, NativeSelect } from '@material-ui/core';
import './DonationTableHeader.scss';

function TeamTableHeader({ searchItem, searchDonation,amountOrder,statusOrder,handleStatusSort, handleAmountSort, rowsPerPage, handleChangeRowsPerPage }) {
  return (
    <AppBar style={{backgroundColor:'silver'}} position="static" id='vault-table-header'>
      <div className='search'>
        <InputBase className='input' placeholder="Search" value={searchItem} onChange={searchDonation}/> 
        <i className="fa fa-search" aria-hidden="true"></i>
      </div>
      <div className='table-view'>
        Sort By Amount
        <NativeSelect 
          className='select'
          disableUnderline
          value={amountOrder}
          onChange={handleAmountSort}
          name="sortTableRows"
        >
          <option value="desc" className = "view-option">Descending</option>
          <option value="asc" className = "view-option">Ascending</option>
        </NativeSelect>                         
      </div>
      <div className='table-view'>
        Sort By Status
        <NativeSelect 
          className='select'
          disableUnderline
          value={statusOrder}
          onChange={handleStatusSort}
          name="sortTableByStatus"
        >
          <option value="all" className = "view-option">All</option>
          <option value="true" className = "view-option">True</option>
          <option value="false" className = "view-option">False</option>
        </NativeSelect>                         
      </div>
      <div className='table-view'>
        Show
        <NativeSelect 
          className='select'
          disableUnderline
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          name="viewTableRows"
        >
          <option value="15" className = "view-option">15</option>
          <option value="30" className = "view-option">30</option>
          <option value="60" className = "view-option">60</option>
          <option value="100" className = "view-option">100</option>
          <option value="150" className = "view-option">150</option>
        </NativeSelect>                         
      </div>
    </AppBar>
  );
}

export default TeamTableHeader;
