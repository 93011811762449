import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shareActions from "../../../redux/modules/share";
import { CampaignGoals } from "../../../components/CampaignDetails/Goals";
import { Progress } from "../../../components/CampaignDetails/Progress";
import { SocialGoals } from "../../../components/CampaignDetails/SocialGoals";
import { Donars } from "../../../components/CampaignDetails/Donars";
import { Comment } from "../../../components/CampaignDetails/Comment";
import { getSign } from "../../../Common/getSign";
import "../../../components/CampaignDetails/CampaignDetails.scss";
import DocumentMeta from 'react-document-meta';
import * as campaignActions from "../../../redux/modules/campaign";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { convertCurrency } from "../../../Common/CurrencyCalculator.js";

import { SocialShareLatest } from "./socialShareLatest";
import { DonorBoxLatest } from "./donorBoxLatest";
import createTeamLatest from '../../../assets/images/createTeamLatest.png';

import "./donorBoxRoot.scss";

export const getPercent = (goal, raised) => {
  const percent = (parseFloat(raised) / parseFloat(typeof goal === 'string' ? goal.replace(/,/g, '') : goal)) * 100;
  return percent.toFixed(2);
}

class DonorBoxRoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {},
      donation: 0,
      donors: [],
      shares: {},
      desc: '',
      value: 0,
      showLatestUi: false,
      latestFullTeamData: []

      // all: donors,
      // top: top
    };
    this.fullTeamsDetails = [];
    this.finalArr = [];
    this.fullTeamDetailsFilteredArr = [];
  }

  componentWillMount() {
    const { details, donation, donors, onShareActions, showLatestUi } = this.props;
    if (details) {
      this.setState({ details, donation, donors, showLatestUi });
      const { organizationId, campaignId } = details;
      onShareActions.getShareCount({
        organization: organizationId,
        campaign: campaignId
      });
    }
  }



  componentWillReceiveProps(nextProps) {
    const { details, donation, donors, share, fullTeamsDetails } = nextProps;
    this.setFullTeamsData(donors, fullTeamsDetails, details)

    this.setState({ details, donation, donors, shares: share.data || {} });
  }

  handleShare = () => {
    const { details, onShareActions } = this.props;
    const { organizationId, campaignId } = details;
    // window.location.href='https://www.facebook.com/sharer/sharer.php?u=https://socialshare-js23.herokuapp.com/socialshare/'+ campaignId;
    //window.open('https://www.facebook.com/sharer/sharer.php?u=https://socialshare-js23.herokuapp.com/socialshare/'+ campaignId,'_blank');
    // window.open(
    //   'https://www.facebook.com/sharer/sharer.php?u=https://d6b466af.ngrok.io/campaigns/socialShare',
    //   '_blank' // <- This is what makes it open in a new window.
    // );
    //     window.open(
    //   'https://twitter.com/intent/tweet?url=https://b9f974aa.ngrok.io/campaigns/socialShare',
    //   '_blank' // <- This is what makes it open in a new window.
    // );
    // const {
    //   onCampaignActions
    // } = this.props;
    // onCampaignActions
    //     .socialShare('campaign')

    // onShareActions
    //   .updateShareCount({ organizationId, campaignId })
    //   .then(res => {
    //     onShareActions.getShareCount({
    //       organization: organizationId,
    //       campaign: campaignId
    //     });
    //   });
    // };

    onShareActions
      .updateShareCount({ organizationId, campaignId })
      .then(res => {
        onShareActions.getShareCount({
          organization: organizationId,
          campaign: campaignId
        });
      });
  };

  signup = (id, campaign) => {
    const { history } = this.props;
    history.push(`/signup-volunteer/${id}/${campaign}`, {
      organizationId: id,
      campaignId: campaign,
      name: this.props.history.location.pathname
    });
  };

  join = (id, campaign) => {
    const { history } = this.props;
    const { details } = this.state;
    history.push(`/signup-volunteer-team/${id}/${campaign}/${details.campaignPage}`, {
      organizationId: id,
      campaignId: campaign,
      name: details.campaignPage
    });
  };

  addTeam = (id, campaign) => {
    const { history } = this.props;
    history.push(`/add-team/${id}/${campaign}`, {
      organizationId: id,
      campaignId: campaign,
      name: this.props.history.location.pathname
    });
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  getTeamName = (teamObj, fullTeamsDetails) => {
    for (let id in fullTeamsDetails) {
      if (id == teamObj.teamId || fullTeamsDetails[id].id == teamObj.teamId) {
        // if(!fullTeamsDetails[id].amount){
        //   fullTeamsDetails[id].amount = 0;
        // }
        this.finalArr.push({
          teamName: fullTeamsDetails[id].teamName,
          totalChargedAmount: teamObj.totalChargedAmount
        })

      }
    }
  }


  groupByTeamId = (details) => {
    return details.reduce(
      (r, o) => (
        r[o.teamId]
          ? (r[o.teamId].totalChargedAmount += o.totalChargedAmount)
          : (r[o.teamId] = { ...o }), r
      ),
      {}
    );
  }


  setFullTeamsData = (donors, fullTeamsDetails, details) => {

    let fullTeamDetailsFilteredArr = [];
    for (let id in fullTeamsDetails) {
      if (fullTeamsDetails[id].isActive) {
        fullTeamDetailsFilteredArr.push({
          campaignPath: details.campaignPage + '/' + fullTeamsDetails[id].pageName,
          imageURL1: fullTeamsDetails[id].avatar,
          teamName: fullTeamsDetails[id].teamName,
          id: id,
          goalAmount: fullTeamsDetails[id].goalAmount,
          textImage: `${(fullTeamsDetails[id].firstName[0]).toUpperCase()}${(fullTeamsDetails[id].lastName[0]).toUpperCase()}`,
          description : fullTeamsDetails[id].description
        });
      }
    }
    let totalDonation = 0;
    let teamDonorsCount = 0;
    Promise.all(fullTeamDetailsFilteredArr && fullTeamDetailsFilteredArr.map((team) => {
      donors && donors.map((donation) => {
        if (donation.teamId === team.id) {
          teamDonorsCount = teamDonorsCount + 1;
          totalDonation = totalDonation + convertCurrency(donation.amount, donation.currency || 'usd');
        }
      });
      
      let tempObj = {
        teamName: team.teamName,
        goalAmount: team.goalAmount,
        amountRaised: totalDonation,
        teamDonorsCount,
        teamLink: `https://pledje.com/${team.campaignPath}`,
        textImage: team.textImage,
        percentage: getPercent(team.goalAmount, totalDonation),
        sign: getSign(details.campaignCurrency || details.currency),
        description : team.description
      };
      totalDonation = 0;
      teamDonorsCount = 0;

      return tempObj;
    })
    ).then(finalData => {
      this.setState({
        latestFullTeamData: finalData.sort((a, b) => b.amountRaised - a.amountRaised)
      });
    });
  }

  render() {
    const { details, donation, donors, shares, latestFullTeamData } = this.state;
    const { fullTeamsDetails } = this.props;
    let mainCampaignPath = details.campaignPage;
    
    if ((Object.keys(donors).length > 0) || (donors.length > 0)) {
      let donorDetailsNew = [];
      donors.forEach(donationDetail => {
        if (donationDetail.teamId) {
          donorDetailsNew.push({
            teamId: donationDetail.teamId,
            totalChargedAmount:
              convertCurrency(donationDetail.amount, donationDetail.currency || 'usd')
          });
        }
      });
      this.finalArr = [];
      let groupedTeam = this.groupByTeamId(donorDetailsNew);
      for (let i in groupedTeam) {
        this.getTeamName(groupedTeam[i], fullTeamsDetails)
      }
      let sortTestArr = this.finalArr;
      sortTestArr.sort((a, b) => {
        return (parseFloat(b.totalChargedAmount) - parseFloat(a.totalChargedAmount))
      })
      this.finalArr = sortTestArr;

    }



    let desc = "";
    if (details.desc) {
      desc = details.desc;
    }
    const {
      imageURL,
      location: { pathname }
    } = this.props;
    // const meta = {
    //   title: 'Some Meta Title',
    //   description: 'I am a description, and I can create multiple tags',
    //   canonical: 'http://example.com/path/to/page',
    //   'og:image':imageURL,
    //   'og:image:secure_url':imageURL,
    //   meta: {
    //     charset: 'utf-8',

    //     name: {
    //       keywords: 'react,meta,document,html,tags'
    //     }
    //   }
    // };
    if (imageURL) {
      process.env.imageUrl = imageURL;

      //       document.getElementsByTagName('meta')["og:image"].content = imageURL;
      // document.getElementsByTagName('meta')["og:image:secure_url"].content = imageURL;

      // var metas = document.getElementsByTagName('meta');
      // for (let i = 0; i < metas.length; i++) {
      //   if (metas[i].getAttribute('property') == "og:image" || metas[i].getAttribute('property') == "og:image:secure_url") {
      //     metas[i].setAttribute("content",imageURL);
      //   }
      // }

      // for (let i = 0; i < metas.length; i++) {
      //   if (metas[i].getAttribute('property') == "og:image" || metas[i].getAttribute('property') == "og:image:secure_url") {
      //     console.log('meta',metas[i].getAttribute('content'));
      //   }
      // }

      // var meta1 = document.createElement('meta');
      // meta1.property = "og:image";
      // meta1.content = imageURL;
      // document.getElementsByTagName('head')[0].appendChild(meta1);

      // var meta2 = document.createElement('meta');
      // meta2.property = "og:image:secure_url";
      // meta2.content = imageURL;
      // document.getElementsByTagName('head')[0].appendChild(meta2);


    }
    const sign = getSign(details.campaignCurrency || details.currency);
    const themeColor = details && details.themeColor || "#336DFF";
    const dynamicCss = { 'borderBottomColor': themeColor, 'color': themeColor };
    this.fullTeamDetailsFilteredArr = [];
    for (let id in fullTeamsDetails) {
      if (fullTeamsDetails[id].isActive) {

        this.fullTeamDetailsFilteredArr.push({
          campaignPath: mainCampaignPath + '/' + fullTeamsDetails[id].pageName,
          imageURL1: fullTeamsDetails[id].avatar,
          teamName: fullTeamsDetails[id].teamName,
          id: id,
          goalAmount: fullTeamsDetails[id].goalAmount,
          textImage: `${(fullTeamsDetails[id].firstName[0]).toUpperCase()}${(fullTeamsDetails[id].lastName[0]).toUpperCase()}`
        });
      }
    }
    // ----------------------------------
    return (
      <div className="row">
        <div className="col-md-9">
          <div className="tabs-box">
            <h1 style={{ color: themeColor }}>{donors && donors.length} <span>Donors</span></h1>
            <ul className="donorList">
              <li className={"donor" + " " + (this.state.value == 0 ? `active` : '')} onClick={(e) => { this.handleChange(0) }} style={(this.state.value == 0) ? dynamicCss : {}}>Donors
              {this.state.value == 0 && <span class="steps step-1 step-2 step-3 step-4 step-5 step-6 step-7 step-8 step-9 step-10 step-11">
                  <span class="triangle-demo" style={{ 'borderTopColor': themeColor }}></span>
                </span>
                }
              </li>
              <li className={"teams" + " " + (this.state.value == 1 ? `active` : '')} onClick={(e) => { this.handleChange(1) }} style={(this.state.value == 1) ? dynamicCss : {}}><span>{this.fullTeamDetailsFilteredArr && this.fullTeamDetailsFilteredArr.length}</span>Teams
              {this.state.value == 1 && <span class="steps step-1 step-2 step-3 step-4 step-5 step-6 step-7 step-8 step-9 step-10 step-11">
                  <span class="triangle-demo" style={{ 'borderTopColor': themeColor }}></span>
                </span>
                }
              </li>
              <li className={(this.state.value == 2 ? `active` : '')} onClick={(e) => { this.handleChange(2) }} style={(this.state.value == 2) ? dynamicCss : {}}>2x Power Donors
              {this.state.value == 2 && <span class="steps step-1 step-2 step-3 step-4 step-5 step-6 step-7 step-8 step-9 step-10 step-11">
                  <span class="triangle-demo" style={{ 'borderTopColor': themeColor }}></span>
                </span>
                }
              </li>
              <li className={"about" + " " + (this.state.value == 3 ? `active` : '')} onClick={(e) => { this.handleChange(3) }} style={(this.state.value == 3) ? dynamicCss : {}}>About
              {this.state.value == 3 && <span class="steps step-1 step-2 step-3 step-4 step-5 step-6 step-7 step-8 step-9 step-10 step-11">
                  <span class="triangle-demo" style={{ 'borderTopColor': themeColor }}></span>
                </span>
                }

              </li>
            </ul>
            {/* <Tabs className='donor-tabs' value={this.state.value} indicatorColor="primary"
              textColor="primary" onChange={this.handleChange}>
              <Tab className="donor-tab" label="Donors" />
              <div className="teams-div">254</div><Tab className="donor-tab teams" label="Teams" />
              <Tab className="donor-tab power" label="2x Power Donors" />
              <Tab className="donor-tab about" label="About" />
            </Tabs> */}
          </div>
          <div className="">
            {this.state.value == 0 && <DonorBoxLatest donors={donors} details={details} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} activeTab="1" data-aos="zoom-out-left" />}
            {this.state.value == 1 && <DonorBoxLatest donors={this.finalArr} details={details} fullTeamDetailsFilteredArr={latestFullTeamData} team={false} allTeams={true} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" activeTab="2" />}
          </div>
        </div>
        <div className="col-md-3">
          <button
            className="btn-create-team"
            onClick={() =>
              this.join(details.organizationId, details.campaignId)
            }
          ><img alt="" className="" height="50px" src={createTeamLatest} />CREATE A TEAM PAGE</button>

          <SocialShareLatest
            shares={shares}
            desc={desc}
            imageUrl={imageURL}
            details={details}
            sign={sign}
            handleShare={this.handleShare}
          />
        </div>
      </div>)
    //   <aside id="project-sidebar">
    //     {/* { !this.state.showLatestUi &&
    //       <div className='side-clock' data-aos="zoom-out-left">
    //         <CampaignGoals details={details} donation={donation} sign={sign} />
    //         <Progress
    //           shares={shares}
    //           bonusGoal={details.bonusGoalAmount1}
    //           goalAmount={details.goalAmount}
    //           socialShareAmount={details.socialShareAmount}
    //           donation={donation}
    //           sign={sign}
    //         />
    //       </div>} */}
    //     <div className="mobile-change-order">

    //       {/* <DocumentMeta {...meta}>
    //       <h1>Hello World!</h1>
    //     </DocumentMeta> */}
    //       <SocialShareLatest
    //         shares={shares}
    //         desc={desc}
    //         imageUrl={imageURL}
    //         details={details}
    //         sign={sign}
    //         handleShare={this.handleShare}
    //       />
    //       {pathname !== "/checkout" && details.mode !== "e-commerce" && (
    //         <img className="camp-img-resp" src={imageURL} alt="Campaign" />
    //       )}

    //       <section className="donor-list" data-aos="zoom-out-left">
    //         <Tabs className='tabs' value={this.state.value} indicatorColor="primary"
    //           textColor="primary" onChange={this.handleChange}>
    //           <Tab label="All Donors" />
    //           <Tab label="Top Teams" />
    //           <Tab label="All Teams" />
    //         </Tabs>
    //         {this.state.value == 0 && <DonorBoxLatest donors={donors} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" />}
    //         {this.state.value == 1 && <DonorBoxLatest donors={this.finalArr} team={true} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" />}
    //         {this.state.value == 2 && <DonorBoxLatest donors={this.finalArr} fullTeamDetailsFilteredArr={this.fullTeamDetailsFilteredArr} team={false} allTeams={true} campaignCurrency={details.campaignCurrency} mainCampaignPath={mainCampaignPath} data-aos="zoom-out-left" />}

    //       </section>

    //       {/* <Donars donors={donors} campaignCurrency={details.campaignCurrency} /> */}
    //       {/* <button
    //         className="sidebar-blue-btn"
    //         onClick={() => this.addTeam(details.organizationId, details.campaignId)}
    //       >
    //         JOIN OUR CAMPAIGN
    //       </button>

    //       <button
    //         className="sidebar-blue-btn"
    //         onClick={() =>
    //           this.signup(details.organizationId, details.campaignId)
    //         }
    //       >
    //         JOIN AS A VOLUNTEER
    //       </button> */}

    //       <button
    //         className="sidebar-blue-btn"
    //         onClick={() =>
    //           this.join(details.organizationId, details.campaignId)
    //         }
    //       >
    //         CREATE A TEAM PAGE
    //       </button>

    //     </div>
    //     <Comment details={details} />
    //   </aside>
    // );
  }
}

const mapStateToProps = state => {
  return {
    share: state.share.get("share"),
    verifyPageErr: state.teamManagementReducer.verifyPageErr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShareActions: bindActionCreators(shareActions, dispatch), onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonorBoxRoot);
