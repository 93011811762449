import React, { Component } from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Button,
    withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper
} from "@material-ui/core";
import './toggle.scss';
import moment from 'moment';
import axios from 'axios';
import Errors from './Errors';

const classes = () => ({
    caption: {
        fontSize: 14,
    },
    toolbar: {
        padding: 0,
        height: 40,
        minHeight: 40
    }
});
const paperStyle = {
    caption: {
        fontSize: 14
    },
    toolbar: {
        padding: 0,
        height: 40,
        minHeight: 40
    },
    colorSwitchBase: {
        "&$colorChecked": {
            color: "#008eb5",
            "& + $colorBar": {
                backgroundColor: "#008eb5"
            }
        }
    },
    colorBar: {},
    colorChecked: {}
};
const styles = {
    tableRoot: {
        width: '100%',
        overflowX: 'auto',
    }
};

class Donors extends Component {
    constructor(props) {
        super(props);
        this.selectedCurrency = 'ALL';
        this.state = {
            selected: [],
            page: 0,
            view: 0,
            errors: false
        }
    }

    handleChangePage = (e, page) => {
        const { donations } = this.props;
        {
            this.setState({
                page: page
            })
        }

    }

    showErrors = () => {
        this.setState({
            errors: true
        })
    }

    back = () => {
        this.setState({
            errors: false
        })
    }

    refund = (txnsId) => {

        let headers = {
            "Content-Type": "application/json",
            APIKEY: "bdfaff171efc326c0367107a10dd7059"
        }
        axios.get('https://api.splashpayments.com/txns/' + txnsId, { headers })
            .then(res => {

                // this.setState({
                //     payments: res.data.response.data[0],
                //     loading: false
                // })
            })
            .catch(err => {

            })
    }

    customSoring = (selectedCurrency) => {
        console.log('10000', selectedCurrency)
        this.selectedCurrency = selectedCurrency;
        this.setState({
            view: 1
        })
    }

    render() {
        const { donations, organizationId, handleChange, selected, handleCapture } = this.props;
        const { page, errors } = this.state;
        let temp = [];
        if (this.selectedCurrency == 'ALL') {
            temp = donations;
        } else {
            donations.map(obj => {
                if (obj.currency.trim() == this.selectedCurrency) {
                    console.log(">>>>>>>" ,obj.currency )
                    temp.push(obj);
                } else {
                }
            });
        }
        return (
            <>
                {errors ? <Errors back={this.back} id={organizationId} /> :
                    <div className="main-wrap">
                        <div className="div-donation">
                            {JSON.parse(localStorage.getItem('userInfo')).role === "super-admin" ?
                                <> <button className="capture-donation" onClick={handleCapture}>CAPTURE DONATIONS</button>


                                    <button className="capture-donation" onClick={this.showErrors}>VIEW ERRORS</button>
                                </>
                                : null}
                            <div className="clearfix"></div>
                        </div>

                        <div className="title-donation">
                            <span>Campaign Donations </span>
                            <span className="pull-right" style={{ fontSize: "16px", marginTop: "10px" }}>
                                {this.selectedCurrency == 'ALL' ?
                                    <label className="checkbox-inline">
                                        <input onClick={() => this.customSoring("ALL")} type="radio" name="common" value="" checked />Default/All</label> :
                                    <label className="checkbox-inline">
                                        <input onClick={() => this.customSoring("ALL")} type="radio" name="common" value="" />Default/All</label>


                                }

                                <label className="checkbox-inline">
                                    <input onClick={() => this.customSoring("usd")} type="radio" name="common" value="" />USD</label>
                                <label className="checkbox-inline">
                                    <input onClick={() => this.customSoring("cad")} type="radio" name="common" value="" />CAD</label>
                                <label className="checkbox-inline"><input
                                    onClick={() => this.customSoring("gbp")} type="radio" name="common" value="" />GBP</label>

                                <label className="checkbox-inline"><input
                                    onClick={() => this.customSoring("ils")} type="radio" name="common" value="" />ILS </label>

                                <label className="checkbox-inline"><input
                                    onClick={() => this.customSoring("aud")} type="radio" name="common" value="" />AUD </label>
                            </span>
                        </div>

                        {donations && donations.length > 0 ?
                            <div>
                                <Paper style={styles.tableRoot}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="nowrap">
                                                    Serial Number
                                        </TableCell>
                                                <TableCell className="nowrap">
                                                    Donor Name
                                        </TableCell>
                                                <TableCell className="nowrap">
                                                    Donation Date
                                        </TableCell>
                                                <TableCell className="nowrap">
                                                    Donation Amount
                                        </TableCell>
                                                <TableCell className="nowrap">
                                                    Status
                                        </TableCell>
                                                {JSON.parse(localStorage.getItem('userInfo')).role === "super-admin" ?
                                                    <TableCell className="nowrap">
                                                        Allow Capture
                                        </TableCell> : null}
                                                {JSON.parse(localStorage.getItem('userInfo')).role === "super-admin" ?
                                                    <TableCell className="nowrap">
                                                        Take Action
                                        </TableCell> : null}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {temp.map((item, index) => index >= page * 100 && index < (page + 1) * 100 &&
                                                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{item.fullname}</TableCell>
                                                    <TableCell className="nowrap">{moment(item.donationDate).format("YYYY-MM-DD HH:mm")}</TableCell>
                                                    <TableCell>{item.donationAmount}</TableCell>
                                                    <TableCell className="nowrap">{item.status == "Failed" ? <div>Error while capturing this donation</div> : item.status == "Success" ? <div> Captured successfully </div> : <div> Capture pending </div>}</TableCell>
                                                    {JSON.parse(localStorage.getItem('userInfo')).role === "super-admin" ?
                                                        <TableCell>
                                                            {item.status == "Pending" && !item.isManual && item.paymentType == "credit card" ?
                                                                <label className="switch">
                                                                    <input onChange={() => handleChange(index)} checked={selected[index]} type="checkbox" />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                : null}

                                                        </TableCell>
                                                        : null}
                                                    {JSON.parse(localStorage.getItem('userInfo')).role === "super-admin" ?
                                                        <TableCell><button className="btn" onClick={() => this.refund(item.transactionId)}>Refund</button></TableCell> : null}
                                                </TableRow>
                                            )}
                                        </TableBody>

                                    </Table>
                                </Paper>
                                <TablePagination
                                    classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                                    component="div"
                                    count={temp.length}
                                    rowsPerPage={100}
                                    page={page}
                                    rowsPerPageOptions={[]}
                                    onChangePage={(e, page) => this.handleChangePage(e, page)}
                                />
                                {/* <TablePagination
                        classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                        className="pldj-pagination"
                        // classes={{ caption: classes.caption, toolbar: classes.toolbar }}
                        component="div"
                        count={donations.length}
                        rowsPerPage={8}
                        page={page}
                        rowsPerPageOptions={[]}
                        onChangePage={(e, page) => this.handleChangePage(e, page)}
                    /> */}

                            </div> :
                            <div className="noDonation">No donations to display</div>
                        }
                    </div>
                }
            </>
        )
    }
}

export default Donors;