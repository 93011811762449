import React from 'react';
import Dashboard from "@material-ui/icons/Dashboard";
import ProspectManagement from "../../pages/ProspectManagement";
import CampaignReports from "../../pages/CampaignReports";
import TeamManagement from "../../pages/TeamManagement";
import TeamLeadership from "../../pages/TeamLeadership";
import PreCampaignVault from "../../pages/PreCampaignVault";
import UserManagement from "../../pages/UserManagement";
import NewCampaign from "../../pages/NewCampaign/Container";
import Settings from "../../pages/Settings";
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import PaymentIcon from '@material-ui/icons/Payment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CaptureDonations from './../../pages/CaptureDonations';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';

const dashboardRoutes = [
  {
    path: "/dashboard/prospectmanagement",
    sidebarName: "Prospect Management",
    navbarName: "Prospect Management",
    icon: ThumbsUpDownIcon,
    component: ProspectManagement,
    hoverClass: "prospectmanagement"
  }, {
    path: "/dashboard/campaignreports",
    sidebarName: "Campaign Reports",
    navbarName: "Campaign Reports",
    icon: EventNoteIcon,
    component: CampaignReports,
    hoverClass: "campaignreports"
  },
  {
    path: "/dashboard/captureDonation",
    sidebarName: "Capture Donation",
    navbarName: "Capture Donation",
    icon: PaymentIcon,
    component: CaptureDonations,
    hoverClass: "captureDonation"
  }, 
  {
    path: "/dashboard/campaignvault",
    sidebarName: "Campaign Vault",
    navbarName: "Campaign Vault",
    icon: HistoryIcon,
    component: PreCampaignVault,
    hoverClass: "precampaignvault"
  }, {
    path: "/dashboard/teammanagement",
    sidebarName: "Team Management",
    navbarName: "Team Mamagement",
    icon: GroupIcon,
    component: TeamManagement,
    hoverClass: "teammanagement"
  }, {
    path: "/dashboard/teamleadership",
    sidebarName: "Leaders Board",
    navbarName: "Team Leadership",
    icon: PeopleIcon,
    component: TeamLeadership,
    hoverClass: "teamleadership"
  }, {
    path: "/dashboard/usermanagement",
    sidebarName: "Volunteer Management",
    navbarName: "Volunteer Management",
    icon: AssignmentIndIcon,
    component: UserManagement,
    hoverClass: "usermanagement"
  }, {
    path: "/dashboard/newcampaign",
    sidebarName: "Campaigns",
    navbarName: "Campaigns",
    icon: SurroundSoundIcon,
    component: NewCampaign,
    hoverClass: "newcampaign"
  }, {
    path: "/dashboard/settings",
    sidebarName: "Settings",
    navbarName: "Settings",
    icon: SettingsIcon,
    component: Settings,
    hoverClass: "settings"
  }
];

export default dashboardRoutes;
