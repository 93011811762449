import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';


import './Specialoffer.scss';

class Specialoffer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
       
      }

   

    componentDidMount() {
      
    }

    render(){
        return(
            <div>
                <section className="special_offer">
                    <div className="spacial_img">
                        <img src="/images/special_offer_bg.png" />
                    </div>

                    <div className="head_section">
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-xs-10 p0">
                            <div className="heading_tag text-right text-uppercase" style={{ "backgroundImage": "url(/images/green_tag_left.png)" }} >Special Offer</div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="container special_offer_caption">
                        <div className="row">
                        <div className="col-md-7 col-sm-6 text-center">
                            <div className="campaign_off">40% off campaigns</div>
                            <p><small>Offer ends Dec 31/2019</small></p>
                            <a href="#" className="more_info_anchor">Click here for more info!</a>
                        </div>
                        <div className="col-md-5 col-sm-6">
                            <div className="special_image">
                            <img src="/images/special_img.png" />
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        
    )}
}

export default connect(
    (state) => ({
    }),
    (dispatch) => ({
    })
)(Specialoffer);

   
   
