import { handleActions, createAction } from "redux-actions";
import * as action from "./action";
const _ = require('lodash');

export const resetPreCampaignVault = createAction(action.RESET);

const initialState = {
  filteredDonations: "",
  searchItem: "",
  donations: [],
  isFetching: false,
  verifyPageErr: false,
  currentCampaignId: undefined
};
const searchDonation = (state, action) => {
  console.log(state, action);
  let event = action.payload.e;
  let searchItem = event.target.value;
  state.searchItem = event.target.value;
  let donations = state.donations;
  let filteredDonations;
  donations = _.map(donations, (v) => {
    // v.displayStatus = v.displayStatus.toString(); //USED BEFORE
    v.displayStatus = v.displayStatus ? v.displayStatus.toString() : 'false'; //NEW ADDED
    return v
  })

  donations.map((d, index) => {
    donations[index].amount = (d.amount) ? d.amount : 0;
  });
  filteredDonations = donations.filter(
    donation =>
      donation.name.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      // donation.amount == searchItem || //USED BEFORE
      donation.amount.toString().indexOf(searchItem) > -1 ||//NEW ADDED
      donation.displayStatus.toUpperCase().indexOf(searchItem.toUpperCase()) > -1
  );

  filteredDonations = _.map(filteredDonations, (v) => {
    v.displayStatus = (v.displayStatus === 'true')
    return v
  })
  return filteredDonations;
};
const handlers = {
  [action.RESET]: (state, action) => ({
    ...state,
    ...initialState
  }),
  [action.FETCHING]: (state, action) => ({
    ...state,
    isFetching: true,
  }),
  [action.SEARCH_DONATION]: (state, action) => ({
    ...state,
    filteredDonations: searchDonation(state, action)
  }),
  [action.SUCCESS]: (state, action) => ({ ...state, isFetching: false }),
  [action.ERROR]: (state, action) => ({ ...state, isFetching: false }),
  "DONATIONS_SUCCESS": (state, action) => ({ ...state, donations: action.payload, filteredDonations: "", searchItem: "" }),
  "INVALID_PAGE_ERROR": (state, action) => ({
    ...state,
    verifyPageErr: true
  }),
  "VALID_PAGE": (state, action) => ({ ...state, verifyPageErr: false })
};

export default handleActions(handlers, initialState);
