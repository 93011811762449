import { createAction } from 'redux-actions';
import axios from 'axios';
import * as action from "./action";
import { BASE_URL } from "../../../apis/backEnd.apis";
import { config } from "../../../config";
var token = config.apiAccessKey;

export const fetching = () => ({
  type: action.FETCHING
});

export const success = () => ({
  type: action.SUCCESS
});

export const refresh = () => ({
  type: action.REFRESH
})

export const _error = msg => ({
  type: action.ERROR,
  payload: msg
});

export const searchProspect = values => {
  return {
    type: action.SEARCH_PROSPECT,
    payload: values
  };
};

export const applyProspectFilter = values => {
  return {
    type: action.APPLY_FILTER,
    payload: values
  };
};

export const onClearFilter = values => {
  return {
    type: action.CLEAR_FILTER,
    payload: values
  };
};

export const filterByAssignment = (value) => {
  
  return {
    type: action.FILTER_BY_ASSIGNMENT,
    payload: value
  }
}

export const setProspectId = (values) => {
  return {
    type: action.SET_PROSPECT_ID,
    payload: values
  }
}
export const removeProspectId = (values) => {
  return {
    type: action.REMOVE_PROSPECT_ID
  }
}

export const getProspect = (data) => {
  return {
    type: action.GET_PROSPECT,
    payload: data
  }
}

export const uploadBulkProspects = (data) => (dispatch, getState) => {
  dispatch(fetching());
  var formData = new FormData();
  formData.append("campaignId", data.campaignId);
  formData.append("organizationId", data.organizationId);
  formData.append("userId", data.userId);
  formData.append("file", data.file);
  formData.append("access-token", `${token}`);
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200 || xhr.status === 201) {
        dispatch(refresh());
      } else {
        dispatch(_error())
      }
    }
  };
  xhr.upload.addEventListener("progress", function (evt) {
    if (evt.lengthComputable) {
      console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
    }
  }, false);
  xhr.open("POST", BASE_URL + 'prospects/bulk/');
  xhr.send(formData);
}

export const fetchProspects = (organizationId, clicked, prospectId, campaignId) => dispatch => {
  const promise = (resolve, reject) => {
    axios.get(`${BASE_URL}prospects/prospects/${organizationId}/${campaignId}`, {
      headers: { "access-token": `${token}` }
    })
    .then(res => {
      if (clicked === 'creditcard') {
        const prospect = res.data.data.find(e => e.id === prospectId);
        dispatch(getProspect(prospect));
      }
      return resolve(dispatch({
        type: action.UPDATE_LIST,
        payload: res.data.data
      }));
    })
    .catch(() => reject(dispatch(_error())));
  }

  return new Promise(promise);
}

const addProspect = data =>
  axios.post(`${BASE_URL}prospects/prospect`, data, {
    headers: { 'Content-Type': 'application/json', "access-token": `${token}` }
  });

export const addNew = createAction(action.SUCCESS, addProspect)

const editProspect = (data, id) =>
  axios.post(`${BASE_URL}prospects/prospect/${id}`, data, {
    headers: { 'Content-Type': 'application/json', "access-token": `${token}` }
  });

export const updateProspect = createAction(action.SUCCESS, editProspect);

const removeProspect = id =>
  axios.delete(`${BASE_URL}prospects/prospect/${id}`, {
    headers: { "access-token": `${token}` }
  });

export const deleteProspect = createAction(action.SUCCESS, removeProspect);

const assignProspectToUser = (data) =>
  axios.post(`${BASE_URL}prospects/assignProspects`, data, {
    headers: { 'Content-Type': 'application/json', "access-token": `${token}` }
  });

export const assignProspect = createAction(action.SUCCESS, assignProspectToUser);
