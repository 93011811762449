import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button, Avatar, CssBaseline, FormControl, Input, InputLabel, Typography, withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '../../components/Snackbar/Snackbar';
import { MSGS } from '../../messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './JoinCampaign.scss';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class JoinCampaign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            showMessage: false,
            message: "",
            error: false,
        }

    }

    signup = (id, campaign) => {
        const { history } = this.props;
        history.push(`/signup-volunteer/${id}/${campaign}`, {
            organizationId: id,
            campaignId: campaign,
            name: this.props.history.location.pathname
        });
    };

    addTeam = (id, campaign) => {
        const { history } = this.props;
        history.push(`/add-team/${id}/${campaign}`, {
            organizationId: id,
            campaignId: campaign,
            name: this.props.history.location.pathname
        });
    };

    joinBoth = (id, campaign) => {
        const { history } = this.props;
        history.push(`/signup-volunteer-team/${id}/${campaign}`, {
            organizationId: id,
            campaignId: campaign,
            name: this.props.history.location.pathname
        });
    };


    render() {
        const { classes } = this.props;
        const { campaign, organization } = this.props.match.params;
        return (
            <LoadingOverlay
                active={this.props.isFetching}
                spinner
                text='Please Wait..'
            >

                <div>
                    <div className="heading">
                        <h1>Please Choose an Option</h1>
                        <h6>To extend your help with our campaign</h6>
                    </div>
                    <div className="container">
                        <div className="row row-center">
                            <div onClick={() =>
                                this.signup(organization, campaign)
                            } className="col-md-4 col-sm-5">
                                <div className="box">
                                    <div className="padd"><img alt="" className src="/images/team.svg" height={120} width={120} /></div>
                                    <p className="join">Join as a Volunteer &amp; Assist in contacting prospect</p>
                                </div>
                            </div>
                            <div className="col-sm-2 justify-content-center">
                                <span className="or">OR</span>
                            </div>
                            <div onClick={() =>
                                this.addTeam(organization, campaign)
                            } className="col-md-4 col-sm-5">
                                <div className="box">
                                    <div className="padd"><img alt="" className src="/images/mic.png" height={120} width={120} /></div>
                                    <p className="create">Create your very own fundraising campaign page &amp; run a private fundraiser to benefit our organising</p>
                                </div>
                            </div>
                        </div>
                        <div className="row row-center">
                            {/* <div className="col-sm-5">
                                <div className="box box-user">
                                    <p className="mr-3"><img alt="" className src="/images/profile-icone.png" height={50} width={50} /></p>
                                    <div className="right-side">
                                        <h6>Jenson Roy</h6>
                                        <p>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>
                                        <a className="view-detail" href="#">View Detail</a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="or-mobile">
                                <div className="col-sm-2 justify-content-center">
                                    <span className="or">OR</span>
                                </div>
                            </div>
                            <div onClick={() =>
                                this.joinBoth(organization, campaign)
                            } className="col-md-5 col-sm-6">
                                <div className="box mb-70 box-mobile">
                                    <img alt="" className="both-img" src="/images/both-img.png" height={'100%'} width={'100%'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='login-container'>
                    <button
                        className="sidebar-blue-btn"
                        onClick={() =>
                            this.signup(organization, campaign)
                        }
                    >
                        JOIN AS VOLUNTEER
          </button><hr /><hr />
                    <button
                        className="sidebar-blue-btn"
                        onClick={() =>
                            this.addTeam(organization, campaign)
                        }
                    >
                        JOIN AS TEAM MEMBER
          </button><hr /><hr />
                    <button
                        className="sidebar-blue-btn"
                        onClick={() =>
                            this.joinBoth(organization, campaign)
                        }
                    >
                        JOIN AS BOTH
          </button>
                </div> */}
            </LoadingOverlay>
        );
    }
}


export default JoinCampaign;