import React, { Component } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  NativeSelect,
  Hidden,
  withStyles
} from "@material-ui/core";
import _ from "lodash";

const styles = {
  root: {
    color: "#fff",
    "&$checked": {
      color: "#5585b5"
    }
  },
  checked: {}
};

class GeneralDonation extends Component {
  onChange = e => {
    const { donate } = this.props;
    let donated = [...this.props.selectedItems];
    const title = "general donation";

    if (donated.length === 0) {
      donated = [{ price: e.target.value, title }];
    } else {
      if (!e.target.value) {
        _.remove(donated, d => d.title === title);
      } else {
        const find = donated.find(d => d.title === title);
        if (find) {
          donated.map(d => {
            if (d.title === title) {
              d.price = e.target.value;
              return d;
            }
            return d;
          });
        } else {
          donated.push({ price: e.target.value, title });
        }
      }
    }

    donate(donated);
  };

  render() {
    const { classes, handleChange, donationType, currency, details, handleCurrency } = this.props;

    return (
      <section className="top-section flex-row">
        <Hidden smDown>
          <div className="donation-section">
            <div className="donate-actions">
              <div className="input">
                <label>{currency.sign}</label>
                <input type="tel" onChange={this.onChange} placeholder='0' />
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="donationType"
                  className="type"
                  onChange={handleChange}
                  value={donationType}
                >
                  <FormControlLabel
                    className="button"
                    value="oneTime"
                    control={
                      <Radio
                        classes={{
                          root: classes.root,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="One Time"
                  />
                  <FormControlLabel
                    className="button"
                    value="monthly"
                    control={
                      <Radio
                        classes={{
                          root: classes.root,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="Monthly"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="flex-row heading">
              <div className="img-container">
                <img src="/images/donate.png" alt="item-img" />
              </div>
              <div className="details flex-column">
                <span className="title">General Donation</span>
                <b>TO ORGANIZATION</b>
              </div>
            </div>
            { details.campaignCurrency && details.enableCurrency && details.currency.length > 1 && 
              <div className="currency-selector">
                <label>Select your currency</label>
                <NativeSelect
                  disableUnderline={true}
                  className="select"
                  name="currency"
                  value={currency.code}
                  onChange={handleCurrency}
                >
                  { details.currency.map(c => 
                    <option key={c.code} value={c.code}>{c.code.toUpperCase()}</option>
                  )}
                </NativeSelect>
              </div>
            }
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className="mobile-donation flex-column">
            <label>GENERAL DONATION TO ORGANIZATION</label>
            <div className="details flex-row">
              <div className="img-container">
                <img src="/images/donate.png" alt="item-img" />
              </div>
              <div className="donate-actions">
                <div className="input">
                  <label>{currency.sign}</label>
                  <input type="tel" onChange={this.onChange} />
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="donationType"
                    className="type"
                    onChange={handleChange}
                    value={donationType}
                  >
                    <FormControlLabel
                      className="button"
                      value="oneTime"
                      control={
                        <Radio
                          classes={{
                            root: classes.root,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="One Time"
                    />
                    <FormControlLabel
                      className="button"
                      value="monthly"
                      control={
                        <Radio
                          classes={{
                            root: classes.root,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="Monthly"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </Hidden>
      </section>
    );
  }
}

export default withStyles(styles)(GeneralDonation);
