import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import CampaignHeader from "./CampaignHeader";
import CampaignMiddleSection from "./CampaignMiddleSection";
import CampaignTableHeader from "./CampaignTableHeader";
import EditDonation from "./EditDonation";
import {
  fetchCampaigns,
  searchCampaign,
  getReport,
  filterReports,
  setInitial,
  updateDonation,
  handleCapture
} from "../../redux/modules/CampaignReports/action-creator";
import { getCampaign, totalDonation } from "../../redux/modules/campaign";
import { fetchTeams } from "../../redux/modules/TeamManagement/action-creator";
import CampaignTable from "./CampaignTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getExchangeRate } from "../../Common/Exchange";
import './index.scss';
import { Button } from "@material-ui/core";
import moment from "moment";
import { NumberFormat } from '../../Common/NumberFormat';
import { currencies } from "../../Common/currencies";

class CampaignReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editLoader: false,
      currentProspect: null,
      campaign: "",
      filterByDonor: "allDonors",
      filterByPayment: "allPayments",
      isAscending: true,
      rowsPerPage: 15,
      fields: {
        firstName: true,
        lastName: true,
        email: true,
        donationDate: true,
        alias: true,
        donationAmount: true,
        donationMatched: false,
        totalDonation: false,
        totalDonationInUSD: false,
        paymentType: true,
        teamId: false,
        transactionCostCovered: false,
        transactionCost: false,
        transactionId: true,
        billingPhone: false,
        billingAddress: false,
        dedicationMessage: false,
        status: false,
        raffle: false,
        specialNotes: false,
        items: false,
        state: false,
        city: false,
        zip: false,
        mode: true,
        action: true
      },
      loading: true,
      details: {},
      total_donation: "",
      openEdit: false,
      visibleFields: [],
      tableWidth: "",
      openPrintWindow: false
    };
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.props.fetchCampaigns(this.props.authUser.organizationId);
  }

  componentDidMount() {
    const test = document.getElementById("report-table");
    const tableWidth = test.clientWidth;
    this.setState({ tableWidth });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.filteredReports !== newProps.filteredReports) {
      this.filterFields(newProps.campaignReports);
    }

    if (oldProps.initialCampaign !== newProps.initialCampaign) {
      // this.setState({ loading: false });
      getExchangeRate(newProps.initialCampaign.campaignCurrency || newProps.initialCampaign.currency || 'usd');
      this.setState({ campaign: newProps.initialCampaign }, () => {
        newProps.getCampaign(this.state.campaign.campaignPage).then(res => {
          newProps.totalDonation(res.data.data.organizationId, res.data.data.campaignId || this.state.campaign.Id);
          this.props.fetchTeams(
            newProps.authUser.organizationId,
            this.state.campaign.Id || this.state.campaign.campaignId
          );
          this.props.getReport(
            newProps.authUser.organizationId,
            this.state.campaign.Id || this.state.campaign.campaignId
          );
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      campaign: { data },
      total_donation,
      campaignReports
    } = nextProps;

    if (campaignReports.length > 0) {
      this.filterFields(campaignReports);
    }
    if (typeof data === "object") {
      this.setState({ details: data });
    }
    if (total_donation.data) {
      this.setState({ total_donation: total_donation.data.total, loading: false });
    }
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  }

  handleCheckbox = name => e => {
    const { campaignReports } = this.props;

    e.persist();
    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          [name]: e.target.checked
        }
      }),
      () => {

        this.filterFields(campaignReports);
      }
    );
  };

  filterReports = e => {
    const { filterByPayment } = this.state;
    e.persist();
    this.setState({ filterByDonor: e.target.value });
    this.props.filterReports({
      donor: e.target.value,
      payment: filterByPayment
    });
  };

  onFilterByPayment = e => {
    const { filterByDonor } = this.state;
    e.persist();
    this.setState({ filterByPayment: e.target.value });
    this.props.filterReports({ payment: e.target.value, donor: filterByDonor });
  };

  onFormInputValueChange = e => {
    const { setInitial } = this.props;
    const { value } = e.target;
    setInitial(value);
  };

  onClose = () => {
    this.setState({ openEdit: false });
  };

  onEditDonation = item => {
    item.donationAmount = "";
    this.setState({ openEdit: true, currentProspect: item });
  };

  onSubmitAndClose = data => {
    const { updateDonation, getReport, authUser } = this.props;
    const { campaign } = this.state;
    data.alias = !data.displayName ? data.alias : "Anonymous";
    data.displayName = !data.displayName;
    this.setState({ openEdit: false, editLoader: true });
    // console.log("data===========", data)
    // data.mode = ((data.mode.trim().toUpperCase()) === 'RECURRING') ? 'monthly' : data.mode;
    data.mode = ((data.mode.trim().toUpperCase()) === 'RECURRING') ? 'monthly' : ((data.mode.trim().toUpperCase() == "ONE TIME") ? "oneTime" : data.mode);
    updateDonation(data.id, data).then(res => {
      getReport(authUser.organizationId, campaign.Id || campaign.campaignId);
      this.setState({ editLoader: false })
      this.setState({ loading: true })
    });
  };

  filterFields = campaignReports => {
    const { fields } = this.state;
    const { filteredReports } = this.props;
    const reports = filteredReports ? filteredReports : campaignReports;

    let visibleArray = [];
    reports.forEach(items => {
      const visibleObj = {};
      for (const i in items) {
        if (fields[i] === true) {
          if (i == "teamId") {
            visibleObj[i] = items.teamData2 ? items.teamData2.teamName : "";
          } else {
            visibleObj[i] = items[i];
          }
        }
      }
      if (Object.keys(visibleObj).length > 0) {
        visibleArray.push(visibleObj);
      } else {
        visibleArray = [];
      }
    });

    this.setState({ visibleFields: visibleArray }, () => {
      const test = document.getElementById("report-table");
      const tableWidth = test.clientWidth;
      this.setState({ tableWidth });
    });
  };

  scrollEmpty = () => {
    const empty = document.getElementById("empty-wrapper");
    const table = document.getElementById("campaign-report-table");

    table.scrollLeft = empty.scrollLeft;
  };

  captureDonation = () => {
    const { campaign: { organizationId, campaignId, Id } } = this.state;
    const { handleCapture } = this.props;

    handleCapture(organizationId, Id || campaignId);
  }

  async generateFinalCampiagnReport(donorsFullData, totalDonation, details) {
    donorsFullData = donorsFullData || [];
    this.numberOfDonors = donorsFullData.length;
    this.totalDonors = donorsFullData.length;
    this.manualDonationsInfo = await this.getManualDonations(donorsFullData, details);
    this.recurringDonationsInfo = this.getRecurringDonations(donorsFullData, details);
    this.setState({
      openPrintWindow: true
    }, () => {
      this.print();
    })

  }
  getManualDonations = async (reports, details) => {
    let manualDonationsSumBeforeMatched = 0;
    let manualDonationsSumAfterMatched = 0;
    let manualDonationsCount = 0;

    await Promise.all(reports.map(report => {
      if (report.isManual) {
        manualDonationsCount = manualDonationsCount + 1;
        manualDonationsSumBeforeMatched = manualDonationsSumBeforeMatched + parseFloat(report.donationAmount);
        if (report.isBonusRoundActive) {
          manualDonationsSumAfterMatched = manualDonationsSumAfterMatched + (parseFloat(report.donationAmount)) * parseInt(details.bonusGoal1MatchFactor || 1)
        } else {
          manualDonationsSumAfterMatched = manualDonationsSumAfterMatched + (parseFloat(report.donationAmount)) * parseInt(details.matchFactor || 1)
        }
      }
    }))
    return {
      manualDonationsCount,
      manualDonationsSumBeforeMatched,
      manualDonationsSumAfterMatched
    }
  };
  getRecurringDonations = (reports, details) => {
    let recurringDonationsSumBeforeMatched = 0;
    let recurringDonationsSumAfterMatched = 0;
    let bonusRecurringDonationsSumAfterMatched = 0;
    let nonBonusRecurringDonationsSumAfterMatched = 0;
    let recurringDonationsCount = 0;
    let ccDonationsCount = 0;
    let failedDonationsCount = 0;
    let failedDonationsSum = 0;
    let failedDonationsArr = [];
    let transactionCoveredDonationsCount = 0;
    let sumOfTransactionsCost = 0;

    // ------------CP LOGIC---------
    let cp1 = 0;
    let cp2 = 0;
    let cp1cp2Sum = 0;
    // ------------------------
    reports.map(report => {

      if (report.mode === "Recurring") {
        recurringDonationsCount = recurringDonationsCount + 1;
        recurringDonationsSumBeforeMatched = recurringDonationsSumBeforeMatched + report.totalDonation;
        console.log(">>>>>>>>>", report)

        if (report.isBonusRoundActive) {
          bonusRecurringDonationsSumAfterMatched = bonusRecurringDonationsSumAfterMatched + (report.totalDonation * (parseInt(details.bonusGoal1MatchFactor) || 1));
          recurringDonationsSumAfterMatched = recurringDonationsSumAfterMatched + (report.totalDonation * (parseInt(details.bonusGoal1MatchFactor) || 1));
          console.log("inside active*********", recurringDonationsSumAfterMatched, ">>>", details.bonusGoal1MatchFactor)
        } else {
          console.log("inside in-active")
          recurringDonationsSumAfterMatched = recurringDonationsSumAfterMatched + (report.donationMatchedMonthly);
          nonBonusRecurringDonationsSumAfterMatched = nonBonusRecurringDonationsSumAfterMatched + (report.donationMatchedMonthly);
        }


      }
      if (report.paymentType === "credit card") {
        ccDonationsCount = ccDonationsCount + 1;
      }
      if (report.transactionCostCovered.toLowerCase() === "yes") {
        sumOfTransactionsCost = sumOfTransactionsCost + parseFloat(report.transactionCost);
        transactionCoveredDonationsCount = transactionCoveredDonationsCount + 1;
      }

      if (report.status.toLowerCase() === "failed") {
        failedDonationsCount = failedDonationsCount + 1;
        failedDonationsSum = failedDonationsSum + report.totalDonation;
        failedDonationsArr.push(report);
      }
    });


    // -------CP LOGIC----------
    cp1 = (transactionCoveredDonationsCount * 0.30);
    cp2 = (transactionCoveredDonationsCount * 0.25)
    cp1cp2Sum = cp1 + cp2;
    let finalCp = (((sumOfTransactionsCost - cp1cp2Sum) / 4) + cp2).toFixed(2);
    let organizationProfit = (sumOfTransactionsCost - finalCp).toFixed(2);
    // console.log("final cp", finalCp, "covered", sumOfTransactionsCost - finalCp, "details+++", details)
    // ----------------
    return {
      recurringDonationsCount,
      recurringDonationsSumBeforeMatched,
      recurringDonationsSumAfterMatched,
      ccDonationsCount,
      transactionCoveredDonationsCount,
      sumOfTransactionsCost,
      finalCp,
      organizationProfit,
      transactionCoveredDonationsCount,
      failedDonationsCount,
      failedDonationsSum,
      failedDonationsArr,
      bonusRecurringDonationsSumAfterMatched,
      nonBonusRecurringDonationsSumAfterMatched
    };
  };
  print() {
    var content = document.getElementById("divcontents");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  render() {
    const {
      editLoader,
      fields,
      total_donation,
      details,
      openEdit,
      currentProspect,
      visibleFields,
      loading,
      tableWidth
    } = this.state;
    const { teams, filteredReports, campaignReports, authUser } = this.props;
    const sign = details.campaignCurrency ? (currencies.find(e => e.code === details.campaignCurrency.code || details.campaignCurrency)).sign : '$';

    return (
      authUser.role !== 'volunteer' ?
        <LoadingOverlay active={loading || editLoader} spinner text="Loading">

          <iframe id="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute" }}></iframe>

          {/* code starts */}
          {<div id="divcontents" style={{ display: 'none' }}>
            <table style={{ "borderWidth": "2px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'width': '100%', 'border-collapse': 'collapse', 'margin': '0', 'padding': '0' }}>
              <tr>
                <th style={{ color: 'green', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Report as of:</th>
                <td style={{ color: 'green', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{moment().format('MM/DD | hh:mm A')}</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Number of Donors:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{this.numberOfDonors}</td>

              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Total raised:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{sign}{NumberFormat(this.state.total_donation)}</td>

              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Number of manual donations:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{this.manualDonationsInfo && this.manualDonationsInfo.manualDonationsCount}</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Total amount of manual donations before matched:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.manualDonationsInfo && this.manualDonationsInfo.manualDonationsSumBeforeMatched)}</td>
              </tr>

              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Total amount of manual donations after matched:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.manualDonationsInfo && this.manualDonationsInfo.manualDonationsSumAfterMatched)}</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Number of recurring donations:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{this.recurringDonationsInfo && this.recurringDonationsInfo.recurringDonationsCount}</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Total amount of recurring donations Before matched:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.recurringDonationsInfo && this.recurringDonationsInfo.recurringDonationsSumBeforeMatched)}</td>
              </tr>

              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Total amount of recurring donations After matched:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.recurringDonationsInfo && this.recurringDonationsInfo.recurringDonationsSumAfterMatched)} = (
                  {this.recurringDonationsInfo && this.recurringDonationsInfo.nonBonusRecurringDonationsSumAfterMatched} Campaign round total +
                  {this.recurringDonationsInfo && this.recurringDonationsInfo.bonusRecurringDonationsSumAfterMatched} Bonus round total )

                </td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Number of donations via credit card:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{this.recurringDonationsInfo && this.recurringDonationsInfo.ccDonationsCount}</td>
              </tr>
            </table>

            <br></br>
            <table style={{ "borderWidth": "2px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'width': '100%', 'border-collapse': 'collapse', 'margin': '0', 'padding': '0' }}>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Processing Fee:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>2.9% + 30 cents</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Net after processing fee:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>API -Payrix</td>

              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Number of donors that covered transaction:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{this.recurringDonationsInfo && this.recurringDonationsInfo.transactionCoveredDonationsCount}</td>

              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Amount of transaction covered:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.recurringDonationsInfo && this.recurringDonationsInfo.organizationProfit)}</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Cost for transaction covered:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.recurringDonationsInfo && this.recurringDonationsInfo.finalCp)}</td>
              </tr>

              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>Total failed transactions:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>{this.recurringDonationsInfo && this.recurringDonationsInfo.failedDonationsCount}</td>
              </tr>
              <tr>
                <th style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '65%', 'font-family': 'Roboto, sans-serif' }}>failed transactions sum:</th>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '35%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(this.recurringDonationsInfo && this.recurringDonationsInfo.failedDonationsSum)}</td>
              </tr>
            </table>
            <br></br>
            <table style={{ "borderWidth": "2px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'width': '100%', 'border-collapse': 'collapse', 'margin': '0', 'padding': '0' }}>
              <tr>
                <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'center', 'padding': '5px 10px', 'width': '100%', 'font-family': 'Roboto, sans-serif', 'color': '#c69696' }}>Failed Donations</td>
              </tr>
            </table>

            <table style={{ "borderWidth": "2px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'width': '100%', 'border-collapse': 'collapse', 'margin': '0', 'padding': '0' }}>
              {this.recurringDonationsInfo && this.recurringDonationsInfo.failedDonationsArr.map((donors, index) => {
                return (<tr>
                  <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '15.33%', 'font-family': 'Roboto, sans-serif' }}>{index + 1}</td>
                  <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '69.33%', 'font-family': 'Roboto, sans-serif' }}>{donors.fullname}</td>
                  <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '15.33%', 'font-family': 'Roboto, sans-serif' }}>{donors.email}</td>
                  <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '15.33%', 'font-family': 'Roboto, sans-serif' }}>{donors.billingPhone}</td>
                  <td style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', 'textAlign': 'left', 'padding': '5px 10px', 'width': '15.33%', 'font-family': 'Roboto, sans-serif' }}>${NumberFormat(donors.totalDonation)}</td>
                </tr>)
              })
              }
            </table>

          </div>}

          <CampaignHeader
            onFormInputValueChange={this.onFormInputValueChange}
            campaignList={this.props.campaigns}
            campaign={this.state.campaign}
          />
          <CampaignMiddleSection
            campaignReports={filteredReports || campaignReports || []}
            visibleFields={visibleFields}
            completeReport={campaignReports}
            fields={fields}
            handleCheckbox={this.handleCheckbox}
            filterByPayment={this.state.filterByPayment}
            filterByDonor={this.state.filterByDonor}
            onFilterByPayment={this.onFilterByPayment}
            onCampaignSortbyRadioChange={this.filterReports}
            donation={total_donation}
            details={details}
            captureDonation={this.captureDonation}
          />
          {/* <div className="get-full-report-latest">
            <Button onClick={() => this.generateFinalCampiagnReport(campaignReports, total_donation, details)}>GET FULL REPORT</Button>
          </div> */}
          <CampaignTableHeader
            searchItem={this.props.searchItem}
            searchCampaign={e =>
              this.props.searchCampaign(e.target.value, fields)
            }
            rowsPerPage={this.state.rowsPerPage}
            handleChangeRowsPerPage={event => this.handleChangeRowsPerPage(event)}
          />
          <div
            id="empty-wrapper"
            className="empty"
            onScroll={this.scrollEmpty}>
            <div className="scroll" style={{ width: tableWidth }} />
          </div>
          <CampaignTable
            fields={fields}
            campaignReports={filteredReports || campaignReports || []}
            rowsPerPage={this.state.rowsPerPage}
            onEditDonation={this.onEditDonation}
          />
          {openEdit && (
            <EditDonation
              currentProspect={currentProspect}
              visible={openEdit}
              campaignTeams={teams}
              handleClose={this.onClose}
              details={details}
              onSubmitAndClose={this.onSubmitAndClose}
            />
          )}
        </LoadingOverlay>
        :
        null
    );
  }
}

CampaignReports.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    campaignReports: state.campaignReportReducer.campaignReports || [],
    filteredReports: state.campaignReportReducer.filteredReports,
    searchItem: state.campaignReportReducer.searchItem,
    campaigns: state.campaignReportReducer.campaigns,
    authUser: state.userAuthReducer.authUser,
    initialCampaign: state.campaignReportReducer.initialCampaign,
    campaign: state.campaign.get("campaignData"),
    total_donation: state.campaign.get("total_donation"),
    teams: state.teamManagementReducer.teams
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCampaigns,
      searchCampaign,
      getReport,
      filterReports,
      getCampaign,
      totalDonation,
      setInitial,
      fetchTeams,
      updateDonation,
      handleCapture
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignReports);