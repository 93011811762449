import React, { Component } from 'react';
import { currencies } from '../../Common/currencies';

class Calculator extends Component {
  constructor() {
    super();

    this.state = { 
      amount: '',
      donation_type: 'oneTime',
      sign: '$'
    }
  }

  componentDidMount() {
    const { currency } = this.props;

    let s = currencies.find(e => e.code === currency);
    this.setState({ sign: s ? s.sign : '$' })
  }

  handleChange = (e) => {
    this.setState({ amount: e.target.value })
  }

  handleDonate = () => {
    const { amount, donation_type } = this.state;
    const { handleDonate, matchFactor } = this.props;
    handleDonate(amount, donation_type, matchFactor)
  }

  render() {
    const{ amount, sign } = this.state;
    const { name, matchFactor } = this.props;

    return(
      <div className="precampaign-calculator">
        <div className="text-box">
          <div className="title">YOUR DONATION</div>
          <div className="currency">{sign}</div>
          <input min='0' type='tel' value={amount} onChange={this.handleChange} />
          <div className="decimal">.00</div>
        </div>
        <div className="summary text-uppercase">
          <div className="organization">{name}<br></br> WILL RECEIVE</div>
          <div className="calculate">
           { matchFactor > 1 && <div className="multiplier">
              <span className="cross">x</span>
              <span className="value">{matchFactor}</span>
            </div> }
            <div className="total"> = {sign}{amount ? amount*matchFactor : '0'}.00</div></div>
        </div>
        <button disabled={amount ? false : true} onClick={this.handleDonate} >DONATE NOW</button>
      </div>
    );
  }
}

export { Calculator };
