import React, { Component } from "react";
import {
  withStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { convertCurrency } from "../../Common/CurrencyCalculator";
import "./Summary.scss";

const styles = {
  root: {
    color: "#fff",
    padding: 4,
    "&$checked": {
      color: "#fff"
    }
  },
  checked: {}
};

const oneTime = (amount, currency) => {
  return (
    <div className="oneTime">
      One payment of
      <span> {currency.sign}{amount}</span>
    </div>
  );
};

const monthly = (amount, duration, type, changeDonation, currency) => {
  const m = type === "monthly" ? duration : 10;
  return (
    <div className="monthly">
      {duration
        ? `${currency.sign}${amount % m === 0 ? amount / m : (amount / m).toFixed(2)}`
        : amount}{" "}
      X
      {type === "monthly" ? (
        <input value={m} onChange={e => changeDonation(e, "monthly")} />
      ) : (
          m
        )}{" "}
      Months
    </div>
  );
};

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      donation: props.donation
    };
  }

  componentDidUpdate(oldProps) {
    const { props } = this;

    if (JSON.stringify(oldProps) !== JSON.stringify(props)) {
      this.setState({ donation: props.donation });
    }
  }

  handleEdit = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    const { donation } = this.props;
    this.setState({ edit: false, donation });
  };

  handleSave = () => {
    const { updateItems } = this.props;
    const { donation } = this.state;
    this.setState({ edit: false, donation });
    updateItems(donation);
  };

  handleAmount = e => {
    const {
      donation: { selectedItems }
    } = this.state;
    let items = selectedItems.map(i => {
      if (i.title === "general donation") {
        i.price = e.target.value;
      }
      return i;
    });

    e.persist();
    this.setState(
      prevState => ({
        donation: {
          ...prevState.donation,
          selectedItems: items
        }
      }),
      () => {
        this.handleTotal(this.state.donation);
      }
    );
  };

  handleCount = (e, d) => {
    e.persist();
    this.setState(
      prevState => ({
        donation: {
          ...prevState.donation,
          selectedItems: prevState.donation.selectedItems.map(s => {
            if (s.id === d.id) {
              s.count = parseInt(e.target.value) || 0;
            }
            return s;
          })
        }
      }),
      () => {
        this.handleTotal(this.state.donation);
      }
    );
  };

  handleRemove = d => {
    this.setState(
      prevState => ({
        donation: {
          ...prevState.donation,
          selectedItems: prevState.donation.selectedItems.filter(
            s => JSON.stringify(d) !== JSON.stringify(s)
          )
        }
      }),
      () => {
        this.handleTotal(this.state.donation);
      }
    );
  };

  handleTotal = donation => {
    const { selectedItems, currency } = donation;
    let total = 0;
    selectedItems.forEach(s => {
      if (s.title === "general donation") {
        total = total + s.price;
      } else {
        total = parseInt(total) + parseInt(s.count) * parseInt(convertCurrency(s.price, currency.code));
      }
    });
    this.setState(prevState => ({
      donation: {
        ...prevState.donation,
        amount: total
      }
    }));
  };

  render() {
    const {
      details: { name },
      classes,
      changeDonation
    } = this.props;
    const {
      donation: { selectedItems, amount, duration, donation_type, matchFactor, currency },
      edit
    } = this.state;

    return (
      <div className="cart-checkout-summary">
        <div className="items flex-column">
          {!edit ? (
            <button className="edit-btn" onClick={this.handleEdit}>
              Edit Donation
            </button>
          ) : (
              <div className="actions flex-row">
                <button className="save-btn" onClick={this.handleSave}>
                  Save
              </button>
                <button className="cancel-btn" onClick={this.handleCancel}>
                  Cancel
              </button>
              </div>
            )}
          <div className='all-items'>
            {selectedItems.map((d, i) =>
              <div key={i} className='item flex-row'>
                <label className='title'>{d.title}</label>
                {d.title === 'general donation' ?
                  <span>
                    {currency.sign} {edit ? <input value={d.price} type='number' onChange={this.handleAmount} /> : d.price}
                    {edit && selectedItems.length > 1 && <i className="fa fa-trash-o" aria-hidden="true" onClick={() => this.handleRemove(d)}></i>}
                  </span> :
                  <div className='calc flex-row'>
                    <span>{edit ? <input value={d.count} type='number' onChange={(e) => this.handleCount(e, d)} /> : d.count}</span>
                    <label>x</label>
                    <span>{currency.sign}{convertCurrency(d.price, currency.code)}</span>
                    {edit && selectedItems.length > 1 && <i className="fa fa-trash-o" aria-hidden="true" onClick={() => this.handleRemove(d)}></i>}
                  </div>
                }
              </div>
            )}
          </div>
        </div>
        <div className="total-donation flex-column">
          <label className="title">Total Donation:</label>
          <FormControl component="fieldset" className="type-group">
            <RadioGroup
              name="donation_type"
              className="type"
              value={donation_type}
              onChange={changeDonation}
            >
              <FormControlLabel
                className="radio"
                value="oneTime"
                control={
                  <Radio
                    classes={{ root: classes.root, checked: classes.checked }}
                  />
                }
                label={oneTime(amount, currency)}
              />
              <div className="divider">
                <label>OR</label>
                <div className="hr" />
              </div>
              <FormControlLabel
                className="radio"
                value="monthly"
                control={
                  <Radio
                    classes={{ root: classes.root, checked: classes.checked }}
                  />
                }
                label={monthly(amount, duration, donation_type, changeDonation, currency)}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="grad-total flex-row">
          {matchFactor > 1 ?
            <>
              <div className="matched flex-column">
                <label>Matched</label>
                <div className="factor">
                  x<span>{matchFactor}</span>
                </div>
              </div>
            </> : null}
          <span className="eq">=</span>
          <div className="org-rec flex-column">
            <label>{name} receives:</label>
            <span>{currency.sign}{amount * matchFactor}</span>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(Summary);
