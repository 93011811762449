import axios from 'axios';
import sessionStore from "../lib/sessionStorage";

export const getExchangeRate = base => {

  let exhangeRateTemp = {
    "USD": 1,
    "EUR": 0.830255,
    "GBP": 0.717075,
    "ILS": 3.267315,
    "MXN": 19.898499,
    "CAD": 1.24995,
    "AUD": 1.289075,
    "BRL": 5.569105,
    "ZAR": 14.25098,
    "RUB": 76.665897
  }
  sessionStore.set("exchange", JSON.stringify(exhangeRateTemp));
  const exchangeRates = sessionStore.get("exchange");
  console.log("ex?????", exchangeRates)

  let symbols = ['USD', 'EUR', 'GBP', 'ILS', 'MXN', 'CAD', 'AUD', 'BRL', 'ZAR', 'RUB'];
  if (base) {
    if ((typeof base == "object")) {
      base = base[0].code
    }
    const i = symbols.indexOf(base.toUpperCase());
    symbols.splice(i, 1);
    let checkBase = ''
    if (exchangeRates) {
      checkBase = exchangeRates[base.toUpperCase()];
    }
    if (checkBase || !exchangeRates) {
      // return axios.get(`https://api.exchangeratesapi.io/latest?base=${base.toUpperCase()}&symbols=${symbols}`)
      return axios.get(`https://api.exchangeratesapi.io/latest?access_key=7f3559db2ff99b9917bfdd418c1aca2c&base=${base.toUpperCase()}&symbols=${symbols}`)
        .then(res => {
          sessionStore.set("exchange", JSON.stringify(res.data.rates));
        });
    }
  }
}

export function getRates(base) {

  let exhangeRateTemp = {
    "USD": 1,
    "EUR": 0.830255,
    "GBP": 0.717075,
    "ILS": 3.267315,
    "MXN": 19.898499,
    "CAD": 1.24995,
    "AUD": 1.289075,
    "BRL": 5.569105,
    "ZAR": 14.25098,
    "RUB": 76.665897
  }


  let symbols = ['USD', 'EUR', 'GBP', 'ILS', 'MXN', 'CAD', 'AUD', 'BRL', 'ZAR', 'RUB'];
  if (base) {

    return new Promise((resolve, reject) => {
     resolve(exhangeRateTemp);
    });
    // return axios.get(`https://api.exchangeratesapi.io/latest?base=${base.toUpperCase()}&symbols=${symbols}`)
    // return axios.get(`https://api.exchangeratesapi.io/latest?access_key=7f3559db2ff99b9917bfdd418c1aca2c&base=${base.toUpperCase()}&symbols=${symbols}`)
    //   .then(res => {

    //     return res.data.rates;
    //     //sessionStore.set("exchange", JSON.stringify(res.data.rates));
    //   });
  }

}
