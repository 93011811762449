import React, { Component } from 'react';
import { Input, Button, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { getStatuses, getDonationMethods } from './helperFuntions';
import './ProspectFilters.scss';

class ProspectFilters extends Component {
  constructor() {
    super();

    this.state = {
      status: '',
      amountFrom: '',
      amountTo: '',
      suggFrom: '',
      suggTo: '',
      method: '',
      notes: false
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleApply = () => {
    const { applyFilter } = this.props;
    const { status, amountFrom, amountTo, suggFrom, suggTo, method, notes } = this.state;

    applyFilter({ status, amountFrom, amountTo, suggFrom, suggTo, method, notes })
  }

  handleClear = () => {
    const { clearFilter } = this.props; 
    this.setState({ status: '', amountFrom: '', amountTo: '', suggFrom: '', suggTo: '', method: '', notes: false });

    clearFilter();
  }

  render() {
    const { status, amountFrom, amountTo, suggFrom, suggTo, method, notes } = this.state;

    return (
      <div className='prospect-filters-tab'>
        <div className='col-1 directtion-col'>
          <label className='filter-label'>Status</label>
          <Select className='filter-select' onChange={this.handleChange} disableUnderline={true} name='status' value={status}>
            { getStatuses().map((status, index) =>
                <MenuItem id='status-item' key={index} value={status.status}>{status.status}</MenuItem>
              )
            }
          </Select>
        </div>
        <div className='col-2 direction-col'>
          <label>Donation Amount</label>
          <div className='direction-row'>
            <Input name='amountFrom' value={amountFrom} onChange={this.handleChange} disableUnderline={true} className='filter-input' />
            <label>To</label>
            <Input name='amountTo' value={amountTo} onChange={this.handleChange} disableUnderline={true} className='filter-input' />
          </div>
        </div>
        <div className='col-2 direction-col'>
          <label>Suggested Donation</label>
          <div className='direction-row'>
            <Input name='suggFrom' value={suggFrom} onChange={this.handleChange} disableUnderline={true} className='filter-input' />
            <label>To</label>
            <Input name='suggTo' value={suggTo} onChange={this.handleChange} disableUnderline={true} className='filter-input' />
          </div>
        </div>
        <div className='col-1 directtion-col'>
          <label className='filter-label'>Donation Method</label>
          <Select className='filter-select' disableUnderline={true} name='method' value={method} onChange={this.handleChange}>
            { getDonationMethods.map((e, index) =>
                <MenuItem id='status-item' key={index} value={e.method}>{e.name}</MenuItem>
              )
            }
          </Select>
        </div>
        <div className='col-3'>
          <FormControlLabel
            control={
              <Checkbox
                name='notes'
                checked={notes}
                onChange={this.handleCheck}
              />
            }
            label="Notes"
          />
        </div>
        <div className='col-4 direction-row'>
          <Button className='filter-btn' onClick={this.handleApply}>Apply Filters</Button>
          <Button className='filter-btn' onClick={this.handleClear}>Clear Filters</Button>
        </div>
      </div>
    );
  }
}

export default ProspectFilters;
