import React from "react";

export const Comment = (props) => {
  const loc = window.location.protocol + '//' + window.location.host + '/' + props.details.campaignPage;
  let details = props.details;
  let showAnimationCampaign = (details && (Object.keys(details).length) && ((details.campaignPage.toString() == 'ccla') || (details.campaignPage.toString() == 'mpcorpnew'))) ? true : false;

  return (
    <div>
      {!showAnimationCampaign ? <div id="site-fb-comments">
        <h4 className="widget-title" style={{display:'none'}}>TEll US WHY YOU GAVE!</h4>
        <div className="textwidget" style={{display:'none'}}>
          <p>Will not post to your wall unless selected</p>
          <span>
            <iframe
              name="f28bfeb2f25dc54"
              height="280px"
              style={{ border: 'none', overflow: 'hidden', width: '100%' }}
              frameBorder={0}
              allowtransparency="true"
              allowFullScreen={true}
              allow="encrypted-media"
              title="fb:comments Facebook Social Plugin"
              src={`https://www.facebook.com/plugins/comments.php?href=${window.location.href.slice(-1) === '/' ? window.location.href : loc + '/'}`}
            />
          </span>
        </div>
      </div> : ''}
    </div>
  );
};
