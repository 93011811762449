import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.scss';
import "./latestFooter.scss";
import logoFooter from "../../assets/images/latestImages/footer-logo.png";
class Footer extends Component {
  render() {
    return (
      //old footer
        <footer>
          <div className="container footer">
            <div className='footer-wrapper'>

              <div className='menu col-md-4'>
                <ul>
                  <li className='item'>
                    <Link to='/'>Home</Link>
                  </li>
                  <li className='item'>
                    <Link to='/'>Why Choose Us?</Link>
                  </li>
                  <li className='item'>
                    <Link to='/'>Contact Us</Link>
                  </li>
                  <li className='item'>
                    <Link to='/'>Dashboard</Link>
                  </li>
                  <li className='item'>
                    <Link to='/'>Login</Link>
                  </li>
                </ul>
              </div>
              <div className='subscribe col-md-8'>
                <div className='content'>
                  <h4>SUBSCRIBE TO OUR NEWSLETTERS</h4>
                  <div className='form'>
                    <input placeholder='Enter your email' />
                    <span>We hate spam as much as you do. Your email will not be shared.</span>
                    <button>SUBSCRIBE</button>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className='image row'>
              <img src='/images/bottom-logo.png' alt="logo" />
            </div>
          </div>
          <div className='inner-footer'>
            <div className='inner-footer-items'>
              <span>Copyright 2019. All right reserve</span>
              <div className='social-icons'>
                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                <i className="fa fa-twitter" aria-hidden="true"></i>
                <i className="fa fa-youtube" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </footer>
      )

      // new footer

      // (<div>

        {/* comment this section  */}
        // <section className="newsletter-latest">
          {/* <div className="container">
             <div className="row">
              <div className="col-md-4">
                <div className="img-box">
                  <img alt="Newletter Image" className="img-fluid" src={newsLetter} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="content">
                  <h5>Newsletter</h5>
                  <p>If you are going to use a passage of Lorem Ipsum</p>
                  <p className="input-box"><input placeholder="Email Address" type="text" /><button className="btn btn-subscribe">Subscribe</button></p>
                </div>
              </div> 
            </div> 
          </div> */}
        // </section>

        // <footer className="footer-home">
        //   <div className="container">
        //     <div className="row">
        //       <p><img alt="Footer Logo" className="" height="100px" src={logoFooter} /></p>
        //     </div>
        //   </div>
        // </footer>
      // </div>)
    // )
  }
}

export default Footer;