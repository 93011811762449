import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, AppBar, Tabs, Tab, withStyles } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfoIcon from '@material-ui/icons/Info';
import CallIcon from '@material-ui/icons/Call'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ProspectTabContent from './ProspectTabContent';
import './AccessProspect.scss';

function getStyle(isActive) {
  return isActive ? {
    background: '#53c1e8',
    color: '#fff'
  } : {
    background: '#f6f6f6',
    color: '#404040'
  }
}

const paperStyle = {
  dialogPaper: {
    maxHeight: '100vh',
    position: 'absolute',
    top: 0
  },
};

class AccessProspect extends Component {
  constructor() {
    super();

    this.state = {
      tabValue: 0
    }
    this.child = React.createRef();
  }

  onTabValueChange = (e, value) => {
    this.setState({ tabValue: value });
  }

  handleClose = () => {
    this.child.current.closeModal();
  }

  render() {
    const { fullScreen, onSubmitAndClose, campaignTeams, onManualPaymentClick, classes } = this.props;
    const { tabValue } = this.state;

    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullScreen={fullScreen}
        maxWidth='md'
        scroll='paper'
        open={true}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar position="static" id="appbar-tab-container" >
          <Tabs className="tabs"
            name="tabValue"
            value={tabValue}
            onChange={this.onTabValueChange}
            scrollButtons="off"
            indicatorColor="default"
          >
            <Tab label="Prospect Info" icon={<InfoIcon />} className="tab" style={getStyle(tabValue === 0)} />
            <Tab label="Notes" icon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 25 }} />} className="tab" style={getStyle(tabValue === 1)} />
            <Tab label="Call Script" icon={<CallIcon />} className="tab" style={getStyle(tabValue === 2)} />
            <Tab label="Incentive List" icon={<FormatListBulletedIcon />} className="tab" style={getStyle(tabValue === 3)} />
          </Tabs>
          <CloseIcon fontSize="large" className="close-icon" onClick={this.handleClose} />
        </AppBar>
        {tabValue === 0 && <ProspectTabContent
          {...this.props}
          ref={this.child}
          onSubmitAndClose={onSubmitAndClose}
          campaignTeams={campaignTeams}
          onManualPaymentClick={onManualPaymentClick}
        />}
        {tabValue === 1 && <div>Notes</div>}
        {tabValue === 2 && <div>Call Script</div>}
        {tabValue === 3 && <div>Incentive List</div>}
      </Dialog>
    )
  }
}

AccessProspect.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(paperStyle)(AccessProspect));
