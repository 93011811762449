import React, { Component } from "react";
import { Button, Dialog } from "@material-ui/core";
import { CSVLink } from "react-csv";
import HeaderComponent from "../HeaderComponent";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetch,
  add,
  update,
  resetPassword,
  remove,
  searchUser
} from "../../redux/modules/Users/action-creator";
// import AddVolunteer from "../../components/Signup/AddVolunteer";
import AddVolunteer from "./AddVolunteer";
import UserTable from "./UserTable";
import UserTableHeader from "./UserTableHeader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "../../components/Snackbar/Snackbar";
import "./index.scss";

class UserManagement extends Component {
  constructor() {
    super();

    this.state = {
      user: {},
      userFormVisible: false,
      edit: false,
      rowsPerPage: 15,
      page: 0,
      users: [],
      filteredUsers: []
    };
  }

  componentDidMount() {
    const {
      authUser: { organizationId },
      fetch
    } = this.props;
    fetch(organizationId);
  }

  componentWillReceiveProps(nextProps) {
    const { users, filteredUsers } = nextProps;

    if (users || filteredUsers) {
      this.setState({ users, filteredUsers });
    }
  }

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 1500);
  };

  updateUser = data => {
    this.props.update(data);
    this.setState({ userFormVisible: false });
  };

  onResetPassword = userEmail => {
    const {
      authUser: { organizationId }
    } = this.props;
    const data = {
      userEmail: userEmail,
      organizationId: organizationId
    };
    this.props.resetPassword(data);
  };

  removeUser = userId => {
    const {
      authUser: { organizationId }
    } = this.props;
    this.props.remove({ userid: userId, organizationId });
  };

  handleAddUserFormClose = () => {
    this.setState({ userFormVisible: false });
  };

  onEditUserClick = user => {
    this.setState({
      user: Object.assign({}, user),
      userFormVisible: true,
      edit: true
    });
  };

  handleChangePage = (e, page) => {
    this.setState({ page });
  };

  handleStatus = (e, user) => {
    e.persist();
    this.setState(
      {
        user: {
          ...user,
          [e.target.name]: e.target.checked ? "Active" : "Inactive"
        }
      },
      () => {
        this.updateUser({ user: this.state.user });
      }
    );
  };

  render() {
    const { users, filteredUsers, user, edit } = this.state;
    const {
      authUser: { organizationId, role }
    } = this.props;

    return (
      role !== 'volunteer' ?
      <LoadingOverlay
        active={this.props.isFetching}
        spinner
        text="Please wait..."
      >
        <Snackbar
          message={this.state.message}
          color={this.state.error ? "danger" : "success"}
          icon={this.state.error ? ErrorIcon : CheckCircleIcon}
          place="tr"
          open={this.state.showMessage}
        />
        <div className="user-container">
          <HeaderComponent
            title1="USER"
            title2="MANAGEMENT"
            baseColor="#006400"
          />
          <div className="user-main-actions">
            <div className="export-btn">
              <CSVLink data={users || []}>
                <i class="fa fa-file-text" aria-hidden="true" />
                Export
              </CSVLink>
            </div>
            <Button
              className="add-btn"
              variant="contained"
              onClick={() =>
                this.setState({
                  userFormVisible: true,
                  edit: false,
                  user: {}
                })
              }
            >
              <i class="fa fa-user-plus" aria-hidden="true" />
              ADD NEW USER
            </Button>
          </div>
          <div className="main-wrap">
            <UserTableHeader
              searchItem={this.props.searchItem}
              searchUser={e => this.props.searchUser(e)}
              rowsPerPage={this.state.rowsPerPage}
              handleChangeRowsPerPage={e =>
                this.setState({ rowsPerPage: e.target.value })
              }
            />
            <UserTable
              handleStatus={this.handleStatus}
              users={filteredUsers || users || []}
              onEditUserClick={this.onEditUserClick}
              onDeleteUserClick={this.removeUser}
              onResetPassword={(event, userEmail) =>
                this.onResetPassword(userEmail)
              }
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              handleChangePage={(e, page) => this.handleChangePage(e, page)}
            />
          </div>
          <Dialog
            open={this.state.userFormVisible}
            aria-labelledby="form-dialog-title"
            scroll="body"
            // fullScreen
          >
            <div className="volunteer-management-form">
              <AddVolunteer
                organizationId={organizationId}
                campaignId={""}
                user={user}
                edit={edit}
                handleAddUserFormClose={this.handleAddUserFormClose}
                updateUser={this.updateUser}
              />
            </div>
          </Dialog>
        </div>
      </LoadingOverlay>
      :
      null
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.userManagementReducer.isFetching,
    authUser: state.userAuthReducer.authUser,
    users: state.userManagementReducer.users,
    searchItem: state.userManagementReducer.searchItem,
    filteredUsers: state.userManagementReducer.filteredUsers
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      add,
      update,
      resetPassword,
      remove,
      fetch,
      searchUser
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);
