import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, withStyles } from '@material-ui/core';
import { getSign } from '../../Common/getSign';

const paperStyle = {
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0
  }
};
const columns = ['Team Name','Donated Amount'];

function TeamTable({ donationDetails, handleChangePage, rowsPerPage, page, classes, campaign }) {
                 
  return (
    <div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((columnName,index)=>
                <TableCell key ={index} className="team-table-cell-head" >{columnName}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {donationDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((donationDetail,index) => (
              <TableRow key={index} style={{ backgroundColor : index % 2 === 0 ? '#fafafa' : 'white'}}>
                <TableCell component="th">{donationDetail.teamName}</TableCell>
                <TableCell component="th">{getSign(campaign.campaignCurrency || campaign.currency)}{donationDetail.totalChargedAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        classes={{ caption: classes.caption, toolbar: classes.toolbar }}
        component="div"
        count={donationDetails.length}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={[]} 
        onChangePage={handleChangePage}
      />
    </div>
  );
}

export default withStyles(paperStyle)(TeamTable);
