import { BASE_URL } from "../src/apis/backEnd.apis";

// var config = {
//   apiKey: "AIzaSyAMhg5rn-H9w-hjafyE-pf5csLzf6d0bRY",
//   authDomain: "pledjeapi-prod.firebaseapp.com",
//   databaseURL: "https://pledjeapi-prod.firebaseio.com",
//   projectId: "pledjeapi-prod",
//   storageBucket: "pledjeapi-prod.appspot.com",
//   apiAccessKey:
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1NTIwOTYwNDAsImV4cCI6MTU4MzYzMjA0MCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.WA0k20-mDYAMAcoVlV-0g-vqmvNJWUjdbC1d9QigqzI",
//   messagingSenderId: "20201363512"
// };


var config = {
  apiKey: "AIzaSyAMhg5rn-H9w-hjafyE-pf5csLzf6d0bRY",
  authDomain: "pledjeapi-prod.firebaseapp.com",
  //authDomain: BASE_URL,
  databaseURL: "https://pledjeapi-prod.firebaseio.com",
  //databaseURL: "https://test-ef716.firebaseio.com",
  projectId: "pledjeapi-prod",
  //projectId: "test-ef716",
  storageBucket: "pledjeapi-prod.appspot.com",
  //storageBucket: "test-ef716.appspot.com",
  messagingSenderId: "20201363512",
  apiAccessKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODI5MTA5ODQsImV4cCI6MTcxMzE1MDk4NH0.QTCw9teX_ZlkzjutH3iDPUWfvT13v6pDHcb6hrBfljo"
}

// var config = {
//   apiKey: "AIzaSyAHTB7yg-WYm7CFCSIZPvcuUoZh2wTLZ9w",
//   authDomain: "pledje-drafts2.firebaseapp.com",
//   //authDomain: BASE_URL,
//   databaseURL: "https://pledje-drafts2.firebaseio.com",
//   //databaseURL: "https://test-ef716.firebaseio.com",
//   projectId: "pledje-drafts2",
//   //projectId: "test-ef716",
//   // storageBucket: "pledjeapi-prod.appspot.com",
//   storageBucket: "pledje-drafts2.appspot.com",
//   messagingSenderId: "20201363512",
//   // apiAccessKey:
//   //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1ODM3NDI0MDQsImV4cCI6MTYxNTI3ODQwNCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.4jDs1x3PCrX5qCT8aYoPUTj9hGXsaGPvPwIBPkwxOKs",

//   apiAccessKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTU3MTUyNDQsImV4cCI6MTY0NTk1NTI0NH0.KeCfdJ8s-Mt72bgVT_ab-Fu8XDYFkXsF62QIwejphvA"

// };

export { config };
