import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Confetti from "react-confetti";
import sizeMe from "react-sizeme";
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import * as campaignActions from "../../redux/modules/campaign";
import { CampaignVideo } from "./CampaignVideo";
import { PowerDonars } from "./PowerDonars";
import { Calculator } from "../../components/Calculator/Calculator";
import CampaignDetails from "../../components/CampaignDetails";
import { CampaignGoals } from "../../components/CampaignDetails/Goals";
import { Progress } from "../../components/CampaignDetails/Progress";
import CampaignItems from "../../components/CampaignItems";
import { Summary } from "../../components/CampaignItems/Summary";
import PreCampaign from "../../components/PreCampaign";
import MobileCalculator from "../../components/Calculator/Responsive/MobileCalculator";
import Error from "../../components/Notification/Error";
import { ProgressLoader } from "../../components/Loader";
import { currencies } from "../../Common/currencies";
import { getExchangeRate } from "../../Common/Exchange";
import { NumberFormat } from '../../Common/NumberFormat';

import "./Campaign.scss";
import Parser from 'html-react-parser';

import $ from 'jquery';
import sliderImage1 from "../../assets/images/sliderImage1.jpg";
import videoIconLatest from "../../assets/images/videoIconLatest.png";

import ImageGallery from 'react-image-gallery';
// import animationImage from '../../assets/images/building-only.png';
import animationImage from '../../assets/images/building-onlyupdated.png';
import arrow from '../../assets/images/arrow.png';

import { GoalTimerRoot } from "../latestUi/goalTimerRoot";
import { LatestProgress } from "../latestUi/latestProgress";
import { GoalCaption } from "../latestUi/goalCaption";
import DonorBoxRoot from "../latestUi/donorBox/donorBoxRoot";

const images = [
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F52e5ee19-cdf4-4b63-81eb-e197c60e7448.jpg?alt=media&token=0ca49a43-e7c0-493a-9b35-d295d638175c',
    thumbnail: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F52e5ee19-cdf4-4b63-81eb-e197c60e7448.jpg?alt=media&token=0ca49a43-e7c0-493a-9b35-d295d638175c',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F7933ac8c-ff7e-4c70-9556-fc91d0204c69.jpg?alt=media&token=503e9571-f4be-4c37-8930-81ce13f97216',
    thumbnail: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F7933ac8c-ff7e-4c70-9556-fc91d0204c69.jpg?alt=media&token=503e9571-f4be-4c37-8930-81ce13f97216',
  },
  // {
  //   original: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F8c4cf09e-3cae-482b-9a28-a3c8132cbda1.jpg?alt=media&token=c46b0e60-4c3e-4882-bd04-b81e58695234',
  //   thumbnail: 'https://firebasestorage.googleapis.com/v0/b/pledje-drafts2.appspot.com/o/CampaignPicture%2F8c4cf09e-3cae-482b-9a28-a3c8132cbda1.jpg?alt=media&token=c46b0e60-4c3e-4882-bd04-b81e58695234',
  // },
  // {
  //   original: 'https://picsum.photos/id/1015/1000/600/',
  //   thumbnail: 'https://picsum.photos/id/1015/250/150/',
  // },
  // {
  //   original: 'https://picsum.photos/id/1019/1000/600/',
  //   thumbnail: 'https://picsum.photos/id/1019/250/150/',
  // },
];
const mobileBanner = [{
  original: 'https://firebasestorage.googleapis.com/v0/b/pledjeapi-prod.appspot.com/o/CampaignPicture%2F767701a3-527a-4364-a382-b0e86c6a901a.jpg?alt=media&token=2cdb03b3-b7fa-48ba-98c2-d80d90532961',
  thumbnail: 'https://firebasestorage.googleapis.com/v0/b/pledjeapi-prod.appspot.com/o/CampaignPicture%2F767701a3-527a-4364-a382-b0e86c6a901a.jpg?alt=media&token=2cdb03b3-b7fa-48ba-98c2-d80d90532961'
}];

const Campaign = sizeMe({
  monitorHeight: true,
  monitorWidth: true
})(
  class Campaign extends Component {
    static propTypes = {
      size: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
      })
    };

    constructor(props) {
      super(props);

      this.state = {
        campiagHtmlContent: "",
        title: "",
        video: "",
        powerDonars: [],
        details: {},
        total_donation: "",
        donors: [],
        open: false,
        responsive_calculator: false,
        error: "",
        notify: false,
        imageURL: "",
        donate: [],
        donationType: "oneTime",
        loadingPercent: 0,
        shares: {},
        currency: {
          name: 'United States Dollar (USD)',
          code: 'usd',
          sign: '$'
        },
        videoModal: false,
        finalAnimationArr: [],
        orgInfo: {}
      };
      this.fullTeamsDetails = [];
    }

    componentWillMount() {
      const {
        onCampaignActions,
        match: { params },
        history,
        campaign
      } = this.props;
      if (campaign.success && campaign.data) {

        this.setState({ loadingPercent: 100 });
        const { data } = campaign;
        const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
        this.setState({ currency, details: data });

        // ----------------------FOR LATEST UI NEED TO FIND ORG DETAILS FOR LATEST UI---------------------
        onCampaignActions.fetchOrg(data && data.organizationId).then(res => {
          this.setState({ orgInfo: res });
        })
        // --------------------------------------------


        onCampaignActions
          .getCampaign(params.campaign)
          .then(res => {
            this.fullTeamsDetails = res.data.data ? res.data.data.Teams : [];
            console.log("hee@", res.data.data);
          })
      } else {
        onCampaignActions
          .getCampaign(params.campaign)
          .then(res => {
            const { data, data: { campaignId, organizationId } } = res.data;
            this.fullTeamsDetails = res.data.data.Teams;
            getExchangeRate(res.data.data.campaignCurrency || res.data.data.currency[0].code || 'usd');
            this.setState({ loadingPercent: 25 });
            if (
              data === false ||
              data === "No Records Found For That Route"
            ) {
              this.setState({
                error: `No Record Found for ${params.campaign}!`,
                notify: true
              });
              setTimeout(() => {
                history.push("/not-found-404", { isPushed: true });
              }, 2000);
            } else {

              // ----------------------FOR LATEST UI NEED TO FIND ORG DETAILS FOR LATEST UI---------------------
              onCampaignActions.fetchOrg(organizationId).then(res => {
                this.setState({ orgInfo: res });
              })
              // --------------------------------------------
              onCampaignActions
                .totalDonation(organizationId, campaignId)
                .then(() => {
                  this.setState({ loadingPercent: 50 });
                  onCampaignActions
                    .getDonations(organizationId, campaignId)
                    .then(() => {
                      this.setState({ loadingPercent: 75 });
                      onCampaignActions
                        .getItems(
                          organizationId,
                          campaignId
                        )
                        .then(() => {
                          onCampaignActions.socialShare('456757', 'https://drafts.pledje.com/campaign3', 'imageUrl', 'Test Campaign 3');
                          this.setState({ loadingPercent: 100 });
                        });
                    });
                });

              setInterval(() => {
                onCampaignActions.totalDonation(organizationId, campaignId);
                onCampaignActions.getDonations(organizationId, campaignId);
              }, 50000);
            }
          })
          .catch(() => {
            this.setState({ loadingPercent: 100 });
            this.setState({ error: "No Record Found!", notify: true });
          });
      }
    }

    componentDidUpdate(oldProps) {
      const { campaign } = this.props;
      const {
        onCampaignActions
      } = this.props;

      if (oldProps.campaign !== campaign) {
        const { data } = campaign;
        const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
        this.setState({ currency, campiagHtmlContent: campaign.data ? campaign.data.mainContent.htmlBody : '' })
      }
    }

    componentWillReceiveProps(nextProps) {
      const {
        campaign: { data },
        total_donation,
        donations,
        share
      } = nextProps;


      //animation code----------------
      let oldDonors = this.props.donations;
      let tempOldDonorsHold = new Array();
      let tempNewDonorsHold = new Array();
      let animationDonorsArr = [];
      let newDonationsCount = 0;
      tempOldDonorsHold = oldDonors.data ? oldDonors.data : [];
      tempNewDonorsHold = donations.data ? donations.data : [];

      newDonationsCount = (tempNewDonorsHold.length - tempOldDonorsHold.length);
      if (tempOldDonorsHold.length !== 0) {
        for (let i = 1; i <= newDonationsCount; i++) {
          if (parseInt(tempNewDonorsHold[i].amount) >= 500) {
            animationDonorsArr.push(tempNewDonorsHold[i - 1]);
          }
        }
      }
      // animation code---------------

      if (typeof data === "object") {
        this.setState({
          title: data.name,
          video: data.videoURL,
          powerDonars: data.superDonors,
          details: data,
          imageURL: data.mainContent.imageURL
        });
        if (
          data.status === "pre-campaign" &&
          new Date() < new Date(data.preCampaignEndDate)
        ) {
          this.setState({ open: true });
        }
      }
      if (total_donation.data) {
        this.setState({ total_donation: total_donation.data.total });
      }
      if (donations.data) {
        this.setState({ donors: donations.data, finalAnimationArr: animationDonorsArr });
      }
      if (share.data) {
        this.setState({ shares: share.data });
      }
    }

    openCalculator = () => {
      this.setState({ responsive_calculator: true });
    };

    closeCalculator = () => {
      this.setState({ responsive_calculator: false });
    };

    handleNotification = () => {
      this.setState({ notify: false });
    };

    donate = donated => {
      this.setState({ donate: donated });
    };

    checkout = total => {
      const { onCampaignActions, history } = this.props;
      const {
        donate,
        details: { matchFactor },
        donationType,
        currency
      } = this.state;

      if (!total) {
        this.setState({
          error: `Your shopping cart is empty. Please choose items to donate or enter a valid general donation amount to proceed. Thanks You!`,
          notify: true
        });

        return;
      }

      const data = {
        amount: total,
        donation_type: donationType,
        matchFactor,
        selectedItems: donate,
        duration: donationType === "monthly" ? 10 : 10,
        currency,
        cart: true
      };

      onCampaignActions.setDonation(data);
      history.push("/checkout");
    };

    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };

    handleCurrency = (c) => {
      this.setState({ currency: c });
    }

    openVideoModal = () => {
      this.setState({
        videoModal: true
      });
    }

    closeVideoModal = () => {
      this.setState({
        videoModal: false
      });
    }

    execJqueryCode = (item, i) => {
      let donorName = item.alias == 'Anonymous' ? 'Anonymous' : item.name;
      // (function () {
      $(document).ready(function () {
        function appendHtml(el, str) {
          var div = document.createElement('div');
          div.innerHTML = str;
          while (div.children.length > 0) {
            el.appendChild(div.children[0]);
          }
        }
        var fromElem = $('.campaign_wrapper .erd_scroll_detection_container');
        var toElem = $('.campaign_wrapper .xDollar');
        var boxElement = `<div id='flyPostUX'><span><span class='name' title='testsuper testsuper'>${donorName}</span></span></div>`;
        appendHtml(document.body, boxElement);
        $('body #flyPostUX').css({ 'z-index': 999999999, position: 'absolute', top: fromElem.offset().top - (i * 500), left: fromElem.offset().left }).animate({ top: toElem.offset().top, left: toElem.offset().left }, 5000, 'swing', function () {
          //put your logic here
          setTimeout(function () {
            // alert("animation completed ...");
          }, 2000)
          //End of your logic
          $('body #flyPostUX').remove();
        });
      });
      // })();
    }

    render() {
      const {
        title,
        video,
        powerDonars,
        details,
        open,
        responsive_calculator,
        notify,
        error,
        total_donation,
        donors,
        imageURL,
        donate,
        donationType,
        loadingPercent,
        shares,
        currency
      } = this.state;
      const { onCampaignActions, history, location, items } = this.props;
      const bonus = total_donation > details.goalAmount;
      const sign = details.campaignCurrency ? (currencies.find(e => e.code === details.campaignCurrency.code || details.campaignCurrency)).sign : '$';
      let { campiagHtmlContent } = this.state;

      let margins = '0%';
      let totalSocialShare2 = details.socialShareAmount ? parseInt(details.socialShareAmount) : 0;
      let total_donation2 = total_donation ? parseInt(total_donation) : 0;
      let goalAmount2 = details.goalAmount ? parseInt(details.goalAmount) : 0;
      let achivedPercentage = ((totalSocialShare2 + total_donation2) * 100) / goalAmount2;
      achivedPercentage = achivedPercentage >= 100 ? 100 : achivedPercentage;
      let finalMargin = 91 - ((parseInt(achivedPercentage) * 84) / 100);
      margins = Math.ceil(finalMargin).toString();
      let showAnimationCampaign = (details && (Object.keys(details).length) && ((details.campaignPage.toString() == 'ccla') || (details.campaignPage.toString() == 'mpcorpnew'))) ? true : false;
      let tempAnimationArr = [];
      let originalAmount = 0;
      let showLatestUi = (details && (Object.keys(details).length)) && details.enableLatestUi ? true : false;
      // new code added for bonus round matchfactor update--------------
      const timeRemaining = new Date(details.campaignEndTime) - new Date();
      // const isBonusRoundActive = total_donation > parseInt(details.goalAmount) && timeRemaining >= 0;

      let totalCampaignDonationIncludingShareAmount = shares && parseInt(shares.shareCount) >= parseInt(shares.shareGoal) ? (total_donation + totalSocialShare2) : total_donation;

      const isBonusRoundActive = totalCampaignDonationIncludingShareAmount > parseInt(details.goalAmount);
      // console.log("bt>>", totalCampaignDonationIncludingShareAmount)
      // -------------------------------------
      const sliderImagesArr = details && details.sliderImagesArr ? details.sliderImagesArr : [];
      const finalSliderImgs = sliderImagesArr.map(url => {
        return {
          original: url,
          thumbnail: url
        }
      });


      // animation code for ccla july 2020 campaign only--------------
      if (showAnimationCampaign) {
        this.state.finalAnimationArr.map((item, index) => {
          let result = Math.floor((parseInt(item.amount)) / 500);
          for (let i = 1; i <= result; i++) {
            this.execJqueryCode(item, i);
          }
        });
        donors.map((item, index) => {
          originalAmount = (parseInt(item.amount)) / parseInt(details.matchFactor)
          if ((parseInt(originalAmount) >= 500)) {
            tempAnimationArr.push(item);
          }
        });
      }
      // ---------------------------------------------------
      return (
        <div>
          {/* **********************************LATEST UI********************************** */}
          {!showAnimationCampaign && showLatestUi &&
            < div
              className={"campaign_wrapper" + (bonus ? "" : " blue-bg")}
            // className={"campaign_wrapper"}
            >
              <ProgressLoader loadingPercent={loadingPercent}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{details.name}</title>
                  <link rel="canonical" href={window.location.href} />
                </Helmet>

                <div className="banner">
                  <ImageGallery items={images} onClick={this.openVideoModal} showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false} />
                </div>
                <div className="wrapper">

                  <div className="row main-row">
                    <div className="col-md-4">
                      <LatestProgress
                        shares={shares}
                        bonusGoal={details.bonusGoalAmount1}
                        goalAmount={details.goalAmount}
                        socialShareAmount={details.socialShareAmount}
                        donation={total_donation}
                        sign={sign}
                        details={details}
                      />
                      <GoalCaption details={details} donation={total_donation} sign={sign} showLatestUi={showLatestUi} donors={donors} />

                    </div>
                    <div className="col-md-4">
                      {this.state.videoModal ? <div className="mask"></div> : ""}

                      <div className="video-tag-latest">

                        {this.state.videoModal ? <div id='description' className="description block description-popup">
                          <span className="btn-close" onClick={this.closeVideoModal}><i className="fa fa-times"></i></span>

                          {video && (
                            <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                          )}
                        </div> : ""}
                        <img src={videoIconLatest} onClick={this.openVideoModal} alt="videoicon" />
                        <div className="captions">
                          <h4>{this.state.orgInfo && this.state.orgInfo.organizationName}</h4>
                          <h5>{this.state.orgInfo && this.state.orgInfo.organizationAddress}</h5>
                          <h6>{this.state.orgInfo && this.state.orgInfo.organizationPhone} | {this.state.orgInfo && this.state.orgInfo.orgEmail}</h6>
                        </div>
                        {/* {video && (
                          <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="">
                        <GoalTimerRoot details={details} donation={total_donation} sign={sign} showLatestUi={showLatestUi} />
                      </div>
                    </div>

                  </div>



                  <div className="content">
                    <div id='description' className={details.mode === 'e-commerce' ? 'description instruction' : 'description block'}>
                      {details.mode === 'e-commerce' && <img className='instruction-img' src='/images/instruction.png' alt='camp-img' />}
                      {/* {video && (
                        <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                      )} */}
                    </div>

                    {/* ------------write bannner code-------------- */}



                    {/* ***************************************************** */}
                    {/* write video and progress bar code */}
                    {/* --------------------------- */}

                    {powerDonars && powerDonars.length > 0 && (
                      <PowerDonars
                        powerDonars={powerDonars}
                        title={title}
                        details={details}
                        donation={total_donation}
                      />
                    )}

                    {/* <div className="project-inner-content">

                      {title.length < 30 ? (
                        <h1 className="projects-custom-title">{title}</h1>
                      ) : title.length < 35 ? (
                        <h2 className="projects-custom-title">{title}</h2>
                      ) : (
                            <h3 className="projects-custom-title">{title}</h3>
                          )}
                    </div> */}
                    {/* <div className="">
                      <CampaignGoals details={details} donation={total_donation} sign={sign} />
                      <Progress
                        shares={shares}
                        bonusGoal={details.bonusGoalAmount1}
                        goalAmount={details.goalAmount}
                        socialShareAmount={details.socialShareAmount}
                        donation={total_donation}
                        sign={sign}
                      />
                    </div> */}
                    {Object.keys(details).length > 0 &&
                      details.mode === "e-commerce" &&
                      items.length > 0 ? (
                      <>
                        <CampaignItems
                          handleChange={this.handleChange}
                          donationType={donationType}
                          handleDonate={this.donate}
                          donate={donate}
                          items={items}
                          details={details}
                          onCampaignActions={onCampaignActions}
                          history={history}
                          handleCurrency={this.handleCurrency}
                          location={location}
                        />
                        <Hidden mdUp>
                          <Summary
                            donate={donate}
                            details={details}
                            checkout={this.checkout}
                            currency={currency}
                          />
                        </Hidden>
                      </>
                    ) : null}

                    {/* <img className="camp-img" src={imageURL} alt="Campaign" /> */}
                    <div className="content">{Parser(campiagHtmlContent)}</div>
                  </div>
                </div>
                <div className="donorBox">
                  <div className="wrapper">
                    {Object.keys(details).length > 0 && (
                      <DonorBoxRoot
                        location={location}
                        details={details}
                        donation={total_donation}
                        donors={donors}
                        imageURL={imageURL}
                        history={history}
                        fullTeamsDetails={this.fullTeamsDetails}
                        showLatestUi={true}
                      />
                    )}
                    <div style={{ clear: "both" }} />
                  </div>
                </div>
                {details.status === "completed" && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Confetti {...this.props.size} numberOfPieces={400} />
                  </div>
                )}
                {Object.keys(details).length > 0 &&
                  (details.mode === "e-commerce" ? (
                    <Hidden smDown>
                      <Summary
                        donate={donate}
                        details={details}
                        checkout={this.checkout}
                        currency={currency}
                      />
                    </Hidden>
                  ) : (
                    details.hideCalculator ?
                      new Date() < new Date(details.campaignEndTime) ?
                        <Calculator
                          onCampaignActions={onCampaignActions}
                          history={history}
                          details={details}
                          isBonusRoundActive={isBonusRoundActive}
                        />
                        :
                        null
                      :
                      <Calculator
                        onCampaignActions={onCampaignActions}
                        history={history}
                        details={details}
                        isBonusRoundActive={isBonusRoundActive}
                      />
                  ))
                }
                {details.mode !== "e-commerce" && (
                  details.hideCalculator ?
                    new Date() < new Date(details.campaignEndTime) ?
                      <div className="mobile_donate" onClick={this.openCalculator}>
                        <p style={{ textAlign: "center" }}>DONATE NOW</p>
                      </div>
                      :
                      null
                    :
                    <div className="mobile_donate" onClick={this.openCalculator}>
                      <p style={{ textAlign: "center" }}>DONATE NOW</p>
                    </div>
                )}
                {open && (
                  <PreCampaign
                    onCampaignActions={onCampaignActions}
                    history={history}
                    details={details}
                  />
                )}
                {responsive_calculator && details.mode !== "e-commerce" && (
                  <MobileCalculator
                    history={history}
                    handleClose={this.closeCalculator}
                    open={responsive_calculator}
                    details={details}
                    isBonusRoundActive={isBonusRoundActive}
                  />
                )}

                <Error
                  open={notify}
                  message={error}
                  handleClose={this.handleNotification}
                />
              </ProgressLoader>
            </div>}
          {/* ******************************************************************** */}

























          {/* +++++++++++++++++++++++++++++++++++++Regular UI+++++++++++++++++++++++++++++++++++++++++++++++ */}
          {!showAnimationCampaign && !showLatestUi &&

            <div
              className={"campaign_wrapper" + (bonus ? " green-bg" : " blue-bg")}
            >
              <ProgressLoader loadingPercent={loadingPercent}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{details.name}</title>
                  <link rel="canonical" href={window.location.href} />
                </Helmet>

                <div className="wrapper">
                  <div className="content">
                    <div id='description' className={details.mode === 'e-commerce' ? 'description instruction' : 'description block'}>
                      {details.mode === 'e-commerce' && <img className='instruction-img' src='/images/instruction.png' alt='camp-img' />}
                      {video && (
                        <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                      )}
                      {details && (Object.keys(details).length) && (details.campaignPage == "hbTestingMarch21") && (
                        <div className="image-slider-new">
                          <ImageGallery items={finalSliderImgs} showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false} />
                        </div>
                      )}
                    </div>


                    {powerDonars && powerDonars.length > 0 && (
                      <PowerDonars
                        powerDonars={powerDonars}
                        title={title}
                        details={details}
                        donation={total_donation}
                      />
                    )}

                    <div className="project-inner-content">

                      {title.length < 30 ? (
                        <h1 className="projects-custom-title">{title}</h1>
                      ) : title.length < 35 ? (
                        <h2 className="projects-custom-title">{title}</h2>
                      ) : (
                        <h3 className="projects-custom-title">{title}</h3>
                      )}
                    </div>

                    {/* new block for april campaign---------- */}

                    {/* {console.log("iiiiiiiiiiiiiii", details)} */}
                    {details && (Object.keys(details).length) && (details.campaignPage == "MyPartner") && <div >
                      <iframe src="https://vimeo.com/event/922772/embed" style={{ height: "400px", width: "100%" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>

                    </div>}
                    {/* <iframe src="https://player.vimeo.com/video/540694602" style={{height: "400px", width: "100%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen  title="Pillars of Faith: Building A Better World"></iframe> */}
                    {/* -------------- */}


                    <div className="mobile-clock">
                      <CampaignGoals details={details} donation={total_donation} sign={sign} />
                      <Progress
                        shares={shares}
                        bonusGoal={details.bonusGoalAmount1}
                        goalAmount={details.goalAmount}
                        socialShareAmount={details.socialShareAmount}
                        donation={total_donation}
                        sign={sign}
                      />
                    </div>
                    {Object.keys(details).length > 0 &&
                      details.mode === "e-commerce" &&
                      items.length > 0 ? (
                      <>
                        <CampaignItems
                          handleChange={this.handleChange}
                          donationType={donationType}
                          handleDonate={this.donate}
                          donate={donate}
                          items={items}
                          details={details}
                          onCampaignActions={onCampaignActions}
                          history={history}
                          handleCurrency={this.handleCurrency}
                          location={location}
                        />
                        <Hidden mdUp>
                          <Summary
                            donate={donate}
                            details={details}
                            checkout={this.checkout}
                            currency={currency}
                          />
                        </Hidden>
                      </>
                    ) : null}

                    <img className="camp-img" src={imageURL} alt="Campaign" />
                    <div className="content">{Parser(campiagHtmlContent)}</div>
                  </div>
                  {Object.keys(details).length > 0 && (
                    <CampaignDetails
                      location={location}
                      details={details}
                      donation={total_donation}
                      donors={donors}
                      imageURL={imageURL}
                      history={history}
                      fullTeamsDetails={this.fullTeamsDetails}
                    />
                  )}
                  <div style={{ clear: "both" }} />
                </div>
                {details.status === "completed" && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Confetti {...this.props.size} numberOfPieces={400} />
                  </div>
                )}
                {Object.keys(details).length > 0 &&
                  (details.mode === "e-commerce" ? (
                    <Hidden smDown>
                      <Summary
                        donate={donate}
                        details={details}
                        checkout={this.checkout}
                        currency={currency}
                      />
                    </Hidden>
                  ) : (
                    details.hideCalculator ?
                      new Date() < new Date(details.campaignEndTime) ?
                        <Calculator
                          onCampaignActions={onCampaignActions}
                          history={history}
                          details={details}
                          isBonusRoundActive={isBonusRoundActive}
                        />
                        :
                        null
                      :
                      <Calculator
                        onCampaignActions={onCampaignActions}
                        history={history}
                        details={details}
                        isBonusRoundActive={isBonusRoundActive}
                      />
                  ))
                }
                {details.mode !== "e-commerce" && (
                  details.hideCalculator ?
                    new Date() < new Date(details.campaignEndTime) ?
                      <div className="mobile_donate" onClick={this.openCalculator}>
                        <p style={{ textAlign: "center" }}>DONATE NOW</p>
                      </div>
                      :
                      null
                    :
                    <div className="mobile_donate" onClick={this.openCalculator}>
                      <p style={{ textAlign: "center" }}>DONATE NOW</p>
                    </div>
                )}
                {open && (
                  <PreCampaign
                    onCampaignActions={onCampaignActions}
                    history={history}
                    details={details}
                  />
                )}
                {responsive_calculator && details.mode !== "e-commerce" && (
                  <MobileCalculator
                    history={history}
                    handleClose={this.closeCalculator}
                    open={responsive_calculator}
                    details={details}
                    isBonusRoundActive={isBonusRoundActive}
                  />
                )}

                <Error
                  open={notify}
                  message={error}
                  handleClose={this.handleNotification}
                />
              </ProgressLoader>
            </div>}
          {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}


          {/* ------------------------------------------------------------------------------------------             */}
          {/* Animation campaign code starts-- do not edit this block this was only for animation campaign july 2020--------- */}
          {
            showAnimationCampaign && <div
              className={"campaign_wrapper" + (bonus ? " green-bg" : " blue-bg")}
            >
              <ProgressLoader loadingPercent={loadingPercent}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{details.name}</title>
                  <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="banner">
                  <ImageGallery items={images} onClick={this.openVideoModal} showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false} />;
                </div>

                <div className="banner-for-mobile">
                  <ImageGallery items={mobileBanner} onClick={this.openVideoModal} showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false} />;
                </div>

                {this.state.videoModal ? <div id='description' className="description block description-popup">
                  <span className="btn-close" onClick={this.closeVideoModal}><i className="fa fa-times"></i></span>
                  {video && (
                    <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                  )}
                </div> : ""}
                {this.state.videoModal ? <div className="mask"></div> : ""}

                <div className="wrapper campaign-wrapper">
                  <div className="content">
                    <div id='description' className={details.mode === 'e-commerce' ? 'description instruction' : 'description block'}>
                      {details.mode === 'e-commerce' && <img className='instruction-img' src='/images/instruction.png' alt='camp-img' />}
                      {video && (
                        <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                      )}
                    </div>
                    <div className="image-section" data-aos="zoom-out-right" data-aos-duration="10000">
                      <img src={animationImage} className="animationImage" />
                      <div className="xDollar-outer">
                        <div className="xDollar">
                          {
                            tempAnimationArr.map((item, index) => {
                              return (
                                <span>
                                  <span className="name" title={item.alias == 'Anonymous' ? 'Anonymous' : item.name}>{item.alias == 'Anonymous' ? 'Anonymous' : item.name}</span>
                                  <span className="dollor">${NumberFormat(parseInt(item.amount || 0))}</span>
                                </span>
                              )
                            })
                          }
                        </div>
                      </div>
                      <div className="percentage">
                        <span className="full">100%</span><span>90%</span><span>80%</span><span>70%</span><span>60%</span><span className="half">50%</span><span>40%</span><span>30%</span><span>20%</span><span>10%</span><span>0%</span>
                      </div>
                      <div className="progress-bar"></div>
                      <img style={{ top: `${margins}%` }} className="per-arrow" src={arrow} />
                    </div>

                    <img className="camp-img" src={imageURL} alt="Campaign" data-aos="zoom-out-right" />
                    {/* {powerDonars && powerDonars.length > 0 && (
                  <PowerDonars
                    powerDonars={powerDonars}
                    title={title}
                    details={details}
                    donation={total_donation}
                  />
                )} */}

                    <div className="project-inner-content" style={{ visibility: 'hidden' }}>

                      {title.length < 30 ? (
                        <h1 className="projects-custom-title">{title}</h1>
                      ) : title.length < 35 ? (
                        <h2 className="projects-custom-title">{title}</h2>
                      ) : (
                        <h3 className="projects-custom-title">{title}</h3>
                      )}
                    </div>
                    <div className="mobile-clock">
                      <CampaignGoals details={details} donation={total_donation} sign={sign} />
                      <Progress
                        shares={shares}
                        bonusGoal={details.bonusGoalAmount1}
                        goalAmount={details.goalAmount}
                        socialShareAmount={details.socialShareAmount}
                        donation={total_donation}
                        sign={sign}
                      />
                    </div>
                    {Object.keys(details).length > 0 &&
                      details.mode === "e-commerce" &&
                      items.length > 0 ? (
                      <>
                        <CampaignItems
                          handleChange={this.handleChange}
                          donationType={donationType}
                          handleDonate={this.donate}
                          donate={donate}
                          items={items}
                          details={details}
                          onCampaignActions={onCampaignActions}
                          history={history}
                          handleCurrency={this.handleCurrency}
                          location={location}
                        />
                        <Hidden mdUp>
                          <Summary
                            donate={donate}
                            details={details}
                            checkout={this.checkout}
                            currency={currency}
                          />
                        </Hidden>
                      </>
                    ) : null}

                    {/* <img className="camp-img" src={imageURL} alt="Campaign" /> */}
                    {/* <div className="content">{Parser(campiagHtmlContent)}</div> */}
                  </div>
                  {Object.keys(details).length > 0 && (
                    <CampaignDetails
                      location={location}
                      details={details}
                      donation={total_donation}
                      donors={donors}
                      imageURL={imageURL}
                      history={history}
                      fullTeamsDetails={this.fullTeamsDetails}
                    />
                  )}
                  <div style={{ clear: "both" }} />
                </div>
                {details.status === "completed" && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Confetti {...this.props.size} numberOfPieces={400} />
                  </div>
                )}
                {Object.keys(details).length > 0 &&
                  (details.mode === "e-commerce" ? (
                    <Hidden smDown>
                      <Summary
                        donate={donate}
                        details={details}
                        checkout={this.checkout}
                        currency={currency}
                      />
                    </Hidden>
                  ) : (
                    details.hideCalculator ?
                      new Date() < new Date(details.campaignEndTime) ?
                        <Calculator
                          onCampaignActions={onCampaignActions}
                          history={history}
                          details={details}
                          isBonusRoundActive={isBonusRoundActive}
                        />
                        :
                        null
                      :
                      <Calculator
                        onCampaignActions={onCampaignActions}
                        history={history}
                        details={details}
                        isBonusRoundActive={isBonusRoundActive}
                      />
                  ))
                }
                {details.mode !== "e-commerce" && (
                  details.hideCalculator ?
                    new Date() < new Date(details.campaignEndTime) ?
                      <div className="mobile_donate" onClick={this.openCalculator}>
                        <p style={{ textAlign: "center" }}>DONATE NOW</p>
                      </div>
                      :
                      null
                    :
                    <div className="mobile_donate" onClick={this.openCalculator}>
                      <p style={{ textAlign: "center" }}>DONATE NOW</p>
                    </div>
                )}
                {open && (
                  <PreCampaign
                    onCampaignActions={onCampaignActions}
                    history={history}
                    details={details}
                  />
                )}
                {responsive_calculator && details.mode !== "e-commerce" && (
                  <MobileCalculator
                    history={history}
                    handleClose={this.closeCalculator}
                    open={responsive_calculator}
                    details={details}
                    isBonusRoundActive={isBonusRoundActive}
                  />
                )}

                <Error
                  open={notify}
                  message={error}
                  handleClose={this.handleNotification}
                />
              </ProgressLoader>
            </div>
            // Animation campaign code ends here------------------------------------------
          }
        </div >
      );
    }
  }
);

const mapStateToProps = state => {
  return {
    share: state.share.get("share"),
    campaign: state.campaign.get("campaignData"),
    donations: state.campaign.get("donations"),
    total_donation: state.campaign.get("total_donation"),
    items: state.campaign.get("items")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaign);
