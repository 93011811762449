import React from 'react';

export const MonthlyTotal = ({ donate_amount, duration, currency }) => {

  return (
    <div className='monthly-total'>
      <div className='you-pay'>You Pay</div>
      <div className='calculate'>
        <label>{currency.sign} {donate_amount ? donate_amount :0}</label>
        <span>X</span>
        <label>{duration}</label>
        <p>Months</p>
        <span>=</span>
        <label className='total-amount'>{currency.sign}{donate_amount?donate_amount * duration:0}</label>
      </div>
    </div>
  );
}