import {handleActions} from 'redux-actions';
import * as action from './action';
var request = require('request');

const initialState ={
    activeCampaign: null,
    isFetching : false,
}

const handlers ={
    [action.FETCHING] : (state,action) => ({...state , isFetching : true}),
    [action.SUCCESS] : (state,action) => ({...state , isFetching : false}),
    [action.ERROR] : (state,action) => ({...state , isFetching : false}),
    [action.ACTIVE_CAMPAIGN] :  (state,action) => ({...state,activeCampaign : action.payload})
}

export default handleActions(handlers,initialState)