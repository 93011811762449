import React from "react";
import { NumberFormat } from "../../Common/NumberFormat";
import { LetestTimer } from "./latestTimer";
import "./progress.scss";

export const GoalCaption = ({ details, donation, sign, showLatestUi, donors }) => {
    const timeRemaining = new Date(details.campaignEndTime) - new Date();
    const bonusEnd = new Date(details.bonusGoalEndDate) - new Date();
    const bonus = donation > parseInt(details.goalAmount) && timeRemaining <= 0
    let currency = 'USD';
    const themeColor = details && details.themeColor || "#336DFF";
    if (details.campaignCurrency) {
        currency = details.campaignCurrency ? details.campaignCurrency.toUpperCase() : details.currency[0].code.toUpperCase();
    }

    return (
        <section className="heart-goals">
            {/* {(timeRemaining <= 0 && bonusEnd <= 0 && bonus) || (timeRemaining <= 0 && !bonus) ? (
                <div className="bouns_goal_div">
                    <img src="/images/successful.png" alt="Success" />
                </div>
            ) : (
                    // <Goals
                    //   startTime={details.campaignStartTime}
                    //   endTime={details.campaignEndTime}
                    //   bonus={bonus}
                    //   bonusEnd={details.bonusGoalEndDate}
                    // />

                    (Object.keys(details).length) && (!details.enableNoTimeCampaign) ? <LetestTimer
                        startTime={details.campaignStartTime}
                        endTime={details.campaignEndTime}
                        bonus={bonus}
                        bonusEnd={details.bonusGoalEndDate}
                        showLatestUi={showLatestUi}
                    /> : null




                )} */}
            {/* {(timeRemaining >=0 )&& (donation > parseInt(details.goalAmount)) && details.bonusGoalAmount1 && ( */}
            {(donation > parseInt(details.goalAmount)) && details.bonusGoalAmount1 && (
                <p className="goal-point bouns-goal-point">
                    <span>
                        <span className="left-span">GOAL</span> | <span className="amount" style={{ 'color': themeColor }}>{sign}{NumberFormat(details.bonusGoalAmount1)} {currency}</span>
                    </span>
                </p>
            )}
            <p className="goal-point goal-point-bouns">
                <span className={bonus && "check"}>
                    <span className="left-span">BONUS GOAL</span> | <span className="amount" style={{ 'color': themeColor }}>{sign}{NumberFormat(details.goalAmount)} {currency}</span>
                </span>{" "}
            </p>

            <p className="goal-point goal-point-bouns donors-goal">
                <span className={bonus && "check"}>
                    <span className="left-span">DONORS</span> | <span className="amount" style={{ 'color': themeColor }}>{donors && donors.length}</span>
                </span>{" "}
            </p>
        </section>
    );
};
