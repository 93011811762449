import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as shareActions from '../../redux/modules/share';
import { Goals } from './Goals';
import { Progress } from '../CampaignDetails/Progress';
import { CampaignGoal } from './CampaignGoal';
import { DonorList } from './DonorList';
import { SocialGoals } from '../CampaignDetails/SocialGoals';
import { NumberFormat } from '../../Common/NumberFormat';
import { getSign } from "../../Common/getSign";

class TeamDetails extends Component {
  constructor() {
    super();

    this.state = {
      details: {},
      team: {},
      donation: 0,
      team_donation: 0,
      shares: {}
    }
  }

  componentWillMount() {
    const { details, team, donation, onShareActions } = this.props;
    if (details) {
      this.setState({ details, team, donation });
      const { organizationId, campaignId } = details;
      onShareActions.getShareCount({ organization: organizationId, campaign: campaignId });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { details, team, donation, team_donation, donors, share } = nextProps;
    if (details) {
      this.setState({ details, team, donation, team_donation, donors, shares: share.data || {} });
    }
  }

  handleShare = () => {
    const { details, onShareActions } = this.props;
    const { organizationId, campaignId } = details;

    onShareActions.updateShareCount({ organizationId, campaignId })
      .then(res => {
        onShareActions.getShareCount({
          organization: organizationId,
          campaign: campaignId
        });
      });
  }

  render() {
    const { details, team, donation, team_donation, donors, shares } = this.state;
    const { donationDetails } = this.props;
    const sign = getSign(details.campaignCurrency || details.currency);

    return (
      <aside id="project-sidebar">
        {/* <Goals startTime={details.campaignStartTime} endTime={details.campaignEndTime} /> */}
        {(Object.keys(details).length) && (!details.enableNoTimeCampaign) ? <Goals startTime={details.campaignStartTime} endTime={details.campaignEndTime} /> : null}



        <p className="goal">
          <strong>Team Goal {sign}{NumberFormat(team.goalAmount)} &nbsp;</strong>
          <span>{details.campaignCurrency ? details.campaignCurrency.toUpperCase() : details.currency.toUpperCase()}</span>
        </p>
        <Progress goalAmount={team.goalAmount} donation={team_donation} sign={sign} />
        <CampaignGoal shares={shares} goal={details.goalAmount} donation={donation} sign={sign} socialShareAmount={details.socialShareAmount} />
        <img className='team-img-mobile' src={details.mainContent && details.mainContent.imageURL} alt='Team' />
        <SocialGoals shares={shares} details={details} handleShare={this.handleShare} sign={sign} />
        <DonorList id={team.id} donors={donors} donationDetails={donationDetails} sign={sign} campaignCurrency={details.campaignCurrency} />
      </aside>
    )
  }
}

const mapStateToProps = state => {
  return {
    share: state.share.get('share')
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onShareActions: bindActionCreators(shareActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);
